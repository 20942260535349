// Services
import { quotationApi, quotationConstants } from "./index";

import { modalsActions } from "../modals/modals.actions";
import { genericErrorHandler } from "@next/utils/apiUtils";
import { enqueueSnackbar } from "notistack";

export const quotationActions = {
  getQuotationDetails,
  setIsEditQuotationMode,
  editQuotation,
  setLeadTimeOptions,
};

function getQuotationDetails(token, quotationId) {
  return (dispatch) => {
    dispatch(request());
    quotationApi.getQuotationDetails(token, quotationId).then(
      (quotationDetails) => {
        dispatch(success(quotationDetails));
      },
      (error) => {
        dispatch(failure(error?.toString()));
      }
    );
  };

  function request() {
    return { type: quotationConstants.FETCH_QUOTATION_DETAILS_REQUEST };
  }
  function success(quotationDetails) {
    return {
      type: quotationConstants.FETCH_QUOTATION_DETAILS_SUCCESS,
      quotationDetails,
    };
  }
  function failure(error) {
    return {
      type: quotationConstants.FETCH_QUOTATION_DETAILS_FAILURE,
      error,
    };
  }
}

function setIsEditQuotationMode(isEditQuotationMode) {
  return {
    type: quotationConstants.SET_IS_EDIT_QUOTATION_MODE,
    isEditQuotationMode,
  };
}

/**
 * Redux Action to Dispatch a query for EDIT  Quotation's belonging to a certain company
 *
 * @param {*} token - Token For User Authentication
 * @param {*} quote - the object quote to send to backend
 */
function editQuotation(token, quote, quoteId, t) {
  const { hideModal } = modalsActions;

  return (dispatch) => {
    dispatch(request());
    quotationApi.editQuotation(token, quote, quoteId).then(
      (quote) => {
        enqueueSnackbar(t("quotation:yourQuotationHasBeenUpdated"), {
          variant: "success",
        });
        dispatch(success(quote));
        //close modal and redirect to suppliers page after EDIT quotation success
        dispatch(hideModal("isEditQuotationDialogOpen"));
      },
      (error) => {
        dispatch(failure(error?.toString()));
        genericErrorHandler(error);
      }
    );
  };

  function request() {
    return { type: quotationConstants.EDIT_QUOTATION_REQUEST };
  }
  function success(quote) {
    return { type: quotationConstants.EDIT_QUOTATION_SUCCESS, quote };
  }
  function failure(error) {
    return { type: quotationConstants.EDIT_QUOTATION_FAILURE, error };
  }
}
/**
 * Redux Action to Dispatch a query for set leadTime option
 *
 * @param {number} numberOfDay - number of day
 * @param {function} t - i18n translation function
 */
function setLeadTimeOptions(numberOfDay) {
  return (dispatch) => {
    quotationApi.setLeadTimeOptions(numberOfDay).then((leadTimeOptions) => {
      dispatch(success(leadTimeOptions));
    });
  };

  function success(leadTimeOptions) {
    return {
      type: quotationConstants.SET_LEAD_TIME_OPTIONS_SUCCESS,
      leadTimeOptions,
    };
  }
}
