import { Box, Theme, Tooltip } from "@mui/material";
import { CircularAvatarsList } from "@next/components/circular-avatars-list";
import { getUserDisplayName } from "@next/modules/profile/helpers/profile-helpers";
import { useRFQCollaborators } from "@next/modules/workspace/hooks/useRFQCollaborators";
import React, { ComponentProps, useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import PersonAddAlt1Icon from "@mui/icons-material/PersonAddAlt1";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import { AvatarSizes } from "@next/components/generic-avatar/generic-avatar";
import { useDispatch } from "react-redux";
import { modalsActions } from "@next/redux/modalsSlices";
import { SharedModalTypes } from "@next/modals/types";
import { getCollaboratorRoleTag } from "@next/modules/workspace/helpers/rfq-collaborators.helpers";
import CustomIconButton from "@next/components/custom-icon-button/custom-icon-button";
import { GenericUser } from "@next/modules/profile/redux";
import { createStyles, makeStyles } from "@mui/styles";
import CustomButton from "@next/components/custom-button";

type StyleProps = {
  size?: keyof typeof AvatarSizes;
};

const useStyles = makeStyles<Theme, StyleProps>((theme) => {
  return createStyles({
    addBtn: {
      width: 24,
      height: 24,
      minWidth: "unset",
      padding: "16px",
      marginLeft: theme.spacing(1),
    },
    addIcon: {
      fontSize: "1.5rem",
      color: "black",
    },
  });
});
export type ColloboratorListData = {
  collaborators?: GenericUser[];
  rfqPk?: number;
  createdBy?: GenericUser;
  poId?: number;
  draftId?: number;
};
interface Props {
  data: ColloboratorListData;
  size: ComponentProps<typeof CircularAvatarsList>["size"];
  editMode?: boolean;
  onRfqForm?: boolean;
  disableShareCollaboratorOnRfqForm?: boolean;
}

const CollaboratorsList: React.FC<Props> = ({
  data,
  size,
  editMode,
  onRfqForm,
  disableShareCollaboratorOnRfqForm,
}) => {
  const { collaborators, rfqPk, createdBy, poId, draftId } = data;
  const { owner, collaborators: _collaborators } = useRFQCollaborators(
    collaborators,
    createdBy
  );
  const { t } = useTranslation();
  const classes = useStyles({ size });
  const dispatch = useDispatch();

  const users = useMemo(
    () => (owner ? [owner, ..._collaborators] : _collaborators),
    [owner, _collaborators]
  );

  const onAdd = useCallback(() => {
    dispatch(
      modalsActions.showModal({
        key: SharedModalTypes.ADD_COLLABORATOR_MODAL,
        data: {
          collaborators,
          rfqPk,
          poId,
          createdBy,
          refetchData: false,
          draftId,
        },
      })
    );
  }, [collaborators, rfqPk, createdBy, poId, draftId]);

  return (
    <Box display="flex" alignItems="center">
      <CircularAvatarsList
        images={users.map((item) => ({
          name: getUserDisplayName(item) || item?.email,
          tooltip: `${
            getUserDisplayName(item) || item?.email
          } (${getCollaboratorRoleTag(item, owner, t)})`,
          url: item?.picture,
        }))}
        overlapping
        combinedTooltip
        size={size}
      />

      {editMode ? (
        <>
          <Tooltip title={t("project:table:row:addCollaborator")}>
            <CustomIconButton onClick={onAdd} className={classes.addBtn}>
              <GroupAddIcon className={classes.addIcon} />
            </CustomIconButton>
          </Tooltip>
        </>
      ) : null}

      {onRfqForm ? (
        <CustomButton
          onClick={onAdd}
          variant="outlined"
          startIcon={<PersonAddAlt1Icon />}
          sx={{ ml: 1 }}
          size="large"
          disabled={disableShareCollaboratorOnRfqForm}
        >
          {t("common:share")}
        </CustomButton>
      ) : null}
    </Box>
  );
};

export default CollaboratorsList;
