import React, { useCallback, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { modalsActions } from "@next/redux/modalsSlices";
import { SupplierContactsListProps } from "../components/supplier-contacts-list/supplier-contacts-list";
import { Form, Formik } from "formik";
import { Box, Divider, Typography } from "@mui/material";
import UsersPaginatedAutoComplete from "../components/rfq-creation-form/users-paginated-autocomplete";
import CustomButton from "@next/components/custom-button";
import { workspaceNextActions } from "../redux";
import { selectSupplierContactsList } from "../redux/selectors";
import { GenericUser } from "@next/modules/profile/redux";
import { SharedModalTypes } from "@next/modals/types";

export const SupplierContactsForm: React.FC<SupplierContactsListProps> = ({
  supplier,
  users,
  onValidate,
  loadingSelector,
}) => {
  const submitLoading = useSelector(loadingSelector || (() => false));
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const targetProfiles = useRef<GenericUser[]>(users || []);
  const setFieldValueRef = useRef<null | Function>(null);
  const options = useSelector(selectSupplierContactsList);

  useEffect(() => {
    dispatch(
      workspaceNextActions.fetchSupplierContactsListRequest(
        supplier?.pk || (supplier?.id as number)
      )
    );
  }, []);

  const onInviteSuccess = useCallback(
    (newUser) => {
      if (typeof setFieldValueRef.current === "function")
        setFieldValueRef.current("target_profiles", [
          ...targetProfiles.current,
          newUser,
        ]);
    },
    [setFieldValueRef.current, targetProfiles.current]
  );

  return (
    <Formik
      initialValues={{ target_profiles: users || [] }}
      onSubmit={(values) => onValidate && onValidate(values.target_profiles)}
    >
      {({ values, setFieldValue }) => {
        targetProfiles.current = values.target_profiles;
        setFieldValueRef.current = setFieldValue;
        return (
          <Form>
            <Box mb={2} mt={1}>
              <Typography variant="body1">
                {t("workspaceNext:supplierContactsList:manageModal:content", {
                  company: supplier?.name,
                })}
              </Typography>
            </Box>
            <UsersPaginatedAutoComplete
              fieldName="target_profiles"
              placeholder={t(
                "workspaceNext:supplierContactsList:manageModal:searchByNameOrEmail"
              )}
              loading={submitLoading}
              valueKey={undefined}
              options={options}
              allowInvite
              onInvite={(email) => {
                dispatch(
                  modalsActions.showModal({
                    key: SharedModalTypes.INVITE_SUPPLIER_CONTACT_MODAL,
                    data: {
                      email,
                      onSuccess: onInviteSuccess,
                      supplierId: supplier?.pk || supplier?.id,
                    },
                  })
                );
              }}
            />
            <Box mt={3} mb={2} mx={-2}>
              <Divider />
            </Box>
            <Box display="flex" justifyContent="flex-end">
              <CustomButton
                loading={submitLoading}
                disabled={submitLoading}
                type="submit"
                color="primary"
                variant="contained"
              >
                {t("workspaceNext:supplierContactsList:manageModal:submit")}
              </CustomButton>
            </Box>
          </Form>
        );
      }}
    </Formik>
  );
};
