import { Box, Typography } from "@mui/material";
import { workspaceNextActions } from "@next/modules/workspace/redux";
import { selectShowRevisionStatusBar } from "@next/modules/workspace/redux/selectors";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import RFQPartsStatusBar from "./rfq-parts-status-bar";
import { RFQPartsTable } from "@next/modules/project/components/part/rfq-part-table";
import { useHasCompanyRole } from "@next/hooks/useHasCompanyRole";
import { useTranslation } from "react-i18next";

export const BuyerRfqsInformationPartList = () => {
  const { t } = useTranslation();
  const showRevisionStatusBar = useSelector(selectShowRevisionStatusBar);
  const dispatch = useDispatch();
  const hasViewerRole = useHasCompanyRole(["viewer"]);

  const setShowRevisionStatusBar = (val: any) => {
    dispatch(workspaceNextActions.setShowRevisionStatusBar(val));
  };

  return (
    <Box>
      <Box display="flex" alignItems="center" mb={2}>
        <Box mr={2}>
          <Typography variant="h6">{t("rfq:rfqInfosPartTitle")}</Typography>
        </Box>
      </Box>
      <Box mb={1.5}>
        <RFQPartsStatusBar
          showRevisionPrompt={showRevisionStatusBar}
          onDismiss={() => setShowRevisionStatusBar(false)}
        />
      </Box>
      <RFQPartsTable
        readOnly={hasViewerRole}
        onEditStart={() => setShowRevisionStatusBar(true)}
      />
    </Box>
  );
};
