import { GridSortModel } from "@mui/x-data-grid-pro";
import {
  BuyerRFQSTableField,
  BuyerRFQsTableSortField,
} from "./buyer-rfqs-table.types";

export const getSortQueryString = (sortModel: GridSortModel) => {
  //disallow multiple sort by taking first item only
  const item = sortModel[0];
  if (!item) return "";

  let queryString = "ordering=";
  const directionStr = item.sort === "desc" ? "-" : "";

  switch (item.field) {
    case BuyerRFQSTableField.RFQ_DISPLAY_NAME:
      queryString += `${directionStr}${BuyerRFQsTableSortField.RFQ_NUMBER},${directionStr}${BuyerRFQsTableSortField.RFQ_NAME}`;
      break;
    case BuyerRFQSTableField.PARTS:
      queryString += `${directionStr}${BuyerRFQsTableSortField.PARTS_COUNT}`;
      break;
    case BuyerRFQSTableField.USERS:
      queryString += `${directionStr}${BuyerRFQsTableSortField.USERS}`;
      break;
    case BuyerRFQSTableField.CREATED_ON:
      queryString += `${directionStr}${BuyerRFQsTableSortField.CREATED_AT}`;
      break;
    case BuyerRFQSTableField.DEADLINE:
      queryString += `${directionStr}${BuyerRFQsTableSortField.DEADLINE}`;
      break;
  }

  return queryString;
};
