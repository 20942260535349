import React from "react";

import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Formik } from "formik";

import { ContactSuppliersFormValues } from "./contact-suppliers.form.types";
import ContactSuppliersFormBase from "./contact-suppliers.from.base";
import { modalsActions } from "@next/redux/modalsSlices";
import { useAxyaTracking } from "@next/hooks/useAxyaTracking";
import { WorkspaceModalTypes } from "../../modals/types";

import {
  getContractDetails,
  getRfqDetails,
} from "services/workspace/workspace.selectors";
import {
  getTargetedSupplierIds,
  validationSchema,
} from "./contact-suppliers.definitions";
import { enqueueSnackbar } from "notistack";

interface Props {
  supplierId?: number;
}

const ContactSuppliersForm: React.FC<Props> = ({ supplierId }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const axyaTracking = useAxyaTracking();
  const rfqDetails = useSelector(getRfqDetails);
  const contractDetails = useSelector(getContractDetails);
  const details = rfqDetails || contractDetails;

  const allSuppliers = [
    ...(details?.targeted_suppliers || []),
    ...(details?.marketplace_suppliers || []),
  ];
  const supplier = allSuppliers.find((s) => s.id === supplierId)?.name;

  const initialValues: ContactSuppliersFormValues = {
    suppliers: supplier ? [supplier] : [],
    body: "",
  };

  const handleContactSuppliers = (values: ContactSuppliersFormValues) => {
    const suppliersIds = getTargetedSupplierIds({
      targetedSuppliersName: values.suppliers,
      targetedSuppliers: allSuppliers,
    });

    axyaTracking({
      app: "axya-app",
      scout_category: "qa",
      scout_feature_name: "create_qa_on_buyer_side",
      scout_rfq_pk: details?.pk,
      scout_rfq_name: details?.rfq_display_name,
      data: {
        suppliers: suppliersIds,
        body: values.body,
      },
    });

    enqueueSnackbar(`${t("workspaceNext:contactSuppliersModal:success")}`, {
      variant: "success",
      autoHideDuration: 6000,
    });

    dispatch(
      modalsActions.closeModal(WorkspaceModalTypes.CONTACT_SUPPLIERS_MODAL)
    );
  };

  return (
    <>
      <Formik
        render={(props) => (
          <ContactSuppliersFormBase
            {...props}
            suppliers={allSuppliers}
            disabled={!!supplier}
          />
        )}
        initialValues={initialValues}
        onSubmit={handleContactSuppliers}
        validationSchema={validationSchema}
      />
    </>
  );
};

export default ContactSuppliersForm;
