import { Box, Theme } from "@mui/material";
import CurrencyField from "components/common/fields/CurrencyField";
import React from "react";
import SyncAltIcon from "@mui/icons-material/SyncAlt";
import { CustomTypography } from "@next/components/custom-typography";
import {
  CurrencyConversion,
  generateExchangeRateTooltipText,
} from "./quote-total-price.utils";
import { createStyles, makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: { cursor: "pointer" },
    syncIcon: {
      color: theme.palette.text.disabled,
      width: "19.15px",
      marginRight: "5px",
    },
    text: {
      display: "flex",
      marginTop: "5px",
    },
  })
);

type QuoteTotalPriceProps = {
  price?: number;
  currency?: string;
  currencyConversion?: CurrencyConversion;
};

export const QuoteTotalPrice: React.FC<QuoteTotalPriceProps> = ({
  price,
  currency,
  currencyConversion,
}) => {
  const classes = useStyles();
  const tooltipText = generateExchangeRateTooltipText(
    currency || "",
    currencyConversion || {}
  );

  return (
    <Box display="flex" flexDirection="column" className={classes.root}>
      {price != null ? (
        <>
          <CurrencyField value={price} currency={currency} />

          {currency === "USD" && currencyConversion?.calculated_price ? (
            <CustomTypography
              variant="body1"
              tooltipTitle={tooltipText}
              className={classes.text}
            >
              <SyncAltIcon className={classes.syncIcon} />

              <CurrencyField
                value={currencyConversion?.calculated_price}
                currency={currencyConversion?.key}
              />
            </CustomTypography>
          ) : null}
        </>
      ) : (
        <>
          <span className="blurred-content"></span>
          {currency}
        </>
      )}
    </Box>
  );
};
