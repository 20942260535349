import { put, takeEvery, takeLatest } from "redux-saga/effects";
import { PayloadAction } from "@reduxjs/toolkit";

import { safeCall } from "@next/redux/safeCall";
import {
  CustomQuotationExportInput,
  CustomQuotationImportInput,
  IntentToNoQuoteInput,
  PublicItbItnbRequestInput,
  RequestStatus,
  marketplaceActions,
} from ".";
import { marketplaceService } from "../services/marketplaceService";
import { downloadFileNext } from "@next/utils/fileUtils";
import { enqueueSnackbar } from "notistack";
import { frontendUrl } from "urls";
import { t } from "assets/configi18n/i18n";
import { history } from "helpers/history";
import { marketplaceConstants } from "services/marketplace";
import QueryString from "qs";

function* handleCustomQuotationExportRequest({
  payload,
}: PayloadAction<CustomQuotationExportInput>) {
  const { response, error } = yield safeCall(
    marketplaceService.customQuotationExport,
    payload
  );

  if (error) {
    yield put(marketplaceActions.customQuotationExportFailure(error));
  } else {
    if (response.data instanceof Blob) {
      downloadFileNext(response.data, payload?.fileName, "xlsx");

      yield put(marketplaceActions.customQuotationExportSuccess());
    } else {
      yield put(marketplaceActions.customQuotationExportFailure(error));
    }
  }
}

function* handleCustomQuotationImportRequest({
  payload,
}: PayloadAction<CustomQuotationImportInput>) {
  const { response, error } = yield safeCall(
    marketplaceService.customQuotationImport,
    payload
  );

  if (error) {
    yield put(marketplaceActions.customQuotationImportFailure(error));
  } else {
    if (response?.data instanceof Array) {
      yield put(
        marketplaceActions.customQuotationImportSuccess(response?.data)
      );
    } else {
      yield put(marketplaceActions.customQuotationImportFailure(error));
    }
  }
}
function* handleIntentToNoQuoteRequest({
  payload,
}: PayloadAction<IntentToNoQuoteInput>) {
  const { response, error } = yield safeCall(
    marketplaceService.intentToNoQuote,
    payload
  );

  if (error) {
    yield put(marketplaceActions.intentToNoQuoteFailure(error));
  } else {
    yield put(
      marketplaceActions.intentToNoQuoteSuccess({
        data: response.data,
        input: payload,
      })
    );
    if (typeof payload.onSuccess === "function") payload.onSuccess();
    if (payload.noQuoteReason) {
      yield enqueueSnackbar(t("workspaceNext:buyerRfqs:rfqMovedToNoQuoteTab"), {
        buttonCallback: () => {
          history.push(
            `${frontendUrl.marketplace}?${QueryString.stringify({
              ...QueryString.parse(window.location.search),
              status: RequestStatus.NOT_INTERESTED,
            })}`
          );
        },
        buttonText: t("workspaceNext:buyerRfqs:goToTab") as string,
      });
    }
  }
}

function* handleSavePublicItbItnbRequest({
  payload,
}: PayloadAction<PublicItbItnbRequestInput>) {
  const { error } = yield safeCall(
    marketplaceService.savePublicItbItnb,
    payload
  );

  if (error) {
    yield put(marketplaceActions.savePublicItbItnbFailure(error));
  } else {
    yield put(marketplaceActions.savePublicItbItnbSuccess());
  }
}

function* hanldeItbItnbSuccess(action: any) {
  let rfqId: string, itbItnbType: string, deletion: boolean;
  if (action.type === marketplaceConstants.SET_INTENT_TO_BID_DUE_DATE_SUCCESS) {
    rfqId = action.prefs.rfq_pk;
    itbItnbType = "itb";
    deletion = !action.prefs.due_date;
  } else {
    rfqId = action.payload.input.rfqId;
    itbItnbType = "itnb";
    deletion = !action.payload.input.noQuoteReason;
  }

  const status =
    QueryString.parse(window.location.search).status || RequestStatus.ALL;
  yield put({
    type: marketplaceConstants.ITN_ITNB_SUCCESS,
    payload: {
      rfqId,
      itbItnbType,
      deletion,
      status,
    },
  });
}

export function* marketplaceSaga() {
  yield takeLatest(
    marketplaceActions.customQuotationExportRequest,
    handleCustomQuotationExportRequest
  );

  yield takeLatest(
    marketplaceActions.customQuotationImportRequest,
    handleCustomQuotationImportRequest
  );
  yield takeLatest(
    marketplaceActions.intentToNoQuoteRequest,
    handleIntentToNoQuoteRequest
  );
  yield takeLatest(
    marketplaceActions.savePublicItbItnbRequest,
    handleSavePublicItbItnbRequest
  );

  yield takeEvery(
    [
      marketplaceActions.intentToNoQuoteSuccess,
      marketplaceConstants.SET_INTENT_TO_BID_DUE_DATE_SUCCESS,
    ],
    hanldeItbItnbSuccess
  );
}
