import React from "react";
import { useTranslation } from "react-i18next";

import { Box, Button } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { CustomGridToolbarImport } from "@next/components/custom-grid-toolbar-import";
import ExportMenu from "../export-menu";

import { useParams } from "react-router-dom";
import {
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
} from "@mui/x-data-grid-pro-v5";

type Props = {
  onClickAddPart: () => void;
  onImport: (fileResults?: any) => void;
  onClickOnBulkImport: () => void;
};

export const ProjectPartTableToolbar: React.FC<Props> = ({
  onClickAddPart,
  onImport,
  onClickOnBulkImport,
}) => {
  const { t } = useTranslation();

  const { code: projectCode = "" } = useParams<{ code: string }>();

  return (
    <Box style={{ display: "flex", alignItems: "center", height: "50px" }}>
      <Button
        variant="text"
        color="inherit"
        onClick={onClickAddPart}
        startIcon={<AddIcon />}
      >
        {t("project:navbar:button:addPart")}
      </Button>
      <GridToolbarColumnsButton
        onResize={undefined}
        onResizeCapture={undefined}
        color="inherit"
      />
      <GridToolbarFilterButton
        onResize={undefined}
        onResizeCapture={undefined}
        color="inherit"
      />
      <ExportMenu isInDetail={true} projectCodes={[projectCode]} />

      <CustomGridToolbarImport
        importButtonTitle={t("project:table:toolbar:importParts")}
        bulkImportButtonTitle={t("project:table:toolbar:bulkFileImport")}
        onClickOnBulkImport={onClickOnBulkImport}
        onImport={onImport}
      />
    </Box>
  );
};
