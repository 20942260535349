// Services
import { marketplaceApi, marketplaceConstants } from "./index";

// Helper functions
import { history } from "../../helpers/history";

// Urls for redirection
import { frontendUrl } from "../../urls";

import { modalsActions } from "../modals/modals.actions";

import { t } from "assets/configi18n/i18n";
import { enqueueSnackbar } from "notistack";
import { genericErrorHandler } from "@next/utils/apiUtils";
import queryString from "querystring";
import { RequestStatus } from "@next/modules/marketplace/redux";

export const marketplaceActions = {
  fetchActiveRFQList,
  fetchRecommendedRFQList,
  marketplaceLikeDislikeRecommend,
  createQuotation,
  fetchCompanyQuotations,
  fetchCompanyContractList,
  getActiveRfqDetails,
  fetchHiddenRFQList,
  marketplacePreferences,
  getRFQStats,
  setSelectedTabInSubmissions,
  setSellerNdaAttachments,
  clearActiveRfqDetails,
  setRfqHasNDA,
  setRfqDetailsLoading,
  setNDAStepperIndex,
  getBuyerStatsByRfqId,
  fetchIntentToBidRFQList,
  setIntentToBidDueDate,
  setIntentToNoBidReasonData,
  incrementRfqQuestionsCount,
  decrementRFQNewQaMsgCount,
};

/**
 * Redux Action to Dispatch a query for a subset of RFQ's belonging to a active status
 * @param {*} token - Token For User Authentication
 *
 */
function fetchActiveRFQList(token, queryParams) {
  return (dispatch) => {
    dispatch(request());
    marketplaceApi.fetchActiveRFQList(token, queryParams).then(
      (data) => {
        const pathParams = new URLSearchParams(window.location.search);
        const apiParams = new URLSearchParams("?" + queryParams);
        const isPathPremium = pathParams.get("type") === "premium";
        const isApiPremium = apiParams.get("is_private") === "false";

        /* explanation: if query doesent match with the selected request type(coming from search Param), 
           then we should not store the results in state */
        if (!(isPathPremium ^ isApiPremium)) dispatch(success(data));
      },
      (error) => {
        dispatch(failure(error?.toString()));
      }
    );
  };

  function request() {
    return { type: marketplaceConstants.FETCH_ACTIVE_RFQLIST_REQUEST };
  }
  function success(data) {
    return Array.isArray(data.results)
      ? {
          type: marketplaceConstants.FETCH_ACTIVE_RFQLIST_SUCCESS,
          activeRFQList: data.results,
          count: {
            total: data.count,
            itb: data.count_itb,
            itnb: data.count_itnb,
          },
          next: data.next,
        }
      : {
          activeRFQList: data,
        };
  }
  function failure(error) {
    return { type: marketplaceConstants.FETCH_ACTIVE_RFQLIST_FAILURE, error };
  }
}

function fetchRecommendedRFQList() {
  return (dispatch) => {
    dispatch(request());
    marketplaceApi.fetchRecommendedRFQList().then(
      (recommendedRFQList) => {
        dispatch(success(recommendedRFQList));
      },
      (error) => {
        dispatch(failure(error?.toString()));
      }
    );
  };

  function request() {
    return { type: marketplaceConstants.FETCH_RECOMMENDED_RFQLIST_REQUEST };
  }
  function success(recommendedRFQList) {
    return {
      type: marketplaceConstants.FETCH_RECOMMENDED_RFQLIST_SUCCESS,
      recommendedRFQList,
    };
  }
  function failure(error) {
    return {
      type: marketplaceConstants.FETCH_RECOMMENDED_RFQLIST_FAILURE,
      error,
    };
  }
}

function fetchHiddenRFQList(token) {
  return (dispatch) => {
    dispatch(request());
    marketplaceApi.fetchHiddenRFQList(token).then(
      (hiddenRFQList) => {
        dispatch(success(hiddenRFQList));
      },
      (error) => {
        dispatch(failure(error?.toString()));
      }
    );
  };

  function request() {
    return { type: marketplaceConstants.FETCH_HIDDEN_RFQLIST_REQUEST };
  }
  function success(hiddenRFQList) {
    return {
      type: marketplaceConstants.FETCH_HIDDEN_RFQLIST_SUCCESS,
      hiddenRFQList,
    };
  }
  function failure(error) {
    return { type: marketplaceConstants.FETCH_HIDDEN_RFQLIST_FAILURE, error };
  }
}

function fetchIntentToBidRFQList(token) {
  return (dispatch) => {
    dispatch(request());
    marketplaceApi.fetchIntentToBidRFQList(token).then(
      (intentToBidRFQList) => {
        dispatch(success(intentToBidRFQList));
      },
      (error) => {
        dispatch(failure(error?.toString()));
      }
    );
  };

  function request() {
    return { type: marketplaceConstants.FETCH_INTENT_TO_BID_RFQLIST_REQUEST };
  }
  function success(intentToBidRFQList) {
    return {
      type: marketplaceConstants.FETCH_INTENT_TO_BID_RFQLIST_SUCCESS,
      intentToBidRFQList,
    };
  }
  function failure(error) {
    return {
      type: marketplaceConstants.FETCH_INTENT_TO_BID_RFQLIST_FAILURE,
      error,
    };
  }
}

function getActiveRfqDetails(rfqId) {
  return (dispatch, getState) => {
    dispatch(request());
    marketplaceApi.getActiveRfqDetails(getState().profile.token, rfqId).then(
      (activeRfqDetails) => {
        dispatch(success(activeRfqDetails));
      },
      (error) => {
        dispatch(failure(error?.toString()));
      }
    );
  };

  function request() {
    return { type: marketplaceConstants.FETCH_ACTIVE_RFQ_DETAILS_REQUEST };
  }
  function success(activeRfqDetails) {
    return {
      type: marketplaceConstants.FETCH_ACTIVE_RFQ_DETAILS_SUCCESS,
      activeRfqDetails,
    };
  }
  function failure(error) {
    return {
      type: marketplaceConstants.FETCH_ACTIVE_RFQ_DETAILS_FAILURE,
      error,
    };
  }
}

/**
 * Redux Action to Dispatch a query for create  Quotation's belonging to a certain company
 *
 * @param {*} token - Token For User Authentication
 * @param {*} quote - the object quote to send to backend
 */
function createQuotation(token, quote, t, onSuccess) {
  const { hideModal } = modalsActions;

  return (dispatch) => {
    dispatch(request());
    marketplaceApi.createQuotation(token, quote).then(
      (quote) => {
        enqueueSnackbar(t("yourOfferHasBeenSent"), { variant: "success" });
        dispatch(success(quote));
        if (typeof onSuccess === "function") onSuccess();
        //close modal and redirect to suppliers page after create quotation success
        history.push(frontendUrl.supplierQuotes);
      },
      (error) => {
        dispatch(failure(error?.toString()));
        genericErrorHandler(error);
      }
    );
  };

  function request() {
    return { type: marketplaceConstants.CREATE_QUOTATION_REQUEST };
  }
  function success(quote) {
    return { type: marketplaceConstants.CREATE_QUOTATION_SUCCESS, quote };
  }
  function failure(error) {
    return { type: marketplaceConstants.CREATE_QUOTATION_FAILURE, error };
  }
}

/**
 * Redux Action to Dispatch a query for a subset of Quotation's belonging to a certain company
 *
 * @param {*} token - Token For User Authentication
 * @param {*} companyId - The Company ID  of the company we want to fetch the Quotation
 */
function fetchCompanyQuotations(token, status) {
  return (dispatch) => {
    dispatch(request());
    marketplaceApi.fetchCompanyQuotations(token, status).then(
      (quoteListOfCompany) => {
        dispatch(success(quoteListOfCompany));
      },
      (error) => {
        dispatch(failure(error?.toString()));
        genericErrorHandler(error);
      }
    );
  };

  function request() {
    return { type: marketplaceConstants.FETCH_COMPANY_QUOTATIONS_FAILURE };
  }
  function success(quoteListOfCompany) {
    return {
      type: marketplaceConstants.FETCH_COMPANY_QUOTATIONS_SUCCESS,
      quoteListOfCompany,
    };
  }
  function failure(error) {
    return {
      type: marketplaceConstants.FETCH_COMPANY_QUOTATIONS_FAILURE,
      error,
    };
  }
}

/**
 * Redux Action to Dispatch a query for a subset of RFQ's belonging to a certain SUPPLIER company
 *
 * @param {*} token - Token For User Authentication
 * @param {*} winnerId - The Company ID  of the company we want to fetch the RFQList
 */
function fetchCompanyContractList(token, winnerId) {
  return (dispatch) => {
    dispatch(request());
    marketplaceApi.fetchCompanyContractList(token, winnerId).then(
      (contractListOfCompany) => {
        dispatch(success(contractListOfCompany));
      },
      (error) => {
        dispatch(failure(error?.toString()));
        genericErrorHandler(error);
      }
    );
  };

  function request() {
    return { type: marketplaceConstants.FETCH_COMPANY_CONTRACTS_REQUEST };
  }
  function success(contractListOfCompany) {
    return {
      type: marketplaceConstants.FETCH_COMPANY_CONTRACTS_SUCCESS,
      contractListOfCompany,
    };
  }
  function failure(error) {
    return {
      type: marketplaceConstants.FETCH_COMPANY_CONTRACTS_FAILURE,
      error,
    };
  }
}

/**
 *  Redux Action to Dispatch a query for show and hide rfq
 * @param {string} token -Token For User Authentication
 * @param {object} prefs - payload  for POST or DELETE
 * @param {Boolean} isIntentToNoQuote -
 */
function marketplacePreferences(
  token,
  prefs,
  isIntentToNoQuote,
  isRecommended = false
) {
  return (dispatch) => {
    dispatch(request());
    marketplaceApi.marketplacePreferences(token, prefs, isIntentToNoQuote).then(
      () => {
        dispatch(
          success({
            rfqPk: prefs.rfq_pk,
            intentToNoQuote: isIntentToNoQuote,
            isRecommended,
          })
        );
      },
      (error) => {
        dispatch(failure(error?.toString()));
        genericErrorHandler(error);
      }
    );
  };

  function request() {
    return {
      type: marketplaceConstants.MARKETPLACE_HIDE_SHOW_PREFERENCES_REQUEST,
    };
  }
  function success(preference) {
    return {
      type: marketplaceConstants.MARKETPLACE_HIDE_SHOW_PREFERENCES_SUCCESS,
      preference,
    };
  }
  function failure(error) {
    return {
      type: marketplaceConstants.MARKETPLACE_HIDE_SHOW_PREFERENCES_FAILURE,
      error,
    };
  }
}

function marketplaceLikeDislikeRecommend(rfqId, liked_recommendation) {
  return (dispatch) => {
    dispatch(request());
    marketplaceApi
      .marketplaceLikeDislikeRecommend(rfqId, liked_recommendation)
      .then(
        () => {
          dispatch(
            success({
              rfqId,
              liked_recommendation,
            })
          );
          enqueueSnackbar(
            liked_recommendation === true
              ? t("workspace:toastLike")
              : t("workspace:toastDislike"),
            { variant: "success" }
          );
        },
        (error) => {
          dispatch(failure(error?.toString()));
          enqueueSnackbar(error, { variant: "error" });
        }
      );
  };

  function request() {
    return {
      type: marketplaceConstants.MARKETPLACE_LIKE_DISLIKE_RECOMMENDED_REQUEST,
    };
  }
  function success(payload) {
    return {
      type: marketplaceConstants.MARKETPLACE_LIKE_DISLIKE_RECOMMENDED_SUCCESS,
      payload,
    };
  }
  function failure(error) {
    return {
      type: marketplaceConstants.MARKETPLACE_LIKE_DISLIKE_RECOMMENDED_FAILURE,
      error,
    };
  }
}

/**
 * Redux Action to Dispatch a query to get RFQ statistics
 *
 * @param {*} token - Token For User Authentication
 *
 */
function getRFQStats(id, token, page) {
  return (dispatch) => {
    dispatch(request());
    marketplaceApi.fetchRFQStats(id, token, page).then(
      (stats) => {
        if (stats === undefined) return;
        dispatch(success(stats));
      },
      (error) => {
        dispatch(failure(error?.toString()));
      }
    );
  };

  function request() {
    return { type: marketplaceConstants.FETCH_STATS_REQUEST };
  }
  function success(stats) {
    // Extracting price_unit, delay and company names from API response to match with the format that charts library expecting
    // response {"part1":[{c1 details}{c2 details}], "part2":[{c1 details}{c2 details}]}

    // charts library expected formatted response, barValue contains this data
    // [{0: {c1 price for part1, c1 price for part2 } 1: {c2 price for part1, c2 price for part2 }}]
    if (stats.type === "Chart") {
      let formattedStatsXAxis = [];
      const barValue = [];
      const xAxis = [];
      let partCount = 1;
      const supplierComments = []; // to display comments under the chart
      const data = stats.data;

      for (let stat in data) {
        const barData = [];
        formattedStatsXAxis = data[stat];
        data[stat].forEach((item) => {
          barData.push(item.price_unit);
        });
        barValue.push({
          data: barData,
          name: `${t("workspace:part")} ${partCount}`,
        });
        // incrementing part count here to show part1, part2..etc below the chart
        partCount += 1;
      }
      formattedStatsXAxis.forEach((item) => {
        let prodDelay = ` ${t("workspace:leadTime")} · ${item.lead_time}`;
        xAxis.push([item.company_name, prodDelay]);
        supplierComments.push([item.company_name, item.comment]);
      });

      return {
        type: marketplaceConstants.FETCH_STATS_SUCCESS,
        stats: {
          barValue,
          xAxis,
          supplierComments,
          totalPages: stats.total_pages,
          type: stats.type,
        },
      };
    } else {
      return {
        type: marketplaceConstants.FETCH_STATS_SUCCESS,
        stats: stats,
      };
    }
  }
  function failure(error) {
    return {
      type: marketplaceConstants.FETCH_STATS_FAILURE,
      error,
    };
  }
}

function setSelectedTabInSubmissions(tabValue) {
  return (dispatch) => {
    dispatch({
      type: marketplaceConstants.SET_SELECTED_TAB_IN_SUBMISSIONS,
      selectedTab: tabValue,
    });
  };
}

function setSellerNdaAttachments(attachments) {
  return (dispatch) => {
    dispatch({
      type: marketplaceConstants.SET_SELLER_NDA_ATTACHMENTS,
      attachments,
    });
  };
}

function clearActiveRfqDetails() {
  return (dispatch) => {
    dispatch({
      type: marketplaceConstants.CLEAR_ACTIVE_RFQ_DETAILS,
    });
  };
}

function setRfqHasNDA(hasNda) {
  return (dispatch) => {
    dispatch({
      type: marketplaceConstants.SET_RFQ_HAS_NDA,
      hasNda,
    });
  };
}

function setRfqDetailsLoading(isRfqDeatilsLoading) {
  return (dispatch) => {
    dispatch({
      type: marketplaceConstants.SET_RFQ_DETAILS_LOADING,
      isRfqDeatilsLoading,
    });
  };
}

function setNDAStepperIndex(stepIndex) {
  return (dispatch) => {
    dispatch({
      type: marketplaceConstants.SET_NDA_STEPPER_INDEX,
      stepIndex,
    });
  };
}

/**
 * Redux Action to Dispatch a query for get buyer stats on the seller side
 *
 * @param {*} token - Token For User Authentication
 *
 */
function getBuyerStatsByRfqId(token, rfqPk) {
  return (dispatch) => {
    dispatch(request());
    marketplaceApi.getBuyerStatsByRfqId(token, rfqPk).then(
      (buyerStats) => {
        dispatch(success(buyerStats));
      },
      (error) => {
        dispatch(failure(error?.toString()));
      }
    );
  };

  function request() {
    return { type: marketplaceConstants.GET_BUYER_STATS_BY_RFQ_ID_REQUEST };
  }
  function success(buyerStats) {
    return {
      type: marketplaceConstants.GET_BUYER_STATS_BY_RFQ_ID_SUCCESS,
      buyerStats,
    };
  }
  function failure(error) {
    return {
      type: marketplaceConstants.GET_BUYER_STATS_BY_RFQ_ID_FAILURE,
      error,
    };
  }
}

function setIntentToBidDueDate(
  prefs,
  isIntentToNoQuote,
  tabIndex,
  changeDueDate = false,
  t,
  isRecommended = false,
  onSuccess
) {
  return (dispatch, getState) => {
    marketplaceApi
      .marketplacePreferences(
        getState().profile.token,
        prefs,
        isIntentToNoQuote
      )
      .then((data) => {
        if (data && Object.keys(data).length) {
          dispatch(success(prefs, tabIndex, changeDueDate, isRecommended));
          if (typeof onSuccess === "function") onSuccess(!!prefs.due_date);
        }

        // update active rfq details in the reducer with updated intent to bid due date
        if (changeDueDate) {
          dispatch(marketplaceActions.getActiveRfqDetails(prefs.rfq_pk));
        }
        if (prefs.due_date) {
          enqueueSnackbar(
            t("workspaceNext:buyerRfqs:rfqMovedToSelectedForQuotingTab"),
            {
              buttonCallback: () => {
                history.push(
                  `${frontendUrl.marketplace}?${queryString.stringify({
                    ...queryString.parse(window.location.search),
                    status: RequestStatus.INTERESTED,
                  })}`
                );
              },
              buttonText: t("workspaceNext:buyerRfqs:goToTab"),
            }
          );
        }
      });
  };

  function success(prefs, tabIndex, changeDueDate, isRecommended) {
    return {
      type: marketplaceConstants.SET_INTENT_TO_BID_DUE_DATE_SUCCESS,
      prefs,
      tabIndex,
      changeDueDate,
      isRecommended,
    };
  }
}

function setIntentToNoBidReasonData(noQuoteData) {
  return {
    type: marketplaceConstants.SET_ITNB_REASON_DATA,
    noQuoteData,
  };
}

function incrementRfqQuestionsCount() {
  return {
    type: marketplaceConstants.INCREMENT_RFQ_QUESTIONS_COUNT,
  };
}

function decrementRFQNewQaMsgCount(count) {
  return { type: marketplaceConstants.DECREMENT_RFQ_NEW_QA_MSG_COUNT, count };
}
