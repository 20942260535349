import React, { useState } from "react";
import { Box, Typography, alpha } from "@mui/material";
import { GridRowParams } from "@mui/x-data-grid-pro-v5";
import { useMarketplaceRequestsTableData } from "./marketplace-requests-table.hooks";
import { getRowIdToPk } from "@next/utils/dataGridUtils-v5";
import { RequestStatus, RequestType } from "../../redux";
import { useHistory } from "react-router-dom";
import { frontendUrl } from "urls";
import { useTranslation } from "react-i18next";
import InboxOutlinedIcon from "@mui/icons-material/InboxOutlined";
import { AppTheme } from "layout/AppTheme";
import useUpdateEffect from "@next/hooks/useUpdateEffect";
import { RequestType as REQUEST_TYPE } from "@next/modules/workspace/components/rfq-creation-form/rfq-creation.form.definitions";

import { createStyles, makeStyles } from "@mui/styles";
import { DataGridProV5 } from "@next/components/data-grid-pro-v5";

const PAGE_SIZE = 10;

const useStyles = makeStyles((theme) =>
  createStyles({
    dataGrid: {
      borderRight: "none",
      "& .MuiDataGrid-row": {
        userSelect: "none",
        "&:hover": {
          backgroundColor: "transparent",
          cursor: "pointer",
        },
      },
      "& .MuiDataGrid-withBorder": {
        borderRight: "none",
      },
      "& .MuiDataGrid-main": {
        flexGrow: 0,
      },
      fontSize: "12px",
    },
    row: {
      backgroundColor: alpha(theme.palette.primary.main, 0.05),
    },
    noRows: {
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      gridGap: 24,
    },
  })
);

type Props = {
  type: RequestType;
  status: RequestStatus;
};

export const MarketplaceRequestsTable: React.FC<Props> = ({ type, status }) => {
  const classes = useStyles();
  const [page, setPage] = useState(1);
  const { gridData, count } = useMarketplaceRequestsTableData(
    type,
    status,
    page,
    PAGE_SIZE
  );
  const history = useHistory();
  const { t } = useTranslation();

  const onRowClick = (params: GridRowParams) => {
    if (
      params.row.request_type == REQUEST_TYPE.RFI ||
      params.row.request_type == REQUEST_TYPE.SIMPLE_RFQ
    ) {
      window.open(params.row.portal_url, "_blank");
      return;
    } else {
      history.push(`${frontendUrl.requestForQuotations}/${params.row.pk}`);
    }
  };

  useUpdateEffect(() => {
    setPage(1);
  }, [type]);

  return (
    <DataGridProV5
      className={classes.dataGrid}
      pagination
      paginationMode={type === RequestType.TENDERS ? "client" : "server"}
      pageSize={PAGE_SIZE}
      onPageChange={(p) => setPage(p + 1)}
      rows={gridData?.rows}
      columns={gridData?.columns}
      rowCount={count}
      autoHeight
      disableSelectionOnClick
      disableExtendRowFullWidth
      getRowId={getRowIdToPk}
      onRowClick={type !== RequestType.TENDERS ? onRowClick : undefined}
      getRowClassName={(params) =>
        params.row?.is_seen !== false || type === RequestType.TENDERS
          ? ""
          : classes.row
      }
      components={{
        NoRowsOverlay: () => (
          <Box className={classes.noRows}>
            <Box
              p={2}
              border={`1px solid ${AppTheme.palette.divider}`}
              borderRadius="8px"
            >
              <InboxOutlinedIcon />
            </Box>
            <Typography variant="h6" align="center">
              {t("marketplaceRequests:emptyMessage")}
            </Typography>
          </Box>
        ),
      }}
    />
  );
};
