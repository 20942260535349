import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  Box,
  Button,
  CircularProgress,
  InputAdornment,
  SelectChangeEvent,
  TextField,
  Theme,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { MySuppliersTable } from "../../components/my-suppliers-table/my-suppliers-table";
import { useDispatch, useSelector } from "react-redux";
import { workspaceNextActions } from "../../redux";
import { useAxyaTracking } from "@next/hooks/useAxyaTracking";
import { modalsActions } from "@next/redux/modalsSlices";
import { WorkspaceModalTypes } from "../../modals/types";
import PersonAddRoundedIcon from "@mui/icons-material/PersonAddRounded";
import { SelectFilterSuppliersDropdown } from "../../components/select-filter-suppliers-dropdown/select-filter-suppliers-dropdown";
import { selectFavoriteSupplierGroups } from "../../redux/selectors";
import { InviteSupplierModal } from "../../modals/invite-supplier-modal";
import {
  getCompany,
  getToken,
  getUser,
} from "services/profile/profile.selectors";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import { debounce } from "lodash";
import MySuppliersTableEmpty from "../../components/my-suppliers-table/my-suppliers-table-empty";
import useUpdateEffect from "@next/hooks/useUpdateEffect";
import i18n from "assets/configi18n/i18n";
import { checkIsSellerCompany } from "@next/utils/checkCompanyUtils";
import { CompanyTypeNext } from "@next/redux/types";
import { createStyles, makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
    content: { marginTop: theme.spacing(3) },
    search: {
      "& input": {
        paddingTop: 11.5,
        paddingBottom: 11.75,
      },
    },
  })
);

export const MySuppliers = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const axyaTracking = useAxyaTracking();
  const user = useSelector(getUser);
  const company = useSelector(getCompany);
  const isSeller = checkIsSellerCompany(company?.type as CompanyTypeNext);

  const language = i18n.language;
  const favoriteSuppliersGroups = useSelector(selectFavoriteSupplierGroups);
  const [selectedGroup, setSelectedGroup] = useState<number>(0);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [isShowSuppliersDirectory, setIsShowSuppliersDirectory] =
    useState(isSeller);

  const [iframeLoading, setIframeLoading] = useState(isSeller);
  const gridIframe = useRef<HTMLIFrameElement | null>(null);
  const classes = useStyles();
  const token = useSelector(getToken);

  const ASLOptions = [
    {
      pk: 0,
      name: t("workspaceNext:supplierTable:inputs:all"),
      value: null,
    },
    {
      pk: 1,
      name: t("workspaceNext:supplierTable:inputs:filterByASLOptionsASLTrue", {
        buyer: company?.name,
      }),
      value: true,
    },
    {
      pk: 2,
      name: t("workspaceNext:supplierTable:inputs:filterByASLOptionsASLFalse"),
      value: false,
    },
  ];
  const [selectedASL, setSelectedASL] = useState(ASLOptions[0]);

  useEffect(() => {
    if (!isSeller) {
      dispatch(workspaceNextActions.fetchFavoriteSupplierGroupsRequest());
    }
  }, [isSeller]);

  useUpdateEffect(() => {
    setSearchTerm("");
    setSelectedGroup(0);
  }, [isShowSuppliersDirectory]);

  const handleOpenInviteModal = () => {
    dispatch(
      modalsActions.showModal({
        key: WorkspaceModalTypes.INVITE_SUPPLIER_MODAL,
      })
    );

    axyaTracking({
      app: "axya-app",
      scout_category: "invite-supplier",
      scout_feature_name: "open-invite-supplier-modal",
    });
  };

  const onChangeGroup = (event: SelectChangeEvent<any>) => {
    setSelectedGroup(event.target.value as number);
  };

  const onChangeASL = (event: SelectChangeEvent<any>) => {
    const element = ASLOptions.find(
      (item) => item.pk === (event.target.value as number)
    );
    setSelectedASL(element!);
  };

  const handleSearch = useCallback(
    debounce(
      (term: string) => {
        if (term.length === 0 || term.length >= 3) setSearchTerm(term);
      },
      800,
      {
        leading: false,
        trailing: true,
      }
    ),
    []
  );

  function handleIframe() {
    if (gridIframe?.current?.contentWindow) {
      setTimeout(() => {
        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        gridIframe?.current?.contentWindow?.postMessage(token, "*");
        setIframeLoading(false);
      }, 100);
    }
  }

  type ASLOptionsType = {
    pk: number;
    name: any;
    value: boolean;
  };
  const sortedASLOptions = (list: ASLOptionsType[]) => {
    return list.sort((a, b): any => {
      const firstKey = a.name.toLowerCase();
      const keytoCompare = b.name.toLowerCase();
      if (a.name.toLowerCase() === "all") return -1;
      if (b.name.toLowerCase() === "all") return 1;
      if (firstKey < keytoCompare) {
        return -1;
      } else if (firstKey > keytoCompare) {
        return 1;
      } else {
        return 0;
      }
    });
  };

  return (
    <div className={classes.root}>
      {/* <Box display="flex" alignItems="center" top="24px">
        <Typography variant="h5">
          {!isShowSuppliersDirectory
            ? t("SIDE_BAR:mySuppliers")
            : t("SIDE_BAR:suppliersDirectory")}
        </Typography>
        {isShowSuppliersDirectory && !isSeller ? (
          <Box ml={2}>
            <Button variant="contained" startIcon={<KeyboardReturn />}>
              {t("workspaceNext:supplierTable:buttons:backToMySuppliers")}
            </Button>
          </Box>
        ) : null}
      </Box> */}

      {/* <Box mt="47px" ml="-40px" mr="-32px" position="relative" zIndex="1">
        <Divider />
      </Box> */}

      <Box mr="-32px">
        {iframeLoading && (
          <Box
            mt="60px"
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <CircularProgress />
          </Box>
        )}
        {isShowSuppliersDirectory ? (
          <iframe
            title="Grid Generator"
            ref={gridIframe}
            src={`${process.env.REACT_APP_SUPPLIER_DIRECTORY_IFRAME_URL}/${
              language === "en" ? "en" : "fr"
            }/suppliers_directory/?_company=${user.company?.uuid}`}
            width="100%"
            style={{
              height: "100vh",
            }}
            frameBorder={0}
            onLoad={handleIframe}
          />
        ) : (
          <>
            <Box my={3} display="flex" alignItems="center" gap="8px">
              <TextField
                className={classes.search}
                size="small"
                variant="outlined"
                placeholder={t(
                  "workspaceNext:supplierTable:inputs:searchCompany"
                )}
                type="search"
                onChange={(e) => handleSearch(e.target.value)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchOutlinedIcon />
                    </InputAdornment>
                  ),
                }}
              />

              <SelectFilterSuppliersDropdown
                label={t("workspaceNext:supplierTable:inputs:filterByGroup")}
                onChange={onChangeGroup}
                value={selectedGroup}
                items={[
                  { pk: 0, name: t("workspaceNext:supplierTable:inputs:all") },
                  ...favoriteSuppliersGroups,
                ]}
              />

              <SelectFilterSuppliersDropdown
                label={t("workspaceNext:supplierTable:inputs:filterByASL")}
                onChange={onChangeASL}
                value={selectedASL?.pk}
                items={sortedASLOptions(ASLOptions)}
              />

              <Button
                variant="outlined"
                onClick={handleOpenInviteModal}
                size="large"
                startIcon={<PersonAddRoundedIcon />}
              >
                {t("workspaceNext:supplierTable:buttons:addSupplier")}
              </Button>
            </Box>
            <MySuppliersTable
              selectedGroup={selectedGroup}
              selectedASL={selectedASL?.value}
              searchTerm={searchTerm}
              NoRowsOverlay={
                !searchTerm
                  ? undefined
                  : () => (
                      <MySuppliersTableEmpty
                        searchTerm={searchTerm}
                        onClickAddSupplier={handleOpenInviteModal}
                      />
                    )
              }
            />
          </>
        )}
      </Box>

      <InviteSupplierModal />
    </div>
  );
};
