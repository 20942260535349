import React from "react";
import { Badge, Box, IconButton } from "@mui/material";
import {
  GridColDef,
  GridColumns,
  GridRenderCellParams,
} from "@mui/x-data-grid-pro-v5";
import { SuppliersField } from "../suppliers-table/suppliers-table-v5.types";
import { modalsActions } from "@next/redux/modalsSlices";
import { batch, useDispatch, useSelector } from "react-redux";
import ChatBubbleOutlineIcon from "@mui/icons-material/ChatBubbleOutline";
import { WorkspaceModalTypes } from "../../modals/types";
import { SupplierWithContactsList } from "./supplier-with-contacts-list";
import {
  selectRfqQuestionsStats,
  selectRfqQuestionsStatsLoading,
} from "../../redux/selectors";

const NameCell: React.FC<
  GridRenderCellParams & { showContactsList?: boolean }
> = (props) => {
  const dispatch = useDispatch();
  const rfqQAStats = useSelector(selectRfqQuestionsStats);
  const rfqQAStatsLoading = useSelector(selectRfqQuestionsStatsLoading);
  const question = rfqQAStats?.find((q) => q?.company?.id === props.row?.id);

  const handleOpenChat = () => {
    batch(() => {
      dispatch(
        modalsActions.showModal({
          key: WorkspaceModalTypes.RFQ_COMMUNICATION_DRAWER,
          data: {
            supplier: props.row,
            showContactsList: props.showContactsList,
            qaId: question?.id,
          },
        })
      );
    });
  };

  return (
    <>
      <SupplierWithContactsList
        supplier={props.row}
        showContactsList={props.showContactsList}
      />

      <Box ml={1}>
        <IconButton
          onClick={handleOpenChat}
          disabled={!question || rfqQAStatsLoading}
        >
          <Badge
            badgeContent={question?.unseen_messages_count || 0}
            color="primary"
          >
            <ChatBubbleOutlineIcon
              sx={{
                color: !question || rfqQAStatsLoading ? "inherit" : "black",
              }}
            />
          </Badge>
        </IconButton>
      </Box>
    </>
  );
};

const defaultColumnConfig: Partial<GridColDef> = {
  disableColumnMenu: true,
  sortable: false,
  hideSortIcons: true,
};

export const getSuppliersColumns = (showContactsList: boolean) => {
  const _columns: GridColumns = [
    {
      ...defaultColumnConfig,
      field: SuppliersField.NAME,
      renderCell: (props) => (
        <NameCell {...props} showContactsList={showContactsList} />
      ),
      flex: 1,
    },
  ];

  return _columns;
};
