import { Box, Divider, Link, Theme, Typography } from "@mui/material";
import { AttachFileList } from "@next/components/attach-file/attach-file-list";
import { StateStatusTag } from "@next/components/state-status-tag";
import { Status } from "@next/components/state-status-tag/state-status-tag.utils";
import StatusVariantTag from "@next/modules/workspace/components/status-variant-tag/status-variant-tag";
import { formatDate } from "helpers/formatDate";
import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { getRfqDetails } from "services/workspace/workspace.selectors";
import RfqDescription from "./rfq-description";
import { DUMMY_ORDER_STATUS } from "@next/constants";
import { RfqAddenda } from "./rfq-addenda";
import { createStyles, makeStyles } from "@mui/styles";
import { frontendUrl } from "urls";

const useStyles = makeStyles<Theme>(() =>
  createStyles({
    listItem: {
      display: "flex",
      paddingBottom: "8px",
      paddingTop: "8px",
    },
    listItemLeft: {
      display: "flex",
      alignItems: "center",
      flex: 0.35,
    },
    listItemRight: {
      display: "flex",
      alignItems: "center",
      flex: 0.65,
    },
    alignTop: {
      alignItems: "flex-start",
    },
  })
);

export const BuyerRfqsInformationDetail: React.FC = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const rfqDetails = useSelector(getRfqDetails);
  const isAwarded = [Status.CONTRACT, Status.SPLITTED].includes(
    rfqDetails?.status
  );

  console.log("===>>", rfqDetails);
  return (
    <Box>
      <Box className={classes.listItem}>
        <Box className={classes.listItemLeft}>
          <Typography variant="body2" color="textSecondary">
            {t("orders:orderDetail:status")}
          </Typography>
        </Box>

        <Box className={classes.listItemRight}>
          {rfqDetails?.status === DUMMY_ORDER_STATUS ? null : isAwarded ? (
            <StatusVariantTag
              withBg
              variant="awarded"
              deadline={rfqDetails?.deadline}
            />
          ) : (
            <StateStatusTag
              cancellationReason={rfqDetails?.cancellation_reason}
              state={rfqDetails?.state}
              stateInfo={rfqDetails?.state_info}
              status={rfqDetails?.status}
              quotationsCount={rfqDetails?.quotations?.length}
              showStateInfoInTag={true}
              onHoldNote={rfqDetails?.on_hold_note}
            />
          )}
        </Box>
      </Box>

      <Box className={classes.listItem}>
        <Box className={classes.listItemLeft}>
          <Typography variant="body2" color="textSecondary">
            {t("workspaceNext:buyerInformations:sentOn")}
          </Typography>
        </Box>

        <Box className={classes.listItemRight}>
          {formatDate(rfqDetails?.created_at, "DD-MMM-YYYY")}
        </Box>
      </Box>

      <Box className={classes.listItem}>
        <Box className={classes.listItemLeft}>
          <Typography variant="body2" color="textSecondary">
            {t("workspaceNext:buyerInformations:endsOn")}
          </Typography>
        </Box>

        <Box className={classes.listItemRight}>
          {formatDate(rfqDetails?.deadline, "DD-MMM-YYYY")}
        </Box>
      </Box>

      {rfqDetails?.status === Status.CANCELED ? (
        <Box className={classes.listItem}>
          <Box className={classes.listItemLeft}>
            <Typography variant="body2" color="textSecondary">
              {t("workspaceNext:buyerInformations:cancelledOn")}
            </Typography>
          </Box>

          <Box className={classes.listItemRight}>
            {rfqDetails?.cancellation_date
              ? formatDate(rfqDetails?.cancellation_date, "DD-MMM-YYYY")
              : "-"}
          </Box>
        </Box>
      ) : null}

      {isAwarded ? (
        <Box className={classes.listItem}>
          <Box className={classes.listItemLeft}>
            <Typography variant="body2" color="textSecondary">
              {t("workspaceNext:buyerInformations:awardedOn")}
            </Typography>
          </Box>

          <Box className={classes.listItemRight}>
            {rfqDetails?.winner_date
              ? formatDate(rfqDetails?.winner_date, "DD-MMM-YYYY")
              : "-"}
          </Box>
        </Box>
      ) : null}

      <Box className={classes.listItem}>
        <Box className={classes.listItemLeft}>
          <Typography variant="body2" color="textSecondary">
            {t("workspaceNext:buyerInformations:requestFiles")}
          </Typography>
        </Box>

        <Box className={classes.listItemRight}>
          {rfqDetails?.attachments?.files?.length ? (
            <AttachFileList files={rfqDetails?.attachments?.files} />
          ) : (
            "-"
          )}
        </Box>
      </Box>

      {rfqDetails?.purchase_order?.name ? (
        <Box className={classes.listItem}>
          <Box className={classes.listItemLeft}>
            <Typography variant="body2" color="textSecondary">
              {t("workspaceNext:buyerInformations:orderReference")}
            </Typography>
          </Box>

          <Box className={classes.listItemRight}>
            <Link
              target="_blank"
              href={`${frontendUrl.orders}/${rfqDetails?.purchase_order?.id}`}
            >
              <Typography variant="body2">
                {rfqDetails?.purchase_order?.name}
              </Typography>
            </Link>
          </Box>
        </Box>
      ) : null}

      <Box className={classes.listItem}>
        <Box className={`${classes.listItemLeft} ${classes.alignTop}`}>
          <Typography variant="body2" color="textSecondary">
            {t("workspaceNext:buyerInformations:description")}
          </Typography>
        </Box>

        <Box className={classes.listItemRight}>
          {rfqDetails.additional_details ? (
            <RfqDescription
              pk={rfqDetails.pk}
              additionalDetailsRichText={
                rfqDetails.additional_details_rich_text ||
                rfqDetails.additional_details
              }
            />
          ) : (
            "-"
          )}
        </Box>
      </Box>
      <Box my={2}>
        <Divider light />
      </Box>
      <RfqAddenda addendaList={rfqDetails?.addenda} allowCreation />
    </Box>
  );
};
