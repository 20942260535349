import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";

import { profile } from "./profile";

// Need to refactor
import { modals } from "./modals/modals.reducers";

import { workspace } from "./workspace";
import { marketplace } from "./marketplace";
import { quotation } from "./quotation";
import { partConfiguration } from "./part-configuration";
import { inbox } from "./inbox";
import { userOnboarding } from "./user-onboarding";
import { companyRatings } from "./company-ratings";
import { userRating } from "./rating";
import { nps } from "./nps";
import { requestDemo } from "./request-demo";
import { history } from "../helpers/history";

import projectNext from "@next/modules/project/redux/slices";
import modalsNext from "@next/redux/modalsSlices";
import appNext from "@next/modules/app/redux/slices";
import marketplaceNext from "@next/modules/marketplace/redux/slices";
import profileNext from "@next/modules/profile/redux/slices";
import workspaceNext from "@next/modules/workspace/redux/slices";
import announcementNext from "@next/modules/announcement/redux/slices";
import ordersNext from "@next/modules/orders/redux/slices";
import dashboardNext from "@next/modules/dashboard/redux/slices";
import newsfeedNext from "@next/modules/newsfeed/redux/slices";
import companyCapabilitiesNext from "@next/modules/company-capabilities/redux/slices";
import analyticsNext from "@next/modules/analytics/redux/slices";

const appReducer = combineReducers({
  profile,
  marketplace,
  quotation,
  workspace,
  partConfiguration,
  modals,
  inbox,
  userOnboarding,
  companyRatings,
  userRating,
  nps,
  requestDemo,
  router: connectRouter(history),

  // @next reducer
  projectNext,
  modalsNext,
  appNext,
  marketplaceNext,
  profileNext,
  workspaceNext,
  announcementNext,
  ordersNext,
  dashboardNext,
  newsfeedNext,
  companyCapabilitiesNext,
  analyticsNext,
});

export const rootReducer = (state: any, action: any) => {
  if (["USER_LOGOUT_FAILURE", "USER_LOGOUT_SUCCESS"].includes(action.type)) {
    // // Clear the redux state when the USER_LOGOUT action is recieved.
    state = undefined;
  }
  return appReducer(state, action);
};
