import { Box, Chip, Link, Paper, Theme, Typography } from "@mui/material";
import { CustomTypography } from "@next/components/custom-typography";
import React, { forwardRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { formatDate } from "helpers/formatDate";
import { OrderDetailMenu } from "../order-detail.menu";
import { useTranslation } from "react-i18next";
import { frontendUrl } from "urls";
import { OrderStepper } from "../../order-stepper";
import { getActiveStepForStepper } from "./order-detail-header.utils";
import SupplierContactsList from "@next/modules/workspace/components/supplier-contacts-list/supplier-contacts-list";
import SupplierListItem from "@next/modules/workspace/components/rfq-creation-form/supplier-list-item";
import { RevisePoModal } from "@next/modules/orders/modals/revise-po-modal";
import { getModal, modalsActions } from "@next/redux/modalsSlices";
import { OrdersModalTypes } from "@next/modules/orders/modals/types";
import { DUMMY_ORDER_STATUS } from "@next/constants";
import { ordersActions } from "@next/modules/orders/redux";
import { fileDownloadFromUrl } from "@next/utils/downloadUtils";
import InsertDriveFileOutlinedIcon from "@mui/icons-material/InsertDriveFileOutlined";
import { GenericUser } from "@next/modules/profile/redux";
import { SharedModalTypes } from "@next/modals/types";
import {
  selectOrderByIsPortal,
  selectUpdateOrderTargetProfilesLoading,
} from "@next/modules/orders/redux/selectors";
import { useHasCompanyRole } from "@next/hooks/useHasCompanyRole";
import { createStyles, makeStyles } from "@mui/styles";
import { OrderDetailHeaderModal } from "@next/modals/shared-order-detail-header-modal";
import { OrderStateTag } from "../../order-state-tag";
import CollaboratorsList from "components/workspace/buyer-rfqs-view/collaborators-list";
import TopicTitle from "@next/modules/supplier-portal/components/topic-title/topic-title";
import { OrderPOFiles } from "./order-po-files";
import { AttachFileList } from "@next/components/attach-file/attach-file-list";

const useStyles = makeStyles<Theme>((theme: Theme) =>
  createStyles({
    root: {
      padding: "24px",
      paddingTop: "0px",
      paddingLeft: "0px",
      paddingBottom: "0px",
      boxShadow: "none",
      border: `none`,
    },
    divider: {
      marginLeft: "-24px",
      marginRight: "-24px",
      marginTop: "10px",
      marginBottom: "16px",
    },
    listItem: {
      display: "flex",
      paddingBottom: "8px",
      paddingTop: "8px",
    },
    listItemLeft: {
      display: "flex",
      alignItems: "center",
      width: "200px",
    },
    listItemRight: {
      display: "flex",
      alignItems: "center",
      flex: 1,
    },
  })
);

type Props = {
  isPortal?: boolean;
};

export const OrderDetailHeader = forwardRef(({ isPortal }: Props, ref) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const hasViewerRole = useHasCompanyRole(["viewer", "restricted_viewer"]);
  const [menuAnchorEl, setMenuAnchorEl] = useState<null | Element>(null);
  const modal = useSelector(
    getModal(SharedModalTypes.SUPPLIER_VIEW_PART_MODAL)
  );
  const partViewFileData = modal?.data;
  const orderDetail = useSelector(selectOrderByIsPortal(isPortal));

  const onRevisePO = () => {
    dispatch(
      modalsActions.showModal({
        key: OrdersModalTypes.REVISE_PO_MODAL,
        data: { order: orderDetail },
      })
    );
  };

  const onClickDownloadAdditionalAttachments = () => {
    if (orderDetail?.rfq?.bundle_url) {
      fileDownloadFromUrl(
        orderDetail?.rfq?.bundle_url || "",
        `${orderDetail?.name}`
      );
    }
  };

  const handleUpdateOrderSupplierContactsList = (
    target_profiles: GenericUser[]
  ) => {
    const target_profiles_ids = target_profiles.map((item) => item.id);
    if (!orderDetail) return;
    dispatch(
      ordersActions.updateOrderTargetedProfilesRequest({
        rfqId: orderDetail.rfq.pk,
        target_profiles: target_profiles_ids as number[],
        supplierId: orderDetail.supplier.id,
        onSuccess: () => {
          dispatch(
            modalsActions.closeModal(
              SharedModalTypes.SUPPLIER_CONTACTS_LIST_MODAL
            )
          );
        },
      })
    );
  };

  return (
    <Paper className={classes.root}>
      <Box height="100%" ref={ref}>
        {isPortal ? (
          <Box mt="12px" mb="12px">
            <TopicTitle company={orderDetail?.buyer} height={40} />
          </Box>
        ) : null}

        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          height={60}
        >
          <Box display="flex" alignItems="center">
            <CustomTypography variant="h6">
              {orderDetail?.name}
            </CustomTypography>

            <Box ml="12px">
              <OrderStateTag state={orderDetail?.state} />
            </Box>

            <Box display="flex" alignItems="center" ml="12px">
              <CollaboratorsList
                data={{
                  collaborators: orderDetail?.collaborators,
                  rfqPk: orderDetail?.rfq.pk,
                  createdBy: orderDetail?.created_by,
                  poId: orderDetail?.pk,
                }}
                editMode={!hasViewerRole}
                size="medium"
              />
            </Box>

            {!hasViewerRole && !isPortal ? (
              <OrderDetailMenu
                anchorEl={menuAnchorEl}
                setAnchorEl={setMenuAnchorEl}
                menuActiveData={orderDetail}
                showIcon={true}
                refetchOrdersAfterAction={false}
              />
            ) : null}
          </Box>
        </Box>

        <Box>
          <OrderStepper
            activeStep={getActiveStepForStepper(orderDetail)}
            order={orderDetail}
            allowStepChange={!isPortal && !hasViewerRole}
            lineItemsCount={orderDetail?.line_items_count}
            shippedLineItemsCount={orderDetail?.shipped_line_items_count}
          />
        </Box>

        <Box mt="12px">
          {!isPortal ? (
            <Box className={classes.listItem}>
              <Box className={classes.listItemLeft}>
                <Typography variant="body2" color="textSecondary">
                  {t("orders:orderDetail:supplier")}
                </Typography>
              </Box>

              <Box className={classes.listItemRight}>
                <SupplierListItem
                  name={orderDetail?.supplier?.name || ""}
                  avatarStyle={{ width: "32px", height: "32px" }}
                  picture={orderDetail?.supplier?.picture}
                  rootWidth="unset"
                />
                <SupplierContactsList
                  supplier={orderDetail?.supplier}
                  users={orderDetail?.supplier?.target_profiles}
                  views={orderDetail?.views}
                  showVisibilityBadge={true}
                  editMode={!hasViewerRole}
                  onValidate={handleUpdateOrderSupplierContactsList}
                  loadingSelector={selectUpdateOrderTargetProfilesLoading}
                />
              </Box>
            </Box>
          ) : (
            <Box className={classes.listItem}>
              <Box className={classes.listItemLeft}>
                <Typography variant="body2" color="textSecondary">
                  Created by
                </Typography>
              </Box>

              <Box className={classes.listItemRight}>
                <SupplierListItem
                  name={orderDetail?.buyer?.name || ""}
                  avatarStyle={{ width: "32px", height: "32px" }}
                  picture={orderDetail?.buyer?.picture}
                />
              </Box>
            </Box>
          )}

          {orderDetail?.updated_delivery_date ? (
            <Box className={classes.listItem}>
              <Box className={classes.listItemLeft}>
                <Typography variant="body2" color="textSecondary">
                  {t("orders:orderDetail:promisedDelivery")}
                </Typography>
              </Box>

              <Box className={classes.listItemRight}>
                {formatDate(orderDetail?.updated_delivery_date, "DD-MMM-YYYY")}
              </Box>
            </Box>
          ) : null}

          <Box className={classes.listItem}>
            <Box className={classes.listItemLeft}>
              <Typography variant="body2" color="textSecondary">
                {t("orders:orderDetail:purchaseOrder")}
              </Typography>
            </Box>

            <Box className={classes.listItemRight}>
              <OrderPOFiles
                poFiles={orderDetail?.files}
                onRevisePO={onRevisePO}
                isPortal={isPortal}
                hasViewerRole={hasViewerRole}
              />
            </Box>
          </Box>
          {orderDetail?.po_confirmation_file?.file_url && (
            <Box className={classes.listItem}>
              <Box className={classes.listItemLeft}>
                <Typography variant="body2" color="textSecondary">
                  {t("orders:orderDetail:orderConfirmation")}
                </Typography>
              </Box>

              <Box className={classes.listItemRight}>
                <AttachFileList
                  files={[orderDetail?.po_confirmation_file]}
                  disableFileItemMarginBottom
                  downloadable
                  getDisablePaddingBottom
                />
              </Box>
            </Box>
          )}

          {orderDetail?.rfq?.bundle_url ? (
            <Box className={classes.listItem}>
              <Box className={classes.listItemLeft}>
                <Typography variant="body2" color="textSecondary">
                  {t("orders:orderDetail:additionalAttachments")}
                </Typography>
              </Box>

              <Box className={classes.listItemRight}>
                <>
                  <Chip
                    size="small"
                    icon={<InsertDriveFileOutlinedIcon />}
                    label={"zip"}
                  />

                  <Box ml="8px">
                    <Link
                      href="#"
                      onClick={() => onClickDownloadAdditionalAttachments()}
                    >
                      <Typography variant="body2">
                        {orderDetail?.name}
                      </Typography>
                    </Link>
                  </Box>
                </>
              </Box>
            </Box>
          ) : null}

          {!isPortal &&
          orderDetail?.rfq?.status !== DUMMY_ORDER_STATUS &&
          orderDetail?.rfq?.display_name ? (
            <Box className={classes.listItem}>
              <Box className={classes.listItemLeft}>
                <Typography variant="body2" color="textSecondary">
                  {t("orders:orderDetail:requestReference")}
                </Typography>
              </Box>

              <Box className={classes.listItemRight}>
                <Link
                  target="_blank"
                  href={`${frontendUrl.bundleView}/${orderDetail?.rfq?.pk}`}
                >
                  <Typography variant="body2">
                    {orderDetail?.rfq?.display_name}
                  </Typography>
                </Link>
              </Box>
            </Box>
          ) : null}

          <RevisePoModal />

          <OrderDetailHeaderModal
            isModalOpen={modal}
            partViewFileData={partViewFileData}
          />
        </Box>
      </Box>
    </Paper>
  );
});
