import { Box, IconButton, InputAdornment, TextField } from "@mui/material";
import { SharedModalTypes } from "@next/modals/types";
import { workspaceNextActions } from "@next/modules/workspace/redux";
import { modalsActions } from "@next/redux/modalsSlices";
import React, { useCallback } from "react";
import { batch, useDispatch } from "react-redux";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import { useTranslation } from "react-i18next";
import { createStyles, makeStyles } from "@mui/styles";

const useStyles = makeStyles(() =>
  createStyles({
    search: {
      width: 160,
      marginRight: 8,
      "&:hover": {
        "& .MuiOutlinedInput-notchedOutline": {
          borderColor: "black",
        },
      },
    },
  })
);

type Props = {
  iconOnly?: boolean;
};

export const SearchActionItem: React.FC<Props> = ({ iconOnly }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const onSearch = useCallback(() => {
    batch(() => {
      dispatch(workspaceNextActions.clearGlobalSearch());
      dispatch(
        modalsActions.showModal({ key: SharedModalTypes.GLOBAL_SEARCH })
      );
    });
  }, []);

  return (
    <Box
      onClick={onSearch}
      data-tut="reactour__search"
      data-pid="btnGlobalSearch"
    >
      {iconOnly ? (
        <IconButton size="large">
          <SearchOutlinedIcon style={{ color: "black" }} />
        </IconButton>
      ) : (
        <TextField
          className={classes.search}
          variant="outlined"
          size="small"
          placeholder={t("globalSearch:inputText")}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchOutlinedIcon />
              </InputAdornment>
            ),
            readOnly: true,
          }}
        />
      )}
    </Box>
  );
};
