import React from "react";
import { Box, Chip, Link, Typography } from "@mui/material";
import InsertDriveFileOutlinedIcon from "@mui/icons-material/InsertDriveFileOutlined";
import { FileData } from "../attach-file/attach-file-list.types";
import { createStyles, makeStyles } from "@mui/styles";
import CloseIcon from "@mui/icons-material/Close";
import { bytesToReadableFormat } from "@next/utils/miscUtils";

const useStyles = makeStyles(() =>
  createStyles({
    pointer: {
      cursor: "pointer",
    },
  })
);

interface Props {
  file: Partial<FileData>;
  onDelete?: (file: Partial<FileData>) => void;
  showSize?: boolean;
}

const DownloadableFileItem: React.FC<Props> = ({
  file,
  onDelete,
  showSize,
}) => {
  const classes = useStyles();

  return (
    <Box display="flex" alignItems="center" gap="8px">
      <Chip
        size="small"
        label={file?.file_extension?.toUpperCase()}
        icon={<InsertDriveFileOutlinedIcon />}
      />
      <Link
        target="_blank"
        rel="noopener noreferrer"
        href={file?.file_url}
        className={classes.pointer}
      >
        {file?.file_name}
      </Link>
      {showSize && file?.file_size && (
        <Typography variant="caption" color="textSecondary">
          {bytesToReadableFormat(file.file_size)}
        </Typography>
      )}
      {typeof onDelete === "function" ? (
        <CloseIcon className={classes.pointer} onClick={() => onDelete(file)} />
      ) : null}
    </Box>
  );
};

export default DownloadableFileItem;
