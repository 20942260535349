import React, { useCallback } from "react";
import { Box, Button, Typography, alpha, lighten } from "@mui/material";
import TouchAppOutlinedIcon from "@mui/icons-material/TouchAppOutlined";
import CreateOutlinedIcon from "@mui/icons-material/CreateOutlined";
import { useTranslation } from "react-i18next";
import { RFQPartsRevisionModal } from "@next/modules/workspace/modals/rfq-parts-revision-modal/rfq-parts-revision-modal";
import { modalsActions } from "@next/redux/modalsSlices";
import { WorkspaceModalTypes } from "@next/modules/workspace/modals/types";
import { useDispatch } from "react-redux";
import { useHasCompanyRole } from "@next/hooks/useHasCompanyRole";

import { createStyles, makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) =>
  createStyles({
    rootCommon: {
      display: "flex",
      borderRadius: theme.spacing(1),
      padding: theme.spacing(1.5),
    },
    rootRevision: {
      flexDirection: "column",
      backgroundColor: alpha(theme.palette.primary.main, 0.05),
      color: theme.palette.primary.main,
    },
    rootEdit: {
      alignItems: "center",
      backgroundColor: alpha(theme.palette.common.black, 0.05),
      gap: theme.spacing(1),
      color: theme.palette.common.black,
    },
    dismissBtn: {
      backgroundColor: theme.palette.common.white,
      "&:hover": {
        backgroundColor: lighten(theme.palette.common.black, 0.92),
      },
    },
    icon: {
      fontSize: 21,
    },
  })
);

interface Props {
  showRevisionPrompt: boolean;
  onDismiss?: () => void;
}

const RFQPartsStatusBar: React.FC<Props> = ({
  showRevisionPrompt,
  onDismiss,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();
  const hasViewerOrRestrictedViewerRole = useHasCompanyRole([
    "viewer",
    "restricted_viewer",
  ]);

  const onSend = useCallback(() => {
    dispatch(
      modalsActions.showModal({
        key: WorkspaceModalTypes.RFQ_PARTS_REVISION_MODAL,
        data: { onSuccess: onDismiss },
      })
    );
  }, []);

  if (hasViewerOrRestrictedViewerRole) return null;

  return showRevisionPrompt ? (
    <Box className={`${classes.rootRevision} ${classes.rootCommon}`}>
      <Box display="flex" gap="8px">
        <CreateOutlinedIcon className={classes.icon} />
        <Typography variant="body2">
          {t("rfq:partsStatusBar:revisionHelpText")}
        </Typography>
      </Box>

      <Box display="flex" justifyContent="flex-end" gap="12px" mt={1.5}>
        <Button
          size="large"
          variant="outlined"
          className={classes.dismissBtn}
          onClick={onDismiss}
        >
          {t("rfq:partsStatusBar:dismissButton")}
        </Button>

        <Button
          size="large"
          variant="contained"
          color="primary"
          onClick={onSend}
        >
          {t("rfq:partsStatusBar:sendButton")}
        </Button>
      </Box>

      <RFQPartsRevisionModal />
    </Box>
  ) : (
    <Box className={`${classes.rootEdit} ${classes.rootCommon}`}>
      <TouchAppOutlinedIcon className={classes.icon} />
      <Typography variant="body2">
        {t("rfq:partsStatusBar:editHelpText")}
      </Typography>
    </Box>
  );
};

export default RFQPartsStatusBar;
