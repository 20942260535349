export enum WorkspaceModalTypes {
  ASK_QUESTION_MODAL = "workspace-ask-question-modal",
  CONTACT_SUPPLIERS_MODAL = "contact-suppliers-modal",
  INVITE_SUPPLIER_MODAL = "invite-supplier-modal",
  CONTACT_US_MODAL = "contact-us-modal",
  NDA_LIST_MODAL = "nda-list-modal",
  RFQ_CREATION_DRAWER = "rfq-creation-drawer",
  RFQ_CREATION_LITE_DRAWER = "rfq-creation-lite-drawer",
  RFQ_DELETE_CONFIRMATION_MODAL = "rfq-delete-confirmation-modal",
  REMOVE_MY_SUPPLIER_CONFIRMATION_MODAL = "remove-my-supplier-confirmation-modal",
  PART_MODAL = "part-modal",
  ADD_SUPPLIERS_MODAL = "add-suppliers-modal",
  TOPIC_PORTAL_SEND_QUOTATION_MODAL = "topic-portal-send-quotation-modal",
  RFQ_PARTS_REVISION_MODAL = "rfq-parts-revision-modal",
  AMQ_HOW_IT_WORKS_MODAL = "amq-how-it-works-modal",
  CONNECTION_REQUESTS_MODAL = "connection-requests-modal",
  GROUPING_AFTER_CONNECTION_MODAL = "grouping-after-connection-modal",
  RFQ_ADD_ADDENDA_MODAL = "rfq-add-addenda-modal",
  QUOTE_COMPARISON_AWARD_DRAWER = "quote-comparison-award-drawer",
  QUOTE_COMPARISON_CUSTOMIZE_VIEW_MODAL = "quote-comparison-customize-view-modal",
  RFQ_ADD_FILES_AND_PARTS_MODAL = "rfq-add-files-and-parts-modal",
  RFQ_COMMUNICATION_DRAWER = "rfq-communication-drawer",
}
