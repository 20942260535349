import { Box, Divider, styled } from "@mui/material";

export const TopDivider = styled(Divider)`
  margin-left: -36px;
  margin-right: -36px;
  margin-top: -10px;
`;

export const SectionContainer = styled(Box)`
  display: flex;
  height: calc(100vh - 81px);
  ${(props) => props.theme.breakpoints.down(937)} {
    flex-direction: column;
    height: 100%;
  }
`;

export const LeftSection = styled(Box)`
  flex: 1;
  width: 60%;
  padding-right: 16px;
  padding-bottom: 0px;
  border-right: 1px solid ${({ theme }) => theme.palette.divider};

  ${(props) => props.theme.breakpoints.down(937)} {
    width: 100%;
    border-right: none;
  }
`;

export const RightSection = styled(Box)<{ rightTabOpen: boolean }>`
  width: ${(props) => (props.rightTabOpen ? "40%" : "45px")};

  margin-right: -36px;
  ${(props) => props.theme.breakpoints.down(937)} {
    width: 100%;
  }
`;
