import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { Button, Card, CircularProgress, Grid } from "@mui/material";
import * as Yup from "yup";
import { Formik } from "formik";
// Image Assets
import logoBlack from "../../assets/img/logoHorizontalBlack.svg";
import { RequestDemoForm } from "./request-demo-form/request-demo-form.component";
import { requestDemoActions } from "../../services/request-demo";
import { history } from "../../helpers/history";
import { frontendUrl } from "../../urls";

const RequestDemo = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const demoState = useSelector((state) => state.requestDemo);
  // this function validates the format of the data we send
  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .required(t("requestDemo:demoFormEmailFieldRequired"))
      .email(t("requestDemo:demoFormEmailFieldValidation")),
    company: Yup.string(),
    erpType: Yup.string(),
  });

  // sends the email by trigering the redux action
  const sendEmail = (values) => {
    dispatch(
      requestDemoActions.requestDemo(
        values.email,
        values.company,
        values.erpType
      )
    );
  };
  const values = { email: "", company: "", erpType: "" };

  return (
    <section className="c-request-demo">
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
      >
        <Grid item xs={1}>
          <img
            src={logoBlack}
            alt="Axya logo"
            className="c-user-rating--logo"
          />
        </Grid>
      </Grid>
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
        align="center"
      >
        {demoState.isRequestDemoSuccess ? (
          <Grid
            className="c-request-demo--success"
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
          >
            <p>{t("requestDemo:requestDemoSuccessMessage")}</p>
            <Button
              variant="contained"
              type="submit"
              size="large"
              className="c-request-demo--btn"
              onClick={() => history.push(frontendUrl.login)}
            >
              {t("ratingSuccess:login")}
            </Button>
          </Grid>
        ) : (
          <Grid
            item
            container
            xs={11}
            sm={8}
            md={7}
            className="c-user-rating--section"
          >
            <Card className="c-user-rating--card">
              {demoState.isRequestDemoLoading ? (
                <Grid
                  container
                  direction="column"
                  justifyContent="center"
                  alignItems="center"
                  className="c-loader"
                >
                  <Grid item>
                    <CircularProgress color="inherit" size={40} />
                    {t("requestDemo:loadingAssetsInProgress")}
                  </Grid>
                </Grid>
              ) : (
                <>
                  <Grid item xs={12} sm={8}>
                    <Formik
                      validationSchema={validationSchema}
                      initialValues={values}
                      render={(props) => <RequestDemoForm {...props} t={t} />}
                      onSubmit={sendEmail}
                    />
                  </Grid>
                </>
              )}
            </Card>
          </Grid>
        )}
      </Grid>
    </section>
  );
};

export default RequestDemo;
