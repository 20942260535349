import React from "react";
// MaterialUI Specific Imports
import { Box, Button, Grid, MenuItem, Select, Typography } from "@mui/material";
import { GenericTextField } from "../../generic-text-field/generic-text-field.component";

export const RequestDemoForm = (props) => {
  const {
    handleSubmit,
    handleChange,
    values: { email, company, erpType },
    t,
  } = props;

  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      xs={8}
      className="c-request-demo-form"
    >
      <Box my={4}>
        <Typography variant="h5">{t("requestDemo:demoTitle")}</Typography>
        <Typography variant="h6">{t("requestDemo:demoSubTitle")}</Typography>
      </Box>
      <form onSubmit={handleSubmit} style={{ width: "100%" }}>
        <Grid container spacing={2} justifyContent="center">
          <Grid item xs={12}>
            <GenericTextField
              value={email}
              name="email"
              label={t("requestDemo:demoFormEmailField")}
              variant="outlined"
              fullWidth
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12}>
            <GenericTextField
              value={company}
              name="company"
              label={t("requestDemo:demoFormCompanyField")}
              variant="outlined"
              fullWidth
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12}>
            <Select
              value={erpType}
              onChange={handleChange}
              name="erpType"
              label="ERP"
              variant="outlined"
              displayEmpty
              fullWidth
            >
              <MenuItem value="GeniusERP">
                {t("requestDemo:GeniusERP")}
              </MenuItem>
              <MenuItem value="Dynamics">{t("requestDemo:Dynamics")}</MenuItem>
              <MenuItem value="EPICOR">{t("requestDemo:EPICOR")}</MenuItem>
              <MenuItem value="SysproERP">
                {t("requestDemo:SysproERP")}
              </MenuItem>
              <MenuItem value="InforSyteline">
                {t("requestDemo:InforSyteline")}
              </MenuItem>
              <MenuItem value="SAP">{t("requestDemo:SAP")}</MenuItem>
              <MenuItem value="Oracle">{t("requestDemo:Oracle")}</MenuItem>
            </Select>
          </Grid>
          <Grid item xs={12} justifyContent="center">
            <Button
              variant="contained"
              type="submit"
              className="c-submit-button"
            >
              {t("requestDemo:demoFormSubmit")}
            </Button>
          </Grid>
        </Grid>
      </form>
    </Grid>
  );
};
