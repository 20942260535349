import { CompanyTypeNext } from "@next/redux/types";
import { isEmpty } from "lodash";
import { dynamicEnvs } from "urls";
import { store } from "store";
import { getCompany } from "services/profile/profile.selectors";

export const checkIsBRPCompany = (companyId: number): boolean => {
  try {
    return dynamicEnvs.REACT_APP_BRP_RD_GATEWAY_COMPANY_ID
      ? parseInt(dynamicEnvs.REACT_APP_BRP_RD_GATEWAY_COMPANY_ID) === companyId
      : false;
  } catch (error) {
    console.error(
      `REACT_APP_BRP_RD_GATEWAY_COMPANY_ID env var not valid:`,
      dynamicEnvs.REACT_APP_BRP_RD_GATEWAY_COMPANY_ID
    );
    return false;
  }
};

export const checkIsRTASCompany = (
  companyId: number = getCompany(store.getState()).id
): boolean => {
  try {
    return dynamicEnvs.REACT_APP_RTAS_COMPANY_ID
      ? parseInt(dynamicEnvs.REACT_APP_RTAS_COMPANY_ID) === companyId
      : false;
  } catch (error) {
    console.error(
      `REACT_APP_RTAS_COMPANY_ID env var not valid:`,
      dynamicEnvs.REACT_APP_RTAS_COMPANY_ID
    );
    return false;
  }
};

export const checkIsShowCommissionFeeCompany = (companyId: number): boolean => {
  try {
    const envShowCommissionFeeCompanyIDs = !isEmpty(
      dynamicEnvs.REACT_APP_SHOW_COMMISSION_FEE_COMPANIES
    )
      ? dynamicEnvs.REACT_APP_SHOW_COMMISSION_FEE_COMPANIES?.split(",")
          .map(Number)
          .filter((n) => n > 0) || []
      : [];

    return envShowCommissionFeeCompanyIDs.includes(companyId);
  } catch (error) {
    console.error(
      `REACT_APP_SHOW_COMMISSION_FEE_COMPANIES env var not valid:`,
      dynamicEnvs.REACT_APP_SHOW_COMMISSION_FEE_COMPANIES
    );
    return false;
  }
};

export const checkIsSellerCompany = (companyType?: CompanyTypeNext) => {
  return companyType === CompanyTypeNext.FAB;
};

export const checkIsRedirectMyPartsToCatalog = (companyId: number): boolean => {
  try {
    const companies =
      dynamicEnvs.REACT_APP_SHOW_REDIRECT_MY_PARTS_TO_CATALOG_COMPANIES?.split(
        ","
      );
    return companies ? companies.includes(`${companyId}`) : false;
  } catch (error) {
    console.error(
      `REACT_APP_SHOW_REDIRECT_MY_PARTS_TO_CATALOG_COMPANIES env var not valid:`,
      dynamicEnvs.REACT_APP_BRP_RD_GATEWAY_COMPANY_ID
    );
    return false;
  }
};
