import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Formik } from "formik";
import { modalsActions } from "@next/redux/modalsSlices";
import { WorkspaceModalTypes } from "../../modals/types";

import { getRfqDetails } from "services/workspace/workspace.selectors";
import {
  getInitialValues,
  validationSchema,
} from "./rfq-parts-revision-form.definitions";
import { getTargetedSupplierIds } from "../../components/contact-suppliers/contact-suppliers.definitions";
import { RFQPartsRevisionFormValues } from "./rfq-parts-revision-form.types";
import RFQPartsRevisionFormBase from "./rfq-parts-revision-form.base";
import { workspaceNextActions } from "../../redux";

type Props = {
  onSuccess?: () => void;
};

const RFQPartsRevisionForm: React.FC<Props> = ({ onSuccess }) => {
  const dispatch = useDispatch();
  const rfqDetails = useSelector(getRfqDetails);

  const handleSubmit = useCallback(
    (values: RFQPartsRevisionFormValues) => {
      const suppliersIds = getTargetedSupplierIds({
        targetedSuppliersName: values.suppliers,
        targetedSuppliers: (rfqDetails?.targeted_suppliers || []).concat(
          rfqDetails?.marketplace_suppliers || []
        ),
      });

      dispatch(
        workspaceNextActions.sendRevisionNoteRequest({
          rfqId: rfqDetails.pk,
          companies: suppliersIds,
          note: values.note,
          onSuccess: () => {
            dispatch(
              modalsActions.closeModal(
                WorkspaceModalTypes.RFQ_PARTS_REVISION_MODAL
              )
            );
            if (typeof onSuccess === "function") onSuccess();
            // want to refresh the page to get the updated status of supplier
            window.location.reload();
          },
        })
      );
    },
    [rfqDetails]
  );

  return (
    <Formik
      initialValues={getInitialValues()}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
    >
      {(props) => (
        <RFQPartsRevisionFormBase
          {...props}
          suppliers={[
            ...(rfqDetails?.targeted_suppliers || []),
            ...(rfqDetails?.marketplace_suppliers || []),
          ]}
        />
      )}
    </Formik>
  );
};

export default RFQPartsRevisionForm;
