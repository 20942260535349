import React from "react";
import { Box, Popover, PopoverProps, Theme, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import WifiTetheringOffIcon from "@mui/icons-material/WifiTetheringOff";
import WifiTetheringIcon from "@mui/icons-material/WifiTethering";
import NextAxyaLogo from "@next/assets/nextLogo.png";
import {
  ErpConfigurationType,
  getErpConfigurationIcon,
  getErpConfigurationName,
} from "./app-bar-next/app-bar-next-erp-configuration";
import CircleIcon from "@mui/icons-material/Circle";
import { useSelector } from "react-redux";
import { getErpConfiguration } from "services/part-configuration/part-configuration.selectors";
import { Trans, useTranslation } from "react-i18next";
import { formatDateTimeAsCalender } from "@next/utils/dateTimeUtils";

export type StyleProps = {
  erpStatus?: boolean;
};

const useStyles = makeStyles<Theme, StyleProps>((theme: Theme) => ({
  popover: {
    "& .MuiPopover-paper": {
      borderRadius: theme.spacing(3),
    },
  },
  root: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(3),
    padding: theme.spacing(3),
    borderRadius: theme.spacing(3),
    backgroundColor: theme.palette.text.primary,
    color: theme.palette.background.default,
    overflow: "hidden",
  },
  connectionText: {
    display: "flex",
    gap: theme.spacing(1),
    alignItems: "center",
  },
  connectionDiagramContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: theme.spacing(1),
    padding: theme.spacing(4),
    backgroundColor: theme.palette.background.default,
    borderRadius: theme.spacing(2),
    color: ({ erpStatus }) =>
      erpStatus ? theme.palette.success.main : theme.palette.text.disabled,
  },
  diagramCard: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: theme.spacing(4),
    borderRadius: theme.spacing(3),
    border: ({ erpStatus }) =>
      erpStatus
        ? `2px solid ${theme.palette.success.main}`
        : `2px solid ${theme.palette.divider}`,
    backgroundColor: theme.palette.background.default,
  },
  connectionContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  logo: {
    width: "48px",
    height: "48px",
    objectFit: "contain",
  },
  circle: {
    width: "8px",
    height: "8px",
  },
  dashedLine: {
    border: ({ erpStatus }) =>
      erpStatus ? `1px dashed ${theme.palette.success.main}` : "",
    width: "120px",
    height: "2px",
  },
  detailContainer: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(1),
  },
  detailItem: {
    display: "flex",
    justifyContent: "space-between",
  },
}));

export const ErpConfigurationDetailPopover: React.FC<Partial<PopoverProps>> = ({
  ...props
}) => {
  const { t } = useTranslation();
  const erpConfiguration = useSelector(getErpConfiguration);
  const erpStatus = erpConfiguration?.erp_connection_status?.value;
  const connectedErpType: ErpConfigurationType =
    erpConfiguration?.erp_type?.value;
  const classes = useStyles({
    erpStatus: erpStatus,
  });
  const { id, anchorEl, open, onClose } = props;

  return (
    <Popover
      id={id}
      open={open}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
      onClose={onClose}
      disableRestoreFocus
      className={classes.popover}
      {...props}
    >
      <Box className={classes.root}>
        <Box className={classes.connectionText}>
          {erpStatus ? (
            <WifiTetheringIcon color={"success"} />
          ) : (
            <WifiTetheringOffIcon color={"inherit"} />
          )}
          <Typography variant="body1" color={"inherit"}>
            {erpStatus ? (
              <Trans
                i18nKey="erpConfiguration:connectedWith"
                values={{ erpName: getErpConfigurationName(connectedErpType) }}
                components={[<b />]}
              />
            ) : (
              <Trans
                i18nKey="erpConfiguration:connectionLostWith"
                values={{ erpName: getErpConfigurationName(connectedErpType) }}
                components={[<b />]}
              />
            )}
          </Typography>
        </Box>
        <Box className={classes.connectionDiagramContainer}>
          <Box className={classes.diagramCard}>
            <img
              src={NextAxyaLogo}
              alt="next-axya-logo"
              className={classes.logo}
            />
          </Box>
          <Box className={classes.connectionContainer}>
            <CircleIcon color="inherit" className={classes.circle} />
            <Box className={classes.dashedLine}></Box>
            <CircleIcon color="inherit" className={classes.circle} />
          </Box>
          <Box className={classes.diagramCard}>
            <img
              src={getErpConfigurationIcon(connectedErpType)}
              alt="next-axya-logo"
              className={classes.logo}
            />
          </Box>
        </Box>
        <Box className={classes.detailContainer}>
          <Box className={classes.detailItem}>
            <Typography variant="body2" color={"GrayText"}>
              {t("erpConfiguration:entries")}
            </Typography>
            <Typography variant="body2" color={"GrayText"}>
              {t("erpConfiguration:lastSync")}
            </Typography>
          </Box>
          {erpConfiguration?.erp_connection_status
            ?.last_synchronization_date ? (
            <Box className={classes.detailItem}>
              <Typography variant="body2" color={"inherit"}>
                {t("erpConfiguration:connectivity")}
              </Typography>

              <Typography variant="body2" color={"inherit"}>
                {formatDateTimeAsCalender(
                  erpConfiguration?.erp_connection_status
                    ?.last_synchronization_date
                )}
              </Typography>
            </Box>
          ) : null}
          {erpConfiguration?.po_synchronization?.last_synchronization_date ? (
            <Box className={classes.detailItem}>
              <Typography variant="body2" color={"inherit"}>
                {t("erpConfiguration:orders")}
              </Typography>

              <Typography variant="body2" color={"inherit"}>
                {formatDateTimeAsCalender(
                  erpConfiguration?.po_synchronization
                    ?.last_synchronization_date
                )}
              </Typography>
            </Box>
          ) : null}
          {erpConfiguration?.project_synchronization
            ?.last_synchronization_date ? (
            <Box className={classes.detailItem}>
              <Typography variant="body2" color={"inherit"}>
                {t("erpConfiguration:projects")}
              </Typography>
              <Typography variant="body2" color={"inherit"}>
                {formatDateTimeAsCalender(
                  erpConfiguration?.project_synchronization
                    ?.last_synchronization_date
                )}
              </Typography>
            </Box>
          ) : null}
        </Box>
      </Box>
    </Popover>
  );
};
