import { Box, Typography } from "@mui/material";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ProjectPartTableToolbar } from "./project-part-table.toolbar";
import { useTranslation } from "react-i18next";
import {
  PartTableDataProps,
  usePartTableData,
} from "./project-part-table.hooks";
import { v4 as uuid } from "uuid";
import {
  selectProjectBulkDeletePartLoading,
  selectProjectBulkInsertPartLoading,
  selectProjectCompanyPartsCount,
  selectProjectCompanyPartsLoading,
  selectProjectCompanyPartsSelectAll,
  selectProjectStats,
  selectProjectTemplates,
  selectProjectTemplatesLoading,
  selectSelectedPart,
  selectSelectedPartRows,
} from "@next/modules/project/redux/selectors";
import { projectActions } from "@next/modules/project/redux/slices";
import {
  ProjectCompanyPart,
  ProjectTemplate,
} from "@next/modules/project/redux";
import { modalsActions } from "@next/redux/modalsSlices";
import { ProjectFileDialogModal } from "@next/modules/project/modals/project-file-dialog-modal";
import { ProjectPartDeleteConfirmationModal } from "@next/modules/project/modals/project-part-delete-confirmation-modal";
import { ProjectModalTypes } from "@next/modules/project/modals/types";
import { ProjectsPartField } from "./types";
import { ProjectBulkFileImportModal } from "../../modals/project-bulk-file-import-modal";
import { PAGINATION_PAGE_SIZE } from "@next/constants";
import {
  GridCellEditCommitParams,
  GridCellParams,
  GridFilterModel,
  GridLinkOperator,
  GridRenderCellParams,
  GridRowModel,
  GridRowParams,
  MuiEvent,
  useGridApiRef,
} from "@mui/x-data-grid-pro-v5";
import { addOrRemove } from "@next/utils/arrayUtils";
import { getProjectPartTableCheckboxColumn } from "./project-part-table.columns";
import { debounce } from "lodash";
import { SearchTextField } from "@next/components/search-text-field";
import i18n from "assets/configi18n/i18n";
import { ProjectBulkPartImportLoaderModal } from "../../modals/project-bulk-part-import-loader-modal";
import { enqueueSnackbar } from "notistack";
import { NoRowsOverlayBase } from "@next/components/no-rows-overlay-with-api/no-rows-overlay.base";
import {
  ProjectPartTableEditMenu,
  ProjectPartTableMenuItemType,
} from "./project-part-table.menu";
import {
  CustomNumberColumnType,
  CustomStringColumnType,
} from "@next/constants/data-grid-pro-v5-constants";
import TemplateSelector from "./template-selector";
import { createStyles, makeStyles } from "@mui/styles";
import { UpperSectionFromRfqCreation } from "./upper-section-from-rfq-creation";
import { RfqDataToPartsModal } from "@next/modules/workspace/modals/rfq-add-filesandparts-modal/rfq-add-filesandparts-modal";
import { selectRfqFormAdditionalFiles } from "@next/modules/workspace/redux/selectors";
import DownloadableFileItem from "@next/components/downloadable-file-item/downloadable-file-item";
import { DataGridProV5 } from "@next/components/data-grid-pro-v5";

const INITIAL_PAGE = 1;

const useStyles = makeStyles(() =>
  createStyles({
    grid: {
      "& .MuiDataGrid-columnHeader": {
        animation: "fadein 1.5s",
      },
      "& .MuiDataGrid-main": {
        borderTop: "solid 1px rgba(224, 224, 224, 1)",
        overflow: "unset",
      },
      "& .MuiDataGrid-columnHeaderTitleContainerContent": {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      },
    },
    gridDefault: {
      "& .MuiDataGrid-main": {
        borderTop: "solid 1px rgba(224, 224, 224, 1)",
      },
      "& .MuiDataGrid-columnHeaderTitleContainerContent": {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      },
    },
  })
);

type Props = {
  rfqData?: RfqDataToPartsModal;
};

export const ProjectPartTable: React.FC<Props> = ({ rfqData }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const gridApiRef = useGridApiRef();
  const [menuAnchorEl, setMenuAnchorEl] = useState<null | Element>(null);
  const [searchValue, setSearchValueState] = useState<string | undefined>();
  const projectTemplates = useSelector(selectProjectTemplates);
  const projectTemplatesLoading = useSelector(selectProjectTemplatesLoading);

  const selectedPart = useSelector(selectSelectedPart);
  const projectStats = useSelector(selectProjectStats);
  const projectCompanyPartsCount = useSelector(selectProjectCompanyPartsCount);

  const selectedPartRows = useSelector(selectSelectedPartRows);
  const projectCompanyPartsLoading = useSelector(
    selectProjectCompanyPartsLoading
  );
  const projectBulkInsertPartLoading = useSelector(
    selectProjectBulkInsertPartLoading
  );
  const projectCompanyPartsSelectAll = useSelector(
    selectProjectCompanyPartsSelectAll
  );

  const projectBulkDeletePartLoading = useSelector(
    selectProjectBulkDeletePartLoading
  );

  const rfqAdditionalFiles = useSelector(selectRfqFormAdditionalFiles);

  const [projectPartTableDataParams, setProjectPartTableDataParams] =
    useState<PartTableDataProps>({
      currentPage: INITIAL_PAGE,
      debouncedSearchValue: "",
      filterModel: { items: [], linkOperator: GridLinkOperator.And },
    });

  const { gridData, setGridData } = usePartTableData({
    ...projectPartTableDataParams,
    fromRfq: !!rfqData,
  });

  const resetSelections = () => {
    dispatch(projectActions.setSelectedPartRows([]));
    dispatch(projectActions.setProjectCompanyPartsSelectAll(false));
  };

  const handleTemplateChange = useCallback(
    (template: ProjectTemplate) => {
      if (projectStats?.code) {
        dispatch(projectActions.updateProjectTemplateInState(template));
        dispatch(
          projectActions.updateProjectTemplateRequest({
            projectCode: projectStats.code,
            template: template.id,
          })
        );
      }
    },
    [projectStats?.code]
  );

  const empty =
    !projectCompanyPartsLoading && projectStats?.total_parts_count === 0;
  const showTemplateSelector = empty && projectTemplates?.length > 0;

  const fetchProjectTemplates = useCallback(
    debounce(
      () => {
        dispatch(projectActions.fetchProjectTemplatesRequest());
      },
      1000,
      { leading: false, trailing: true }
    ),
    []
  );

  useEffect(() => {
    if (empty) fetchProjectTemplates();
  }, [empty]);

  useEffect(() => {
    resetSelections();

    return () => {
      resetSelections();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (rfqData?.onPartsCountChange) {
      rfqData.onPartsCountChange({
        partsCount: projectCompanyPartsCount || 0,
        filesCount:
          (projectStats?.attached_files_count || 0) +
          (rfqAdditionalFiles?.length || 0),
      });
    }
  }, [
    projectCompanyPartsCount,
    rfqAdditionalFiles,
    projectStats?.attached_files_count,
  ]);

  const isCellEditable = (params: GridCellParams) =>
    !(
      params.colDef.editable &&
      params.field === "quantity" &&
      params.row.winner
    );

  const onCellEditCommit = useCallback(
    (params: GridCellEditCommitParams & { api?: any }) => {
      let field = params.field;
      let value = params.value;

      if (field === "processes") {
        if (value instanceof Object || !value) {
          return;
        }

        value = [value];
      }

      if (gridApiRef.current) {
        const row = gridApiRef.current.getRow(params.id);

        if (row) {
          // Here, we perform operations to save the extraFields in the appropriate format for the API.
          const field_parts = field.split(".");
          if (field_parts.length > 1) {
            field = "extra_fields";
            const extraFields = row[field];
            const oldFieldValue = extraFields?.[field_parts[1]];
            if (oldFieldValue === params.value) {
              return;
            }
            value = {
              [field_parts[1]]: params.value,
            };
          } else {
            const oldFieldValue = row.field;
            if (params.value === oldFieldValue) {
              return;
            }
          }
        }
      }

      const updates = {
        [field]: value,
      };

      dispatch(
        projectActions.updateProjectPartRequest({
          pk: params.id as number,
          part: updates,
          skipSuccessToast: true,
        })
      );
    },
    [projectStats?.pk, gridApiRef.current]
  );

  const onClickAddPart = () => {
    const newRow = {
      name: "New Part",
      pk: `${uuid()}`,
    };
    setGridData({
      ...gridData,
      rows: [newRow, ...gridData.rows],
    });
    dispatch(
      projectActions.createProjectPartRequest({
        part: {
          name: newRow.name,
          project: projectStats?.pk,
        },
        skipSuccessToast: true,
      })
    );
  };

  const onAddPart = (part: ProjectCompanyPart) => {
    setGridData({
      ...gridData,
      rows: [part, ...gridData.rows],
    });
  };

  const onCellClick = (
    params: GridCellParams,
    event: MuiEvent<React.MouseEvent<Element, MouseEvent>>
  ) => {
    dispatch(projectActions.setSelectedPart(params.row as ProjectCompanyPart));

    switch (params.field) {
      case ProjectsPartField.DISPLAY_NAME:
        if (params.row.rfq) {
          dispatch(
            modalsActions.showModal({
              key: ProjectModalTypes.RFQ_DETAILS_MODAL,
              data: params.row.rfq,
            })
          );
        }

        break;
      case ProjectsPartField.EDIT_BUTTON:
        setMenuAnchorEl(event?.currentTarget);
        break;
    }
  };

  const onImportFile = (file: any) => {
    if (file) {
      dispatch(projectActions.resetBulkPartImportReport());

      dispatch(
        projectActions.bulkInsertProjectPartRequest({
          file,
        })
      );
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const setSearchValueWithDebounce = useCallback(
    debounce(
      (value) =>
        setProjectPartTableDataParams((prev) => ({
          ...prev,
          currentPage: INITIAL_PAGE,
          debouncedSearchValue: value,
        })),
      1000
    ),
    []
  );

  const setSearchValue = (value: string) => {
    setSearchValueState(value);
    setSearchValueWithDebounce(value);

    if (selectedPartRows.length > 0) {
      resetSelections();
      enqueueSnackbar(`${t("project:selectionCleared")}`, {
        autoHideDuration: 4000,
        disableCloseButton: true,
      });
    }
  };

  const clearSearch = () => {
    setSearchValueState("");
    setProjectPartTableDataParams((prev) => ({
      ...prev,
      currentPage: INITIAL_PAGE,
      debouncedSearchValue: "",
    }));
  };

  const onPageChange = (newPage: number) => {
    setProjectPartTableDataParams((prev) => ({
      ...prev,
      currentPage: newPage + 1,
    }));
  };

  const checkboxColumn = useMemo(
    () => {
      const gridRowsFiltered = gridData.rows?.filter((row) => !row?.rfq);
      const selectedPartRowsFiltered = selectedPartRows?.filter(
        (row) => !row?.rfq
      );

      const onCheckboxChange =
        ({
          row,
          selectAll = false,
        }: {
          row?: GridRenderCellParams["row"];
          selectAll?: boolean;
        }) =>
        () => {
          if (projectCompanyPartsSelectAll && !selectAll) {
            dispatch(projectActions.setProjectCompanyPartsSelectAll(selectAll));
            dispatch(
              projectActions.setSelectedPartRows(
                selectedPartRowsFiltered?.length === PAGINATION_PAGE_SIZE && row
                  ? addOrRemove(selectedPartRowsFiltered, row)
                  : []
              )
            );
          } else {
            dispatch(
              projectActions.setSelectedPartRows(
                selectAll
                  ? selectedPartRowsFiltered?.length === PAGINATION_PAGE_SIZE ||
                    selectedPartRowsFiltered?.length > 0
                    ? []
                    : gridRowsFiltered
                  : row
                  ? addOrRemove(selectedPartRowsFiltered, row)
                  : []
              )
            );
          }
        };

      return getProjectPartTableCheckboxColumn(
        onCheckboxChange,
        selectedPartRowsFiltered,
        projectCompanyPartsLoading || projectBulkDeletePartLoading,
        projectCompanyPartsSelectAll,
        gridRowsFiltered?.length === 0
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      selectedPartRows,
      projectCompanyPartsLoading,
      projectBulkDeletePartLoading,
      gridData.rows,
      projectCompanyPartsSelectAll,
      projectPartTableDataParams?.filterModel,
    ]
  );

  const onClickOnBulkImport = () => {
    dispatch(projectActions.resetBomFilesUploadReport());

    dispatch(
      modalsActions.showModal({
        key: ProjectModalTypes.BULK_FILE_IMPORT_MODAL,
        data: [],
      })
    );
  };

  const onFilterChange = (filterModel: GridFilterModel) => {
    dispatch(
      projectActions.setProjectCompanyPartsFiltered(
        filterModel.items.length > 0
      )
    );

    resetSelections();

    setProjectPartTableDataParams((prev) => ({
      ...prev,
      filterModel,
    }));

    if (filterModel.items.length === 0) {
      gridApiRef.current.hideFilterPanel();
    }
  };

  const onClickMenuItem = (menuItem: ProjectPartTableMenuItemType) => {
    switch (menuItem) {
      case ProjectPartTableMenuItemType.CLONE:
        dispatch(
          projectActions.cloneProjectPartRequest({
            partPk: Number(selectedPart?.pk),
          })
        );
        setMenuAnchorEl(null);
        break;
      case ProjectPartTableMenuItemType.DELETE:
        dispatch(
          modalsActions.showModal({
            key: ProjectModalTypes.PART_DELETE_CONFIRMATION_MODAL,
            data: selectedPart?.pk,
          })
        );
        setMenuAnchorEl(null);
        break;
    }
  };

  const loadingAdditionalOffset = projectBulkInsertPartLoading ? 30 : 0;

  return (
    <Box className="c-project-part-table c-parts-table-column-borders">
      <Box className="c-project-part-table__container">
        {!rfqData && (
          <Box
            className="c-project-part-table__container-search"
            style={{
              left:
                i18n?.language === "fr"
                  ? 480 + loadingAdditionalOffset
                  : 440 + loadingAdditionalOffset,
            }}
          >
            <SearchTextField
              clearSearch={clearSearch}
              setSearchValue={setSearchValue}
              searchValue={searchValue}
              disabled={
                projectCompanyPartsLoading || projectBulkDeletePartLoading
              }
            />
          </Box>
        )}
        {!!rfqData && (
          <UpperSectionFromRfqCreation
            onClickAddRow={onClickAddPart}
            onAddFile={onImportFile}
            rfqData={rfqData}
            onAddPart={onAddPart}
            isProjectEmpty={empty}
          />
        )}

        <DataGridProV5
          className={showTemplateSelector ? classes.grid : classes.gridDefault}
          apiRef={gridApiRef}
          autoHeight={!showTemplateSelector}
          disableSelectionOnClick
          rowHeight={32}
          headerHeight={39}
          pagination={!showTemplateSelector}
          autoPageSize
          pageSize={PAGINATION_PAGE_SIZE}
          onCellClick={onCellClick}
          rows={gridData?.rows || []}
          columns={[...checkboxColumn, ...gridData?.columns] || []}
          isCellEditable={isCellEditable}
          isRowSelectable={(params: GridRowParams) => !params.row.rfq}
          getRowId={(row: GridRowModel) => row.pk}
          onCellEditCommit={onCellEditCommit}
          components={{
            NoRowsOverlay: () =>
              showTemplateSelector && !rfqData ? (
                <TemplateSelector
                  value={projectStats?.template as ProjectTemplate}
                  onChange={handleTemplateChange}
                  options={projectTemplates}
                  disabled={!empty && projectTemplatesLoading}
                  onImport={onImportFile}
                  onClickOnBulkImport={onClickOnBulkImport}
                  projectCode={projectStats?.code || ""}
                  asProjectOverlay
                />
              ) : (
                <NoRowsOverlayBase />
              ),
            Toolbar: () =>
              !rfqData && (
                <ProjectPartTableToolbar
                  onClickAddPart={onClickAddPart}
                  onImport={onImportFile}
                  onClickOnBulkImport={onClickOnBulkImport}
                />
              ),
          }}
          onPageChange={onPageChange}
          paginationMode="server"
          rowCount={projectCompanyPartsCount}
          filterMode="server"
          onFilterModelChange={onFilterChange}
          columnTypes={{
            string: CustomStringColumnType,
            number: CustomNumberColumnType,
          }}
          localeText={{
            toolbarColumns: t("common:columns"),
            toolbarFilters: t("common:filters"),
          }}
        />

        {!!rfqData && rfqAdditionalFiles && rfqAdditionalFiles.length > 0 && (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "12px",
              paddingBottom: "20px",
            }}
          >
            <Typography variant="body2" color={"textSecondary"}>
              {t("workspaceNext:rfqDrawer:partsAndFilesModal:additionalFiles")}
            </Typography>
            {rfqAdditionalFiles.map((file) => (
              <DownloadableFileItem
                file={file}
                showSize
                onDelete={(file) => rfqData.onAttachmentsChange([], file.pk)}
              />
            ))}
          </Box>
        )}

        <ProjectPartTableEditMenu
          anchorEl={menuAnchorEl}
          setAnchorEl={setMenuAnchorEl}
          onClickMenuItem={onClickMenuItem}
          disabledClone={!selectedPart?.rfq}
        />
      </Box>

      {/* MODALS */}
      <ProjectFileDialogModal />
      <ProjectPartDeleteConfirmationModal />
      <ProjectBulkFileImportModal />
      <ProjectBulkPartImportLoaderModal />
    </Box>
  );
};
