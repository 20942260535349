// URL
import { backendUrl } from "../../urls";
import { handleResponse } from "../../helpers/responseHandling";
import { axiosApi } from "@next/api";

function requestDemo(email, company, erpType) {
  return axiosApi
    .post(backendUrl.requestDemo, {
      email: email?.toLowerCase(),
      company: company,
      erpType,
    })
    .then(handleResponse);
}

export const requestDemoApi = {
  requestDemo,
};
