import { Box, Grid } from "@mui/material";
import {
  GridCellParams,
  GridRowParams,
  MuiEvent,
} from "@mui/x-data-grid-pro-v5";
import { SHORT_PAGINATION_PAGE_SIZE } from "@next/constants";
import useSavedGridState from "@next/hooks/useSavedGridState-v5";
import { useTableSavedQueryPagination } from "@next/hooks/useTableSavedQueryPagination";
import { getRowIdToPk } from "@next/utils/dataGridUtils-v5";
import { history } from "helpers/history";
import useLocalStorage from "hooks/useLocalStorage";
import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { frontendUrl } from "urls";
import { Order, OrderSupplier } from "../../redux";
import {
  selectOrdersCounts,
  selectOrdersStatusCounts,
} from "../../redux/selectors";
import { OrderDetailMenu } from "../order-detail/order-detail.menu";
import OrdersSuppliersAutocompleteDropdown from "../orders-suppliers-autocomplete-dropdown/orders-suppliers-autocomplete-dropdown";
import { useOrdersTableData } from "./orders-table.hooks";
import { OrdersTableField } from "./orders-table.types";
import {
  SelectOrderStateCards,
  initialCardStateOrder,
} from "./select-order-state-cards/select-order-state-cards";
import { CardOrderState } from "./select-order-state-cards/select-order-state.cards.types";
import {
  ORDERS_GRID_COLUMN_STATE,
  ORDERS_GRID_TABLE_NAME,
} from "@next/constants/data-grid-state-constants";
import useTableFilterAndSort from "@next/hooks/useTableFilterAndSort-v5";
import { generateFilterQuery, getSortQueryString } from "./order-table.helpers";
import {
  CustomDateColumnType,
  CustomNumberColumnTypeV2,
  CustomStringColumnType,
} from "@next/constants/data-grid-pro-constants-v5";
import { DataGridProV5 } from "@next/components/data-grid-pro-v5";

type Props = {
  isCompletedOrders: boolean;
  disableFiltering?: boolean;
};

export const ORDERS_TABLE_NAVIGATION_KEY = "ORDERS_TABLE_NAVIGATION";

export const OrdersTable: React.FC<Props> = ({
  isCompletedOrders,
  disableFiltering,
}) => {
  const {
    handleSortChange,
    handleFilterChange,
    sortQueryString,
    sortModel,
    filterModel,
    filterQueryString,
  } = useTableFilterAndSort(getSortQueryString, generateFilterQuery);

  const { apiRef } = useSavedGridState(
    ORDERS_GRID_COLUMN_STATE,
    ORDERS_GRID_TABLE_NAME
  );

  const [selectedSuppliers, setSelectedSuppliers] = useState<OrderSupplier[]>(
    []
  );

  const {
    savedTableQueryPagination,
    setSavedTableQueryPagination,
    onPageChangeTable,
  } = useTableSavedQueryPagination(ORDERS_TABLE_NAVIGATION_KEY);
  const ordersStatusCounts = useSelector(selectOrdersStatusCounts);

  const [selectedStateFilter, setSelectedStateFilter] =
    useLocalStorage<CardOrderState>(
      "SAVED_SELECTED_STATE_FILTER",
      initialCardStateOrder(ordersStatusCounts?.total_count)
    );

  const { gridData, ordersSuppliers } = useOrdersTableData({
    isCompletedOrders,
    currentPage: savedTableQueryPagination?.p || 1,
    selectedStateFilter,
    selectedSuppliers,
    sortQueryString,
    filterQueryString,
  });
  const ordersCounts = useSelector(selectOrdersCounts);
  const [menuAnchorEl, setMenuAnchorEl] = useState<null | Element>(null);
  const [menuActiveData, setMenuActiveData] = useState<Order | null>(null);

  useEffect(() => {
    // Reset page to 1 when filter changes
    setSavedTableQueryPagination({
      ...savedTableQueryPagination,
      p: 1,
    });
  }, [selectedStateFilter?.slug]);

  const onCellClick = (
    params: GridCellParams,
    event: MuiEvent<React.MouseEvent<Element, MouseEvent>>
  ) => {
    switch (params?.field) {
      case OrdersTableField.EDIT_BUTTON:
        const buttonElement = event.currentTarget.querySelector("button");
        setMenuAnchorEl(buttonElement);
        setMenuActiveData(params.row as Order);
        break;
      case OrdersTableField.SHIPPING_NOTE:
        return;

      default:
        setSavedTableQueryPagination({
          ...savedTableQueryPagination,
          lsr: params?.row?.pk,
        });
        history.push(`${frontendUrl.orders}/${params.row.pk}`);
        break;
    }
  };

  const getRowClassName = (params: GridRowParams) =>
    `c-cursor-pointer c-highlighted-row--${
      params.id === savedTableQueryPagination?.lsr
    }`;

  // To fix flickeing when resizing column width
  const renderDataGrid = useMemo(
    () => (
      <DataGridProV5
        apiRef={apiRef}
        autoHeight
        autoPageSize
        pagination
        paginationMode="server"
        filterModel={filterModel}
        onFilterModelChange={handleFilterChange}
        sortingMode="server"
        filterMode="server"
        pageSize={SHORT_PAGINATION_PAGE_SIZE}
        rowCount={ordersCounts?.count}
        onSortModelChange={handleSortChange}
        sortModel={sortModel}
        onPageChange={onPageChangeTable}
        getRowId={getRowIdToPk}
        disableSelectionOnClick
        rowHeight={60}
        loading={false}
        onCellClick={onCellClick}
        rows={gridData?.rows}
        columns={gridData?.columns}
        getRowClassName={getRowClassName}
        columnTypes={{
          string: CustomStringColumnType,
          date: CustomDateColumnType,
          number: CustomNumberColumnTypeV2,
        }}
      />
    ),
    [gridData?.rows, gridData?.columns, ordersCounts?.count, filterModel]
  );

  return (
    <div>
      <Box mt="16px" mb="16px">
        <Grid container spacing={2}>
          {!disableFiltering ? (
            <SelectOrderStateCards
              selectedFilterState={selectedStateFilter}
              setSelectedFilterState={setSelectedStateFilter}
            />
          ) : null}

          <Grid item>
            <Box pr="16px">
              <OrdersSuppliersAutocompleteDropdown
                suppliers={ordersSuppliers || []}
                selectedSuppliers={selectedSuppliers}
                setSelectedSuppliers={setSelectedSuppliers}
              />
            </Box>
          </Grid>
        </Grid>
      </Box>
      {renderDataGrid}
      {/* This menu appears only 
          when OrdersTableField.EDIT_BUTTON is in columns. 
      */}
      <OrderDetailMenu
        anchorEl={menuAnchorEl}
        setAnchorEl={setMenuAnchorEl}
        menuActiveData={menuActiveData}
        refetchOrdersAfterAction={true}
      />
    </div>
  );
};
