import { applyMiddleware, createStore } from "redux";
import { history } from "helpers/history";

// Connected React Router
import { routerMiddleware } from "connected-react-router";
import { initStateWithPrevTab } from "redux-state-sync";

// Thunk
import thunk from "redux-thunk";

// Saga
import createSagaMiddleware from "redux-saga";
import rootSaga from "@next/redux/sagas";

// Redux Profiler (Used to look at the store state)
import { composeWithDevTools } from "redux-devtools-extension";

// Persistance
import { createTransform, persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";

import socketMiddleware from "./socket-middleware";
import SocketClient from "./socket-client";

// Services
import { rootReducer } from "services";
import { initialState } from "@next/modules/project/redux";

// Configuration for the persistance layer of Redux
const persistConfig = {
  key: "root",
  storage,
  // There is no need to persist modals reducer otherwise we need to clean it on every refresh.
  blacklist: ["modalsNext"],
  transforms: [
    createTransform(
      (inboundState) => {
        return inboundState;
      },
      (outboundState, key) => {
        switch (key) {
          case "projectNext":
            return { ...initialState, ...((outboundState as object) || {}) };
          default:
            return outboundState;
        }
      },
      { whitelist: ["projectNext"] }
    ),
  ],
};
const socketClient = new SocketClient();
// Here we create the persistance layer for redux using the config and the root reducer
const persistedReducer = persistReducer(persistConfig, rootReducer);

const sagaMiddleware = createSagaMiddleware({
  onError: (err, errorInfo) => {
    console.error("SAGA ERROR", { error: err, info: errorInfo.sagaStack });
  },
});

const middlewares = [
  thunk,
  sagaMiddleware,
  routerMiddleware(history),
  socketMiddleware(socketClient),
];

// Here we create the store and we wrap it in a persistance store.
// This means the store will be backed up in the localstorage at all time
export const store = createStore(
  persistedReducer,
  undefined,
  composeWithDevTools(applyMiddleware(...middlewares))
);
export const persistor = persistStore(store);

store.subscribe(() =>
  // sync the state to the localstorage
  {
    localStorage.setItem("accessToken", store?.getState()?.profile?.token);
    localStorage.setItem("userId", store?.getState()?.profile?.user?.id ?? "");
  }
);

initStateWithPrevTab(persistor as any);

sagaMiddleware.run(rootSaga);

export type RootState = ReturnType<typeof store.getState>;
