// General Imports
import React, { useEffect } from "react";

// Links
import { frontendUrl } from "../../urls";

import { Redirect } from "react-router-dom";

import { CircularProgress, Grid } from "@mui/material";

import "./logout.scss";

export function LogoutView(props) {
  const { refresh, user } = props.profile;

  useEffect(() => {
    if (user && refresh) {
      props.logout(refresh);
    }
  }, [user, refresh]);

  return props.isLoggedIn ? (
    <Grid
      container
      direction="column"
      justifyContent="center"
      alignItems="center"
      alignContent="center"
      className="logout"
    >
      <Grid item>
        <CircularProgress size={80} thickness={4} />
      </Grid>
    </Grid>
  ) : (
    <Redirect to={frontendUrl.login} />
  );
}
