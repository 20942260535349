import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

import { Button, Fade, Menu, MenuItem } from "@mui/material";
import GetAppIcon from "@mui/icons-material/GetApp";

import { projectActions } from "@next/modules/project/redux";

type Props = {
  projectCodes?: string[];
  isInDetail?: boolean;
  resetSelectedProject?: () => void;
};

const ExportMenu: React.FC<Props> = ({
  projectCodes = [],
  isInDetail,
  resetSelectedProject,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const disabledExport = projectCodes?.length === 0;
  const open = Boolean(anchorEl);

  const onClickDownloadAttachment = () => {
    dispatch(
      projectActions.downloadQuotationFileRequest({
        projectCodes,
      })
    );

    setAnchorEl(null);

    resetSelectedProject && resetSelectedProject();
  };

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Button
        id="fade-button"
        aria-controls="fade-menu"
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        variant={isInDetail ? "text" : "outlined"}
        startIcon={
          isInDetail ? <GetAppIcon style={{ fontSize: "18px" }} /> : null
        }
      >
        {t("project:navbar:button:extract")}
      </Button>
      <Menu
        id="fade-menu"
        MenuListProps={{
          "aria-labelledby": "fade-button",
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        TransitionComponent={Fade}
      >
        <MenuItem onClick={onClickDownloadAttachment} disabled={disabledExport}>
          {t("project:navbar:button:extractSelectedProject", {
            count: projectCodes?.length,
          })}
        </MenuItem>
      </Menu>
    </>
  );
};

export default ExportMenu;
