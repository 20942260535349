import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import {
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Theme,
} from "@mui/material";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import ClearIcon from "@mui/icons-material/Clear";
import DoneIcon from "@mui/icons-material/Done";
import { Order, OrderState, ordersActions } from "../../redux";
import { confirmDialog } from "@next/modals/shared-confirm-dialog";
import { enqueueSnackbar } from "notistack";
import { createStyles, makeStyles } from "@mui/styles";
import CustomIconButton from "../../../../components/custom-icon-button/custom-icon-button";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    listItemIcon: {
      minWidth: "32px",
    },
    icon: {
      width: "20px",
      height: "20px",
      color: "black",
    },
    menuIcon: {},
    iconButton: {
      borderRadius: "8px",
      width: "32px",
      height: "32px",
      marginLeft: theme.spacing(1),
      color: "black",
    },
  })
);

type Props = {
  anchorEl: null | Element;
  setAnchorEl: (anchorEl: null | Element) => void;
  clearMenuActiveData?: () => void;
  menuActiveData: Order | undefined | null;
  showIcon?: boolean;
  refetchOrdersAfterAction?: boolean;
};

export const OrderDetailMenu: React.FC<Props> = ({
  anchorEl,
  setAnchorEl,
  menuActiveData,
  showIcon,
  refetchOrdersAfterAction,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();
  const isDraftOrder = menuActiveData?.state.slug === OrderState.UNPUBLISHED;

  const onOpen = useCallback((e) => {
    setAnchorEl(e.currentTarget);
  }, []);

  const onClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const onClickCancelOrder = useCallback(() => {
    confirmDialog(
      `${t("orders:cancelOrder")}`,
      t("orders:cancelOrderConfirm"),
      () => {
        if (!menuActiveData?.pk) return;

        dispatch(
          isDraftOrder
            ? ordersActions?.deleteOrderRequest({
                pk: menuActiveData?.pk,
                refetchOrders: refetchOrdersAfterAction,
              })
            : ordersActions.updateOrderRequest({
                pk: menuActiveData?.pk,
                is_cancelled: true,
                is_completed: true,
                qa_token: menuActiveData?.qa_token,
              })
        );
      },
      t("orders:cancelOrder"),
      "default",
      t("common:back")
    );

    onClose();
  }, [menuActiveData?.pk, isDraftOrder]);

  const onClickMarkOrderAsDone = useCallback(() => {
    if (!menuActiveData?.pk) return;

    dispatch(
      ordersActions.updateOrderRequest({
        pk: menuActiveData?.pk,
        is_completed: !menuActiveData?.is_completed,
        refetchOrders: refetchOrdersAfterAction,
        onSuccess: () => {
          enqueueSnackbar(
            t(
              menuActiveData?.is_completed
                ? "orders:orderMarkedAsActive"
                : "orders:orderMarkedAsDone"
            ),
            {
              autoHideDuration: 3000,
            }
          );
        },
        qa_token: menuActiveData?.qa_token,
      })
    );

    onClose();
  }, [menuActiveData?.pk, menuActiveData?.is_completed]);

  return (
    <>
      {showIcon ? (
        <CustomIconButton onClick={onOpen} className={classes.iconButton}>
          <MoreHorizIcon className={classes.menuIcon} />
        </CustomIconButton>
      ) : null}

      <Menu
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={onClose}
      >
        <MenuItem
          onClick={onClickCancelOrder}
          disabled={menuActiveData?.is_completed}
        >
          <ListItemIcon className={classes.listItemIcon}>
            <ClearIcon className={classes.icon} />
          </ListItemIcon>
          <ListItemText
            primary={t(
              isDraftOrder ? "orders:deleteDraft" : "orders:menu:cancelOrder"
            )}
          />
        </MenuItem>

        {!isDraftOrder ? (
          <MenuItem
            onClick={onClickMarkOrderAsDone}
            disabled={menuActiveData?.is_cancelled}
          >
            <ListItemIcon className={classes.listItemIcon}>
              <DoneIcon className={classes.icon} />
            </ListItemIcon>
            <ListItemText
              primary={t(
                menuActiveData?.is_completed
                  ? "orders:menu:markOrderAsActive"
                  : "orders:menu:markOrderAsDone"
              )}
            />
          </MenuItem>
        ) : null}
      </Menu>
    </>
  );
};
