import { Box, Divider, Theme, useMediaQuery, useTheme } from "@mui/material";
import React, { useCallback, useEffect, useRef } from "react";

import { TopicType } from "@next/modules/workspace/redux/types";
import TopicAccordionWrapper from "./topic-accordion-wrapper";
import TopicTitle from "./topic-title";
import TopicMessage from "./topic-message";
import TopicMessageForm from "./topic-message-form";
import { useDispatch, useSelector } from "react-redux";
import {
  AnswerTopicInput,
  workspaceNextActions,
} from "@next/modules/workspace/redux";
import { useIsStaffUser } from "@next/modules/profile/hooks/useIsStaffUser";
import { createStyles, makeStyles } from "@mui/styles";
import { getRfqDetails } from "services/workspace/workspace.selectors";

type StyleProps = {
  requiresName?: boolean;
};

const useStyles = makeStyles<Theme, StyleProps>(() =>
  createStyles({
    root: {
      width: "100%",
      height: "100%",
      display: "flex",
      flexDirection: "column",
      "& .MuiAccordionSummary-content": {
        margin: 0,
      },
      "& .MuiAccordion-root.Mui-expanded": {
        height: "100%",
        overflowY: "hidden",
      },
    },
    fullWidth: { width: "100%" },
    footer: {
      width: "100%",
      height: ({ requiresName }) => (requiresName ? "140px" : "unset"),
    },
    dividerContainer: {
      paddingBottom: "16px",
    },
    divider: {
      width: "100%",
    },
    messages: {
      overflow: "auto",
      flex: 1,
      width: "100%",
    },
    disabled: { pointerEvents: "none", opacity: 0.3 },
  })
);

type Props = {
  topic: TopicType;
  initiallyOpen?: boolean;
  publicToken?: string;
  replyDisabled?: boolean;
  showToastOnMessage?: boolean;
  TitleComponent?: React.ReactNode;
  showExpandIcon?: boolean;
  disableAccordion?: boolean;
  isPortal?: boolean;
};

export const Topic: React.FC<Props & StyleProps> = ({
  topic,
  initiallyOpen,
  publicToken,
  replyDisabled,
  showToastOnMessage = true,
  TitleComponent,
  showExpandIcon,
  disableAccordion,
}) => {
  const dispatch = useDispatch();
  const messagesContainerRef = useRef<null | any>(null);
  const isStaffUser = useIsStaffUser();
  const rfqDetails = useSelector(getRfqDetails);
  const theme = useTheme();
  const onlyForMobile = useMediaQuery(theme.breakpoints.down(937));

  // first time scroll to bottom after some delay
  useEffect(() => {
    setTimeout(() => {
      scrollToBottom();
    }, 1000);
  }, []);

  // scroll to bottom on new message
  useEffect(() => scrollToBottom(), [topic?.messages?.length]);

  const scrollToBottom = () => {
    if (messagesContainerRef.current) {
      messagesContainerRef.current.scroll({
        top: messagesContainerRef.current.scrollHeight,
        behavior: "smooth",
      });
    }
  };

  const onSubmitNewMessage = (values: Partial<AnswerTopicInput>) => {
    if (values.answer) {
      dispatch(
        workspaceNextActions.answerQATopicRequest({
          topicToken: publicToken || topic?.token,
          answer: values.answer,
          firstName: values.firstName,
          lastName: values.lastName,
          attachments: values.attachments,
          showToastOnMessage,
        })
      );
    }
  };

  const handleOnChangeExpanded = useCallback(
    (expanded) => {
      if (
        expanded &&
        topic?.unseen_messages_count &&
        !publicToken &&
        !isStaffUser
      ) {
        dispatch(
          workspaceNextActions.qaMarkAsReadRequest({
            qaId: topic.pk,
            onSuccess: () => {
              dispatch(
                workspaceNextActions.fetchRFQQuestionsStatsRequest({
                  rfqId: rfqDetails?.pk.toString(),
                })
              );
            },
          })
        );
      }
    },
    [topic?.unseen_messages_count, publicToken, isStaffUser]
  );

  const classes = useStyles({
    requiresName: topic.is_profile_complete === false,
  });

  return (
    <div className={classes.root}>
      <TopicAccordionWrapper
        showExpandIcon={showExpandIcon}
        expandedKey={topic?.pk?.toString()}
        initiallyOpen={initiallyOpen}
        title={
          <div className={classes.fullWidth}>
            {TitleComponent !== undefined ? (
              TitleComponent
            ) : (
              <TopicTitle topic={topic} />
            )}
          </div>
        }
        onChangeExpanded={handleOnChangeExpanded}
        disableAccordion={disableAccordion}
      >
        <div className={classes.messages} ref={messagesContainerRef}>
          {topic?.messages?.map((message, index) => {
            return (
              <React.Fragment key={index}>
                {index > 0 ? (
                  <div className={classes.dividerContainer}>
                    <Divider className={classes.divider} />
                  </div>
                ) : null}

                <Box pt={index === 0 ? 2 : 0} mt={2}>
                  <TopicMessage message={message} key={index} index={index} />
                </Box>
              </React.Fragment>
            );
          })}
        </div>

        <div className={classes.fullWidth}>
          <Divider
            sx={{
              marginRight: onlyForMobile ? -20 : 0,
              marginLeft: onlyForMobile ? -20 : 0,
            }}
          />
        </div>

        {publicToken || topic?.token !== null ? (
          <div className={classes.footer}>
            <Box
              className={`${classes.fullWidth} ${
                replyDisabled ? classes.disabled : ""
              }`}
              p={2}
            >
              <TopicMessageForm
                requiresName={topic.is_profile_complete === false}
                onSubmit={onSubmitNewMessage}
                topic={topic}
              />
            </Box>
          </div>
        ) : null}
      </TopicAccordionWrapper>
    </div>
  );
};
