import { GridCellParams } from "@mui/x-data-grid-pro-v5";
import React, { useEffect } from "react";
import { useAddOrderPartsData } from "./add-order-parts-table.hooks";
import { Box, Button, Theme, Typography } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { formatDate } from "helpers/formatDate";
import { AddOrderPartsTableField } from "./add-order-parts-table.types";
import { useDispatch, useSelector } from "react-redux";
import { ordersActions } from "../../redux";
import { selectAddOrderPartsLoading, selectOrder } from "../../redux/selectors";
import { enqueueSnackbar } from "notistack";
import CustomButton from "@next/components/custom-button";
import { useTranslation } from "react-i18next";
import { createStyles, makeStyles, withStyles } from "@mui/styles";
import { DataGridProV5 } from "@next/components/data-grid-pro-v5";

const useStyles = makeStyles<Theme>((theme) =>
  createStyles({
    table: {
      "& .requiredCell": {
        border: `solid 0.5px ${theme.palette.error.main}`,
      },
    },
  })
);

export const AddOrderPartsTable = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();
  const orderDetail = useSelector(selectOrder);
  const addOrderPartsLoading = useSelector(selectAddOrderPartsLoading);
  const timeoutId = React.useRef<NodeJS.Timeout>();

  const { gridData, columns, onClickAddRow, apiRef } = useAddOrderPartsData();

  useEffect(() => {
    return () => {
      if (timeoutId.current !== undefined) clearTimeout(timeoutId.current);
    };
  }, []);

  const onClickAddItems = () => {
    /* here we need to wait for the updated data from data grid because
    data grid turns from edit mode to view mode after clicking the button
    precisely for delivery date field */
    timeoutId.current = setTimeout(() => {
      const updatedData = Array.from(apiRef?.current?.getRowModels()?.values());

      if (updatedData?.length === 0) {
        return enqueueSnackbar(t("orders:addOrderParts:pleaseInsertPartData"));
      }

      const isNotValid =
        updatedData?.filter((item) => {
          return (
            item[AddOrderPartsTableField.PART_NUMBER] === undefined ||
            item[AddOrderPartsTableField.QUANTITY] === undefined ||
            item[AddOrderPartsTableField.QUANTITY] < 1
          );
        })?.length > 0;

      if (isNotValid) {
        return enqueueSnackbar(
          t("orders:addOrderParts:pleaseFillRequiredFields")
        );
      }

      const rows = updatedData?.map((item) => {
        return {
          ...item,
          [AddOrderPartsTableField.DELIVERY_DATE]:
            item?.[AddOrderPartsTableField.DELIVERY_DATE] ||
            formatDate(new Date(), "YYYY-MM-DD"),
        };
      });

      const partsData = Array.from(
        rows?.map((item: any) => {
          delete item?.id;

          return item;
        })
      );

      if (orderDetail?.pk) {
        dispatch(
          ordersActions.addOrderPartsRequest({
            order_pk: orderDetail?.pk,
            parts: partsData as any,
          })
        );
      }
    }, 200);
  };

  const getCellClassName = (params: GridCellParams) => {
    const value = params?.row[params.field];

    switch (params?.field) {
      case AddOrderPartsTableField.PART_NUMBER:
        return value === undefined || value === "" ? "requiredCell" : "";
      case AddOrderPartsTableField.QUANTITY:
        return value === undefined || parseInt(value as string) < 1
          ? "requiredCell"
          : "";

      default:
        return "";
    }
  };

  return (
    <Box
      minHeight="400px"
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
      className={classes.table}
    >
      <Box mb="16px">
        <Typography>{t("orders:addOrderParts:subTitle")}</Typography>
      </Box>

      <StyledDataGridPro
        apiRef={apiRef}
        rows={gridData?.rows || []}
        columns={columns || []}
        rowHeight={40}
        disableSelectionOnClick
        hideFooter
        editMode="row"
        getCellClassName={getCellClassName}
      />

      <Box display="flex" justifyContent="space-between">
        <Box>
          <Button
            startIcon={<AddIcon />}
            variant="outlined"
            color="primary"
            onClick={onClickAddRow}
          >
            {t("orders:addOrderParts:newRow")}
          </Button>
        </Box>

        <Box>
          <CustomButton
            variant="contained"
            color="primary"
            onClick={onClickAddItems}
            loading={addOrderPartsLoading}
          >
            {t("orders:addOrderParts:addToOrder")}
          </CustomButton>
        </Box>
      </Box>
    </Box>
  );
};

const StyledDataGridPro = withStyles(() => {
  return {
    root: {
      "& .MuiDataGrid-editInputCell input": {
        padding: "9px",
      },
      maxHeight: 350,
    },
  };
})(DataGridProV5);
