import React, { useEffect, useMemo, useState } from "react";
import { SupplierReport } from "./supplier-report";
import { TabsView } from "@next/components/tabs-view";
import { TabPanel } from "@next/components/tab-panel";
import { RequestsReport } from "./requests-report";
import { OrdersReport } from "./orders-report";
import { Box, Typography } from "@mui/material";
import { AppThemeV5 } from "layout/AppTheme";
import { useDispatch, useSelector } from "react-redux";
import { workspaceNextActions } from "@next/modules/workspace/redux";
import { useTranslation } from "react-i18next";
import i18n from "assets/configi18n/i18n";
import { getCompany } from "services/profile/profile.selectors";

export enum AnalyticsTabType {
  REQUESTS = 0,
  ORDER = 1,
  SUPPLIER = 2,
}

export const Analytics = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [selectedTab, setSelectedTab] = useState(AnalyticsTabType.REQUESTS);

  const company = useSelector(getCompany);
  const isRfqAnalyticsFeatureEnabled = company?.is_rfq_analytics_enabled;
  const isPoAnalyticsFeatureEnabled = company?.is_po_analytics_enabled;

  useEffect(() => {
    dispatch(workspaceNextActions.fetchFavoriteSupplierGroupsRequest());
  }, []);

  const tabItems = useMemo(() => {
    const items = [];

    if (isRfqAnalyticsFeatureEnabled) {
      items.push({
        tabName: (
          <Box>
            <Typography>{t("analytics:requestsReport:tab")}</Typography>
          </Box>
        ),
      });
    }

    if (isPoAnalyticsFeatureEnabled) {
      items.push({
        tabName: (
          <Box>
            <Typography>{t("analytics:ordersReport:tab")}</Typography>
          </Box>
        ),
      });
    }

    // Uncomment and add conditions if needed for additional tabs
    // if (someOtherCondition) {
    //   items.push({
    //     tabName: (
    //       <Box>
    //         <Typography>Supplier report</Typography>
    //       </Box>
    //     ),
    //   });
    // }

    return items;
  }, [
    isRfqAnalyticsFeatureEnabled,
    isPoAnalyticsFeatureEnabled,
    i18n.language,
  ]);

  const tabHandleChange = (_: React.ChangeEvent<{}>, value: any) =>
    setSelectedTab(value);

  return (
    <Box>
      <Box p="36px" pt="8px" pb="0px" ml="-40px">
        <TabsView
          value={selectedTab}
          tabItems={tabItems}
          handleChange={tabHandleChange}
        />
      </Box>

      <Box
        pt="0px"
        sx={{ backgroundColor: AppThemeV5.palette.customBackground.light }}
      >
        <Box>
          <TabPanel value={selectedTab} index={AnalyticsTabType.REQUESTS}>
            <RequestsReport />
          </TabPanel>

          <TabPanel value={selectedTab} index={AnalyticsTabType.ORDER}>
            <OrdersReport />
          </TabPanel>

          <TabPanel value={selectedTab} index={AnalyticsTabType.SUPPLIER}>
            <SupplierReport />
          </TabPanel>
        </Box>
      </Box>
    </Box>
  );
};
