import React from "react";

import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { Formik } from "formik";

import { useAxyaTracking } from "@next/hooks/useAxyaTracking";

import { ContactUsFormProps, ContactUsFormValues } from "./contact-us.types";
import ContactUsFormBase from "./contact-us-form.base";

import { getValidationSchema } from "./contact-us.definitions";
import { workspaceNextActions } from "../../redux";

const ContactUsForm: React.FC<ContactUsFormProps> = ({
  formTitle,
  hasFormTitle,
  hasSubjectAndMsg,
  helpSubject,
  label,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const axyaTracking = useAxyaTracking();

  const initialValues: ContactUsFormValues = {
    subject: "" || (helpSubject as string),
    msg: "",
  };

  const validationSchema = getValidationSchema(hasSubjectAndMsg as boolean);

  const handleContactUs = ({ msg, subject }: ContactUsFormValues) => {
    dispatch(workspaceNextActions.contactUsRequest({ msg, subject }));
    axyaTracking({
      app: "axya-app",
      scout_category: "contact-us",
      scout_feature_name: `${
        hasSubjectAndMsg ? "contact-support" : "give-you-feedback"
      }`,
    });
  };

  return (
    <>
      <Formik
        render={(props) => (
          <ContactUsFormBase
            {...props}
            formTitle={formTitle}
            hasFormTitle={hasFormTitle}
            hasSubjectAndMsg={hasSubjectAndMsg}
            label={label}
          />
        )}
        initialValues={initialValues}
        onSubmit={handleContactUs}
        validationSchema={validationSchema}
      />
    </>
  );
};

export default ContactUsForm;
