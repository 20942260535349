export enum SharedModalTypes {
  CANCEL_RFQ_MODAL = "shared-cancel-rfq-modal",
  CANCEL_RFQ_CONFIRMATION_MODAL = "shared-cancel-rfq-confirmation-modal",
  EXTEND_RFQ_MODAL = "shared-extend-rfq-modal",
  ADD_COLLABORATOR_MODAL = "add-collaborator-modal",
  EXTEND_RFQ_CONFIRMATION_MODAL = "shared-extend-rfq-confirmation-modal",
  PROJECT_INTRODUCTION_MODAL = "shared-project-introduction-modal",
  ATTACH_FILE_DELETE_CONFIRMATION_MODAL = "shared-attach-file-delete-confirmation-modal",
  ORDER_PURCHASE_MODAL = "shared-order-purchase-modal",
  USER_RATING_MODAL = "shared-user-rating-modal",
  ADD_ON_HOLD_NOTE_MODAL = "shared-add-on-hold-note-modal",
  INVITE_COMPANY_USER_FORM = "shared-invite-company-user-form",
  GLOBAL_SEARCH = "shared-global-search",
  UPGRADE_TO_PREMIUM_MODAL = "shared-upgrade-to-premium-modal",
  SUPPLIER_ONBOARDING_MODAL = "shared-supplier-onboarding-modal",
  SUPPLIER_CONTACTS_LIST_MODAL = "shared-supplier-contacts-list-modal",
  INVITE_SUPPLIER_CONTACT_MODAL = "shared-invite-supplier-contact-modal",
  SUPPLIER_VIEW_PART_MODAL = "shared-supplier-view-part-modal",
}
