import React from "react";
import { AttachFileItem } from "./attach-file-item";
import { FileData } from "./attach-file-list.types";
import { Box, Typography } from "@mui/material";

export const AttachFileList: React.FC<{
  files: FileData[];
  onClickDelete?: (id: string) => void;
  title?: string;
  getDisablePaddingBottom?: boolean;
  readOnly?: boolean;
  downloadable?: boolean;
  disableFileItemMarginBottom?: boolean;
}> = ({
  files,
  onClickDelete,
  title,
  getDisablePaddingBottom,
  readOnly,
  downloadable = true,
  disableFileItemMarginBottom,
}) => {
  return (
    <div>
      {title ? (
        <Box mb={1}>
          <Typography variant="h6">{title}</Typography>
        </Box>
      ) : null}

      {files?.map((file, index) => (
        <AttachFileItem
          key={file?.file_name}
          file={file}
          onClickDelete={onClickDelete}
          disablePaddingBottom={getDisablePaddingBottom}
          readOnly={readOnly}
          downloadable={downloadable}
          disableFileItemMarginBottom={disableFileItemMarginBottom}
        />
      ))}
    </div>
  );
};
