import React from "react";
import { useSelector } from "react-redux";
import CurrencyFormat from "react-currency-format";

const CurrencyField = (props) => {
  const language = useSelector((state) => state.profile.user.language);
  const currency = props?.currency || "$";
  const toFixedValue =
    props?.toFixedValue !== undefined ? props.toFixedValue : 2;

  return (
    <CurrencyFormat
      value={Number.parseFloat(props.value).toFixed(toFixedValue)}
      displayType={"text"}
      thousandSeparator={language === "fr" ? " " : ","}
      decimalSeparator={language === "fr" ? "," : "."}
      isNumericString={true}
      prefix={props.hideCurrency ? "" : `${currency?.toUpperCase()} `}
    />
  );
};
export default CurrencyField;
