import { useCallback } from "react";
import { useTracking } from "react-tracking";
import { useSelector } from "react-redux";
import { getCompany, getUser } from "services/profile/profile.selectors";

type AxyaTrackingParams = {
  app?: string;
  scout_category?: string;
  scout_feature_name?: string;
  scout_action?: string;
  scout_origin?: string;
  scout_rfq_pk?: number;
  scout_rfq_name?: string;
  data?: {
    subject?: string;
    body?: string;
    suppliers?: number[];
  } & Record<string, any>;
};

export const useAxyaTracking = () => {
  const tracking = useTracking();
  const company = useSelector(getCompany);
  const user = useSelector(getUser);

  const axyaTrack = useCallback(
    ({
      scout_category,
      scout_feature_name,
      scout_action,
      scout_origin = "app",
      app,
      data,
      scout_rfq_name,
      scout_rfq_pk,
    }: AxyaTrackingParams) => {
      tracking.trackEvent({
        app,
        scout_category,
        scout_feature_name,
        scout_action,
        scout_origin,
        scout_company: company?.name,
        scout_username: user?.username,
        scout_user_type: user?.type,
        scout_rfq_name,
        scout_rfq_pk,
        data,
        scout_date: new Date(),
      });
    },
    [company, user, tracking]
  );

  return axyaTrack;
};
