import { Box, Divider, Link, Theme, Typography, alpha } from "@mui/material";
import React, { useMemo } from "react";
import { Trans, useTranslation } from "react-i18next";
import AddIcon from "@mui/icons-material/Add";
import { useDropzone } from "react-dropzone";
import { MultipleDNDFileUploadList } from "./multiple-dnd-file-upload-list";
import {
  DND_RESTRICTED_FILE_FORMATS,
  MAX_FILE_SIZE,
} from "./multiple-dnd-file-upload.utils";
import { CustomFileObject } from "./multiple-dnd-file-upload.types";
import { CustomTypography } from "../custom-typography";
import { IconCircle } from "../icon-circle/icon-circle";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import { createStyles, makeStyles } from "@mui/styles";
import { enqueueSnackbar } from "notistack";

type StyleProps = {
  isDragActive?: boolean;
  disableDndMargin?: boolean;
  compactMode?: boolean;
  dndBackgroundColor?: string;
  centerInnnerContent?: boolean;
};

const useStyles = makeStyles<Theme, StyleProps>((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      flexDirection: "column",
    },
    dndRoot: {
      flex: 1,
      minHeight: ({ compactMode }) => (compactMode ? "100px" : "270px"),
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      padding: "20px",
      margin: ({ disableDndMargin }) => (disableDndMargin ? "0px" : "20px"),
      marginTop: "0px",
      borderWidth: 2,
      borderRadius: "16px",
      borderColor: ({ isDragActive }) =>
        isDragActive ? theme.palette.primary.main : theme.palette.divider,
      borderStyle: "dashed",
      backgroundColor: ({ isDragActive, dndBackgroundColor }) =>
        isDragActive
          ? alpha(theme.palette.primary.main, 0.05)
          : dndBackgroundColor
          ? dndBackgroundColor
          : theme.palette.grey[50],
      color: theme.palette.text.secondary,
      outline: "none",
      transition: "border .24s ease-in-out",
      cursor: "pointer",
    },
    innerContent: {
      display: "flex",
      flexDirection: "column",
      alignItems: ({ centerInnnerContent }) =>
        centerInnnerContent ? "center" : "flex-start",
      justifyContent: ({ centerInnnerContent }) =>
        centerInnnerContent ? "center" : "flex-start",
      textAlign: ({ centerInnnerContent }) =>
        centerInnnerContent ? "center" : "left",
      width: "100%",
    },
    icon: {
      width: "24px",
      height: "24px",
      color: "white",
    },
    iconWrapper: {
      marginBottom: "16px",
    },
    title: { color: theme.palette.text.primary, whiteSpace: "pre-line" },
    subTitle: { color: theme.palette.text.secondary },
    subtitleText: {
      padding: "24px",
      paddingTop: "12px",
      paddingBottom: "0px",
      width: "100%",
    },
    files: {
      width: "100%",
      maxHeight: "160px",
      overflow: "auto",
      padding: "20px",
      paddingTop: "9px",
      paddingRight: "10px",
      paddingLeft: ({ disableDndMargin }) =>
        disableDndMargin ? "0px" : "20px",
    },
    importButton: {
      marginTop: "16px",
      marginBottom: "16px",
    },
    divider: {
      width: "100%",
    },
    lockIcon: {
      width: "20px",
      height: "20px",
      color: theme.palette.text.secondary,
    },
    textWithIconContainer: {
      display: "flex",
      alignItems: "center",
    },
    customIcon: {
      width: "40px",
      height: "40px",
      marginRight: "24px",
    },
  })
);

const MultipleDNDFileUpload: React.FC<{
  filesState: CustomFileObject[];
  setFilesState: (files: CustomFileObject[]) => void;
  disableDndMargin?: boolean;
  fileFormats?: string[];
  maxSize?: number;
  multiple?: boolean;
  compactMode?: boolean;
  dndBackgroundColor?: string;
  showSecureInfo?: boolean;
  centerInnnerContent?: boolean;
  customText?: string;
  customStartIcon?: string;
  showUploadList?: boolean;
}> = ({
  filesState = [],
  setFilesState,
  disableDndMargin,
  fileFormats,
  maxSize = MAX_FILE_SIZE,
  multiple = true,
  compactMode,
  dndBackgroundColor,
  showSecureInfo = true,
  centerInnnerContent = true,
  customText,
  customStartIcon,
  showUploadList = true,
}) => {
  const { t } = useTranslation();

  const onDrop = (acceptedFiles: File[]) => {
    const filteredFiles = acceptedFiles.filter((file) => {
      const fileExtension = file.name.split(".").pop();
      return !DND_RESTRICTED_FILE_FORMATS.includes(
        `${fileExtension.toUpperCase()}`
      );
    });
    setFilesState([
      ...filesState,
      ...filteredFiles.map((file) => {
        return { file, pk: -1 };
      }),
    ]);
    if (filteredFiles.length !== acceptedFiles.length) {
      return enqueueSnackbar(
        t("project:bulkFileImport:fileNotAccepted", {
          fileCount: acceptedFiles.length - filteredFiles.length,
        }),
        {
          variant: "error",
          autoHideDuration: 5000,
        }
      );
    }
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    multiple,
    accept: fileFormats?.map((item: string) => `.${item}`),
    maxSize: maxSize * 1024 * 1024,
  });
  const classes = useStyles({
    isDragActive,
    disableDndMargin,
    compactMode,
    dndBackgroundColor,
    centerInnnerContent,
  });

  const onClickDeleteFile = (customFileObject: CustomFileObject) => {
    setFilesState(
      filesState.filter(
        (f: any) => f.file?.name !== customFileObject.file?.name
      )
    );
  };

  const showInputAfterFileUpload = useMemo(() => {
    if (showUploadList && !multiple) return true;
    else if (!showUploadList && !multiple) return false;
  }, [multiple, showUploadList]);

  return (
    <Box width="100%">
      {showInputAfterFileUpload && filesState.length ? null : (
        <div {...getRootProps({ className: classes.dndRoot })}>
          <input {...getInputProps()} />

          <div className={classes.innerContent}>
            {!compactMode ? (
              <IconCircle className={classes.iconWrapper}>
                <AddIcon className={classes.icon} />
              </IconCircle>
            ) : null}
            <div className={classes.textWithIconContainer}>
              {customStartIcon ? (
                <img
                  src={customStartIcon}
                  alt="customStartIcon"
                  className={classes.customIcon}
                />
              ) : null}
              <div>
                <Typography variant="body1" className={classes.title}>
                  <Trans
                    i18nKey="project:bulkFileImport:browseOrDropFiles"
                    components={[
                      <Link style={{ cursor: "pointer" }}>Browse</Link>,
                    ]}
                  />
                </Typography>
                {customText ? (
                  <Typography variant="body2" className={classes.subTitle}>
                    {customText}
                  </Typography>
                ) : null}
              </div>
            </div>

            {!compactMode ? (
              <CustomTypography
                variant="caption"
                className={classes.subtitleText}
              >
                {t("project:bulkFileImport:restrictedFiles")}:{" "}
                {DND_RESTRICTED_FILE_FORMATS?.join(", ")}.
              </CustomTypography>
            ) : null}

            {!compactMode ? (
              <Typography variant="caption" className={classes.subtitleText}>
                {t("project:bulkFileImport:maximumSizePerFile", {
                  size: maxSize,
                })}
              </Typography>
            ) : null}
          </div>
        </div>
      )}

      {showSecureInfo && (
        <Box display="flex" alignItems="center" textAlign="left" mt="16px">
          <LockOutlinedIcon className={classes.lockIcon} />

          <Box ml="8px">
            <Typography color="textSecondary" variant="body1">
              {t("project:bulkFileImport:secureInfo")}
            </Typography>
          </Box>
        </Box>
      )}
      {filesState.length && showUploadList ? (
        <>
          {disableDndMargin ? null : <Divider className={classes.divider} />}

          <div className={classes.files}>
            <MultipleDNDFileUploadList
              files={filesState}
              onClickDeleteFile={onClickDeleteFile}
            />
          </div>
        </>
      ) : null}
    </Box>
  );
};

export default MultipleDNDFileUpload;
