import { IconButton, TextField, TextFieldProps, Theme } from "@mui/material";
import React, { useEffect, useState } from "react";
import ClearIcon from "@mui/icons-material/Clear";
import SearchIcon from "@mui/icons-material/Search";
import { useTranslation } from "react-i18next";
import { createStyles, makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    inputRoot: {
      height: "36.5px",
      ...theme.typography.body2,
      color: theme.palette.text.secondary,
    },
    textField: {
      [theme.breakpoints.down("md")]: {
        width: "100%",
      },

      "& .MuiInput-underline:before": {
        borderBottom: `1px solid ${theme.palette.divider}`,
      },
    },
  })
);

type Props = {
  clearSearch?: () => void;
  setSearchValue?: (searchValue: string) => void;
  searchValue?: string;
  disabled?: TextFieldProps["disabled"];
};

const SearchTextField: React.FC<Props> = ({
  searchValue,
  setSearchValue,
  clearSearch,
  disabled,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 250);

    return () => {
      clearTimeout(timer);
    };
  }, [disabled]);

  return (
    <TextField
      variant="outlined"
      size="small"
      value={searchValue}
      onChange={(event: any) => {
        setLoading(true);
        setSearchValue && setSearchValue(event?.target?.value);
      }}
      placeholder={t("workspaceNext:myParts:inputs:typeToSearch")}
      className={classes.textField}
      disabled={disabled}
      InputProps={{
        startAdornment: (
          <SearchIcon fontSize="small" style={{ marginRight: 4 }} />
        ),
        classes: { root: classes.inputRoot },
        endAdornment: (
          <IconButton
            title="Clear"
            aria-label="Clear"
            size="small"
            style={{
              visibility: searchValue && !loading ? "visible" : "hidden",
            }}
            onClick={clearSearch}
            disabled={disabled}
          >
            <ClearIcon fontSize="small" />
          </IconButton>
        ),
      }}
    />
  );
};

export default SearchTextField;
