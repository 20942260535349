import React from "react";
import { SellerDashboardCard } from "../seller-dashboard-card/seller-dashboard-card";
import { Box, Typography } from "@mui/material";
import { AppTheme } from "layout/AppTheme";
import { ProgressCircle } from "@next/components/progress-circle";
import { Link } from "react-router-dom";
import { frontendUrl } from "urls";
import CreateIcon from "@mui/icons-material/Create";
import { useTranslation } from "react-i18next";

interface Props {
  completenessPercentage?: number;
  allowEditAction?: boolean;
}

const SellerDashboardProfileCompletenessCard: React.FC<Props> = ({
  completenessPercentage,
  allowEditAction,
}) => {
  const { t } = useTranslation();
  const completionPercentage = completenessPercentage || 0;
  const isCompleted = completionPercentage === 100;

  return (
    <SellerDashboardCard
      variant={isCompleted ? "success" : "primary"}
      title={
        <Box display="flex" alignItems="center" gap="12px">
          {!isCompleted && (
            <Box
              width="8px"
              height="8px"
              borderRadius="50%"
              bgcolor={AppTheme.palette.primary.main}
            />
          )}
          {t("sellerDashboard:profileCompletedAt")}
        </Box>
      }
      content={
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          flex={1}
        >
          <Box display="flex" alignItems="center" gap="12px">
            <ProgressCircle
              value={completionPercentage}
              colors={[
                { value: 0, color: AppTheme.palette.primary.main },
                { value: 99, color: AppTheme.palette.primary.main },
                { value: 100, color: AppTheme.palette.success.main },
              ]}
            />
            <Typography variant="h6">{completionPercentage}%</Typography>
          </Box>
          {allowEditAction && (
            <Link to={frontendUrl.companyProfile}>
              <CreateIcon htmlColor="#000" />
            </Link>
          )}
        </Box>
      }
    />
  );
};

export default SellerDashboardProfileCompletenessCard;
