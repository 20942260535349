import React from "react";
import { useDispatch, useSelector } from "react-redux";

import { useTracking } from "react-tracking";

import { useTranslation } from "react-i18next";
import {
  Box,
  Button,
  Card,
  CardContent,
  Chip,
  Divider,
  Grid,
  Link,
  Typography,
} from "@mui/material";

import LockIcon from "@mui/icons-material/LockOutlined";
import LockOpenIcon from "@mui/icons-material/LockOpenOutlined";

// Helpers functions

import { history } from "helpers/history";
import { downloadFile } from "helpers/download";
import { frontendUrl } from "urls";

// actions
import { modalsActions } from "services/modals/modals.actions";

import { DownloadAllAsZip } from "components/utils/download-all-as-zip/download-all-as-zip.component";
import { QuoteStatus } from "components/common/table-list/types";
import { openProfileInNewTab } from "@next/utils/browserUtils";
import { RichTextEditor } from "@next/components/rich-text-editor";
import { AttachFileList } from "@next/components/attach-file/attach-file-list";
import { useIsLocationPath } from "@next/hooks/useIsPath";
import { LeadTime } from "@next/components/lead-time/lead-time";
import { SuppliersStats } from "./suppliers-stats";
import { CustomTypography } from "@next/components/custom-typography";
import StatusVariantTag from "@next/modules/workspace/components/status-variant-tag/status-variant-tag";
import AddendaItemViewOnly from "@next/modules/marketplace/components/addenda-item-view-only/addenda-item-view-only";

const CardInfo = ({
  quotation,
  displayName,
  additionalDetails,
  additionalDetailsRichText,
  tag,
  nda,
  supplier_signed_nda,
  contracting,
  dateToDisplay,
  subText,
}) => {
  const { t } = useTranslation("workspace", "userCompany");
  const isRFQDetailPage = useIsLocationPath(frontendUrl.requestForQuotations);
  const tracking = useTracking();
  const dispatch = useDispatch();
  const { company, user } = useSelector((state) => state.profile);
  const { showModal } = modalsActions;
  //use this the to show edit quotation button
  const isEditQuotationMode = useSelector(
    (state) => state.quotation.isEditQuotationMode
  );

  const handleDownloadClick = (e) => {
    e.stopPropagation();
    downloadFile(e);
  };

  const onClickOpenEditQuotationDialog = () => {
    dispatch(showModal(true, "isEditQuotationDialogOpen"));
    history.push(`${frontendUrl.reviseQuotation}/${quotation["pk"]}`);

    tracking.trackEvent({
      scout_category: "feature",
      scout_feature_name: "open_edit_quotation_dialog",
      scout_company: company["name"],
      scout_username: user["name"],
      scout_user_type: user["type"],
      scout_date: new Date(),
    });
  };

  const onClickSupplierName = () => {
    openProfileInNewTab(
      quotation?.company_info?.company_name,
      quotation?.company_info?.company_uuid
    );
  };

  return (
    <Card elevation={0} className="card-info">
      <CardContent className="card-info--card-content-name">
        <Grid container direction="row">
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Box display="flex" alignItems="center" className="card-info--box">
              <Box
                p={1}
                textAlign="left"
                fontSize="h4.fontSize"
                className="c-details-summary-header--quotation-name"
              >
                {nda && !supplier_signed_nda ? (
                  <LockIcon className="lock-icon" />
                ) : supplier_signed_nda &&
                  !supplier_signed_nda["is_approved"] ? (
                  <LockIcon className="lock-icon-grey" />
                ) : supplier_signed_nda &&
                  supplier_signed_nda["is_approved"] ? (
                  <LockOpenIcon className="lock-open-icon" />
                ) : null}
                <CustomTypography
                  variant="h5"
                  style={{ fontSize: 20 }}
                  maxWidth="calc(100vw - 722px)"
                >
                  {displayName}
                </CustomTypography>
              </Box>
              <Box className="card-info--tag" p={1} flexGrow={1}>
                {tag && Object.keys(tag).length && (
                  <Chip label={tag.name} variant="outlined" />
                )}
              </Box>
              <Box
                textAlign="left"
                className={
                  nda &&
                  (!supplier_signed_nda ||
                    !supplier_signed_nda["is_approved"]) &&
                  "disable-button-download-zip"
                }
              >
                <DownloadAllAsZip
                  rfqFilesInfos={{
                    ...quotation,
                    attachments: quotation?.attachments,
                  }}
                />
              </Box>
              {isEditQuotationMode && (
                <>
                  {quotation?.rfq_info?.status === QuoteStatus.ACTIVE && (
                    <Box p={1} display="flex" alignItems="center">
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={onClickOpenEditQuotationDialog}
                      >
                        {t("quotation:editQuotation")}
                      </Button>
                    </Box>
                  )}
                  {quotation?.is_revision_requested ? (
                    <StatusVariantTag withBg variant="revision-requested" />
                  ) : null}
                </>
              )}
            </Box>
          </Grid>
        </Grid>
        {isRFQDetailPage && !quotation?.is_private ? (
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <SuppliersStats stats={quotation?.stats} />
            </Grid>
          </Grid>
        ) : null}
      </CardContent>
      <Box mt={1}>
        <Divider />
      </Box>
      <CardContent className="card-info--card-content-description">
        <Box fontSize={14} className="card-info-color--text-light">
          {subText}
        </Box>
        {quotation?.rfq_info &&
        quotation?.rfq_info?.status === QuoteStatus.CONTRACT &&
        dateToDisplay ? (
          <Box fontSize={16} mt={1}>
            {dateToDisplay}
          </Box>
        ) : (
          <Box fontSize={16} mt={1}>
            <LeadTime value={dateToDisplay} />
          </Box>
        )}

        <Box mt={2}>
          <Box fontSize={14} mb={1} className="card-info-color--text-light">
            {t("userCompany:description")}
          </Box>
          <Box fontSize={16} className="card-info-details">
            {additionalDetails ? (
              <RichTextEditor
                readOnly
                defaultValue={additionalDetailsRichText || additionalDetails}
              />
            ) : (
              "-"
            )}
          </Box>
        </Box>

        {quotation?.attachments?.length ? (
          <Box mt="10px">
            <Box fontSize={14} mb={1} className="card-info-color--text-light">
              {t("attachedFiles")}
            </Box>
            <AttachFileList files={quotation?.attachments} />
          </Box>
        ) : null}
        {supplier_signed_nda &&
          supplier_signed_nda["supplier_signed_nda_file_url"] && (
            <Box mt={3} className="c-nda-signature">
              <Box fontSize={14} mb={1} className="card-info-color--text-light">
                {t("supplierNda:nda")}
              </Box>
              <Link
                download-link={
                  supplier_signed_nda["supplier_signed_nda_file_url"]
                }
                download
                onClick={(e) => handleDownloadClick(e)}
                className="c-nda-link"
              >
                {t("supplierNda:downloadNda")}
              </Link>
            </Box>
          )}
        {contracting && (
          <Box mt={3}>
            <Box fontSize={14} mb={1} className="card-info-color--text-light">
              {company["type"] === "FAB" ? t("HOME:buyer") : t("supplier")}
            </Box>

            <Box
              className="card-info-details c-cursor-pointer c-text-underline"
              onClick={onClickSupplierName}
            >
              <Typography variant="subtitle1">{contracting}</Typography>
            </Box>
          </Box>
        )}
        {(quotation?.addenda || []).map((addenda, i) => (
          <>
            <Box my={2} mx={-2}>
              <Divider />
            </Box>
            <AddendaItemViewOnly
              addenda={addenda}
              number={(quotation?.addenda?.length || 1) - i}
              downloadable={
                !nda ||
                (nda &&
                  supplier_signed_nda &&
                  supplier_signed_nda["is_approved"])
              }
            />
          </>
        ))}
      </CardContent>
    </Card>
  );
};

export default CardInfo;
