import { GenericObject } from "@next/modules/profile/redux";
import { t } from "assets/configi18n/i18n";
import * as yup from "yup";

const getValidationSchema = (hasSubjectAndMsg: boolean) => {
  let validationSchema: GenericObject = {};

  validationSchema = {
    msg: yup.string().required(t("userOnboarding:thisFieldIsRequired")),
  };
  if (hasSubjectAndMsg) {
    validationSchema.subject = yup
      .string()
      .required(t("userOnboarding:thisFieldIsRequired"));
  }

  return yup.object().shape(validationSchema);
};

export { getValidationSchema };
