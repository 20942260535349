import { Theme, alpha } from "@mui/material";
import React from "react";

import { createStyles, makeStyles } from "@mui/styles";

type Props = {
  iconColor: string;
};

const useStyles = makeStyles<Theme, Props>(() =>
  createStyles({
    root: {
      width: "40px",
      height: "40px",
      backgroundColor: (props) => alpha(props.iconColor, 0.15),
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      borderRadius: "8px",
    },
  })
);

export const DashboardCardIcon: React.FC<Props> = ({ children, iconColor }) => {
  const classes = useStyles({ iconColor });

  return (
    <div className={classes.root}>
      <div>{children}</div>
    </div>
  );
};
