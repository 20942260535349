import { Box, Theme } from "@mui/material";
import {
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
} from "@mui/x-data-grid-pro";
import {
  CustomDateColumnType,
  CustomStringColumnType,
} from "@next/constants/data-grid-pro-constants";
import { useHasCompanyRole } from "@next/hooks/useHasCompanyRole";
import { ProjectOrderTable } from "@next/modules/project/components/order/project-order-table-v4";
import { selectProjectCompanyOrdersLoading } from "@next/modules/project/redux/selectors";
import i18n from "assets/configi18n/i18n";
import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import { getBuyerRFQSTableColumns } from "./buyer-rfqs-table.columns";
import { getCompany } from "services/profile/profile.selectors";
import { REQUESTS_TABLE_NAVIGATION_KEY } from "../../pages/buyer-rfqs/buyer-rfqs";
import { useTableSavedQueryPagination } from "@next/hooks/useTableSavedQueryPagination";
import { useCompanyPlan } from "@next/hooks/useIsPremiumCompany";
import { createStyles, makeStyles } from "@mui/styles";
import useTableFilterAndSort from "@next/hooks/useTableFilterAndSort";
import { getSortQueryString } from "./buyer-rfqs-table.helpers";
import useLocalStorageGridState from "@next/hooks/useLocalStorageGridState";
import { BUYER_RFQ_GRID_COLUMN_STATE } from "@next/constants/data-grid-state-constants";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    toolbar: {
      display: "flex",
      alignItems: "center",
      borderBottom: `1px solid ${theme.palette.divider}`,
      paddingBottom: 10,
    },
    toolbarItem: {
      color: theme.palette.text.primary,
    },
    dataGrid: {
      borderTop: "none",
    },
  })
);

type Props = {
  query: string;
  isContractQuery?: boolean;
  isCancelledQuery?: boolean;
};

const BuyerRfqsTable: React.FC<Props> = ({
  query,
  isContractQuery = false,
  isCancelledQuery = false,
}) => {
  const { savedTableQueryPagination } = useTableSavedQueryPagination(
    REQUESTS_TABLE_NAVIGATION_KEY
  );
  const companyOrdersLoading = useSelector(selectProjectCompanyOrdersLoading);

  const {
    handleSortChange,
    handleFilterChange,
    sortQueryString,
    sortModel,
    filterModel,
  } = useTableFilterAndSort(getSortQueryString);

  // const gridApiRef = useGridApiRef();
  const { apiRef, restoreState } = useLocalStorageGridState(
    BUYER_RFQ_GRID_COLUMN_STATE
  );
  const classes = useStyles();
  const hasViewerRole = useHasCompanyRole(["viewer", "restricted_viewer"]);
  const company = useSelector(getCompany);

  const isFreemiumBuyer = useCompanyPlan("freemium");

  const buyerRFQSTableColumns = useMemo(
    () =>
      restoreState(
        getBuyerRFQSTableColumns(
          hasViewerRole,
          isContractQuery,
          company?.is_order_management_enabled,
          isCancelledQuery,
          savedTableQueryPagination?.lsr,
          isFreemiumBuyer
        )
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      companyOrdersLoading,
      hasViewerRole,
      isContractQuery,
      i18n?.language,
      company?.is_order_management_enabled,
      restoreState,
    ]
  );
  return (
    <ProjectOrderTable
      rfqDetailOpenVariant="inline"
      dataGridProProps={{
        columns: buyerRFQSTableColumns,
        rowHeight: 56,
        getRowClassName: () => `c-cursor-pointer`,
        sortingMode: "server",
        sortModel,
        onSortModelChange: handleSortChange,
        filterMode: "server",
        filterModel,
        onFilterModelChange: handleFilterChange,
        apiRef: apiRef,
        columnTypes: {
          string: CustomStringColumnType,
          date: CustomDateColumnType,
        },
        components: {
          Toolbar: () => (
            <Box className={classes.toolbar}>
              {!hasViewerRole ? (
                <GridToolbarColumnsButton className={classes.toolbarItem} />
              ) : null}
              <GridToolbarFilterButton className={classes.toolbarItem} />
            </Box>
          ),
        },
        className: classes.dataGrid,
      }}
      query={query}
      refetchDataAfterCancelRfq={true}
      refetchDataAfterExtendRfq={true}
      sortQueryString={sortQueryString}
      filterValues={filterModel.items}
      savedTableQueryKey={REQUESTS_TABLE_NAVIGATION_KEY}
    />
  );
};

export default React.memo(BuyerRfqsTable);
