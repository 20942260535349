import { history } from "helpers/history";
import { frontendUrl } from "urls";
import { slugifyString } from "./stringUtils";
import { hotjar } from "react-hotjar";
import { enqueueSnackbar } from "notistack";

export const openProfileInNewTab = (name: string | undefined, uuid: string) => {
  window.open(
    `${frontendUrl.companyProfile}/${slugifyString(name || "profile")}/${uuid}`,
    "_blank",
    "noreferrer"
  );
};

export const openBuyerProfileNewTab = (pk: number) => {
  window.open(`${frontendUrl.buyerProfile}/${pk}`, "_blank", "noreferrer");
};

export const getBrowserLanguage = () =>
  navigator.language.includes("en") ? "en" : "fr";

export const LOCAL_STORAGE_LANGUAGE_KEY = "selectedLanguage";

export const getSelectedLanguage = () => {
  const browserLanguage = getBrowserLanguage();

  return getLocalStorageStringValue(
    LOCAL_STORAGE_LANGUAGE_KEY,
    browserLanguage
  );
};

export const getLocalStorageStringValue = (
  key: string,
  initialValue: string = ""
): string => {
  let localValue = initialValue;
  try {
    const value = localStorage.getItem(key);

    localValue =
      value && typeof value === "string" ? JSON.parse(value) : initialValue;
  } catch (error) {
    localValue = initialValue;
  } finally {
    return localValue;
  }
};

export const TAB_QUERY_PARAM = "tab";

export const createRedirectTabUrl = (tabQueryParam: number | string) => {
  return `${history.location.pathname}?${TAB_QUERY_PARAM}=${tabQueryParam}`;
};

declare global {
  interface Window {
    _isHotjarInitialized: any;
    pendo: any;
  }
}

export const initHotjar = (profile: any) => {
  if (!profile) {
    return;
  }

  if (!window._isHotjarInitialized) {
    window._isHotjarInitialized = true;
    // Hotjar Tracking Code for https://app.axya.co/
    const hjid = 2812600;
    const hjsv = 6;
    const userId = profile?.user?.email;
    const companyName = profile?.user?.company?.name;
    const companyType = profile?.user?.company?.type;
    hotjar.initialize(hjid, hjsv);
    hotjar.identify(userId, {
      email: userId,
      company: companyName,
      type: companyType,
    });
  }
};

export const initPendo = (profile?: any) => {
  try {
    // This function creates anonymous visitor IDs in Pendo unless you change the visitor id field to use your app's values
    // This function uses the placeholder 'ACCOUNT-UNIQUE-ID' value for account ID unless you change the account id field to use your app's values
    // Call this function in your authentication promise handler or callback when your visitor and account id values are available
    // Please use Strings, Numbers, or Bools for value types.
    window.pendo.initialize({
      visitor: {
        ...(profile?.user
          ? // Required if user is logged in, default creates anonymous ID
            { id: profile?.user.id, ...profile.user }
          : undefined),
        hostname: window.location.hostname,
        // full_name:    // Recommended if using Pendo Feedback
        // role:         // Optional

        // You can add any additional visitor level key-values here,
        // as long as it's not one of the above reserved names.
      },

      account: {
        ...(profile?.company
          ? // Required if using Pendo Feedback, default uses the value 'ACCOUNT-UNIQUE-ID'
            {
              id: profile?.company.id, // Required if using Pendo Feedback, default uses the value 'ACCOUNT-UNIQUE-ID'
              ...profile.company,
            }
          : undefined),
        hostname: window.location.hostname,
        // name:         // Optional
        // is_paying:    // Recommended if using Pendo Feedback
        // monthly_value:// Recommended if using Pendo Feedback
        // planLevel:    // Optional
        // planPrice:    // Optional
        // creationDate: // Optional

        // You can add any additional account level key-values here,
        // as long as it's not one of the above reserved names.
      },
    });
  } catch (error) {
    console.log("Error initializing pendo", error);
  }
};

export const removeQueryParamsFromUrl = (params: string[] = []) => {
  try {
    let url = window.location.href;

    params.forEach((param) => {
      const paramStart = url.indexOf(`${param}=`);
      if (paramStart !== -1) {
        const paramEnd = url.indexOf("&", paramStart);
        if (paramEnd !== -1) {
          url = url.slice(0, paramStart) + url.slice(paramEnd + 1);
        } else {
          url = url.slice(0, paramStart - 1);
        }
      }
    });

    window.history.pushState({}, "", url);
  } catch (error) {
    console.log("Error removing query params from url", error);
  }
};

export const getQueryParamsFromUrl = () => {
  const queryParams: any = {};

  try {
    const queryString = window.location.search.substring(1);
    const queryPairs = queryString.split("&");

    queryPairs.forEach((pair) => {
      const [key, value] = pair.split("=");
      queryParams[key] = decodeURIComponent(value);
    });
  } catch (error) {
    console.log("Error getting query params from url", error);
  }

  return queryParams;
};

export function historyBack(fallbackUrl: string, delay = 500) {
  fallbackUrl = fallbackUrl || "/";
  const prevPage = window.location.href;

  window.history.go(-1);

  setTimeout(function () {
    if (window.location.href === prevPage) {
      window.location.href = fallbackUrl;
    }
  }, delay);
}

export const copyToClipboard = async (text: string) => {
  try {
    if ("clipboard" in navigator) {
      await navigator.clipboard.writeText(text);
    } else {
      document.execCommand("copy", true, text);
    }
  } catch (error) {
    enqueueSnackbar("Error while copy to clipboard", {
      variant: "error",
    });
    console.error(error);
  }
};
