import { GridSelectionModel } from "@mui/x-data-grid-pro";
import { ordersActions } from "@next/modules/orders/redux";
import {
  selectOrder,
  selectPortalOrder,
  selectUpdatePortalOrderShippingConfirmationLoading,
} from "@next/modules/orders/redux/selectors";
import { Formik } from "formik";
import { formatDate } from "helpers/formatDate";
import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { OrderDetailMarkAsShippedFormBase } from "./order-detail-mark-as-shipped-form.base";
import {
  OrderDetailMarkAsShippedFormState,
  orderDetailMarkAsShippedFormState,
  orderDetailMarkAsShippedFormValidationSchema,
} from "./order-detail-mark-as-shipped-form.definitions";
import { useQueryParam } from "@next/hooks/useQuery";
import { RightPanelTabs } from "../order-detail-right-tabs/order-detail-right-tabs";

type Props = {
  partsSelectionModel?: GridSelectionModel;
  onClose: () => void;
  isPortal?: boolean;
};

export const OrderDetailMarkAsShippedForm: React.FC<Props> = ({
  partsSelectionModel,
  onClose,
  isPortal,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const orderDetail = useSelector(selectOrder);
  const portalOrderDetail = useSelector(selectPortalOrder);
  const params = useParams<{ orderToken?: string }>();
  const submitLoading = useSelector(
    selectUpdatePortalOrderShippingConfirmationLoading
  );
  const [_rightPanelTab, setRightPanelTab] = useQueryParam("panelTab");

  const onSubmit = (values: OrderDetailMarkAsShippedFormState) => {
    dispatch(
      ordersActions.updateOrderShippingConfirmationRequest({
        orderToken: isPortal ? params?.orderToken : undefined,
        isPortal,
        orderPk: isPortal ? portalOrderDetail?.pk : orderDetail?.pk,
        shipment_note: values?.shippingNote,
        shipment_confirmation_date: formatDate(
          values?.shippedDate,
          "YYYY-MM-DD"
        ),
        line_item_ids: partsSelectionModel as number[],
        onSuccess: () => {
          setRightPanelTab(RightPanelTabs.COMMUNICATION);
        },
      })
    );
  };

  return (
    <Formik
      initialValues={orderDetailMarkAsShippedFormState}
      validationSchema={orderDetailMarkAsShippedFormValidationSchema}
      onSubmit={onSubmit}
      validateOnMount
      render={(props) => (
        <OrderDetailMarkAsShippedFormBase
          onClose={onClose}
          partsSelectionModel={partsSelectionModel}
          isPortal={isPortal}
          submitLoading={submitLoading}
          {...props}
        />
      )}
    />
  );
};
