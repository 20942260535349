import {
  TableRowMenu,
  TableRowMenuItem,
  TableRowMenuProps,
} from "@next/components/table-row-menu/table-row-menu";
import React, { useMemo } from "react";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";
import { modalsActions } from "@next/redux/modalsSlices";
import { ProjectModalTypes } from "../../modals/types";
import { useDispatch } from "react-redux";
import { ProjectCreateRenameFormVariant } from "./project-create-rename-form/project-create-rename-form";
import { ProjectCreateRenameModalProps } from "../../modals/project-create-rename-modal";
import { confirmDialog } from "@next/modals/shared-confirm-dialog";
import { useTranslation } from "react-i18next";
import { projectActions } from "../../redux";
import i18n from "assets/configi18n/i18n";

type Props = {
  projectCode?: string;
  projectName?: string;
  disableDeleteButton?: boolean;
} & TableRowMenuProps;

export const ProjectsTableRowMenu: React.FC<Props> = ({
  projectCode,
  projectName,
  disableDeleteButton,
  ...restProps
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const menuItems: TableRowMenuItem[] = useMemo(
    () => [
      {
        text: t("common:rename"),
        onClick: () => {
          dispatch(
            modalsActions.showModal({
              key: ProjectModalTypes.CREATE_RENAME_MODAL,
              data: {
                projectCode,
                projectName,
                variant: ProjectCreateRenameFormVariant.RENAME,
              } as ProjectCreateRenameModalProps,
            })
          );

          restProps.setAnchorEl(null);
        },
        Icon: EditOutlinedIcon,
      },
      {
        text: t("common:delete"),
        onClick: () => {
          confirmDialog(
            t("workspace:confirmation"),
            t("project:areYouSureDeleteProject"),
            () => {
              if (projectCode) {
                dispatch(
                  projectActions.deleteProjectRequest({ code: projectCode })
                );
              }

              restProps.setAnchorEl(null);
            }
          );
        },
        Icon: DeleteForeverOutlinedIcon,
        disabled: disableDeleteButton,
      },
    ],
    [disableDeleteButton, projectName, i18n.language, projectCode]
  );

  return <TableRowMenu {...restProps} menuItems={menuItems} />;
};
