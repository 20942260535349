import { Box, Chip } from "@mui/material";
import { CustomTypography } from "@next/components/custom-typography";
import { useHasCompanyRole } from "@next/hooks/useHasCompanyRole";
import { BuyerRfqsViewMenu } from "components/workspace/buyer-rfqs-view/buyer-rfqs-view-menu";
import CollaboratorsList from "components/workspace/buyer-rfqs-view/collaborators-list";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTracking } from "react-tracking";
import { modalsActions } from "services/modals/modals.actions";
import { getProfile } from "services/profile/profile.selectors";
import { getRfqDetails } from "services/workspace/workspace.selectors";

export const BuyerRfqsInformationHeader = () => {
  const rfqDetails = useSelector(getRfqDetails);
  const profile = useSelector(getProfile);
  const hasViewerRole = useHasCompanyRole(["viewer", "restricted_viewer"]);
  const tracking = useTracking();
  const dispatch = useDispatch();

  const handleOpenTour = () => {
    dispatch(modalsActions.showModal(true, "isRfqViewTourOpen"));

    // tracking open rfq view tour click
    tracking.trackEvent({
      scout_category: "tour_guide",
      scout_feature_name: "open_rfq_view_tour",
      scout_company: profile["company"]["name"],
      scout_username: profile["user"]["name"],
      scout_user_type: profile["user"]["type"],
      scout_date: new Date(),
    });
  };

  return (
    <Box display="flex" alignItems="center">
      <CustomTypography variant="h6">
        {rfqDetails.rfq_display_name}
      </CustomTypography>

      {rfqDetails?.tag && (
        <Box
          textAlign="left"
          fontWeight="fontWeightRegular"
          fontSize="h4.fontSize"
          ml="8px"
        >
          {rfqDetails?.tag && Object.keys(rfqDetails.tag).length && (
            <Chip label={rfqDetails.tag.name} variant="outlined" />
          )}
        </Box>
      )}

      <Box ml={1}>
        <CollaboratorsList
          data={{
            collaborators: rfqDetails.collaborators,
            rfqPk: rfqDetails.pk,
            createdBy: rfqDetails.created_by,
          }}
          editMode={!hasViewerRole}
          size="small"
        />
      </Box>

      {hasViewerRole ? null : (
        <Box ml={1}>
          <BuyerRfqsViewMenu
            rfqDetails={rfqDetails}
            handleOpenTour={handleOpenTour}
          />
        </Box>
      )}
    </Box>
  );
};
