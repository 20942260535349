import { IconButton, Menu, MenuItem, Theme, Tooltip } from "@mui/material";
import { Language } from "@mui/icons-material";
import { useIsLocationPath } from "@next/hooks/useIsPath";
import { useQuery } from "@next/hooks/useQuery";
import { profileNextActions } from "@next/modules/profile/redux";
import { checkIsPublicPage } from "@next/utils/apiUtils";
import {
  LOCAL_STORAGE_LANGUAGE_KEY,
  getBrowserLanguage,
} from "@next/utils/browserUtils";
import i18n from "assets/configi18n/i18n";
import useLocalStorage from "hooks/useLocalStorage";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { getUser, getUserLanguage } from "services/profile/profile.selectors";
import { frontendUrl } from "urls";
import { createStyles, makeStyles } from "@mui/styles";

export enum LANGUAGE {
  EN = "en",
  FR = "fr",
}

const useStyles = makeStyles<Theme, { disablePadding?: boolean }>(
  (theme: Theme) =>
    createStyles({
      iconButton: {
        backgroundColor: theme.palette.grey[300],
        borderRadius: "100%",
        padding: ({ disablePadding }) => (disablePadding ? "0px" : "8px"),
        cursor: "pointer",
      },
      menuPaper: { border: `solid 1px ${theme.palette.divider}` },
      menuItem: {
        textTransform: "uppercase",
      },
    })
);

type Props = {
  disablePadding?: boolean;
  noBg?: boolean;
  iconColor?: "white" | "black";
};

const LanguageToggle: React.FC<Props> = ({
  disablePadding,
  noBg,
  iconColor = "white",
}) => {
  const classes = useStyles({ disablePadding });
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const location = useLocation();
  const isAuthPage = location.pathname.includes("/auth");
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const userLanguage = useSelector(getUserLanguage);
  const user = useSelector(getUser);
  const isPublicPage = checkIsPublicPage();
  const isPortalPage = useIsLocationPath(frontendUrl.portal);

  const [query, setQuery] = useQuery();
  const queryLanguage = query?.lang as LANGUAGE;

  const browserLanguage = getBrowserLanguage();
  const [activeLanguage, setActiveLanguage] = useLocalStorage<LANGUAGE>(
    LOCAL_STORAGE_LANGUAGE_KEY,
    userLanguage || browserLanguage
  );

  useEffect(() => {
    i18n.changeLanguage(activeLanguage);

    import(`moment/locale/${activeLanguage === "en" ? "en-ca" : "fr"}`).then(
      () => {
        moment.locale(activeLanguage);

        if (
          user !== null &&
          activeLanguage !== userLanguage &&
          (!isPublicPage || (isPortalPage && query.rfq_token)) &&
          !isAuthPage
        ) {
          dispatch(
            profileNextActions.updateUserLanguageRequest({
              language: activeLanguage,
              token: isPortalPage
                ? query.rfq_token?.toString().split(".")[1] || ""
                : "",
            })
          );
        }
      }
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeLanguage]);

  useEffect(() => {
    if (
      queryLanguage &&
      (queryLanguage === LANGUAGE.EN || queryLanguage === LANGUAGE.FR) &&
      (isAuthPage || isPortalPage)
    ) {
      setActiveLanguage(queryLanguage);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryLanguage]);

  const handleChange = (event: React.MouseEvent<HTMLLIElement>) => {
    const newLanguage = event.currentTarget?.dataset?.value as LANGUAGE;

    setQuery({ lang: newLanguage });

    setActiveLanguage(newLanguage);

    window.location.reload();

    handleClose();
  };

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const buttonJSX = (
    <IconButton onClick={noBg ? handleClick : undefined} size="large">
      <Language style={{ color: iconColor }} />
    </IconButton>
  );

  return (
    <div>
      <Tooltip title={Boolean(anchorEl) ? "" : t("appTour:language")}>
        {noBg ? (
          buttonJSX
        ) : (
          <div className={classes.iconButton} onClick={handleClick}>
            {buttonJSX}
          </div>
        )}
      </Tooltip>

      <Menu
        id="language-toggle-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        elevation={0}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        PaperProps={{
          className: classes.menuPaper,
        }}
      >
        <MenuItem
          onClick={handleChange}
          selected={activeLanguage === LANGUAGE.FR}
          className={classes.menuItem}
          data-value={LANGUAGE.FR}
        >
          {LANGUAGE.FR}
        </MenuItem>
        <MenuItem
          onClick={handleChange}
          selected={activeLanguage === LANGUAGE.EN}
          className={classes.menuItem}
          data-value={LANGUAGE.EN}
        >
          {LANGUAGE.EN}
        </MenuItem>
      </Menu>
    </div>
  );
};

export default LanguageToggle;
