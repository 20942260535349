import { CircularProgress, CircularProgressProps, Theme } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import { AppTheme } from "layout/AppTheme";
import React from "react";

type StyleProps = {
  color?: string;
};

const useStyles = makeStyles<Theme, StyleProps>((theme: Theme) =>
  createStyles({
    root: {
      position: "relative",
      lineHeight: 0,
    },
    bottom: {
      color: theme.palette.grey[300],
    },
    top: {
      color: ({ color }) => color || theme.palette.primary.main,
      position: "absolute",
      left: 0,
    },
  })
);

type ColorBreakPoint = {
  value: number;
  color: string;
};

interface Props {
  value: number;
  colors?: ColorBreakPoint[];
}

const ProgressCircle: React.FC<Props & CircularProgressProps> = ({
  value,
  colors = [{ value: 100, color: AppTheme.palette.text.primary }],
  ...props
}) => {
  const color =
    colors.find((item) => value <= item.value)?.color ||
    AppTheme.palette.text.primary;
  const classes = useStyles({ color });

  return (
    <div className={classes.root}>
      <CircularProgress
        size={24}
        thickness={5.5}
        {...props}
        variant="determinate"
        className={classes.bottom}
        value={100}
      />
      <CircularProgress
        size={24}
        thickness={5.5}
        {...props}
        variant="determinate"
        className={classes.top}
        value={value}
      />
    </div>
  );
};

export default ProgressCircle;
