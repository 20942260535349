/**
 * The main authentication layout
 */

import React from "react";
import { Redirect, useLocation } from "react-router-dom";
import querystring from "querystring";
import { frontendUrl } from "../urls";
import "./AuthLayout.scss";
import LanguageToggle from "@next/components/language-toggle";
import { HelpToggle } from "@next/components/help-button/help-toggle";
import { Box } from "@mui/material";
import { SharedModals } from "@next/modals/shared-modals";
import { createStyles, makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) =>
  createStyles({
    toolbarRoot: {
      display: "flex",
      alignItems: "center",
      position: "absolute",
      top: "16px",
      right: "6px",
      zIndex: 999,

      "& .MuiSvgIcon-root": {
        color: ({ lightToolbar }) =>
          lightToolbar
            ? theme.palette.common.white
            : theme.palette.text.primary,
      },
    },

    axyaLogo: {
      display: "flex",
      alignItems: "center",
      padding: "12px",
      position: "absolute",
      top: "16px",
      left: "6px",
      zIndex: 999,
    },
  })
);

function AuthLayout({ children, routeConfig, ...rest }) {
  const profile = rest.profile;
  const location = useLocation();
  const { allowAuthenticated, lightToolbar } = routeConfig;

  const classes = useStyles({ lightToolbar });

  const rendered = (
    <div className="auth-root">
      <Box className={classes.toolbarRoot}>
        <HelpToggle noBg />
        <Box ml={-1}>
          <LanguageToggle noBg iconColor={lightToolbar ? "white" : "black"} />
        </Box>
      </Box>

      {children}
      <SharedModals />
    </div>
  );

  if (!!profile.token) {
    //if signed in
    const queryParams = location.search.replace("?", "");
    const qsObject = querystring.parse(queryParams);

    if (qsObject.redirect) {
      //if url has a redirect param then redirect to that path
      return <Redirect to={qsObject.redirect} />;
    }

    const { company } = profile;

    if (company?.id) {
      //if company exists
      const companyPermissions = company?.permissions;

      if (companyPermissions?.length > 0) {
        if ([1, 2].includes(companyPermissions[0]) && company.is_active) {
          //if an active buyer/seller company

          if (allowAuthenticated) {
            //if the route config wants to allow this route even if user is authenticated
            return rendered;
          }

          return (
            <Redirect
              to={{
                pathname: frontendUrl.dashboard,
                state: { fallback: "home" }, //notify AuthLayout to redirect to home based on user role
              }}
            />
          );
        } else return <Redirect to={frontendUrl.companyProfile} />;
      }
    } else return <Redirect to={frontendUrl.joinOrRegisterCompany} />;
  }

  return rendered;
}

export default AuthLayout;
