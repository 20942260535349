import { Box, Divider, Paper, Theme, Typography } from "@mui/material";
import { CustomTypography } from "@next/components/custom-typography";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectOrder } from "../../../redux/selectors";
import CustomButton from "@next/components/custom-button/custom-button";
import { modalsActions } from "@next/redux/modalsSlices";
import { OrdersModalTypes } from "../../../modals/types";
import { confirmDialog } from "@next/modals/shared-confirm-dialog";
import { useTranslation } from "react-i18next";
import { ordersActions } from "../../../redux";
import { AutoSavedStatus } from "@next/components/auto-saved-status/auto-saved-status";
import { OrderStateTag } from "../../order-state-tag";
import { OrderStepper } from "../../order-stepper";
import { createStyles, makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      padding: "24px",
      boxShadow: "none",
      border: `1px solid ${theme.palette.divider}`,
      width: "100%",
    },
    divider: {
      marginLeft: "-24px",
      marginRight: "-24px",
      marginTop: "24px",
    },
  })
);

export const OrderDetailDraftHeader: React.FC = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const orderDetail = useSelector(selectOrder);

  const onClickDeleteDraft = () => {
    confirmDialog(
      `${t("orders:deleteDraft")}?`,
      t("orders:deleteDraftConfirm"),
      () => {
        if (!orderDetail?.pk) return;

        dispatch(
          ordersActions?.deleteOrderRequest({
            pk: orderDetail?.pk,
          })
        );
      },
      t("orders:deleteDraft"),
      "delete",
      t("common:back")
    );
  };

  const onClickSendOrder = () => {
    dispatch(
      modalsActions.showModal({ key: OrdersModalTypes.SEND_ORDER_DRAWER })
    );
  };

  return (
    <Paper className={classes.paper}>
      <Box display="flex" justifyContent="space-between">
        <Box display="flex" alignItems="center">
          <CustomTypography variant="h6">{orderDetail?.name}</CustomTypography>

          <Box ml="16px">
            <OrderStateTag state={orderDetail?.state} />
          </Box>
        </Box>

        <Box display="flex" alignItems="center">
          {orderDetail?.updated_at ? (
            <AutoSavedStatus updated_at={orderDetail?.updated_at} />
          ) : null}

          <CustomButton
            variant="outlined"
            color="error"
            onClick={onClickDeleteDraft}
          >
            {t("orders:deleteDraft")}
          </CustomButton>

          <Box ml="12px">
            <CustomButton
              variant="contained"
              color="primary"
              onClick={onClickSendOrder}
            >
              {t("orders:sendOrder")}
            </CustomButton>
          </Box>
        </Box>
      </Box>

      <Divider light className={classes.divider} />

      <Box mt="24px" width="100%">
        <OrderStepper activeStep={0} />
      </Box>

      <Divider light className={classes.divider} />

      <Box mt="24px">
        <Typography variant="body1">{t("orders:draftHeaderText")}</Typography>{" "}
      </Box>
    </Paper>
  );
};
