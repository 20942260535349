import React from "react";
import {
  GridRenderCellParams,
  GridValueGetterParams,
} from "@mui/x-data-grid-pro";
import { formatDate } from "helpers/formatDate";
import { CustomTypography } from "../custom-typography";

const DateCell: React.FC<GridRenderCellParams> = (
  params: GridValueGetterParams
) => {
  return (
    <CustomTypography>
      {`${formatDate(params?.value, "MMM D, YYYY")}`}
    </CustomTypography>
  );
};

export default DateCell;
