import React, { useMemo } from "react";
import { Box, Button, Theme, Typography } from "@mui/material";
import { ProgressCircle } from "@next/components/progress-circle";
import { AppTheme } from "layout/AppTheme";
import dottedCircle from "assets/img/dotted-circle.png";
import checkIcon from "assets/img/check-icon-green.png";
import { Trans, useTranslation } from "react-i18next";
import { history } from "helpers/history";
import { frontendUrl } from "urls";
import { SupplierOnboardingStatus } from "../../redux";
import { useQueryParam } from "@next/hooks/useQuery";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: "flex",
    width: "100%",
    gap: theme.spacing(3),
  },
  rootWithDescription: {
    border: `4px solid ${theme.palette.divider}`,
    borderRadius: "16px",
    padding: theme.spacing(2),
  },
  completnessContainer: {
    display: "flex",
    alignItems: "center",
    gridGap: "12px",
  },
  container: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(3),
  },
  tile: {
    display: "flex",
    gap: theme.spacing(2),
    alignItems: "center",
    padding: "12px",
    width: "100%",
    cursor: "pointer",
    borderRadius: "8px",
  },
  stepDescriptionContainer: {
    padding: theme.spacing(3),
    borderRadius: "16px",
    background: "#fafafa",
    "& .MuiButton-root": {
      width: "fit-content",
    },
  },
  tileBackground: {
    background: "#fafafa",
  },
  completedLabel: {
    color: theme.palette.text.secondary,
    textDecoration: "line-through",
  },
}));

const StepDescription = ({
  stepName,
  descriptionKey,
  btnText,
  onBtnClick,
}: {
  stepName: string;
  descriptionKey: string;
  btnText: string;
  onBtnClick: () => void;
}) => {
  return (
    <>
      <Typography variant="h6">{stepName}</Typography>
      <Typography variant="body1">
        <Trans i18nKey={descriptionKey} />
      </Typography>
      <Button variant="contained" color="primary" onClick={onBtnClick}>
        {btnText}
      </Button>
    </>
  );
};

export enum OnboardingSteps {
  VIEWING_REQUEST_MARKETPLACE = "Viewing the request marketplace",
  VISITING_NEWSFEED = "Visiting the newsfeed",
  UPDATING_COMPANY_PROFILE = "Updating your company profile",
  SENDING_CONNECTION_REQUEST = "Sending a connection request",
}

interface props {
  onboardingStatus: SupplierOnboardingStatus | undefined;
  showDescription?: boolean;
  onClickStep?: (step: OnboardingSteps) => void;
}

export const SellerDashboardOnboardingPremium = ({
  onboardingStatus,
  showDescription = true,
  onClickStep,
}: props) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const ONBOARDING_DATA = useMemo(() => {
    return [
      {
        key: OnboardingSteps.VIEWING_REQUEST_MARKETPLACE,
        label: t("sellerDashboard:onboardingPremium:viewRequest:title"),
        onboardingCompleted: onboardingStatus?.marketplace_viewed || false,
        panelComponent: (
          <StepDescription
            stepName={t("sellerDashboard:onboardingPremium:viewRequest:title")}
            descriptionKey={
              "sellerDashboard:onboardingPremium:viewRequest:description"
            }
            btnText={t("sellerDashboard:onboardingPremium:viewRequest:btn")}
            onBtnClick={() => {
              history.push(frontendUrl.marketplace);
            }}
          />
        ),
      },
      {
        key: OnboardingSteps.VISITING_NEWSFEED,
        label: t("sellerDashboard:onboardingPremium:visitNewsfeed:title"),
        onboardingCompleted: onboardingStatus?.newsfeed_viewed || false,
        panelComponent: (
          <StepDescription
            stepName={t(
              "sellerDashboard:onboardingPremium:visitNewsfeed:title"
            )}
            descriptionKey={
              "sellerDashboard:onboardingPremium:visitNewsfeed:description"
            }
            btnText={t("sellerDashboard:onboardingPremium:visitNewsfeed:btn")}
            onBtnClick={() => {
              history.push(frontendUrl.newsfeed);
            }}
          />
        ),
      },
      {
        key: OnboardingSteps.UPDATING_COMPANY_PROFILE,
        label: t(
          "sellerDashboard:onboardingPremium:updateCompanyProfile:title"
        ),
        onboardingCompleted:
          onboardingStatus?.company_profile_complete || false,
        panelComponent: (
          <StepDescription
            stepName={t(
              "sellerDashboard:onboardingPremium:updateCompanyProfile:title"
            )}
            descriptionKey={
              "sellerDashboard:onboardingPremium:updateCompanyProfile:description"
            }
            btnText={t(
              "sellerDashboard:onboardingPremium:updateCompanyProfile:btn"
            )}
            onBtnClick={() => {
              history.push(frontendUrl.companyProfile);
            }}
          />
        ),
      },
      {
        key: OnboardingSteps.SENDING_CONNECTION_REQUEST,
        label: t(
          "sellerDashboard:onboardingPremium:sendConnectionRequest:title"
        ),
        onboardingCompleted: onboardingStatus?.company_connection_done || false,
        panelComponent: (
          <StepDescription
            stepName={t(
              "sellerDashboard:onboardingPremium:sendConnectionRequest:title"
            )}
            descriptionKey={
              "sellerDashboard:onboardingPremium:sendConnectionRequest:description"
            }
            btnText={t(
              "sellerDashboard:onboardingPremium:sendConnectionRequest:btn"
            )}
            onBtnClick={() => {}}
          />
        ),
      },
    ];
  }, [onboardingStatus, t]);

  const [onboardingStep, setOnboardingStep] = useQueryParam(
    "onboardingStep",
    ONBOARDING_DATA.find((step) => step.onboardingCompleted === false)?.key ||
      OnboardingSteps.VIEWING_REQUEST_MARKETPLACE
  );

  const handleTileClick = (step: OnboardingSteps) => {
    if (onClickStep) onClickStep(step);
    setOnboardingStep(step);
  };

  const getDescriptionToRender = () => {
    const selectedStep = ONBOARDING_DATA.find(
      (step) => step.key === onboardingStep
    );
    return selectedStep?.panelComponent;
  };

  const completedPercent = useMemo(() => {
    return (
      ONBOARDING_DATA.filter((step) => step.onboardingCompleted).length *
      (100 / ONBOARDING_DATA.length)
    );
  }, [ONBOARDING_DATA]);

  return (
    <Box
      className={`${classes.root} ${
        showDescription ? classes.rootWithDescription : ""
      }`}
    >
      <Box className={classes.container}>
        <Box className={classes.completnessContainer}>
          <Typography variant="h6" color="textPrimary">
            {t("sellerDashboard:onboardingPremium:getStarted")}
          </Typography>
          <ProgressCircle
            value={completedPercent}
            colors={[{ value: 100, color: AppTheme.palette.primary.main }]}
          />
          <Typography variant="body1">
            {t("sellerDashboard:onboardingPremium:precentCompleted", {
              percent: completedPercent,
            })}
          </Typography>
        </Box>
        <Box>
          {ONBOARDING_DATA.map((step, index) => {
            return (
              <Box
                key={index}
                className={`${classes.tile} ${
                  showDescription && onboardingStep === step.key
                    ? classes.tileBackground
                    : ""
                }`}
                onClick={() => handleTileClick(step.key)}
              >
                {step.onboardingCompleted ? (
                  <img src={checkIcon} alt="completed" />
                ) : (
                  <img src={dottedCircle} alt="incomplete" />
                )}
                <Typography
                  variant="body1"
                  className={`${
                    step.onboardingCompleted ? classes.completedLabel : ""
                  }`}
                >
                  {step.label}
                </Typography>
              </Box>
            );
          })}
        </Box>
      </Box>
      {showDescription && (
        <Box
          className={classes.container + " " + classes.stepDescriptionContainer}
        >
          {getDescriptionToRender()}
        </Box>
      )}
    </Box>
  );
};
