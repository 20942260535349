import { ButtonProps } from "@mui/material";
import React, { PropsWithChildren } from "react";
import AttachmentIcon from "@mui/icons-material/Attachment";
import CustomButton from "../custom-button";

export const AttachFilePickButton: React.FC<
  PropsWithChildren<ButtonProps & { loading?: boolean }>
> = ({ children, ...props }) => {
  return (
    <CustomButton
      component="label"
      startIcon={<AttachmentIcon />}
      variant="outlined"
      size="large"
      style={{
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
        overflow: "hidden",
      }}
      {...(props as any)}
    >
      {children}
    </CustomButton>
  );
};
