/*
 *   Auth Actions Menu
 *   Prototype: https://xd.adobe.com/view/0947e4ee-3356-4422-641b-f12cde3a4b03-7f76/screen/2c53c89e-47d7-46af-b3e4-74650cbea928/Problem-Page
 *   This component is the menu appearing below the forms in the authentication section of the app
 */

// General Import
import React from "react";
import PropTypes from "prop-types";

// Material UI Import
import { Grid, Typography } from "@mui/material";
import CustomButton from "@next/components/custom-button";

/*
 *   AuthActionsMenu:
 *   @param {string} minorLinkUrl: The url for the small link on the top of the menu
 *   @param {string} minorLinkText: The text that will display for the small link
 *   @param {string} majorText: The big text before the red link
 *   @param {string} majorLinkUrl: The url for the action url in red
 *   @param {string} majorLinkText: The text that will display for the big red link
 */
export const AuthActionsMenu = ({
  minorLinkUrl,
  minorLinkText,
  majorText,
  majorLinkUrl,
  majorLinkText,
}) => {
  return (
    <Grid
      container
      direction="column"
      justifyContent="center"
      alignItems="center"
    >
      <Grid item xs={12} className="c-link">
        <CustomButton
          href={minorLinkUrl}
          className="c-minorLink"
          variant="text"
          color="primary"
          fullWidth
        >
          {minorLinkText}
        </CustomButton>
      </Grid>
      <Grid item xs={10}>
        <Typography className="c-majorText">{majorText}</Typography>
      </Grid>
      <Grid item xs={12}>
        <CustomButton
          href={majorLinkUrl}
          className="c-minorLink"
          variant="text"
          color="primary"
          fullWidth
        >
          {majorLinkText}
        </CustomButton>
      </Grid>
    </Grid>
  );
};

// This is the type of the props for this component
// Everything is required for this one
AuthActionsMenu.propTypes = {
  minorLinkUrl: PropTypes.string.isRequired,
  minorLinkText: PropTypes.string.isRequired,
  majorText: PropTypes.string.isRequired,
  majorLinkUrl: PropTypes.string.isRequired,
  majorLinkText: PropTypes.string.isRequired,
};
