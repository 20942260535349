import React, { useCallback } from "react";
import { Box, Button, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  CompanyImage,
  CompanyProfileFileReferenceType,
  profileNextActions,
} from "@next/modules/profile/redux";
import { confirmDialog } from "@next/modals/shared-confirm-dialog";
import { getSupportedImageFileTypes } from "@next/utils/constantUtils";
import { selectCompanyProfile } from "@next/modules/profile/redux/selectors";
import PaginatedGridGallery, {
  PaginatedGridGalleryChangeType,
} from "../../company-profile-full/paginated-grid-gallery/paginated-grid-gallery";
import { createStyles, makeStyles } from "@mui/styles";
import { enqueueSnackbar } from "notistack";

const useStyles = makeStyles((theme) =>
  createStyles({
    subTitle: {
      marginTop: 8,
      marginBottom: 24,
    },
    uploadButton: {
      marginTop: 15,
    },
    grid: {
      width: "100%",
      marginTop: theme.spacing(2),
      gridTemplateColumns: "repeat(auto-fill, minmax(min(240px, 100%), 1fr))",
    },
    img: {
      height: 200,
    },
  })
);

type Props = {
  subTitle: string;
  images?: CompanyImage[];
  type: CompanyProfileFileReferenceType;
};

const ImageCardsEdit: React.FC<Props> = ({ subTitle, images, type }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const companyProfile = useSelector(selectCompanyProfile);

  const onImportChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const inputFiles = event?.target?.files;

      // TODO: Move this logic to utils or redux
      if (inputFiles) {
        const inputFilesArr = [];

        for (let i = 0; i < inputFiles.length; i++) {
          const newFileSize = inputFiles[i]?.size / 1024 / 1024; // in MB

          if (newFileSize > 10) {
            return enqueueSnackbar(
              t("profile:companyProfile:fileSizeExceeds", { count: 10 }),
              { variant: "error" }
            );
          }

          inputFilesArr.push(inputFiles[i]);
        }

        if (inputFilesArr?.length) {
          dispatch(
            profileNextActions.updateCompanyFileReferencesRequest({
              files: inputFilesArr,
              type,
              onSuccess: () => {
                dispatch(
                  profileNextActions.fetchCompanyProfileRequest({
                    companyId: companyProfile?.id as number,
                    isPublic: false,
                  })
                );
              },
            })
          );
        }
      }
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const onClickDelete = (ix: number) => {
    if (!images?.[ix].id) return;
    confirmDialog(
      t("workspace:confirmation"),
      t("profile:companyProfile:areYouSureWantToDelete"),
      () => {
        dispatch(
          profileNextActions.deleteCompanyFileReferencesRequest({
            fileId: images[ix].id,
            type,
            onSuccess: () => {
              dispatch(
                profileNextActions.fetchCompanyProfileRequest({
                  companyId: companyProfile?.id as number,
                  isPublic: false,
                })
              );
            },
          })
        );
      }
    );
  };

  const onChange = (
    newImages: string[],
    changeType: PaginatedGridGalleryChangeType
  ) => {
    if (changeType === PaginatedGridGalleryChangeType.REORDER) {
      const sortedImages = newImages
        .map((img) => images?.find((item) => item.url === img))
        .filter((item) => item !== undefined) as CompanyImage[];

      dispatch(
        profileNextActions.reorderCompanyFileReferencesRequest({
          type,
          ordering: sortedImages.map((item) => +item.id),
        })
      );
    }
  };

  return (
    <div>
      <Typography
        variant="body2"
        color="textSecondary"
        className={classes.subTitle}
      >
        {subTitle}
      </Typography>

      <Box display="flex" alignItems="baseline">
        <Button
          variant="outlined"
          component="label"
          className={classes.uploadButton}
        >
          {t("profile:companyProfile:uploadImages")}
          <input
            type="file"
            hidden
            onInput={onImportChange}
            multiple
            accept={`${getSupportedImageFileTypes()}`}
          />
        </Button>

        <Box ml="8px">
          <Typography
            variant="body2"
            color="textSecondary"
            className={classes.subTitle}
          >
            {t("profile:companyProfile:supportedFiles")}: PNG, JPG, JPEG
          </Typography>
        </Box>
      </Box>

      <PaginatedGridGallery
        images={images?.map((item) => item.url) || []}
        batchSize={images?.length}
        sortable
        onClickDelete={onClickDelete}
        onChange={onChange}
        gridClassName={classes.grid}
        imgClassName={classes.img}
      />
    </div>
  );
};

export default ImageCardsEdit;
