import { npsApi } from "./nps.api";
import { npsConstants } from "./nps.constants";
import { modalsActions } from "../modals/modals.actions";
import { profileActions } from "../profile/profile.actions";
import { enqueueSnackbar } from "notistack";

const sendNPS =
  (t, token, nps_token, has_dismissed, score = null) =>
  (dispatch) => {
    return new Promise(function (resolve, reject) {
      dispatch(request());
      npsApi.sendNPS(token, nps_token, has_dismissed, score).then(
        (data) => {
          if (!has_dismissed) {
            enqueueSnackbar(t("workspace:thankYouYourResponseHasBeenSent"), {
              variant: "success",
            });
          }
          dispatch(modalsActions.hideModal("isNPSOpen"));
          dispatch(success(has_dismissed));
          dispatch(profileActions.fetchUserProfile(token));
          resolve(data);
        },
        (error) => {
          dispatch(failure(error?.toString()));
          reject(error);
        }
      );
    });

    function request() {
      return { type: npsConstants.SEND_NPS_REQUEST };
    }
    function success(has_dismissed) {
      return { type: npsConstants.SEND_NPS_SUCCESS, has_dismissed };
    }
    function failure(error) {
      return { type: npsConstants.SEND_NPS_FAILURE, error };
    }
  };

export const npsActions = {
  sendNPS,
};
