import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { FavoriteSupplierGroup } from "../../redux";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
  select: { height: 42.25 },
  filterFormControl: {
    minWidth: 150,
  },
  paper: { maxHeight: "300px" },
}));

type Props = {
  label: string;
  value?: number;
  onChange: (event: SelectChangeEvent<any>) => void;
  items: FavoriteSupplierGroup[];
};

export const SelectFilterSuppliersDropdown: React.FC<Props> = ({
  label,
  onChange,
  value,
  items = [],
}) => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <FormControl variant="outlined" className={classes.filterFormControl}>
      <InputLabel id="select-filter-suppliers-dropdown-label">
        {label}
      </InputLabel>

      <Select
        labelId="select-filter-suppliers-dropdown-label"
        id="select-filter-suppliers-dropdown"
        value={value}
        onChange={onChange}
        variant="outlined"
        className={classes.select}
        label={label}
        MenuProps={{
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "left",
          },
          transformOrigin: {
            vertical: "top",
            horizontal: "left",
          },

          classes: {
            paper: classes.paper,
          },
        }}
      >
        {items?.map((item) => (
          <MenuItem value={item?.pk}>{item?.name}</MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
