export enum OrderDetailLineItemsTableField {
  PART_NUMBER = "name",
  OUR_PN = "our_pn",
  DESCRIPTION = "description",
  ON_DOCK_DELIVERY_DATE = "on_dock_delivery_date",
  QUANTITY = "quantity",
  SHIPPED_BY_DATE = "shipped_by",
  STATUS = "status",
  SHIPPING_NOTE = "shipping_note",
  ACTION = "action",
}
