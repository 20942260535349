import React from "react";
import { Box, Link, Theme, Typography } from "@mui/material";
import { Trans } from "react-i18next";
import { useSelector } from "react-redux";
import { createStyles, makeStyles } from "@mui/styles";
import { getErpConfiguration } from "services/part-configuration/part-configuration.selectors";
import { ErpConfigurationDetailPopover } from "@next/modules/app/components/erp-configuration-detail-popover";
import {
  ErpConfigurationType,
  getErpConfigurationName,
} from "@next/modules/app/components/app-bar-next/app-bar-next-erp-configuration";
import { formatDateTimeAsCalender } from "@next/utils/dateTimeUtils";
import UpdateRoundedIcon from "@mui/icons-material/UpdateRounded";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      gap: theme.spacing(1),
      alignItems: "center",
      color: theme.palette.text.secondary,
    },
  })
);

export enum ErpConfigurationFor {
  PO_SYNCHRONIZATION = "po_synchronization",
  PROJECT_SYNCHRONIZATION = "project_synchronization",
}

type Props = {
  erpConfigurationFor: ErpConfigurationFor;
};
const ErpConnectionStatus: React.FC<Props> = ({ erpConfigurationFor }) => {
  const classes = useStyles();
  const erpConfiguration = useSelector(getErpConfiguration);
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );

  const handleClose = () => {
    setAnchorEl(null);
  };

  const connectedErpType: ErpConfigurationType =
    erpConfiguration?.erp_type?.value;

  const open = Boolean(anchorEl);
  const erpPopoverId = open ? "erp-configuration-popover" : undefined;

  const onClickErpConfiguraion = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    setAnchorEl(event.currentTarget);
  };

  if (!erpConfiguration?.[`${erpConfigurationFor}`]?.value) return null;

  return (
    <Box className={classes.root}>
      <UpdateRoundedIcon color="inherit" />
      <Typography variant="body2" color={"inherit"}>
        <Trans
          i18nKey="erpConfiguration:lastSyncStatus"
          components={[
            <Link
              component={"button"}
              id={erpPopoverId}
              underline="none"
              onClick={onClickErpConfiguraion}
            >
              {getErpConfigurationName(connectedErpType)}
            </Link>,
          ]}
          values={{
            erpName: getErpConfigurationName(connectedErpType),
            lastSyncDate: formatDateTimeAsCalender(
              erpConfiguration?.[`${erpConfigurationFor}`]
                ?.last_synchronization_date
            ),
          }}
        />
      </Typography>
      <ErpConfigurationDetailPopover
        id={erpPopoverId}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      />
    </Box>
  );
};

export default ErpConnectionStatus;
