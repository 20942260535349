import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Form, FormikProps } from "formik";
import { ContactUsFormProps, ContactUsFormValues } from "./contact-us.types";
import {
  Box,
  Button,
  CircularProgress,
  Divider,
  Grid,
  Link,
  Typography,
} from "@mui/material";
import PhoneIcon from "@mui/icons-material/Phone";

import { GenericTextInput } from "components/utils/input/text";
import { selectContactUsLoading } from "../../redux/selectors";
import { createStyles, makeStyles } from "@mui/styles";

const useStyles = makeStyles(() =>
  createStyles({
    link: {
      "&:not(.hot-link)": {
        color: "#1473E6",
      },
      fontWeight: "bold",
    },
    formTitle: {
      padding: 11,
      backgroundColor: "#f1f1f1",
      borderRadius: 5,
      fontSize: 16,
      fontWeight: 400,
      marginBottom: 24,
    },
    buttonContainer: {
      position: "relative",
    },
    circularProgress: {
      position: "absolute",
      top: "30%",
      right: "8%",
    },
  })
);
const ContactUsFormBase: React.FC<
  FormikProps<ContactUsFormValues> & ContactUsFormProps
> = ({ handleSubmit, formTitle, hasFormTitle, hasSubjectAndMsg, label }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const contactUsLoading = useSelector(selectContactUsLoading);
  return (
    <Form onSubmit={handleSubmit}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          {hasFormTitle && <Box className={classes.formTitle}>{formTitle}</Box>}
          {hasSubjectAndMsg && (
            <GenericTextInput name="subject" label={t("contactUs:subject")} />
          )}
        </Grid>
        <Grid item xs={12}>
          <GenericTextInput rows={"5"} name="msg" label={label} />
        </Grid>
        <Grid
          item
          container
          justifyContent="flex-end"
          className={classes.buttonContainer}
        >
          <Button
            color="primary"
            variant="contained"
            type="submit"
            disabled={contactUsLoading}
          >
            {t("common:send")}
          </Button>
          {contactUsLoading && (
            <CircularProgress size={24} className={classes.circularProgress} />
          )}
        </Grid>
        {hasSubjectAndMsg && (
          <Grid item xs={12}>
            <Divider />
            <Box display="flex" alignItems="center" mt={3}>
              <Box mr={1}>
                <PhoneIcon />
              </Box>
              <Typography variant="subtitle1">
                {t("contactUs:footerText")}
                <Link
                  href="tel:+1 581-333-3743"
                  className={classes.link}
                  variant="subtitle1"
                >
                  {`  +1 581-333-3743`}
                </Link>
              </Typography>
            </Box>
          </Grid>
        )}
      </Grid>
    </Form>
  );
};

export default ContactUsFormBase;
