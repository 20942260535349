import {
  AutocompleteRenderOptionState,
  Box,
  ClickAwayListener,
  Paper,
  Typography,
} from "@mui/material";
import { Autocomplete, Skeleton } from "@mui/material";
import { GenericAutoCompleteTextField } from "components/generic-autocomplete-text-field/generic-autocomplete-text-field.component";
import { debounce, isEmpty } from "lodash";
import React, {
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import PaginatedAutoCompleteLoader from "./paginated-autocomplete-loader";
import SearchIcon from "@mui/icons-material/Search";
import useUpdateEffect from "@next/hooks/useUpdateEffect";
import { createStyles, makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) =>
  createStyles({
    loaderContainer: {
      marginTop: theme.spacing(2),
    },
    input: {
      border: `1px solid ${theme.palette.grey[300]}`,
      borderRadius: 8,
    },
    labelContainer: {
      display: "flex",
      alginItems: "center",
    },
    searchIcon: {
      marginRight: theme.spacing(1.25),
    },
    listBox: {
      "& > li": {
        transition: "background 0.3s",
        marginBottom: 0,
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),

        "&:hover": {
          backgroundColor: theme.palette.action.selected,
        },
      },
    },
    paper: {
      boxShadow: `0px 1px 2px rgba(0, 0, 0, 0.3), 0px 2px 6px 2px rgba(0, 0, 0, 0.15)`,
    },
  })
);

export interface PaginatedAutoCompleteProps {
  endReachedPercent?: number;
  endReachedDebouncing?: number;
  minimumSearchLength?: number;
  searchDebouncing?: number;
  placeholder?: string;
  loading?: boolean;
  loadingSkeletonsCount?: number;
  LoadingSkeleton?: ReactNode;
  CustomComponent?: ReactNode;
  name: string;
  paperComponentClassName?: string;
  multiple?: boolean;
  endAdornment?: ReactNode;
  onSelectionChange?: (selected: Record<string, any>[]) => void;
  onEndReached?: () => void;
  onSearch?: (search: string) => void;
  onInitialFetch?: () => void;
  onInputChange?: (ip: string) => void;
  getInputFieldValue?: (value: unknown) => void;
  getOptionDisabled?: (option: unknown) => boolean;
}

const PaginatedAutoComplete: React.FC<any & PaginatedAutoCompleteProps> = ({
  onSelectionChange,
  onEndReached,
  onSearch,
  onInitialFetch,
  renderOption,
  getOptionSelected,
  getOptionDisabled,
  options,
  loading,
  placeholder,
  multiple = true,
  endAdornment,
  endReachedPercent = 80,
  endReachedDebouncing = 500,
  minimumSearchLength = 3,
  searchDebouncing = 500,
  loadingSkeletonsCount = 5,
  LoadingSkeleton = <Skeleton width="100%" height={24} variant="rectangular" />,
  CustomComponent,
  name,
  paperComponentClassName,
  onInputChange,
  getInputFieldValue,
  ...props
}) => {
  const [open, setOpen] = useState(false);
  const [listRef, setListRef] = useState<HTMLUListElement | null>(null);
  const classes = useStyles();
  const [focused, setFocused] = useState(false);
  const initialFetched = useRef(false);
  const [input, setInput] = useState(props.inputValue || "");
  const [clickedAway, setClickedAway] = useState(false);
  const autocompleteRef = useRef<any>(null);

  const onOpen = useCallback(() => {
    setOpen(true);
    setClickedAway(false);
    if (
      !initialFetched.current &&
      !loading &&
      typeof onInitialFetch === "function"
    ) {
      initialFetched.current = true;
      onInitialFetch();
    }
  }, [initialFetched.current, loading, onInitialFetch]);

  const onClose = useCallback(
    (e, reason) => {
      //this is a hack to wait for onClick event on FooterComponent before closing the dropdown
      setTimeout(() => {
        if (!clickedAway && reason === "blur") {
          if (autocompleteRef.current) autocompleteRef.current.focus();
          return;
        }
        setOpen(false);
        setInput("");
      }, 200);
    },
    [clickedAway]
  );

  const _onChange = useCallback(
    (_, selected) => {
      if (typeof onSelectionChange === "function") onSelectionChange(selected);
    },
    [onSelectionChange]
  );

  const _renderOption = useCallback(
    (
      props: React.HTMLAttributes<HTMLLIElement>,
      option: any,
      state: AutocompleteRenderOptionState
    ) => {
      return (
        <li {...props}>
          {typeof renderOption === "function" && renderOption(option, state)}
          {loading &&
          typeof getOptionSelected === "function" &&
          getOptionSelected(option, options?.[options?.length - 1]) ? (
            <div className={classes.loaderContainer}>
              <PaginatedAutoCompleteLoader
                loadingSkeletonsCount={loadingSkeletonsCount}
                LoadingSkeleton={LoadingSkeleton}
              />
            </div>
          ) : null}
        </li>
      );
    },
    [getOptionSelected, renderOption, loading, options]
  );

  const _onEndReached = useCallback(
    debounce(
      () => {
        if (typeof onEndReached === "function") setTimeout(onEndReached, 100);
      },
      endReachedDebouncing,
      {
        leading: true,
        trailing: false,
      }
    ),
    [onEndReached, endReachedDebouncing]
  );

  const _onInputChange = useCallback((_, value, reason) => {
    if (reason === "input") setInput(value);
  }, []);

  const onSearchChange = useCallback(
    debounce(
      (search) => {
        if (
          search.length >= minimumSearchLength &&
          typeof onSearch === "function"
        )
          onSearch(search);
        else if (search.length === 0 && typeof onInitialFetch === "function") {
          onInitialFetch();
        }
      },
      searchDebouncing,
      {
        leading: false,
        trailing: true,
      }
    ),
    [onSearch, searchDebouncing, onInitialFetch]
  );

  const renderInput = useCallback(
    (params) => {
      return (
        <GenericAutoCompleteTextField
          {...params}
          name={name}
          ref={autocompleteRef}
          inputProps={{
            ...params.inputProps,
            onFocus(...args: any[]) {
              setFocused(true);
              if (typeof params.inputProps?.onFocus === "function") {
                params.inputProps.onFocus(...args);
              }
            },
            onBlur(...args: any[]) {
              setFocused(false);
              if (typeof params.inputProps?.onBlur === "function") {
                params.inputProps.onBlur(...args);
              }
            },
            ...(!multiple
              ? {
                  value: open
                    ? input
                    : typeof getInputFieldValue === "function" &&
                      !isEmpty(props.value)
                    ? getInputFieldValue(props.value)
                    : "",
                }
              : {}),
          }}
          label={
            focused ||
            params.inputProps?.value ||
            (!multiple && !isEmpty(props.value)) ? (
              placeholder
            ) : (
              <Box className={classes.labelContainer}>
                <SearchIcon className={classes.searchIcon} />
                <Typography variant="body1">{placeholder}</Typography>
              </Box>
            )
          }
          InputProps={{
            ...params.InputProps,
            endAdornment: endAdornment || params.InputProps?.endAdornment,
          }}
        />
      );
    },
    [focused, placeholder, props.value, multiple, open, input]
  );

  useUpdateEffect(() => {
    if (typeof input === "string") {
      onSearchChange(input);
      if (typeof onInputChange === "function") onInputChange(input);
    }
  }, [input]);

  useEffect(() => {
    let lastScrollTop = 0;
    const onScroll = (e: Record<string, any>) => {
      const { clientHeight, scrollTop, scrollHeight } = e.target as HTMLElement;
      //if scrolldown
      if (scrollTop > lastScrollTop && !loading) {
        const maxScroll = scrollHeight - clientHeight;
        const currentPercent = (scrollTop / maxScroll) * 100;
        if (currentPercent > endReachedPercent) {
          _onEndReached();
        }
      }
      lastScrollTop = Math.max(0, scrollTop);
    };

    if (listRef) listRef.onscroll = onScroll;
  }, [listRef, loading, _onEndReached, endReachedPercent]);

  const ListboxComponent = useMemo(
    () => (props: any) =>
      (
        <ul
          {...props}
          className={`${classes.listBox} ${props?.className || ""}`}
          ref={(ref) => setListRef(ref)}
        />
      ),
    []
  );

  const PaperComponent = useMemo(
    () => (props: any) =>
      (
        <Paper
          {...props}
          className={`${classes.paper} ${paperComponentClassName || ""} ${
            props?.className || ""
          }`}
          onClick={(e) => e.stopPropagation()}
        >
          {CustomComponent || null}
          {props.children || null}
        </Paper>
      ),
    [CustomComponent, paperComponentClassName]
  );

  return (
    <ClickAwayListener
      onClickAway={() => {
        setClickedAway(true);
        setTimeout(() => {
          setOpen(false);
          setInput("");
        }, 200);
      }}
    >
      <Autocomplete
        disableCloseOnSelect={props.disableCloseOnSelect || true}
        loadingText={
          <PaginatedAutoCompleteLoader
            loadingSkeletonsCount={loadingSkeletonsCount}
            LoadingSkeleton={LoadingSkeleton}
          />
        }
        renderTags={() => null}
        isOptionEqualToValue={getOptionSelected}
        disableClearable={true}
        getOptionDisabled={getOptionDisabled}
        filterOptions={(opt) => opt}
        renderInput={renderInput}
        inputValue={input}
        {...props}
        multiple={multiple as any}
        onChange={_onChange}
        onInputChange={_onInputChange}
        ListboxComponent={ListboxComponent}
        PaperComponent={PaperComponent}
        open={open}
        onOpen={onOpen}
        onClose={onClose}
        renderOption={_renderOption}
        options={options || []}
        loading={loading && !options?.length}
        size="small"
      />
    </ClickAwayListener>
  );
};

export default PaginatedAutoComplete;
