import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { modalsActions } from "@next/redux/modalsSlices";
import { Form, Formik } from "formik";
import { Box, Grid, Typography } from "@mui/material";
import CustomButton from "@next/components/custom-button";
import * as yup from "yup";
import { GenericTextInput } from "components/utils/input/text";
import { workspaceNextActions } from "../redux";
import { selectInviteSupplierContactLoading } from "../redux/selectors";
import { GenericUser } from "@next/modules/profile/redux";
import { InviteSupplierContactModalData } from "@next/modals/shared-invite-supplier-contact-modal";
import { SharedModalTypes } from "@next/modals/types";

export const InviteSupplierContactForm: React.FC<
  InviteSupplierContactModalData
> = ({ supplierId, onSuccess, email }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const loading = useSelector(selectInviteSupplierContactLoading);

  const onSubmit = useCallback((values) => {
    dispatch(
      workspaceNextActions.inviteSupplierContactRequest({
        ...values,
        supplierId,
        onSuccess: (user: GenericUser) => {
          onSuccess(user);
          dispatch(
            modalsActions.closeModal(
              SharedModalTypes.INVITE_SUPPLIER_CONTACT_MODAL
            )
          );
        },
      })
    );
  }, []);

  return (
    <Formik
      validationSchema={yup.object().shape({
        first_name: yup.string().required("thisFieldIsRequired"),
        last_name: yup.string().required("thisFieldIsRequired"),
        email: yup
          .string()
          .email(t("workspaceNext:rfqDrawer:error:invalidEmail"))
          .required(t("common:error:thisFieldIsRequired")),
      })}
      initialValues={{ email, first_name: "", last_name: "" }}
      onSubmit={onSubmit}
    >
      <Form>
        <Box mb={2}>
          <Typography variant="body1">
            {t("workspaceNext:supplierContactsList:inviteModal:content")}
          </Typography>
        </Box>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <GenericTextInput
              name="email"
              label={t("workspaceNext:supplierContactsList:inviteModal:email")}
            />
          </Grid>
          <Grid item xs={6}>
            <GenericTextInput
              name="first_name"
              label={t(
                "workspaceNext:supplierContactsList:inviteModal:firstName"
              )}
            />
          </Grid>
          <Grid item xs={6}>
            <GenericTextInput
              name="last_name"
              label={t(
                "workspaceNext:supplierContactsList:inviteModal:lastName"
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <CustomButton
              loading={loading}
              type="submit"
              color="primary"
              variant="contained"
              style={{ width: "100%" }}
            >
              {t("workspaceNext:supplierContactsList:inviteModal:submit")}
            </CustomButton>
          </Grid>
        </Grid>
      </Form>
    </Formik>
  );
};
