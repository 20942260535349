import { GridColDef, GridColumns, useGridApiRef } from "@mui/x-data-grid-pro";
import { debounce } from "lodash";
import { useEffect } from "react";
import useLocalStorage from "hooks/useLocalStorage";
import { getUser } from "services/profile/profile.selectors";
import { useSelector } from "react-redux";

export type ColumnState = {
  [columnName: string]: {
    width: number | undefined;
    computedWidth: number | undefined;
  };
};

export type TableColumsState = {
  order: Array<string>;
  state: ColumnState;
};

export const INITIAL_TABLE_COLUMN_STATE: TableColumsState = {
  order: [],
  state: {},
};

const useLocalStorageGridState = (tableName: string) => {
  const user = useSelector(getUser);
  const apiRef = useGridApiRef();

  const [storedTableState, setTableState] = useLocalStorage<TableColumsState>(
    `${tableName}_${user?.id}`,
    INITIAL_TABLE_COLUMN_STATE
  );

  const saveColumnState = () => {
    const { all, lookup } = apiRef.current.getState().columns;

    const updatedColumnState: ColumnState = {};
    Object.keys(lookup).forEach((column) => {
      const currentColumn = lookup[column];
      updatedColumnState[column] = {
        width: currentColumn.width,
        computedWidth: currentColumn.computedWidth,
      };
    });

    setTableState({
      order: all,
      state: updatedColumnState,
    });
  };

  const columnSort = (columnA: GridColDef, columnB: GridColDef) => {
    const { order } = storedTableState;
    const indexA = order.indexOf(columnA.field);
    const indexB = order.indexOf(columnB.field);

    // If both names are found in orderArray, compare their indices
    if (indexA !== -1 && indexB !== -1) {
      return indexA - indexB;
    }

    // If one name is found and the other is not, prioritize the one found
    if (indexA !== -1) return -1;
    if (indexB !== -1) return 1;

    // If neither name is found, maintain the original order
    return 0;
  };

  const restoreState = (columns: GridColumns) => {
    const columnState = storedTableState.state;
    // update column properties like width etc
    columns = columns.map((column) => ({
      ...column,
      ...columnState[column.field],
    }));

    // restore column order
    columns = columns.sort(columnSort);

    return columns;
  };

  useEffect(() => {
    const handleOnColumnChange = debounce(saveColumnState, 500);
    if (apiRef.current && apiRef.current.subscribeEvent) {
      // Null check for subscribeEvent function
      apiRef.current.subscribeEvent("columnOrderChange", handleOnColumnChange);
      apiRef.current.subscribeEvent("columnWidthChange", saveColumnState);
    }

    return () => {
      apiRef.current.removeListener("columnOrderChange", handleOnColumnChange);
      apiRef.current.removeListener("columnWidthChange", saveColumnState);
    };
  }, [apiRef]);

  return { apiRef, restoreState };
};

export default useLocalStorageGridState;
