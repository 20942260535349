import React from "react";
import { Box, Typography } from "@mui/material";
import { AttachFileList } from "@next/components/attach-file/attach-file-list";
import { Addenda } from "@next/modules/workspace/redux";
import { getAddendaName } from "components/buyer-rfqs/buyer-rfqs-information/rfq-addenda";

interface Props {
  addenda: Addenda;
  number: number;
  downloadable?: boolean;
}

const AddendaItemViewOnly: React.FC<Props> = ({
  addenda,
  number,
  downloadable,
}) => {
  return (
    <Box display="flex" flexDirection="column" gap="12px">
      <Typography variant="body2" color="textSecondary">
        {getAddendaName(addenda, number)}
      </Typography>
      {addenda.note && (
        <Typography variant="body2" style={{ whiteSpace: "pre-line" }}>
          {addenda.note}
        </Typography>
      )}
      {addenda.files?.length ? (
        <Box mb={-1}>
          <AttachFileList files={addenda.files} downloadable={downloadable} />
        </Box>
      ) : null}
    </Box>
  );
};

export default AddendaItemViewOnly;
