import React, { useMemo } from "react";
import { SelectOrderStateCard } from "@next/modules/orders/components/orders-table/select-order-state-cards/select-order-state-card";
import { CardOrderState } from "@next/modules/orders/components/orders-table/select-order-state-cards/select-order-state.cards.types";
import { Box, Button, Grid } from "@mui/material";
import { OrderState } from "@next/modules/orders/redux";
import { useSelector } from "react-redux";
import { selectPortalOrder } from "@next/modules/orders/redux/selectors";
import { useTranslation } from "react-i18next";
import i18n from "assets/configi18n/i18n";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { history } from "helpers/history";
import { frontendUrl } from "urls";
import useLocalStorage from "hooks/useLocalStorage";
import { initialCardStateOrder } from "@next/modules/orders/components/orders-table/select-order-state-cards/select-order-state-cards";

export const SupplierPortalLineItemCards = () => {
  const { t } = useTranslation();
  const order = useSelector(selectPortalOrder);

  const cardStates: CardOrderState[] = useMemo(
    () => [
      {
        slug: OrderState.UPDATED,
        query_slugs: [OrderState.UPDATED],
        name: t("orders:cardStates:waitingReview"),
        count:
          order?.supplier_line_item_counts?.status_count.updated_count || 0,
      },
      {
        slug: OrderState.ON_TRACK,
        query_slugs: [OrderState.ON_TRACK],
        name: t("orders:cardStates:onTrack"),
        count:
          order?.supplier_line_item_counts?.status_count.on_track_count || 0,
      },

      {
        slug: OrderState.AT_RISK,
        query_slugs: [OrderState.AT_RISK],
        name: t("orders:cardStates:atRisk"),
        count:
          order?.supplier_line_item_counts?.status_count.at_risk_count || 0,
      },
      {
        slug: OrderState.LATE,
        query_slugs: [OrderState.LATE],
        name: t("orders:cardStates:late"),
        count: order?.supplier_line_item_counts?.status_count.late_count || 0,
      },
    ],
    [order?.supplier_line_item_counts, i18n?.language]
  );

  const [_selectedStateFilter, setSelectedStateFilter] =
    useLocalStorage<CardOrderState>(
      "SAVED_SELECTED_SUPPLIER_PO_STATE_FILTER",
      initialCardStateOrder(0)
    );

  const handleStateClick = (state: CardOrderState) => {
    setSelectedStateFilter(state);
    history.push(`${frontendUrl.supplierLineItems}?fromPortal=true`);
  };

  return (
    <Box>
      <Grid
        container
        alignItems="center"
        spacing={1}
        data-pid="supplierLineItems:portalToInAppViewClick"
      >
        <Button
          variant="contained"
          color="primary"
          style={{ height: "40px", marginRight: "8px" }}
          onClick={() => handleStateClick(initialCardStateOrder(0))}
        >
          <ArrowBackIcon style={{ marginRight: "4px" }} />
          {t("orders:supplierPortal:menu:viewOrders", {
            count: order?.supplier_line_item_counts?.active_count || 0,
          })}
        </Button>
        {cardStates?.map((cardState) => {
          return (
            <>
              {cardState.count !== 0 && (
                <Grid item>
                  <SelectOrderStateCard
                    state={cardState}
                    selected={false}
                    onClick={handleStateClick}
                  />
                </Grid>
              )}
            </>
          );
        })}
      </Grid>
    </Box>
  );
};
