import {
  Badge,
  Box,
  Chip,
  Tab,
  Tabs,
  Theme,
  Typography,
  styled,
} from "@mui/material";
import { t } from "assets/configi18n/i18n";
import { history } from "helpers/history";
import React, { ChangeEvent, useState } from "react";
import { useLocation } from "react-router-dom";
import { ExternalLinkActionItem } from "./app-bar-next-action-items/components/external-link-action-item";
import { AppBarItemKey } from "./app-bar-next.definitions";
import { getSelectedLanguage } from "@next/utils/browserUtils";
import { createStyles, makeStyles } from "@mui/styles";
import { AppTheme } from "layout/AppTheme";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    tabsFlexContainer: {
      height: "64px",
    },
    tabsIndicator: {
      height: "4px",
      backgroundColor: theme.palette.primary.main,
    },
    tabsRoot: {
      overflow: "initial",
    },
    tabRoot: {
      minWidth: "82px",
      textTransform: "none",
      "&.Mui-selected": {
        backgroundColor: "#FAFAFA",
      },
      opacity: 0.8,
      "&:hover": {
        backgroundColor: "#FAFAFA",
        opacity: 1,
      },
    },
    chipStyle: {
      height: "24px",
      fontSize: "12px",
      marginLeft: "5px",
      "& .MuiChip-label": {
        padding: "4px 6px",
      },
      cursor: "pointer",
    },
  })
);

const StyledChip = styled(Chip)({
  backgroundColor: AppTheme.palette.success.dark,
  color: "white",
  marginLeft: "8px",
  fontWeight: "normal",
});

export interface AppBarItemType {
  index: number;
  label: string;
  path: string;
  hide?: boolean;
  external?: boolean;
  badgeCount?: number;
  isNew?: boolean;
  isBeta?: boolean;
  itemKey?: AppBarItemKey;
  navigateWithHref?: boolean;
}

type Props = {
  appBarItems: AppBarItemType[];
};

export const AppBarNextTabs: React.FC<Props> = ({ appBarItems }) => {
  const classes = useStyles();
  const location = useLocation();
  const initialItem = appBarItems.find((item) =>
    (location.pathname + location.search).includes(item.path)
  );
  const [value, setValue] = useState(initialItem?.index);

  const handleChange = (_event: ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };

  const navigateTo = (appBarItem: AppBarItemType) => () => {
    handleChange({} as ChangeEvent<{}>, appBarItem?.index || 0);

    if (appBarItem?.navigateWithHref) {
      const selectedLanguage = getSelectedLanguage();

      window.location.href = `/${selectedLanguage}/search`;
    } else {
      history.push(appBarItem?.path);
    }
  };

  return (
    <Tabs
      onChange={handleChange}
      classes={{
        root: classes.tabsRoot,
        flexContainer: classes.tabsFlexContainer,
        indicator: classes.tabsIndicator,
      }}
      value={value}
      scrollButtons
      allowScrollButtonsMobile
    >
      {appBarItems?.map((appBarItem, ix) => {
        if (appBarItem?.external) {
          return (
            <ExternalLinkActionItem
              key={ix}
              path={appBarItem?.path}
              label={appBarItem?.label}
            />
          );
        }

        return (
          <Tab
            key={ix}
            label={
              <Badge
                color="primary"
                badgeContent={appBarItem?.badgeCount}
                invisible={!appBarItem?.badgeCount}
                style={{ right: 1 }}
              >
                <Box display="flex" alignItems="center">
                  <Typography variant="body2" color="black">
                    {appBarItem.label}
                  </Typography>
                  {appBarItem?.isNew && (
                    <Chip
                      color="primary"
                      className={classes.chipStyle}
                      label={t("mainNavigationSidebar:new")}
                    />
                  )}
                  {appBarItem?.isBeta && (
                    <StyledChip size="small" label={"Beta"} />
                  )}
                </Box>
              </Badge>
            }
            classes={{ root: classes.tabRoot }}
            style={{ display: appBarItem?.hide ? "none" : "default" }}
            onClick={navigateTo(appBarItem)}
          />
        );
      })}
    </Tabs>
  );
};
