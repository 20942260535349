import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import {
  ClonePartInput,
  OpenRfqDrawerFromProject,
  Part,
  PoFileInput,
  ProjectBulkInsertPartInput,
  ProjectCodes,
  ProjectCompanyOrder,
  ProjectCompanyOrderInput,
  ProjectCompanyPart,
  ProjectCompanyPartInput,
  ProjectCreatePartInput,
  ProjectState,
  ProjectStatsInput,
  ProjectStatsType,
  ProjectUpdatePartInput,
  Rfq,
  UpdateRfqInput,
} from "./types";
import {
  FetchBomFilesUploadReportInput,
  ProjectBulkDeletePartInput,
  ProjectCompanyOrderCounts,
  ProjectCreateInput,
  ProjectCreateMultiContractRFQInput,
  ProjectDeleteInput,
  ProjectExportMultiContractRFQExcelInput,
  ProjectFetchInput,
  ProjectImportMultiContractRFQExcelInput,
  ProjectTemplate,
  ProjectType,
  ProjectUpdateInput,
  UpdateProjectTemplateInput,
  UploadBomFilesInput,
  UploadReportStatus,
} from ".";
import { addOrUpdateField } from "@next/utils/arrayUtils";

export const initialState: ProjectState = {
  projectStats: undefined,
  projectStatsLoading: true,
  projectStatsError: undefined,

  projectCompanyParts: [],
  projectCompanyPartsLoading: true,
  projectCompanyPartsError: undefined,
  projectCompanyPartsCount: 0,
  projectCompanyPartsLastQuery: undefined,
  projectCompanyPartsSelectAll: false,
  projectCompanyPartsFiltered: false,

  projectCompanyOrders: [],
  projectCompanyOrdersLoading: true,
  projectCompanyOrdersError: undefined,
  projectCompanyOrdersLastQuery: undefined,
  projectCompanyOrdersCounts: undefined,

  projectCreatePartLoading: false,
  projectCreatePartError: undefined,

  projectUpdatePartLoading: false,
  projectUpdatePartError: undefined,

  projectBulkInsertPartLoading: false,
  projectBulkInsertPartError: undefined,

  projectDeletePartLoading: false,
  projectDeletePartError: undefined,

  projectBulkDeletePartLoading: false,
  projectBulkDeletePartError: undefined,

  projectClonePartLoading: false,
  projectClonePartError: undefined,

  createRFQLoading: false,
  createRFQError: undefined,

  updateRFQLoading: false,
  updateRFQError: undefined,

  selectedPart: undefined,
  selectedPartRows: [],

  attachPurchaseOrderLoading: false,
  attachPurchaseOrderError: undefined,

  downloadAxyaServtechLoading: false,
  downloadAxyaServtechError: undefined,

  downloadQuotationFileLoading: false,
  downloadQuotationFileError: undefined,

  projects: [],
  projectsCount: 0,
  projectsLoading: false,
  projectsError: undefined,

  projectCreateLoading: false,
  projectCreateError: undefined,

  projectUpdateLoading: false,
  projectUpdateError: undefined,

  projectDeleteLoading: false,
  projectDeleteError: undefined,

  createMultiContractRFQLoading: false,
  createMultiContractRFQError: undefined,

  exportMultiContractRFQExcelLoading: false,
  exportMultiContractRFQExcelError: undefined,

  importMultiContractRFQExcelLoading: false,
  importMultiContractRFQExcelError: undefined,
  importMultiContractRFQExcelReport: {
    payload: [],
    parts_left: [],
    errors: [],
  },

  uploadBomFilesLoading: false,
  uploadBomFilesError: undefined,

  fetchBomFilesUploadReportLoading: false,
  fetchBomFilesUploadReportError: undefined,
  bomFilesUploadReport: undefined,

  uploadBulkPartLoading: false,
  uploadBulkPartError: undefined,

  fetchBulkPartImportReportLoading: false,
  fetchBulkPartImportReportError: undefined,
  bulkPartImportReport: undefined,

  fetchProjectTemplatesLoading: false,
  fetchProjectTemplatesError: undefined,
  projectTemplates: [],

  updateProjectTemplateLoading: false,
  updateProjectTemplateError: undefined,
};

const projectSlice = createSlice({
  name: "projectNext",
  initialState,
  reducers: {
    fetchProjectStatsRequest: (
      state,
      action: PayloadAction<ProjectStatsInput>
    ) => {
      state.projectStatsLoading = true;
      state.uploadBomFilesLoading = false;
      state.projectStatsError = undefined;
      if (action.payload.clearExisting !== false) {
        state.projectStats = undefined;
      }
    },
    fetchProjectStatsFailure: (state, action: PayloadAction<Error>) => {
      state.projectStatsLoading = false;
      state.projectStatsError = action.payload;
    },
    fetchProjectStatsSuccess: (
      state,
      action: PayloadAction<ProjectStatsType>
    ) => {
      state.projectStatsLoading = false;
      state.projectStatsError = undefined;
      state.projectStats = action.payload;
    },
    fetchProjectCompanyPartsRequest: (
      state,
      action: PayloadAction<ProjectCompanyPartInput>
    ) => {
      state.projectCompanyPartsLoading = true;
      state.projectCompanyPartsError = undefined;
      state.projectCompanyPartsLastQuery = action.payload?.query;
    },
    fetchProjectCompanyPartsFailure: (state, action: PayloadAction<Error>) => {
      state.projectCompanyPartsLoading = false;
      state.projectCompanyPartsError = action.payload;
    },
    fetchProjectCompanyPartsSuccess: (
      state,
      action: PayloadAction<{ results: ProjectCompanyPart[]; count: number }>
    ) => {
      state.projectCompanyPartsLoading = false;
      state.projectCompanyPartsError = undefined;
      state.projectCompanyParts = action.payload.results;
      state.projectCompanyPartsCount = action.payload.count;
    },
    fetchProjectCompanyOrdersRequest: (
      state,
      action: PayloadAction<ProjectCompanyOrderInput>
    ) => {
      state.projectCompanyOrdersLoading = true;
      state.projectCompanyOrdersError = undefined;
      state.projectCompanyOrdersLastQuery = action.payload?.query;
      state.projectCompanyPartsSelectAll = false;
      state.selectedPartRows = [];
    },
    fetchProjectCompanyOrdersFailure: (state, action: PayloadAction<Error>) => {
      state.projectCompanyOrdersLoading = false;
      state.projectCompanyOrdersError = action.payload;
      state.projectCompanyOrders = [];
      state.projectCompanyPartsSelectAll = false;
      state.selectedPartRows = [];
    },
    fetchProjectCompanyOrdersSuccess: (
      state,
      action: PayloadAction<
        {
          results: ProjectCompanyOrder[];
        } & ProjectCompanyOrderCounts
      >
    ) => {
      state.projectCompanyOrdersLoading = false;
      state.projectCompanyOrdersError = undefined;
      state.projectCompanyOrders = action.payload.results;
      state.projectCompanyOrdersCounts = {
        count: action.payload.count || 0,
        archived_count: action.payload.archived_count || 0,
        in_progress_count: action.payload.in_progress_count || 0,
        on_hold_count: action.payload.on_hold_count || 0,
        awarded_count: action.payload.awarded_count || 0,
        completed_count: action.payload.completed_count || 0,
      };
    },
    unshiftProjectCompanyOrder: (
      state,
      action: PayloadAction<ProjectCompanyOrder>
    ) => {
      state.projectCompanyOrders.unshift(action.payload);
    },
    createProjectPartRequest: (
      state,
      _action: PayloadAction<ProjectCreatePartInput>
    ) => {
      state.projectCreatePartLoading = true;
      state.projectCreatePartError = undefined;
    },
    createProjectPartFailure: (state, action: PayloadAction<Error>) => {
      state.projectCreatePartLoading = false;
      state.projectCreatePartError = action.payload;
    },
    createProjectPartSuccess: (
      state,
      action: PayloadAction<ProjectCompanyPart>
    ) => {
      state.projectCreatePartLoading = false;
      state.projectCreatePartError = undefined;
      state.projectCompanyParts.unshift(action.payload);
      state.projectCompanyPartsCount += 1;
    },
    updateProjectPartRequest: (
      state,
      _action: PayloadAction<ProjectUpdatePartInput>
    ) => {
      state.projectUpdatePartLoading = true;
      state.projectUpdatePartError = undefined;
    },
    updateProjectPartFailure: (state, action: PayloadAction<Error>) => {
      state.projectUpdatePartLoading = false;
      state.projectUpdatePartError = action.payload;
    },
    updateProjectPartSuccess: (
      state,
      action: PayloadAction<ProjectCompanyPart>
    ) => {
      state.projectUpdatePartLoading = false;
      state.projectUpdatePartError = undefined;

      state.projectCompanyParts = state.projectCompanyParts.map((item) => {
        if (item.pk === action.payload.pk) {
          return { ...item, ...action.payload };
        }

        return item;
      });
    },
    bulkInsertProjectPartRequest: (
      state,
      _action: PayloadAction<ProjectBulkInsertPartInput>
    ) => {
      state.projectBulkInsertPartLoading = true;
      state.projectBulkInsertPartError = undefined;
    },
    bulkInsertProjectPartFailure: (state, action: PayloadAction<Error>) => {
      state.projectBulkInsertPartLoading = false;
      state.projectBulkInsertPartError = action.payload;
    },
    bulkInsertProjectPartSuccess: (
      state,
      action: PayloadAction<Partial<ProjectCompanyPart>[]>
    ) => {
      state.projectBulkInsertPartLoading = false;
      state.projectBulkInsertPartError = undefined;

      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      action.payload.length > 0 &&
        action.payload?.forEach((importedPart) => {
          // Immutably Updating Or Adding An Item To An Array
          state.projectCompanyParts = addOrUpdateField(
            state.projectCompanyParts,
            importedPart
          );
        });
    },
    deleteProjectPartRequest: (
      state,
      _action: PayloadAction<ProjectCompanyPart["pk"] | undefined>
    ) => {
      state.projectDeletePartLoading = true;
      state.projectDeletePartError = undefined;
    },
    deleteProjectPartFailure: (state, action: PayloadAction<Error>) => {
      state.projectDeletePartLoading = false;
      state.projectDeletePartError = action.payload;
    },
    deleteProjectPartSuccess: (
      state,
      action: PayloadAction<ProjectCompanyPart["pk"] | undefined>
    ) => {
      state.projectDeletePartLoading = false;
      state.projectDeletePartError = undefined;
      state.projectCompanyParts = state.projectCompanyParts.filter(
        (item) => item.pk !== action.payload
      );
      state.projectCompanyPartsCount -= 1;
    },
    bulkDeleteProjectPartRequest: (
      state,
      _action: PayloadAction<ProjectBulkDeletePartInput>
    ) => {
      state.projectBulkDeletePartLoading = true;
      state.projectBulkDeletePartError = undefined;
    },
    bulkDeleteProjectPartFailure: (state, action: PayloadAction<Error>) => {
      state.projectBulkDeletePartLoading = false;
      state.projectBulkDeletePartError = action.payload;
    },
    bulkDeleteProjectPartSuccess: (state) => {
      state.projectBulkDeletePartLoading = false;
      state.projectBulkDeletePartError = undefined;
    },

    cloneProjectPartRequest: (
      state,
      _action: PayloadAction<ClonePartInput>
    ) => {
      state.projectClonePartLoading = true;
      state.projectClonePartError = undefined;
    },
    cloneProjectPartFailure: (state, action: PayloadAction<Error>) => {
      state.projectClonePartLoading = false;
      state.projectClonePartError = action.payload;
    },
    cloneProjectPartSuccess: (
      state,
      action: PayloadAction<ProjectCompanyPart>
    ) => {
      state.projectClonePartLoading = false;
      state.projectClonePartError = undefined;
      state.projectCompanyParts.unshift(action.payload);
      state.projectCompanyPartsCount += 1;
    },

    setSelectedPartRows: (state, action: PayloadAction<any>) => {
      state.selectedPartRows = action.payload;
    },
    setSelectedPart: (
      state,
      action: PayloadAction<ProjectCompanyPart | undefined>
    ) => {
      state.selectedPart = action.payload;
    },

    createRFQRequest: (state, _action: PayloadAction<Partial<Rfq>>) => {
      state.createRFQLoading = true;
      state.createRFQError = undefined;
    },
    createRFQFailure: (state, action: PayloadAction<Error>) => {
      state.createRFQLoading = false;
      state.createRFQError = action.payload;
    },
    createRFQSuccess: (state, action: PayloadAction<any>) => {
      state.createRFQLoading = false;
      state.createRFQError = undefined;
      state.projectCompanyParts = state.projectCompanyParts.map((item) => {
        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        action.payload?.parts.forEach((part: Part) => {
          if (part?.pk === item?.pk) {
            item.rfq = {
              pk: action.payload.pk,
              display_name: action.payload.rfq_display_name,
            };
          }
        });

        return item;
      });
    },
    updateRFQRequest: (state, _action: PayloadAction<UpdateRfqInput>) => {
      state.updateRFQLoading = true;
      state.updateRFQError = undefined;
    },
    updateRFQFailure: (state, action: PayloadAction<Error>) => {
      state.updateRFQLoading = false;
      state.updateRFQError = action.payload;
    },
    updateRFQSuccess: (state, action: PayloadAction<Rfq>) => {
      state.updateRFQLoading = false;
      state.updateRFQError = undefined;
      state.projectCompanyOrders = state.projectCompanyOrders.map((item) => {
        if (item.pk === action.payload.pk) {
          return { ...item, ...action.payload };
        }

        return item;
      });
    },
    attachPurchaseOrderRequest: (
      state,
      _action: PayloadAction<PoFileInput>
    ) => {
      state.attachPurchaseOrderLoading = true;
      state.attachPurchaseOrderError = undefined;
    },
    attachPurchaseOrderFailure: (state, action: PayloadAction<Error>) => {
      state.attachPurchaseOrderLoading = false;
      state.attachPurchaseOrderError = action.payload;
    },
    attachPurchaseOrderSuccess: (
      state,
      action: PayloadAction<ProjectCompanyOrder & { quotations: any }>
    ) => {
      state.attachPurchaseOrderLoading = false;

      state.attachPurchaseOrderError = undefined;

      state.projectCompanyOrders = state.projectCompanyOrders.map((item) => {
        if (item.pk === action.payload.pk) {
          return {
            ...item,
            status: action.payload.status,
            order_status: action.payload.order_status,
            po_file: action.payload.po_file,
          };
        }

        return item;
      });
    },

    downloadAxyaServtechRequest: (
      state,
      _action: PayloadAction<{ rfqId: string }>
    ) => {
      state.downloadAxyaServtechLoading = true;
      state.downloadAxyaServtechError = undefined;
    },
    downloadAxyaServtechFailure: (state, action: PayloadAction<Error>) => {
      state.downloadAxyaServtechLoading = false;
      state.downloadAxyaServtechError = action.payload;
    },
    downloadAxyaServtechSuccess: (state) => {
      state.downloadAxyaServtechLoading = false;
      state.downloadAxyaServtechError = undefined;
    },
    downloadQuotationFileRequest: (
      state,
      _action: PayloadAction<ProjectCodes>
    ) => {
      state.downloadQuotationFileLoading = true;
      state.downloadQuotationFileError = undefined;
    },
    downloadQuotationFileFailure: (state, action: PayloadAction<Error>) => {
      state.downloadQuotationFileLoading = false;
      state.downloadQuotationFileError = action.payload;
    },
    downloadQuotationFileSuccess: (state) => {
      state.downloadQuotationFileLoading = false;
      state.downloadQuotationFileError = undefined;
    },

    fetchProjectsRequest: (
      state,
      _action: PayloadAction<ProjectFetchInput>
    ) => {
      state.projectsLoading = true;
      state.projectsError = undefined;
      state.projects = [];
    },
    fetchProjectsFailure: (state, action: PayloadAction<Error>) => {
      state.projectsLoading = false;
      state.projectsError = action.payload;
    },
    fetchProjectsSuccess: (
      state,
      action: PayloadAction<{ results: ProjectType[]; count: number }>
    ) => {
      state.projectsLoading = false;
      state.projectsError = undefined;
      state.projects = action.payload.results;
      state.projectsCount = action.payload.count;
    },

    createProjectRequest: (
      state,
      _action: PayloadAction<ProjectCreateInput>
    ) => {
      state.projectCreateLoading = true;
      state.projectCreateError = undefined;
    },
    createProjectFailure: (state, action: PayloadAction<Error>) => {
      state.projectCreateLoading = false;
      state.projectCreateError = action.payload;
    },
    createProjectSuccess: (state, action: PayloadAction<ProjectType>) => {
      state.projectCreateLoading = false;
      state.projectCreateError = undefined;
      state.projects.unshift(action.payload);
    },

    updateProjectRequest: (
      state,
      _action: PayloadAction<ProjectUpdateInput>
    ) => {
      state.projectUpdateLoading = true;
      state.projectUpdateError = undefined;
    },
    updateProjectFailure: (state, action: PayloadAction<Error>) => {
      state.projectUpdateLoading = false;
      state.projectUpdateError = action.payload;
    },
    updateProjectSuccess: (
      state,
      action: PayloadAction<ProjectUpdateInput>
    ) => {
      state.projectUpdateLoading = false;
      state.projectUpdateError = undefined;

      state.projects = state.projects.map((item) => {
        if (item.code === action.payload.code) {
          return { ...item, ...action.payload };
        }

        return item;
      });
    },
    deleteProjectRequest: (
      state,
      _action: PayloadAction<ProjectDeleteInput>
    ) => {
      state.projectDeleteLoading = true;
      state.projectDeleteError = undefined;
    },
    deleteProjectFailure: (state, action: PayloadAction<Error>) => {
      state.projectDeleteLoading = false;
      state.projectDeleteError = action.payload;
    },
    deleteProjectSuccess: (state, action: PayloadAction<string>) => {
      state.projectDeleteLoading = false;
      state.projectDeleteError = undefined;
      state.projects = state.projects.filter(
        (item) => item.code !== action.payload
      );
    },
    createMultiContractRFQRequest: (
      state,
      _action: PayloadAction<Partial<ProjectCreateMultiContractRFQInput>>
    ) => {
      state.createMultiContractRFQLoading = true;
      state.createMultiContractRFQError = undefined;
    },
    createMultiContractRFQFailure: (state, action: PayloadAction<Error>) => {
      state.createMultiContractRFQLoading = false;
      state.createMultiContractRFQError = action.payload;
    },
    createMultiContractRFQSuccess: (state, action: PayloadAction<any>) => {
      state.createMultiContractRFQLoading = false;
      state.createMultiContractRFQError = undefined;
      state.createMultiContractRFQData = action.payload;
    },
    exportMultiContractRFQExcelRequest: (
      state,
      _action: PayloadAction<Partial<ProjectExportMultiContractRFQExcelInput>>
    ) => {
      state.exportMultiContractRFQExcelLoading = true;
      state.exportMultiContractRFQExcelError = undefined;
    },
    exportMultiContractRFQExcelFailure: (
      state,
      action: PayloadAction<Error>
    ) => {
      state.exportMultiContractRFQExcelLoading = false;
      state.exportMultiContractRFQExcelError = action.payload;
    },
    exportMultiContractRFQExcelSuccess: (
      state,
      _action: PayloadAction<any>
    ) => {
      state.exportMultiContractRFQExcelLoading = false;
      state.exportMultiContractRFQExcelError = undefined;
    },
    importMultiContractRFQExcelRequest: (
      state,
      _action: PayloadAction<Partial<ProjectImportMultiContractRFQExcelInput>>
    ) => {
      state.importMultiContractRFQExcelLoading = true;
      state.importMultiContractRFQExcelError = undefined;
    },
    importMultiContractRFQExcelFailure: (
      state,
      action: PayloadAction<Error>
    ) => {
      state.importMultiContractRFQExcelLoading = false;
      state.importMultiContractRFQExcelError = action.payload;
    },
    importMultiContractRFQExcelSuccess: (state, action: PayloadAction<any>) => {
      state.importMultiContractRFQExcelLoading = false;
      state.importMultiContractRFQExcelError = undefined;
      state.importMultiContractRFQExcelReport = action.payload;
    },
    openRFQDrawer: (
      state,
      _action: PayloadAction<OpenRfqDrawerFromProject>
    ) => {}, //empty action to trigger saga
    setProjectCompanyPartsSelectAll: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.projectCompanyPartsSelectAll = action.payload;
    },
    uploadBomFilesRequest: (
      state,
      _action: PayloadAction<UploadBomFilesInput>
    ) => {
      state.uploadBomFilesLoading = true;
      state.uploadBomFilesError = undefined;
    },
    uploadBomFilesFailure: (state, action: PayloadAction<Error>) => {
      state.uploadBomFilesLoading = false;
      state.uploadBomFilesError = action.payload;
    },
    uploadBomFilesSuccess: (
      state,
      _action: PayloadAction<UploadBomFilesInput>
    ) => {
      state.uploadBomFilesError = undefined;

      // Continue loading status when receiving STATUS=COMPLETED response from fetchBomFilesUploadReport
      state.uploadBomFilesLoading = true;
    },

    fetchBomFilesUploadReportRequest: (
      state,
      _action: PayloadAction<FetchBomFilesUploadReportInput>
    ) => {
      state.fetchBomFilesUploadReportLoading = true;
      state.fetchBomFilesUploadReportError = undefined;
    },
    fetchBomFilesUploadReportFailure: (state, action: PayloadAction<Error>) => {
      state.fetchBomFilesUploadReportLoading = false;
      state.fetchBomFilesUploadReportError = action.payload;
    },
    fetchBomFilesUploadReportSuccess: (
      state,
      action: PayloadAction<any | undefined>
    ) => {
      state.fetchBomFilesUploadReportLoading = false;
      state.fetchBomFilesUploadReportError = undefined;
      state.uploadBomFilesLoading =
        action?.payload?.status === UploadReportStatus.IN_PROGRESS;
      state.bomFilesUploadReport = action.payload;
    },

    fetchBulkPartImportReportRequest: (
      state,
      _action: PayloadAction<FetchBomFilesUploadReportInput>
    ) => {
      state.fetchBulkPartImportReportLoading = true;
      state.fetchBulkPartImportReportError = undefined;
    },
    fetchBulkPartImportReportFailure: (state, action: PayloadAction<Error>) => {
      state.fetchBulkPartImportReportLoading = false;
      state.fetchBulkPartImportReportError = action.payload;
    },
    fetchBulkPartImportReportSuccess: (
      state,
      action: PayloadAction<any | undefined>
    ) => {
      state.fetchBulkPartImportReportLoading = false;
      state.fetchBulkPartImportReportError = undefined;
      state.uploadBulkPartLoading =
        action?.payload?.status === UploadReportStatus.IN_PROGRESS;
      state.bulkPartImportReport = action.payload;
    },
    fetchProjectTemplatesRequest: (state) => {
      state.fetchProjectTemplatesLoading = true;
      state.fetchProjectTemplatesError = undefined;
    },
    fetchProjectTemplatesSuccess: (
      state,
      action: PayloadAction<ProjectTemplate[]>
    ) => {
      state.fetchProjectTemplatesLoading = false;
      state.fetchProjectTemplatesError = undefined;
      state.projectTemplates = action.payload;
    },
    fetchProjectTemplatesFailure: (state, action: PayloadAction<Error>) => {
      state.fetchProjectTemplatesLoading = false;
      state.fetchProjectTemplatesError = action.payload;
    },
    updateProjectTemplateRequest: (
      state,
      _action: PayloadAction<UpdateProjectTemplateInput>
    ) => {
      state.updateProjectTemplateLoading = true;
      state.updateProjectTemplateError = undefined;
    },
    updateProjectTemplateSuccess: (
      state,
      action: PayloadAction<ProjectStatsType>
    ) => {
      state.updateProjectTemplateLoading = false;
      state.updateProjectTemplateError = undefined;
      state.projectStats = action.payload;
    },
    updateProjectTemplateFailure: (state, action: PayloadAction<Error>) => {
      state.updateProjectTemplateLoading = false;
      state.updateProjectTemplateError = action.payload;
    },
    updateProjectTemplateInState: (
      state,
      action: PayloadAction<ProjectTemplate>
    ) => {
      const ix = state.projectTemplates.findIndex(
        (template) => template.id === action.payload.id
      );
      if (ix !== -1) {
        state.projectStats = {
          ...state.projectStats,
          template: state.projectTemplates[ix],
        } as ProjectStatsType;
      }
    },

    resetPolling: () => {},
    resetBomFilesUploadReport: (state) => {
      state.bomFilesUploadReport = undefined;
    },
    resetBulkPartImportReport: (state) => {
      state.bulkPartImportReport = undefined;
    },
    resetSelectedPartRows: (state) => {
      state.selectedPartRows = [];
      state.projectCompanyPartsSelectAll = false;
    },
    setProjectCompanyPartsFiltered: (state, action: PayloadAction<boolean>) => {
      state.projectCompanyPartsFiltered = action.payload;
    },
    resetImportMultiContractRFQExcelReport: (state) => {
      state.importMultiContractRFQExcelReport = {
        payload: [],
        errors: [],
        parts_left: [],
      };
      state.importMultiContractRFQExcelLoading = false;
      state.importMultiContractRFQExcelError = undefined;
      state.createMultiContractRFQData = undefined;
    },
  },
  extraReducers: (builder) => {
    // Default case if no other handlers matched
    // Clear to prevent persist error loading
    builder.addDefaultCase((state) => {
      state.projectDeletePartLoading = false;
      state.projectUpdatePartLoading = false;
      state.projectClonePartLoading = false;
      state.projectCreatePartLoading = false;
      state.projectBulkInsertPartLoading = false;
      state.createRFQLoading = false;
      state.updateRFQLoading = false;
      state.attachPurchaseOrderLoading = false;
      state.projectCreateLoading = false;
      state.createMultiContractRFQLoading = false;
      state.fetchBomFilesUploadReportLoading = false;
      state.projectCompanyPartsFiltered = false;
      state.importMultiContractRFQExcelLoading = false;
    });
  },
});

export const projectActions = projectSlice.actions;

export default projectSlice.reducer;
