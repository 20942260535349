import { CompanyTypeNext } from "@next/redux/types";
import { useSelector } from "react-redux";
import { getCompany } from "services/profile/profile.selectors";
import { frontendUrl } from "urls";
import { useHasCompanyRole } from "./useHasCompanyRole";

// for central home page redirection logic
export const useHomePageLink = () => {
  const company = useSelector(getCompany);
  const hasViewerRole = useHasCompanyRole(["viewer", "restricted_viewer"]);

  if (company.type === CompanyTypeNext.DO && hasViewerRole) {
    return frontendUrl.buyerQuotes;
  }

  return frontendUrl.dashboard;
};
