import { workspaceNextActions } from "@next/modules/workspace/redux";
import { modalsActions } from "@next/redux/modalsSlices";
import { safeCall } from "@next/redux/safeCall";
import { PayloadAction } from "@reduxjs/toolkit";
import { history } from "helpers/history";
import { enqueueSnackbar } from "notistack";
import { put, select, takeEvery, takeLatest } from "redux-saga/effects";
import { frontendUrl } from "urls";
import { OrdersModalTypes } from "../modals/types";
import { ordersService } from "../services/orders-services";
import { selectOrdersLastQuery } from "./selectors";
import { ordersActions } from "./slices";
import {
  AddOrderPartsInput,
  CreateOrderInput,
  DeleteOrderFileInput,
  DeleteOrderInput,
  DeleteShipmentPartInput,
  FetchOrderInput,
  FetchOrdersInput,
  FetchPortalOrderInput,
  OrderState,
  ReviewRevisionProposalInput,
  UpdateOrderFollowupInput,
  UpdateOrderLineItemsInput,
  UpdateOrderShippingConfirmationInput,
  UpdateOrderTargetedProfilesInput,
  UpdatePortalOrderAcknowledgeInput,
  UpdatePortalOrderInput,
  UpdatePortalOrderLineItemsInput,
  UploadOrderFileInput,
} from "./types";
import { t } from "assets/configi18n/i18n";
import { selectPublicQALastToken } from "@next/modules/workspace/redux/selectors";
import { workspaceNextService } from "@next/modules/workspace/services/workspace-services";
import { convertToOrderLineItemChanges } from "./utils";

function* handleFetchOrdersRequest({
  payload,
}: PayloadAction<FetchOrdersInput>) {
  const { response, error } = yield safeCall(
    ordersService.fetchOrders,
    payload
  );

  if (error) {
    yield put(ordersActions.fetchOrdersFailure(error));
  } else {
    yield put(ordersActions.fetchOrdersSuccess(response?.data));
  }
}

function* handleFetchSupplierLineItemsRequest({
  payload,
}: PayloadAction<FetchOrdersInput>) {
  const { response, error } = yield safeCall(
    ordersService.fetchSupplierLineItems,
    payload
  );

  if (error) {
    yield put(ordersActions.fetchSupplierLineItemsFailure(error));
  } else {
    yield put(ordersActions.fetchSupplierLineItemsSuccess(response?.data));
  }
}

function* handleFetchOrderRequest({ payload }: PayloadAction<FetchOrderInput>) {
  const { response, error } = yield safeCall(ordersService.fetchOrder, payload);
  if (error) {
    yield put(ordersActions.fetchOrderFailure(error));
  } else {
    yield put(ordersActions.fetchOrderSuccess(response?.data));
    yield put(ordersActions.fetchRevisionProposalRequest({ pk: payload.pk }));
  }
}

function* handleCreateOrderRequest({
  payload,
}: PayloadAction<CreateOrderInput>) {
  const { response, error } = yield safeCall(
    ordersService.createOrder,
    payload
  );

  if (error) {
    yield put(ordersActions.createOrderFailure(error));
  } else {
    yield put(ordersActions.createOrderSuccess(response?.data));

    yield put(modalsActions.closeModal(OrdersModalTypes.CREATE_ORDER_MODAL));

    yield enqueueSnackbar(t("orders:orderSuccessfullyCreated"), {
      autoHideDuration: 3000,
      variant: "success",
    });

    history.push(`${frontendUrl.orders}/${response?.data?.pk}`);
  }
}

function* handleUpdateOrderRequest({ payload }: PayloadAction<any>) {
  const { response, error } = yield safeCall(
    ordersService.updateOrder,
    payload
  );

  if (error) {
    yield put(ordersActions.updateOrderFailure(error));
  } else {
    const isDraftOrder = response?.data?.state.slug === OrderState.UNPUBLISHED;

    if (response?.data?.qa_token && !isDraftOrder) {
      yield put(
        workspaceNextActions.fetchQATopicRequest({
          token: response?.data?.qa_token,
        })
      );
    }

    yield put(ordersActions.updateOrderSuccess(response?.data));

    if (payload?.onSuccess) {
      payload.onSuccess();
    }

    if (payload?.is_sent) {
      yield put(modalsActions.closeModal(OrdersModalTypes.SEND_ORDER_DRAWER));

      yield enqueueSnackbar(t("orders:orderSuccessfullySent"), {
        autoHideDuration: 3000,
        variant: "success",
      });
    }

    if (payload?.is_cancelled) {
      yield enqueueSnackbar(t("orders:orderSuccessfullyCancelled"), {
        autoHideDuration: 3000,
        variant: "success",
      });
    }

    if (payload?.refetchOrders) {
      const ordersLastQuery: string = yield select(selectOrdersLastQuery);

      yield put(ordersActions.fetchOrdersRequest({ query: ordersLastQuery }));
    }
  }
}

function* handleDeleteOrderRequest({
  payload,
}: PayloadAction<DeleteOrderInput>) {
  const { response, error } = yield safeCall(
    ordersService.deleteOrder,
    payload
  );

  if (error) {
    yield put(ordersActions.deleteOrderFailure(error));
  } else {
    yield put(ordersActions.deleteOrderSuccess(response?.data));

    yield enqueueSnackbar(t("orders:orderSuccessfullyDeleted"), {
      autoHideDuration: 3000,
      variant: "success",
    });

    if (payload?.refetchOrders) {
      const ordersLastQuery: string = yield select(selectOrdersLastQuery);

      yield put(ordersActions.fetchOrdersRequest({ query: ordersLastQuery }));
    } else {
      history.push(frontendUrl.orders);
    }
  }
}

function* handleUpdateOrderFollowupRequest({
  payload,
}: PayloadAction<UpdateOrderFollowupInput>) {
  const { response, error } = yield safeCall(
    ordersService.updateOrderFollowup,
    payload
  );

  if (error) {
    yield put(ordersActions.updateOrderFollowupFailure(error));
  } else {
    yield put(ordersActions.updateOrderFollowupSuccess(response?.data));

    yield enqueueSnackbar(t("orders:followupSuccessfullyUpdated"), {
      autoHideDuration: 3000,
      variant: "success",
    });
  }
}

function* handleUploadOrderFileRequest({
  payload,
}: PayloadAction<UploadOrderFileInput>) {
  const { response, error } = yield safeCall(
    ordersService.uploadOrderFile,
    payload
  );

  if (error) {
    yield put(ordersActions.uploadOrderFileFailure(error));
  } else {
    yield put(ordersActions.uploadOrderFileSuccess(response?.data?.files));

    const isFilesError = !!response.data.filesError;
    if (isFilesError) {
      yield enqueueSnackbar(response.data.filesError, {
        autoHideDuration: 5000,
        variant: "error",
      });
    }

    if (payload?.onSuccess) {
      payload.onSuccess(response?.data?.files);
    }
  }
}

function* handleDeleteOrderFileRequest({
  payload,
}: PayloadAction<DeleteOrderFileInput>) {
  const { error } = yield safeCall(ordersService.deleteOrderFile, payload);

  if (error) {
    yield put(ordersActions.deleteOrderFileFailure(error));
  } else {
    yield put(ordersActions.deleteOrderFileSuccess());

    yield put(ordersActions.fetchOrderRequest({ pk: payload?.orderPk }));

    if (payload?.onSuccess) {
      payload.onSuccess();
    }
  }
}

function* handleFetchPortalOrderRequest({
  payload,
}: PayloadAction<FetchPortalOrderInput>) {
  const { response, error } = yield safeCall(
    ordersService.fetchPortalOrder,
    payload
  );

  if (error) {
    yield put(ordersActions.fetchPortalOrderFailure(error));
  } else {
    yield put(ordersActions.fetchPortalOrderSuccess(response?.data));
  }
}

function* handleUpdatePortalOrderRequest({
  payload,
}: PayloadAction<UpdatePortalOrderInput>) {
  const { qaToken, ...restPayload } = payload;
  const { response, error } = yield safeCall(
    ordersService.updatePortalOrder,
    restPayload
  );

  if (error) {
    yield put(ordersActions.updatePortalOrderFailure(error));
  } else {
    // Clear query params after action

    yield put(ordersActions.updatePortalOrderSuccess(response?.data));

    if (qaToken) {
      yield put(
        workspaceNextActions.fetchQATopicRequest({
          token: qaToken,
          isNotFirstFetch: true,
        })
      );
    }

    yield enqueueSnackbar(t("orders:orderSuccessfullyUpdated"), {
      autoHideDuration: 3000,
      variant: "success",
    });
  }
}

function* handleUpdateOrderDeliveryDatesRequest({
  payload,
}: PayloadAction<any>) {
  const { response, error } = yield safeCall(
    ordersService.updateOrderDeliveryDates,
    payload
  );

  if (error) {
    yield put(ordersActions.updateOrderDeliveryDatesFailure(error));
  } else {
    yield put(ordersActions.updateOrderDeliveryDatesSuccess(response?.data));

    yield enqueueSnackbar(t("orders:orderSuccessfullyUpdated"), {
      autoHideDuration: 3000,
      variant: "success",
    });
  }
}

function* handleUpdateOrderLineItemsRequest({
  payload,
}: PayloadAction<UpdateOrderLineItemsInput>) {
  const { response, error } = yield safeCall(
    ordersService.updateOrderLineItems,
    payload
  );

  yield put(
    modalsActions.closeModal(OrdersModalTypes.FINALIZE_PROPOSAL_REVIEW_MODAL)
  );

  if (error) {
    yield put(ordersActions.updateOrderLineItemsFailure(error));
  } else {
    yield refetchPublicCommunication();

    yield put(ordersActions.updateOrderLineItemsSuccess(response?.data));

    if (typeof payload.onSuccess === "function") payload.onSuccess();

    yield enqueueSnackbar(t("orders:orderSuccessfullyUpdated"), {
      autoHideDuration: 3000,
      variant: "success",
    });
  }
}

function* handleUpdatePortalOrderAcknowledgementRequest({
  payload,
}: PayloadAction<UpdatePortalOrderAcknowledgeInput>) {
  const { response, error } = yield safeCall(
    ordersService.updatePortalOrderAcknowledgement,
    payload
  );

  yield put(modalsActions.closeModal(OrdersModalTypes.ACKNOWLEDGE_ORDER_MODAL));

  if (error) {
    yield put(ordersActions.updatePortalOrderLineItemsFailure(error));
  } else {
    yield refetchPublicCommunication();

    yield put(ordersActions.updatePortalOrderLineItemsSuccess(response?.data));

    if (typeof payload.onSuccess === "function") payload.onSuccess();

    yield enqueueSnackbar(t("orders:orderSuccessfullyUpdated"), {
      autoHideDuration: 3000,
      variant: "success",
    });
  }
}

function* handleUpdatePortalOrderLineItemsRequest({
  payload,
}: PayloadAction<UpdatePortalOrderLineItemsInput>) {
  const { response, error } = yield safeCall(
    ordersService.updatePortalOrderLineItems,
    payload
  );

  if (error) {
    yield put(ordersActions.updatePortalOrderLineItemsFailure(error));
    yield enqueueSnackbar(t("common:error:general"), { variant: "error" });
  } else {
    yield refetchPublicCommunication();

    yield put(ordersActions.updatePortalOrderLineItemsSuccess(response?.data));

    if (typeof payload.onSuccess === "function") payload.onSuccess();

    yield enqueueSnackbar(t("orders:orderSuccessfullyUpdated"), {
      autoHideDuration: 3000,
      variant: "success",
    });
  }
}

function* handleUpdateOrderShippingConfirmationRequest({
  payload,
}: PayloadAction<UpdateOrderShippingConfirmationInput>) {
  let data;
  if (payload?.isPortal) {
    const { isPortal, orderPk, ...rest } = payload;
    data = rest;
  } else {
    const { orderToken, isPortal, ...rest } = payload;
    data = rest;
  }

  const { response, error } = yield safeCall(
    payload.isPortal
      ? ordersService.updatePortalOrderShippingConfirmation
      : ordersService.updateOrderShippingConfirmation,
    data
  );

  yield put(
    modalsActions.closeModal(OrdersModalTypes.ORDER_MARK_AS_SHIPPED_MODAL)
  );

  if (error) {
    yield put(ordersActions.updateOrderShippingConfirmationFailure(error));
  } else {
    yield refetchPublicCommunication();

    yield put(
      ordersActions.updateOrderShippingConfirmationSuccess({
        order: response?.data,
        lineItemsIds: payload.line_item_ids,
        isPortal: payload.isPortal,
        shippingNote: payload.shipment_note,
      })
    );

    yield put(ordersActions.setLineItemsTableSelectionModel([]));

    if (typeof payload.onSuccess === "function") payload.onSuccess();

    yield enqueueSnackbar(t("orders:orderSuccessfullyUpdated"), {
      autoHideDuration: 3000,
      variant: "success",
    });
  }
}

function* refetchPublicCommunication() {
  const lastToken: string = yield select(selectPublicQALastToken);

  yield put(
    workspaceNextActions.fetchQATopicRequest({
      token: lastToken,
      isNotFirstFetch: true,
    })
  );
}

function* handleAddOrderPartsRequest({
  payload,
}: PayloadAction<AddOrderPartsInput>) {
  const { error } = yield safeCall(ordersService.addOrderParts, payload);

  if (error) {
    yield put(ordersActions.addOrderPartsFailure(error));
  } else {
    yield put(ordersActions.addOrderPartsSuccess());

    yield enqueueSnackbar(t("orders:addOrderParts:itemOrganisedIntoShipment"), {
      autoHideDuration: 3000,
      variant: "default",
    });

    yield put(modalsActions.closeModal(OrdersModalTypes.ADD_ORDER_PARTS));

    yield put(ordersActions.fetchOrderRequest({ pk: payload?.order_pk }));
  }
}

function* handleDeleteShipmentPartRequest({
  payload,
}: PayloadAction<DeleteShipmentPartInput>) {
  const { error } = yield safeCall(ordersService.deleteShipmentPart, payload);

  if (error) {
    yield put(ordersActions.deleteShipmentPartFailure(error));
  } else {
    yield put(
      ordersActions.deleteShipmentPartSuccess({
        line_item_id: payload?.line_item_id,
      })
    );

    yield enqueueSnackbar(t("orders:orderSuccessfullyUpdated"), {
      autoHideDuration: 3000,
      variant: "success",
      preventDuplicate: true,
    });
  }
}

function* handleUpdateOrderTargetProfilesRequest({
  payload,
}: PayloadAction<UpdateOrderTargetedProfilesInput>) {
  const { response, error } = yield safeCall(
    workspaceNextService.updateRFQTargetProfiles,
    {
      rfqId: payload.rfqId,
      supplierId: payload.supplierId,
      target_profiles: payload.target_profiles,
    }
  );

  if (error) {
    yield put(ordersActions.updateOrderTargetedProfilesFailure(error));
    yield enqueueSnackbar(t("common:error:general"), { variant: "error" });
  } else {
    yield put(
      ordersActions.updateOrderTargetedProfilesSuccess(
        response?.data.target_profiles
      )
    );
    if (typeof payload.onSuccess === "function") payload.onSuccess();
  }
}

function* handleFetchRevisionProposalRequest({ payload }: PayloadAction<any>) {
  const { response, error } = yield safeCall(
    ordersService.fetchRevisionProposal,
    payload
  );

  if (error) {
    yield put(ordersActions.fetchRevisionProposalFailure(error));
  } else {
    yield put(
      ordersActions.setLineItemsChangesFromRevisionProposal(
        convertToOrderLineItemChanges(response?.data)
      )
    );
    yield put(ordersActions.fetchRevisionProposalSuccess(response?.data));
  }
}

function* handleReviewRevisionProposalRequest({
  payload,
}: PayloadAction<ReviewRevisionProposalInput>) {
  const { response, error } = yield safeCall(
    ordersService.reviewRevisionProposal,
    payload
  );

  yield put(
    modalsActions.closeModal(OrdersModalTypes.FINALIZE_PROPOSAL_REVIEW_MODAL)
  );

  if (error) {
    yield put(ordersActions.reviewRevisionProposalFailure(error));
  } else {
    yield refetchPublicCommunication();
    yield put(ordersActions.reviewRevisionProposalSuccess(response?.data));

    if (typeof payload.onSuccess === "function") payload.onSuccess();

    yield enqueueSnackbar(t("orders:proposalSuccessfullyReviewed"), {
      autoHideDuration: 3000,
      variant: "success",
    });
  }
}

export function* ordersSaga() {
  yield takeLatest(ordersActions.fetchOrdersRequest, handleFetchOrdersRequest);

  yield takeLatest(
    ordersActions.fetchSupplierLineItemsRequest,
    handleFetchSupplierLineItemsRequest
  );

  yield takeLatest(ordersActions.fetchOrderRequest, handleFetchOrderRequest);

  yield takeLatest(ordersActions.createOrderRequest, handleCreateOrderRequest);

  yield takeLatest(ordersActions.updateOrderRequest, handleUpdateOrderRequest);

  yield takeLatest(ordersActions.deleteOrderRequest, handleDeleteOrderRequest);

  yield takeLatest(
    ordersActions.updateOrderFollowupRequest,
    handleUpdateOrderFollowupRequest
  );

  yield takeLatest(
    ordersActions.uploadOrderFileRequest,
    handleUploadOrderFileRequest
  );

  yield takeLatest(
    ordersActions.deleteOrderFileRequest,
    handleDeleteOrderFileRequest
  );

  yield takeLatest(
    ordersActions.fetchPortalOrderRequest,
    handleFetchPortalOrderRequest
  );

  yield takeLatest(
    ordersActions.updatePortalOrderRequest,
    handleUpdatePortalOrderRequest
  );

  yield takeLatest(
    ordersActions.updateOrderDeliveryDatesRequest,
    handleUpdateOrderDeliveryDatesRequest
  );

  yield takeLatest(
    ordersActions.updateOrderLineItemsRequest,
    handleUpdateOrderLineItemsRequest
  );

  yield takeLatest(
    ordersActions.updatePortalOrderAcknowledgementRequest,
    handleUpdatePortalOrderAcknowledgementRequest
  );

  yield takeLatest(
    ordersActions.updatePortalOrderLineItemsRequest,
    handleUpdatePortalOrderLineItemsRequest
  );

  yield takeLatest(
    ordersActions.updateOrderShippingConfirmationRequest,
    handleUpdateOrderShippingConfirmationRequest
  );

  yield takeLatest(
    ordersActions.addOrderPartsRequest,
    handleAddOrderPartsRequest
  );

  yield takeEvery(
    ordersActions.deleteShipmentPartRequest,
    handleDeleteShipmentPartRequest
  );

  yield takeLatest(
    ordersActions.updateOrderTargetedProfilesRequest,
    handleUpdateOrderTargetProfilesRequest
  );

  yield takeLatest(
    ordersActions.fetchRevisionProposalRequest,
    handleFetchRevisionProposalRequest
  );

  yield takeLatest(
    ordersActions.reviewRevisionProposalRequest,
    handleReviewRevisionProposalRequest
  );
}
