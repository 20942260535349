import { Typography } from "@mui/material";
import { useAxyaTracking } from "@next/hooks/useAxyaTracking";
import { selectProjectStats } from "@next/modules/project/redux/selectors";
import { Formik } from "formik";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { workspaceActions } from "services/workspace";
import RFQCreationFormBase from "./rfq-creation.form.base";
import { getIsLastRFQPublic } from "services/part-configuration/part-configuration.selectors";

import {
  RequestType,
  formDataToRFQData,
  getInitialValues,
  getValidationSchema,
} from "./rfq-creation.form.definitions";
import { useStyles } from "./rfq-creation.form.styles";
import { RFQFormValuesType } from "./rfq-creation.form.types";
import { partConfigurationActions } from "services/part-configuration";
import { workspaceNextActions } from "../../redux";
import { enqueueSnackbar } from "notistack";

interface Props {
  initialValues: Partial<RFQFormValuesType>;
  projectCode?: string;
  loading?: boolean;
  selectAllParts?: boolean;
  selectedPartsCount?: number;
  partSearch?: string;
}

const RFQCreationForm: React.FC<Props> = (props) => {
  const {
    initialValues,
    projectCode,
    loading,
    selectAllParts,
    partSearch,
    selectedPartsCount,
  } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const classes = useStyles();
  const projectStats = useSelector(selectProjectStats);
  const axyaTracking = useAxyaTracking();
  const isPublic = useSelector(getIsLastRFQPublic);
  const isPartsEmpty = useRef(false);
  const [selectedPartsInForm, setSelectedPartsInForm] =
    useState(selectedPartsCount);

  useEffect(() => {
    setSelectedPartsInForm(selectedPartsCount);
  }, [selectedPartsCount]);

  const onSubmit = useCallback(
    (values: RFQFormValuesType) => {
      const rfqData = formDataToRFQData(values);

      if (
        values?.invited_suppliers?.length === 0 &&
        !values?.is_public &&
        values?.targeted_suppliers?.length === 0
      ) {
        enqueueSnackbar(
          `${t("workspaceNext:rfqDrawer:error:atLeastOneSupplierReqd")}`,
          {
            autoHideDuration: 3000,
            variant: "error",
          }
        );
        return;
      }

      if (
        (rfqData?.parts?.length === 0 &&
          (rfqData?.partsAndFilesCount?.partsCount === 0 ||
            rfqData?.partsAndFilesCount === (undefined || null))) ||
        (rfqData?.partsAndFilesCount === (undefined || null) &&
          rfqData?.parts?.length === 0)
      ) {
        enqueueSnackbar(
          `${t("workspaceNext:rfqDrawer:error:atLeastOnePartReqd")}`,
          {
            autoHideDuration: 3000,
            variant: "error",
          }
        );
        return;
      } else if (
        rfqData?.request_type === RequestType.RFI &&
        rfqData.attachments.length === 0
      ) {
        enqueueSnackbar(
          `${t("workspaceNext:rfqDrawer:error:rfiAttachmentRequired")}`,
          {
            autoHideDuration: 3000,
            variant: "error",
          }
        );
        return;
      }
      dispatch(
        workspaceActions.requestForQuotation(
          rfqData,
          !!projectCode || !!values.project,
          selectAllParts ||
            selectedPartsInForm === projectStats?.total_parts_count,
          partSearch,
          projectCode || values.project || "",
          (pk: number) => {
            if (rfqData.followup_date)
              axyaTracking({
                scout_category: "usage_metric",
                scout_rfq_pk: pk,
                scout_action: "followup_date_set",
                data: {
                  from: "rfq_form",
                  followup_date: rfqData.followup_date,
                },
              });
            dispatch(partConfigurationActions.fetchPartConfigurationData());
          }
        )
      );
      dispatch(workspaceNextActions.resetFileUploadProgress());
    },
    [t, selectAllParts, partSearch, projectCode]
  );

  const renderForm = useCallback(
    (formikProps) => {
      return (
        <RFQCreationFormBase
          {...formikProps}
          projectCode={projectCode}
          selectAllParts={selectAllParts}
          partSearch={partSearch}
          selectedPartsCount={selectedPartsInForm}
          setSelectedPartsCount={setSelectedPartsInForm}
        />
      );
    },
    [projectCode, selectAllParts, selectedPartsInForm]
  );

  if (
    selectAllParts &&
    selectedPartsInForm === projectStats?.total_parts_count &&
    !isPartsEmpty.current
  ) {
    isPartsEmpty.current = true;
  }

  return (
    <div className={classes.root}>
      {loading ? (
        <div className={classes.loaderContainer}>
          <Typography variant="h5">{t("common:pleaseWait")}</Typography>
        </div>
      ) : (
        <Formik
          render={renderForm}
          initialValues={getInitialValues({
            is_public: isPublic,
            project: projectCode,
            ...initialValues,
            parts: isPartsEmpty.current ? [] : initialValues.parts,
          })}
          onSubmit={onSubmit}
          validationSchema={getValidationSchema(t)}
          enableReinitialize
        />
      )}
    </div>
  );
};

export default RFQCreationForm;
