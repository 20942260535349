import { Button, CircularProgress, Theme } from "@mui/material";
import React, { useEffect, useState } from "react";
import AttachmentIcon from "@mui/icons-material/Attachment";
import CancelIcon from "@mui/icons-material/Cancel";
import { convertFilesToObjects, pickFiles } from "@next/utils/fileUtils";
import { FileData } from "../attach-file/attach-file-list.types";
import ConfirmationDialog from "@next/components/confirmation-dialog/confirmation-dialog";
import { fileDownloadFromUrl } from "@next/utils/downloadUtils";
import { useTranslation } from "react-i18next";
import { createErrorMessage } from "helpers/createErrorMessage";
import { createStyles, makeStyles } from "@mui/styles";
import { enqueueSnackbar } from "notistack";

type Props = Omit<Parameters<typeof pickFiles>[0], "onChange"> & {
  file?: any;
  pickerButtonText: string;
  onFileAdded?: (file: File) => void;
  onFileRemoved?: (file: Partial<FileData> | File) => void;
  onPick?: () => void;
  rootClassName?: string;
  btnProps?: Partial<React.ComponentProps<typeof Button>>;
};

const useStyles = makeStyles<Theme>((theme: Theme) =>
  createStyles({
    roundedBtn: {
      borderRadius: 99,
      marginLeft: theme.spacing(1),
      textTransform: "unset",
      fontWeight: 400,
      paddingTop: 0,
      paddingBottom: 0,
    },
    pickerBtn: {},
    fileBtn: {
      borderColor: theme.palette.primary.main,
    },
  })
);

const InlineFilePickerButton = ({
  file,
  onFileAdded,
  onFileRemoved,
  accept,
  maxMBs,
  pickerButtonText,
  onPick,
  rootClassName,
  btnProps,
}: Props) => {
  const [_file, setFile] = useState(file);
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    setFile(file);
  }, [file]);

  const _onPick = () => {
    if (typeof onPick === "function") {
      onPick();
      return;
    }

    pickFiles({
      accept,
      multiple: false,
      maxMBs,
      onChange: (files) => {
        if (!files.length) return;

        const pickedFile = files[0];
        setFile(convertFilesToObjects([pickedFile])[0]);

        if (typeof onFileAdded === "function") {
          setLoading(true);
          Promise.resolve(onFileAdded(pickedFile))
            .then(() => {
              enqueueSnackbar(t("common:success:uploadingFile"));
            })
            .catch((e) => {
              const error = e?.response?.data || e;
              console.error("Error uploading file: ", error);
              enqueueSnackbar(
                createErrorMessage(error, t("common:error:uploadingFile")) ||
                  t("common:error:uploadingFile"),
                { variant: "error" }
              );
              setFile(undefined);
            })
            .finally(() => setLoading(false));
        }
      },
    });
  };

  const onClose: React.ComponentProps<typeof CancelIcon>["onClick"] = (e) => {
    e.stopPropagation();
    setModalOpen(true);
  };

  const onDelete = () => {
    const currentFile = _file;
    setFile(undefined);
    setModalOpen(false);

    if (typeof onFileRemoved === "function" && currentFile) {
      Promise.resolve(onFileRemoved(currentFile))
        .then(() => {
          enqueueSnackbar(t("common:success:deletingFile"));
        })
        .catch((e) => {
          console.error("Error deleting file: ", e);
          enqueueSnackbar(t("common:error:deletingFile"), { variant: "error" });
          setFile(currentFile);
        });
    }
  };

  const onDownload = () => {
    if (!loading) {
      if (_file?.file_url && _file?.file_name && _file?.file_extension)
        fileDownloadFromUrl(
          _file.file_url,
          _file.file_name,
          _file.file_extension
        );
    }
  };

  if (_file)
    return (
      <>
        <Button
          className={`${rootClassName || classes.roundedBtn} ${
            classes.fileBtn
          }`}
          size="small"
          variant="outlined"
          endIcon={
            loading ? (
              <CircularProgress color="inherit" size={16} />
            ) : (
              <CancelIcon onClick={onClose} className={classes.btnIcon} />
            )
          }
          {...btnProps}
          onClick={onDownload}
        >
          {_file?.file_name || _file?.name}
        </Button>
        <ConfirmationDialog
          title={t("workspace:confirmation")}
          content={t("workspace:areYouSureYouWantToDeleteThisItem")}
          isOpen={modalOpen}
          handleClose={() => setModalOpen(false)}
          dialogActions={
            <div>
              <Button
                autoFocus
                onClick={() => setModalOpen(false)}
                color="primary"
              >
                {t("cancel")}
              </Button>
              <Button onClick={onDelete} color="primary">
                {t("delete")}
              </Button>
            </div>
          }
        />
      </>
    );

  return (
    <Button
      className={`${rootClassName || classes.roundedBtn} ${classes.pickerBtn}`}
      size="small"
      variant="outlined"
      startIcon={<AttachmentIcon className={classes.btnIcon} />}
      {...btnProps}
      onClick={_onPick}
    >
      {pickerButtonText}
    </Button>
  );
};

export default InlineFilePickerButton;
