import React from "react";
// Urls
import { frontendUrl } from "urls";
// Layout
import AppLayout from "layout/AppLayout";
// Components
import { ProjectMain } from "@next/modules/project/pages/project-main";
// Types
import { CustomRoute } from "./types";
import { ProjectNotFound } from "@next/modules/project/pages/project-not-found";
import { Projects } from "@next/modules/project/pages/projects";

const PROJECT_ROUTES: CustomRoute[] = [
  {
    // /projects
    layout: AppLayout,
    path: `${frontendUrl.projects}`,
    key: "pm-projects",
    exact: true,
    component: () => <Projects />,
    allowedRoles: ["admin", "normal", "restricted_viewer"],
  },
  {
    // /projects/not-found
    layout: AppLayout,
    path: `${frontendUrl.projects}/not-found`,
    key: "pm-not-found",
    exact: true,
    component: () => <ProjectNotFound />,
  },
  {
    // /projects/<project_code> (Buyer)
    layout: AppLayout,
    path: `${frontendUrl.projects}/:code`,
    key: "pm-details",
    exact: false,
    component: () => <ProjectMain />,
    allowedRoles: ["admin", "normal", "restricted_viewer"],
  },
];

export default PROJECT_ROUTES;
