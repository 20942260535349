import {
  APP_ENVIRONMENT,
  checkAppEnvironmentIs,
} from "@next/utils/constantUtils";
import { LicenseInfo as LicenseInfoV4 } from "@mui/x-data-grid-pro";

export const initializeDataGridProV4License = () => {
  const isValidEnvironment = checkAppEnvironmentIs([
    APP_ENVIRONMENT.PRODUCTION,
    APP_ENVIRONMENT.STAGING,
    APP_ENVIRONMENT.DEMO,
  ]);

  if (process.env.REACT_APP_MUI_DATA_GRID_PRO_LICENSE && isValidEnvironment) {
    LicenseInfoV4.setLicenseKey(
      process.env.REACT_APP_MUI_DATA_GRID_PRO_LICENSE
    );
  }
};
