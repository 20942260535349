import axios from "axios";
import { getToken } from "services/profile/profile.selectors";
import { store } from "store";
import { downloadFileNext } from "./fileUtils";

export const fileDownloadFromUrl = (
  url: string,
  fileName?: string,
  fileExtension?: string,
  requiresToken: boolean = false,
  propagateError: boolean = false,
  onFinish?: () => void
) => {
  return axios
    .get(url, {
      responseType: "blob",
      headers: requiresToken
        ? {
            Authorization: `JWT ${getToken(store.getState())}`,
          }
        : {},
    })
    .then((res) => {
      downloadFileNext(
        res.data,
        fileName || `download_${Date.now()}`,
        fileExtension,
        onFinish
      );
    })
    .catch((error) => {
      if (propagateError) throw error;
      else console.log("File download error", error);
    });
};
