import { Box } from "@mui/material";
import { AppTheme } from "layout/AppTheme";
import React, { PropsWithChildren } from "react";

export const IconCircle: React.FC<
  PropsWithChildren<{ className?: string; revert?: boolean }>
> = ({ children, className, revert }) => {
  return (
    <Box
      style={{
        height: "48px",
        width: "48px",
        backgroundColor: revert ? "white" : AppTheme.palette.primary.main,
        borderRadius: "50%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        color: revert ? AppTheme.palette.primary.main : "white",
        border: "1px solid white",
      }}
      className={className}
    >
      {children}
    </Box>
  );
};
