import { Typography } from "@material-ui/core";
import {
  Box,
  Button,
  Theme,
  alpha,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { createStyles } from "@mui/styles";
import { GridCellParams } from "@mui/x-data-grid-pro-v5";
import { DataGridProV5 } from "@next/components/data-grid-pro-v5";
import { confirmDialog } from "@next/modals/shared-confirm-dialog";
import { OrderMarkAsShippedModal } from "@next/modules/orders/modals/order-mark-as-shipped-modal";
import {
  ActionPanelType,
  LineItemType,
  ordersActions,
} from "@next/modules/orders/redux";
import {
  selectLineItemsTableSelectionModel,
  selectOrderActionPanelType,
} from "@next/modules/orders/redux/selectors";
import { formatDate } from "helpers/formatDate";
import React, { useEffect } from "react";
import { Trans, useTranslation } from "react-i18next";
import { batch, useDispatch, useSelector } from "react-redux";
import { OrderDetailEditLineItemChangeDate } from "./order-detail-edit-line-items-change-date/order-detail-edit-line-items-change-date";
import { useOrderDetailLineItemsTableData } from "./order-detail-line-items-table.hooks";
import { PO_PART_GRID_STATE } from "@next/constants/data-grid-state-constants";
import useLocalStorageGridState from "@next/hooks/useLocalStorageGridState-v5";
import { OrderDetailLineItemsTableField } from "./order-detail-line-items-table.types";
import { getUser } from "services/profile/profile.selectors";
import { CompanyTypeNext } from "@next/redux/types";
import { useQueryParam } from "@next/hooks/useQuery";
import AdsClickIcon from "@mui/icons-material/AdsClick";
import { RightPanelTabs } from "../order-detail-right-tabs/order-detail-right-tabs";

type Props = {
  isDraft?: boolean;
  isPortal?: boolean;
  headerHeight?: number;
};

const useStyles = makeStyles<Theme>((theme: Theme) =>
  createStyles({
    table: {
      "& .highlightedCell": {
        backgroundColor: alpha(theme.palette.warning.main, 0.1),
      },

      "& .highlightedBlueCell": {
        backgroundColor: alpha(theme.palette.primary.main, 0.1),
      },
    },
    tipContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      marginTop: "12px",
      borderRadius: "16px",
      backgroundColor: theme.palette.divider,
      padding: "6px 12px 6px 6px",
      width: "fit-content",
      gap: "4px",
    },
  })
);

export const OrderDetailLineItemsTable: React.FC<Props> = ({
  isDraft,
  isPortal,
  headerHeight,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const lineItemsTableSelectionModel = useSelector(
    selectLineItemsTableSelectionModel
  );
  const user = useSelector(getUser);
  const isBuyer = user?.type === CompanyTypeNext.DO;
  const { gridData, orderDetail } = useOrderDetailLineItemsTableData(isPortal);
  const theme = useTheme();
  const onlyForMobile = useMediaQuery(theme.breakpoints.down(937));
  const [_rightPanelTab, setRightPanelTab] = useQueryParam("panelTab");
  const orderActionPanelType = useSelector(selectOrderActionPanelType);

  const { apiRef } = useLocalStorageGridState(PO_PART_GRID_STATE);

  const dispatch = useDispatch();

  useEffect(() => {
    if (lineItemsTableSelectionModel?.length > 0) {
      setRightPanelTab(RightPanelTabs.ACTIONS_PANEL);
      dispatch(ordersActions.setOrderDetailRightTabOpen(true));
    }
  }, [lineItemsTableSelectionModel]);

  useEffect(() => {
    if (orderActionPanelType === ActionPanelType.ProposeQtyAndPriceChange) {
      dispatch(ordersActions.setLineItemsTableSelectionModel([]));
    }
  }, [orderActionPanelType]);

  const handleDeleteItems = () => {
    confirmDialog(
      `Are you sure delete this part?`,
      t("orders:deleteDraftConfirm"),
      () => {
        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        lineItemsTableSelectionModel?.forEach((id) => {
          dispatch(
            ordersActions.deleteShipmentPartRequest({
              order_pk: orderDetail?.pk as number,
              line_item_id: id as number,
            })
          );
        });
      },
      t("common:delete"),
      "delete",
      t("common:back")
    );
  };

  const handleEditDate = (date: Date) => {
    if (isDraft) {
      // Draft order auto save it
      dispatch(
        ordersActions.updateOrderDeliveryDatesRequest({
          orderPk: orderDetail?.pk,
          delivery_dates: lineItemsTableSelectionModel?.map((id) => {
            return {
              id: id,
              delivery_date: formatDate(date, "YYYY-MM-DD"),
            };
          }),
        })
      );
    }
  };

  const getCellClassName = (params: GridCellParams) => {
    const fieldsToCheck: string[] = [
      LineItemType.UPDATED_DELIVERY_DATE,
      LineItemType.QUANTITY,
      LineItemType.PRICE,
    ];

    if (fieldsToCheck.includes(params.field)) {
      const previousLineItem = params.row?.previous_line_item;
      const isFieldUpdated =
        isBuyer &&
        params.row?.updated_fields.includes(
          fieldsToCheck.find((field) => field === params.field) as LineItemType
        );
      if (previousLineItem && previousLineItem.hasOwnProperty(params.field)) {
        return "highlightedCell";
      }
      if (isFieldUpdated) {
        return "highlightedCell";
      }
    }

    if (
      orderActionPanelType === ActionPanelType.ModifyDeliveryDate &&
      params.field === LineItemType.UPDATED_DELIVERY_DATE
    ) {
      return "highlightedBlueCell";
    } else if (
      orderActionPanelType === ActionPanelType.ProposeQtyAndPriceChange &&
      (params.field === LineItemType.PRICE ||
        params.field === LineItemType.QUANTITY)
    ) {
      return "highlightedBlueCell";
    }
    return "";
  };

  const onCellClick = (params: GridCellParams) => {
    if (
      params.field === OrderDetailLineItemsTableField.ACTION &&
      params.row.status !== "shipped" &&
      orderActionPanelType !== ActionPanelType.ProposeQtyAndPriceChange &&
      params.row !== undefined
    ) {
      setRightPanelTab(RightPanelTabs.ACTIONS_PANEL);
      batch(() => {
        dispatch(ordersActions.setOrderDetailRightTabOpen(true));
        dispatch(
          ordersActions.setLineItemsTableSelectionModel([
            params.row.id as number,
          ])
        );
      });
    }
  };

  return (
    <Box>
      <Box display="flex" alignItems="center" mt="12px">
        <Typography variant="h6">
          {t("orders:orderDetail:lineItems")}
        </Typography>

        <Box ml="12px" mt="4px">
          <Typography variant="body2" color="textSecondary">
            {t("orders:orderDetail:selectedItems", {
              count: lineItemsTableSelectionModel?.length,
            })}
          </Typography>
        </Box>
        {isDraft ? (
          <>
            <Box ml="12px">
              <Button
                variant="outlined"
                color="error"
                onClick={handleDeleteItems}
                disabled={!lineItemsTableSelectionModel?.length}
              >
                {t("orders:orderDetail:deleteRows", {
                  count: lineItemsTableSelectionModel?.length,
                })}
              </Button>
            </Box>
            <Box ml="12px">
              <OrderDetailEditLineItemChangeDate
                disabled={!lineItemsTableSelectionModel?.length}
                label={t("orders:orderDetail:editReceptionDate")}
                onSelectedDateChange={handleEditDate}
              />
            </Box>
          </>
        ) : null}
      </Box>

      {isDraft ? null : (
        <Box className={classes.tipContainer}>
          <AdsClickIcon />
          <Typography variant="caption">
            <Trans i18nKey="orders:orderDetail:tipToModify" />
          </Typography>
        </Box>
      )}

      <Box
        mt="20px"
        height={
          onlyForMobile
            ? "600px"
            : `calc(100vh - ${
                isDraft
                  ? 550
                  : isPortal
                  ? headerHeight + 169
                  : headerHeight + 240
              }px)`
        }
        className={classes.table}
      >
        <DataGridProV5
          apiRef={apiRef}
          rows={gridData?.rows || []}
          columns={gridData?.columns || []}
          rowHeight={40}
          disableSelectionOnClick
          hideFooter
          checkboxSelection
          onSelectionModelChange={(newSelectionModel) => {
            dispatch(
              ordersActions.setLineItemsTableSelectionModel(newSelectionModel)
            );
          }}
          onCellClick={onCellClick}
          selectionModel={lineItemsTableSelectionModel}
          getCellClassName={getCellClassName}
          isRowSelectable={(params) =>
            params.row.status !== "shipped" &&
            orderActionPanelType !== ActionPanelType.ProposeQtyAndPriceChange &&
            params.row !== undefined
          }
        />
      </Box>

      <OrderMarkAsShippedModal />
    </Box>
  );
};
