import { Box, Button, Theme, Tooltip } from "@mui/material";
import { TabPanel } from "@next/components/tab-panel";
import { TabsView } from "@next/components/tabs-view";
import { modalsActions } from "@next/redux/modalsSlices";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import BuyerRfqsTable from "../../components/buyer-rfqs-table/buyer-rfqs-table";
import { NDAListModal } from "../../modals/nda-list-modal";
import { WorkspaceModalTypes } from "../../modals/types";
import AddIcon from "@mui/icons-material/Add";
import DraftRfqsMenu from "../../components/draft-rfqs-menu";
import { selectProjectCompanyOrdersCounts } from "@next/modules/project/redux/selectors";
import { checkCountAndReturn } from "./buyer-rfqs.utils";

import { CreateOrderModal } from "@next/modules/orders/modals/create-order-modal";
import { useHasCompanyRole } from "@next/hooks/useHasCompanyRole";
import { useTableSavedQueryPagination } from "@next/hooks/useTableSavedQueryPagination";
import { useCompanyPlan } from "@next/hooks/useIsPremiumCompany";
import { createStyles, makeStyles } from "@mui/styles";

export enum EBuyerRfqsTabType {
  ON_GOING = 0,
  ON_HOLD = 1,
  AWARDED = 2,
  COMPLETED = 3,
  CANCELLED = 4,
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: { marginRight: -theme.spacing(4) },
    header: {
      display: "flex",
      flexDirection: "column",
    },
    titleContainer: { display: "flex", alignItems: "center" },
    subtitle: { width: "50%", marginTop: theme.spacing(1.5) },
    content: { marginTop: theme.spacing(0.5) },
    tabPanels: {
      marginTop: theme.spacing(2.5),
    },
    button: {
      marginLeft: theme.spacing(1),
    },
  })
);

export const REQUESTS_TABLE_NAVIGATION_KEY = "REQUESTS_TABLE_NAVIGATION";

export const BuyerRfqs = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const isFreemiumCompany = useCompanyPlan("freemium");
  const hasViewerRole = useHasCompanyRole(["viewer"]);

  const { savedTableQueryPagination, setSavedTableQueryPagination } =
    useTableSavedQueryPagination(REQUESTS_TABLE_NAVIGATION_KEY);

  const selectedTab =
    savedTableQueryPagination?.tab || EBuyerRfqsTabType.ON_GOING;

  const setSelectedTab = (tab: EBuyerRfqsTabType) => {
    setSavedTableQueryPagination({ ...savedTableQueryPagination, tab });
  };

  const projectCompanyOrdersCounts = useSelector(
    selectProjectCompanyOrdersCounts
  );

  const tabItems = useMemo(
    () => [
      {
        tabName: t("workspaceNext:buyerRfqs:ongoing", {
          count: checkCountAndReturn(
            projectCompanyOrdersCounts?.in_progress_count
          ),
        }),
      },
      {
        tabName: t("workspaceNext:buyerRfqs:onhold", {
          count: checkCountAndReturn(projectCompanyOrdersCounts?.on_hold_count),
        }),
      },
      {
        tabName: t("workspaceNext:buyerRfqs:awarded", {
          count: checkCountAndReturn(projectCompanyOrdersCounts?.awarded_count),
        }),
      },
      {
        tabName: t("workspaceNext:buyerRfqs:completed", {
          count: checkCountAndReturn(
            projectCompanyOrdersCounts?.completed_count
          ),
        }),
      },
      {
        tabName: t("workspaceNext:buyerRfqs:cancelled", {
          count: checkCountAndReturn(
            projectCompanyOrdersCounts?.archived_count
          ),
        }),
      },
    ],
    [projectCompanyOrdersCounts, t]
  );

  const tabHandleChange = (_: React.ChangeEvent<{}>, value: any) =>
    setSelectedTab(value);

  const onClickNDAButton = () => {
    dispatch(
      modalsActions.showModal({ key: WorkspaceModalTypes.NDA_LIST_MODAL })
    );
  };

  const openRFQDrawer = () => {
    dispatch(
      modalsActions.showModal({
        key: isFreemiumCompany
          ? WorkspaceModalTypes.RFQ_CREATION_LITE_DRAWER
          : WorkspaceModalTypes.RFQ_CREATION_DRAWER,
        data: {
          selectAllParts: true,
        },
      })
    );
  };

  return (
    <div className={classes.root}>
      <div className={classes.header}>
        <div className={classes.titleContainer}>
          {/* <Typography variant="h5">
            {t("workspaceNext:buyerRfqs:title")}
          </Typography> */}

          {hasViewerRole ? null : (
            <>
              <Button
                startIcon={<AddIcon />}
                onClick={openRFQDrawer}
                className={classes.button}
                variant="contained"
                color="primary"
              >
                {t("BUNDLE:create_quote")}
              </Button>
              {!isFreemiumCompany ? (
                <>
                  <Box className={classes.button}>
                    <DraftRfqsMenu />
                  </Box>
                  <Tooltip title={t("workspaceNext:buyerRfqs:ndaTooltip")}>
                    <Button
                      onClick={onClickNDAButton}
                      className={classes.button}
                      size="medium"
                    >
                      NDA
                    </Button>
                  </Tooltip>
                </>
              ) : null}
            </>
          )}
        </div>

        {/* <Typography variant="caption" className={classes.subtitle}>
          {t("workspaceNext:buyerRfqs:description")}
        </Typography> */}
      </div>
      <div className={classes.content}>
        <TabsView
          value={selectedTab}
          tabItems={tabItems}
          handleChange={tabHandleChange}
        />

        <div className={classes.tabPanels}>
          <TabPanel value={selectedTab} index={EBuyerRfqsTabType.ON_GOING}>
            <BuyerRfqsTable query={`status=InProgress&status=Waiting`} />
          </TabPanel>

          <TabPanel value={selectedTab} index={EBuyerRfqsTabType.ON_HOLD}>
            <BuyerRfqsTable query={`status=onHold`} />
          </TabPanel>

          <TabPanel value={selectedTab} index={EBuyerRfqsTabType.AWARDED}>
            <BuyerRfqsTable query={`status=Contract`} isContractQuery={true} />
          </TabPanel>

          <TabPanel value={selectedTab} index={EBuyerRfqsTabType.COMPLETED}>
            <BuyerRfqsTable query={`status=completed`} isContractQuery={true} />
          </TabPanel>

          <TabPanel value={selectedTab} index={EBuyerRfqsTabType.CANCELLED}>
            <BuyerRfqsTable query={`status=archive`} isCancelledQuery={true} />
          </TabPanel>
        </div>
      </div>

      <NDAListModal />
      <CreateOrderModal />
    </div>
  );
};
