import { Box, Button, Chip, Theme, Tooltip, Typography } from "@mui/material";
import React from "react";
import { useDispatch } from "react-redux";
import AddIcon from "@mui/icons-material/Add";
import { WorkspaceModalTypes } from "@next/modules/workspace/modals/types";
import { modalsActions } from "@next/redux/modalsSlices";
import { RFQAddAddendaModal } from "@next/modules/workspace/modals/rfq-add-addenda-modal/rfq-add-addenda-modal";
import { Addenda } from "@next/modules/workspace/redux";
import { formatDate } from "helpers/formatDate";
import { AttachFileList } from "@next/components/attach-file/attach-file-list";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { useTranslation } from "react-i18next";
import { t } from "assets/configi18n/i18n";
import { createStyles, makeStyles } from "@mui/styles";

export const getAddendaName = (addenda: Addenda, number: number) =>
  `${t("rfqAddenda:addenda")} ${number} - ${formatDate(
    addenda.created_at,
    "DD-MMM-YYYY"
  )}`;

const useStyles = makeStyles<Theme>(() =>
  createStyles({
    listItem: {
      display: "flex",
      alignItems: "flex-start",
    },
    listItemLeft: {
      display: "flex",
      alignItems: "center",
      flex: 0.35,
    },
    listItemRight: {
      display: "flex",
      flex: 0.65,
      flexDirection: "column",
      gap: "0.5rem",
    },
    chip: {
      "& .MuiSvgIcon-root": {
        marginLeft: 8,
      },
    },
    preLine: {
      whiteSpace: "pre-line",
    },
  })
);

type Props = {
  addendaList?: Addenda[];
  allowCreation?: boolean;
};

export const RfqAddenda: React.FC<Props> = ({ addendaList, allowCreation }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const onClickAddAddenda = () => {
    dispatch(
      modalsActions.showModal({
        key: WorkspaceModalTypes.RFQ_ADD_ADDENDA_MODAL,
        data: {
          index: addendaList?.length || 0,
        },
      })
    );
  };
  return (
    <Box display="flex" flexDirection="column" gap="1rem">
      {addendaList?.map((addenda, i) => {
        return (
          <Box className={classes.listItem}>
            <Box className={classes.listItemLeft}>
              <Typography variant="body2" color="textSecondary">
                {getAddendaName(addenda, addendaList.length - i)}
              </Typography>
            </Box>
            <Box className={classes.listItemRight}>
              {addenda.note && (
                <Typography variant="body2" style={{ whiteSpace: "pre-line" }}>
                  {addenda.note}
                </Typography>
              )}
              {addenda.files?.length ? (
                <Box mb={-1}>
                  <AttachFileList files={addenda.files} />
                </Box>
              ) : null}
              <Box alignSelf="flex-start">
                <Tooltip
                  title={
                    <span className={classes.preLine}>
                      {addenda.views?.map((view) => view.name)?.join("\n") ||
                        t("rfqAddenda:noViewsYet")}
                    </span>
                  }
                >
                  <Chip
                    className={classes.chip}
                    variant="outlined"
                    size="small"
                    label={addenda.views?.length || 0}
                    icon={<VisibilityIcon />}
                  />
                </Tooltip>
              </Box>
            </Box>
          </Box>
        );
      })}
      {allowCreation && (
        <>
          <Box alignSelf="flex-start">
            <Button
              variant="outlined"
              startIcon={<AddIcon />}
              onClick={onClickAddAddenda}
            >
              {t("rfqAddenda:addAnAddenda")}
            </Button>
          </Box>
          <RFQAddAddendaModal />
        </>
      )}
    </Box>
  );
};
