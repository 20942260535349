import { Box, Link, Typography } from "@mui/material";
import React from "react";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";

type Props = {
  path: string;
  label: string;
};

export const ExternalLinkActionItem: React.FC<Props> = ({ path, label }) => {
  return (
    <Box
      display="flex"
      alignItems="center"
      style={{ cursor: "pointer" }}
      ml="8px"
    >
      <Link
        href={path}
        target={"_blank"}
        style={{ textDecoration: "none", color: "black" }}
      >
        <Typography variant="body2">{label}</Typography>
      </Link>

      <OpenInNewIcon
        style={{
          width: "18px",
          height: "18px",
          color: "black",
          marginLeft: "8px",
        }}
      />
    </Box>
  );
};
