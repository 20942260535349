import { frontendUrl } from "urls";
import { AppBarItemType } from "./app-bar-next-tabs";
import { t } from "assets/configi18n/i18n";
import { RootState, store } from "store";
import { checkHasCompanyRole } from "@next/hooks/useHasCompanyRole";
import { getSupplierDirectoryLink } from "@next/utils/constantUtils";
import { checkIsRedirectMyPartsToCatalog } from "@next/utils/checkCompanyUtils";
import { SvgIconProps } from "@mui/material";
import DashboardIcon from "@mui/icons-material/Dashboard";
import QueryStatsRoundedIcon from "@mui/icons-material/QueryStatsRounded";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import WorkIcon from "@mui/icons-material/Work";
import PermContactCalendarIcon from "@mui/icons-material/PermContactCalendar";
import ViewListIcon from "@mui/icons-material/ViewList";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import GroupWorkIcon from "@mui/icons-material/GroupWork";
import RssFeedIcon from "@mui/icons-material/RssFeed";
import { selectMarketplaceCounts } from "@next/modules/workspace/redux/selectors";

export enum AppBarItemKey {
  DASHBOARD = "DASHBOARD",
  ANALYTICS = "ANALYTICS",
  PARTS = "PARTS",
  REQUESTS = "REQUESTS",
  ORDERS = "ORDERS",
  SUPPLIER_ORDERS = "SUPPLIER_ORDERS",
  SUPPLIERS = "SUPPLIERS",
  PROJECTS = "PROJECTS",
  DISCOVER_SUPPLIERS = "DISCOVER_SUPPLIERS",
  AXYA_NETWORK = "AXYA_NETWORK",
  SUBMISSIONS = "SUBMISSIONS",
  AWARDED_REQUESTS = "AWARDED_REQUESTS",
  NEWSFEED = "NEWSFEED",
}

export const AppBarItemIconTextMap: {
  [key in AppBarItemKey]?: React.ComponentType<SvgIconProps>;
} = {
  [AppBarItemKey.DASHBOARD]: DashboardIcon,
  [AppBarItemKey.ANALYTICS]: QueryStatsRoundedIcon,
  [AppBarItemKey.PARTS]: FormatListBulletedIcon,
  [AppBarItemKey.REQUESTS]: InsertDriveFileIcon,
  [AppBarItemKey.ORDERS]: WorkIcon,
  [AppBarItemKey.SUPPLIERS]: PermContactCalendarIcon,
  [AppBarItemKey.PROJECTS]: ViewListIcon,
  [AppBarItemKey.DISCOVER_SUPPLIERS]: OpenInNewIcon,
  [AppBarItemKey.SUBMISSIONS]: PermContactCalendarIcon,
  [AppBarItemKey.AWARDED_REQUESTS]: WorkIcon,
  [AppBarItemKey.AXYA_NETWORK]: GroupWorkIcon,
  [AppBarItemKey.NEWSFEED]: RssFeedIcon,
};

export const getBuyerAppBarItems = (): AppBarItemType[] => {
  const state: RootState = store.getState();
  const user = state?.profile?.user;
  const company = state?.profile?.company || user?.company;
  const isPmFeatureEnabled = company?.is_pm_feature_enabled;
  const isRfqAnalyticsFeatureEnabled = company?.is_rfq_analytics_enabled;
  const isPoAnalyticsFeatureEnabled = company?.is_po_analytics_enabled;
  const isOrderManagementEnabled = company?.is_order_management_enabled;
  const hasViewerRole = checkHasCompanyRole(user, ["viewer"], false);
  const hasRestrictedViewerRole = checkHasCompanyRole(
    user,
    ["restricted_viewer"],
    false
  );
  const supplierDirectoryLink = getSupplierDirectoryLink();
  const isCatalogActivated = checkIsRedirectMyPartsToCatalog(company.id);
  const pendingConnections = user?.company_stats?.pending_connections;
  const newNewsfeedPosts = user?.company_stats?.newsfeed_new_posts;

  const requestTabItem = {
    label: t("mainNavigationSidebar:requests"),
    path: frontendUrl.buyerQuotes,
    itemKey: AppBarItemKey.REQUESTS,
  };

  const projectsTabItem = {
    label: t("mainNavigationSidebar:projects"),
    path: frontendUrl.projects,
    hide: !isPmFeatureEnabled,
    itemKey: AppBarItemKey.PROJECTS,
  };

  const orderTabItem = {
    label: t("mainNavigationSidebar:orders"),
    path: frontendUrl.orders,
    hide: !isOrderManagementEnabled,
    itemKey: AppBarItemKey.ORDERS,
  };

  if (!company?.is_active) return [];

  if (hasViewerRole || hasRestrictedViewerRole) {
    return [
      {
        index: 0,
        ...requestTabItem,
      },
      ...(hasRestrictedViewerRole ? [{ index: 1, ...projectsTabItem }] : []),
      {
        index: hasRestrictedViewerRole ? 2 : 1,
        ...orderTabItem,
      },
    ];
  }

  return [
    {
      index: 0,
      label: t("mainNavigationSidebar:dashboard"),
      path: frontendUrl.dashboard,
      itemKey: AppBarItemKey.DASHBOARD,
    },
    {
      index: 1,
      label: t("mainNavigationSidebar:analytics"),
      path: frontendUrl.analytics,
      itemKey: AppBarItemKey.ANALYTICS,
      isBeta: true,
      hide: !(isRfqAnalyticsFeatureEnabled || isPoAnalyticsFeatureEnabled),
    },
    {
      index: 2,
      label: t("mainNavigationSidebar:parts"),
      path: isCatalogActivated ? frontendUrl.catalog : frontendUrl.myParts,
      itemKey: AppBarItemKey.PARTS,
    },
    {
      index: 3,
      ...requestTabItem,
    },
    {
      index: 4,
      ...orderTabItem,
    },
    {
      index: 5,
      label: t("mainNavigationSidebar:suppliers"),
      path: frontendUrl.mySuppliers,
      badgeCount: pendingConnections,
      itemKey: AppBarItemKey.SUPPLIERS,
    },
    {
      index: 6,
      ...projectsTabItem,
    },
    {
      index: 7,
      label: t("mainNavigationSidebar:newsfeed"),
      path: frontendUrl.newsfeed,
      badgeCount: newNewsfeedPosts,
      itemKey: AppBarItemKey.NEWSFEED,
    },
    {
      index: 8,
      label: t("workspaceNext:supplierTable:buttons:discoverSuppliers"),
      path: supplierDirectoryLink || "",
      external: true,
      itemKey: AppBarItemKey.DISCOVER_SUPPLIERS,
    },
  ];
};

export const getFreemiumBuyerAppBarItems = (): AppBarItemType[] => {
  const state = store.getState();
  const user = state?.profile?.user;
  const newNewsfeedPosts = user?.company_stats?.newsfeed_new_posts;
  const supplierDirectoryLink = getSupplierDirectoryLink();

  return [
    {
      index: 0,
      label: t("mainNavigationSidebar:dashboard"),
      path: frontendUrl.dashboard,
      itemKey: AppBarItemKey.DASHBOARD,
    },
    {
      index: 1,
      label: t("mainNavigationSidebar:requests"),
      path: frontendUrl.buyerQuotes,
      itemKey: AppBarItemKey.REQUESTS,
    },
    {
      index: 2,
      label: t("mainNavigationSidebar:suppliers"),
      path: frontendUrl.mySuppliers,
      itemKey: AppBarItemKey.SUPPLIERS,
    },
    {
      index: 3,
      label: t("mainNavigationSidebar:newsfeed"),
      path: frontendUrl.newsfeed,
      badgeCount: newNewsfeedPosts,
      itemKey: AppBarItemKey.NEWSFEED,
    },
    {
      index: 4,
      label: t("workspaceNext:supplierTable:buttons:discoverSuppliers"),
      path: supplierDirectoryLink || "",
      external: true,
      itemKey: AppBarItemKey.DISCOVER_SUPPLIERS,
    },
  ];
};

export const getSellerAppBarItems = (): AppBarItemType[] => {
  const state = store.getState();
  const company = state.profile.company;
  const counts = selectMarketplaceCounts(state);
  const totalUnseenCounts =
    (counts.private_unseen || 0) +
    (counts.public_unseen || 0) +
    (counts.tenders_unseen || 0);

  const user = state?.profile?.user;
  const newNewsfeedPosts = user?.company_stats?.newsfeed_new_posts;

  if (!company?.is_active) return [];

  return [
    {
      index: 0,
      label: t("mainNavigationSidebar:dashboard"),
      path: frontendUrl.dashboard,
      itemKey: AppBarItemKey.DASHBOARD,
    },
    {
      index: 1,
      label: t("mainNavigationSidebar:requests"),
      path: frontendUrl.marketplace,
      itemKey: AppBarItemKey.REQUESTS,
      badgeCount: totalUnseenCounts,
    },
    {
      index: 2,
      label: t("mainNavigationSidebar:submissions"),
      path: frontendUrl.supplierQuotes,
      itemKey: AppBarItemKey.SUBMISSIONS,
    },
    {
      index: 3,
      label: t("mainNavigationSidebar:awardedRequests"),
      path: frontendUrl.supplierContracts,
      itemKey: AppBarItemKey.AWARDED_REQUESTS,
    },
    {
      index: 4,
      label: t("mainNavigationSidebar:orders"),
      path: frontendUrl.supplierLineItems,
      itemKey: AppBarItemKey.SUPPLIER_ORDERS,
    },
    {
      index: 5,
      label: t("workspaceNext:supplierTable:buttons:axyaNetwork"),
      path: "https://app.axya.co/search",
      itemKey: AppBarItemKey.AXYA_NETWORK,
      navigateWithHref: true,
    },
    {
      index: 6,
      label: t("mainNavigationSidebar:newsfeed"),
      path: frontendUrl.newsfeed,
      badgeCount: newNewsfeedPosts,
      itemKey: AppBarItemKey.NEWSFEED,
    },
  ];
};
