import React from "react";
import { Route, Switch, useRouteMatch } from "react-router-dom";
import { withTranslation } from "react-i18next";

import NotFoundPage from "components/not-found-page/not-found-page.component";

import { CustomRoute } from "./config/types";

export const RenderRoutes = ({
  routes,
  props,
}: {
  routes: CustomRoute[];
  props: any;
}) => {
  let { path, url } = useRouteMatch();

  return (
    <Switch>
      {routes.map((route: CustomRoute, i: number) => {
        return (
          <Route
            key={i}
            path={route.path}
            exact={route.exact}
            {...props}
            render={(routeProps) => {
              return (
                <route.layout {...props} routeConfig={route}>
                  <route.component {...props} {...routeProps} />
                </route.layout>
              );
            }}
          />
        );
      })}
      <Route
        path="*"
        component={withTranslation("ERRORS")((props) => {
          return <NotFoundPage {...props} />;
        })}
      />
    </Switch>
  );
};
