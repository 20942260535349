import React from "react";
import { IconButton, Theme } from "@mui/material";
import { grey } from "@mui/material/colors";
import { createStyles, makeStyles } from "@mui/styles";

export type ColorTypes =
  | "primary"
  | "secondary"
  | "error"
  | "success"
  | "warning"
  | "default"
  | "inherit"
  | "info";

type IconButtonProps = {
  bordered?: boolean;
} & React.ComponentProps<typeof IconButton>;

const useStyles = makeStyles<Theme>((theme) =>
  createStyles({
    bordered: {
      border: `1px solid ${grey[400]}`,
      borderRadius: "8px",
      padding: "6px",
    },
  })
);

const CustomIconButton: React.FC<IconButtonProps> = ({
  children,
  bordered,
  ...restProps
}) => {
  const classes = useStyles();

  return (
    <IconButton
      {...restProps}
      color="secondary"
      className={`${restProps.className} ${bordered ? classes.bordered : ""}`}
    >
      {children}
    </IconButton>
  );
};

export default CustomIconButton;
