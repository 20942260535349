import React, { useEffect, useState } from "react";

import { useTranslation } from "react-i18next";
import { useTracking } from "react-tracking";
import { batch, useDispatch, useSelector } from "react-redux";

import { Box, CircularProgress, Grid } from "@mui/material";

import { workspaceNextActions } from "@next/modules/workspace/redux";
import { BuyerRfqsInformation } from "components/buyer-rfqs/buyer-rfqs-information/buyer-rfqs-information";
import { BuyerRfqsOffers } from "components/buyer-rfqs/buyer-rfqs-offers/buyer-rfqs-offers.component";
import PartDialog from "components/part-dialog/part-dialog.component";
//  Helpers imports
import { history } from "helpers/history";
import { frontendUrl } from "urls";
import { getModal, modalsActions } from "@next/redux/modalsSlices";
import { SuppliersSection } from "@next/modules/workspace/components/suppliers-section/suppliers-section";
import { confirmDialog } from "@next/modals/shared-confirm-dialog";
import { useQuery } from "@next/hooks/useQuery";
import { SharedModalTypes } from "@next/modals/types";
import { TabsView } from "@next/components/tabs-view";
import { TabPanel } from "@next/components/tab-panel";
import { QuoteComparison } from "@next/modules/workspace/components/quote-comparison/quote-comparison";
import { RfqCommunicationDrawer } from "@next/modules/workspace/modals/rfq-communication-drawer";
import { useRfqQAStats } from "@next/modules/workspace/components/questions-answers/questions-answers.hooks";
import { WorkspaceModalTypes } from "@next/modules/workspace/modals/types";

export function BuyerRfqsView(props) {
  const {
    token,
    profile,
    fetchRFQDetails,
    isRFQDetailsLoading,
    rfqDetails,
    params,
    selectWinner,
    handleCloseModal,
    handleOpenModal,
    isQuotationsTabs,
    setBuyerQuotationsTabs,
    getQuotationThreadDetails,
    setActiveQuotationThread,
    congratsInfo,
    setCongratulationViewDetails,
  } = props;
  const { t } = useTranslation("workspace");
  const tracking = useTracking();
  const dispatch = useDispatch();
  const [isPublicSwitchEnabled, setIsPublicSwitchEnabled] = useState(false);
  const [initiallyQAOpenedOnce, setInitiallyQAOpenedOnce] = useState(false);
  const { rfqQAStats } = useRfqQAStats(rfqDetails?.pk);

  useEffect(() => {
    if (rfqDetails) {
      setIsPublicSwitchEnabled(!rfqDetails.is_private);
    }
  }, [isRFQDetailsLoading]);

  const isOrderPurchaseModal = useSelector(
    getModal(SharedModalTypes.ORDER_PURCHASE_MODAL)
  );
  const [query, setQuery] = useQuery();

  const selectedTab = +(query?.rfqTab || 0);
  const initiallyOpenQAId = query?.qa;
  const fromSplitOrInbox = query?.fromSplitOrInbox;

  const setSelectedTab = (tab) => {
    setQuery({ rfqTab: tab });
  };

  const [expendedPanel, setExpandedPanel] = useState(false);
  const tabItems = [
    {
      tabName: t("workspaceNext:buyerRfqDetail:information"),
    },
    {
      tabName: t("workspaceNext:buyerRfqDetail:quotes", {
        count: rfqDetails?.quotations.length,
      }),
    },
    {
      tabName: t("workspaceNext:buyerRfqDetail:quoteComparison"),
      hide:
        !rfqDetails ||
        rfqDetails?.hide_quotation_sensitive_data ||
        rfqDetails?.request_type === "rfi",
      badgeContent: t("mainNavigationSidebar:new"),
      badgeMarginLeft: profile?.user?.language == "fr" ? "35px" : "24px",
      badgeMarginRight: "24px",
    },
  ];

  const [congratsViewInfo, setCongratsViewInfo] = useState({});

  useEffect(() => {
    const isBuyerRfqVisited = localStorage.getItem("isBuyerRfqVisited");
    if (!isBuyerRfqVisited && profile?.user?.is_getting_started) {
      handleOpenTour();
      localStorage.setItem("isBuyerRfqVisited", true);
    }
  }, []);

  useEffect(() => {
    if (params.id) {
      if (selectedTab === 2) {
        dispatch(
          workspaceNextActions.fetchQuoteComparisonRequest({
            rfq_id: parseInt(params?.id),
          })
        );
      }
      fetchRFQDetails(params.id, true);
    }
  }, [params.id, selectedTab]);

  const tabHandleChange = (event, value) => {
    setSelectedTab(value);
  };

  const handleOpenTour = () => {
    handleOpenModal(true, "isRfqViewTourOpen");
    // tracking open rfq view tour click
    tracking.trackEvent({
      scout_category: "tour_guide",
      scout_feature_name: "open_rfq_view_tour",
      scout_company: profile["company"]["name"],
      scout_username: profile["user"]["name"],
      scout_user_type: profile["user"]["type"],
      scout_date: new Date(),
    });
  };

  const onPublicSwitchToggle = (is_enabled) => {
    confirmDialog(
      t("rfq:isPublicConfirmation:title"),
      t("rfq:isPublicConfirmation:content"),
      () => {
        setIsPublicSwitchEnabled(is_enabled);
        dispatch(
          workspaceNextActions.updateRFQIsPublicRequest({
            pk: rfqDetails?.pk,
            isPublic: is_enabled,
            onSuccess: () => {},
          })
        );
      },
      t("rfq:isPublicConfirmation:confirm"),
      "default",
      t("rfq:isPublicConfirmation:cancel")
    );
  };

  useEffect(() => {
    if (
      isQuotationsTabs ||
      (rfqDetails &&
        rfqDetails.winner !== null &&
        !fromSplitOrInbox &&
        selectedTab !== 2)
    ) {
      setSelectedTab(1);
      setExpandedPanel(true);
      setBuyerQuotationsTabs(false);
    } else {
      setExpandedPanel(false);
    }
  }, [isQuotationsTabs, rfqDetails?.winner, fromSplitOrInbox]);

  useEffect(() => {
    if (congratsInfo && Object.keys(congratsInfo).length > 0) {
      setCongratsViewInfo((prev) => {
        return { ...prev, ...congratsInfo };
      });
    }
  }, [congratsInfo]);

  useEffect(() => {
    if (initiallyOpenQAId && rfqQAStats && !initiallyQAOpenedOnce) {
      const question = rfqQAStats.find(
        (question) => question.id === initiallyOpenQAId
      );
      const targetedSupplier = rfqDetails?.targeted_suppliers.find(
        (supplier) => supplier.id === question?.company?.id
      );
      const marketplaceSupplier = rfqDetails?.marketplace_suppliers.find(
        (supplier) => supplier.id === question?.company?.id
      );
      if (!targetedSupplier && !marketplaceSupplier) return;
      batch(() => {
        dispatch(
          modalsActions.showModal({
            key: WorkspaceModalTypes.RFQ_COMMUNICATION_DRAWER,
            data: {
              supplier: targetedSupplier || marketplaceSupplier,
              showContactsList: true,
              qaId: initiallyOpenQAId,
            },
          })
        );
        setInitiallyQAOpenedOnce(true);
      });
    }
  }, [
    initiallyOpenQAId,
    rfqQAStats,
    rfqDetails?.targeted_suppliers,
    rfqDetails?.marketplace_suppliers,
  ]);

  const handleClosePOModal = () => {
    dispatch(modalsActions.closeModal(SharedModalTypes.ORDER_PURCHASE_MODAL));
    history.push(frontendUrl.buyerContracts);
    setCongratulationViewDetails({});
  };

  const renderLoading = () => (
    <Grid
      container
      direction="column"
      justifyContent="center"
      alignItems="center"
    >
      <Grid item>
        <Box mt="48px">
          <CircularProgress />
        </Box>
      </Grid>
    </Grid>
  );

  if (!rfqDetails) return renderLoading();

  return (
    <Box
      mt={params?.isProject ? "20px" : undefined}
      width={params?.isProject ? "95%" : undefined}
    >
      <Grid xs={12} container className="c-buyer-rfq-view">
        <Grid container className="c-buyer-rfq-view__container">
          <Grid item xs={12} className="c-buyer-rfq-view__container__tabs">
            <Grid container xs={12} alignItems="center">
              <Grid item xs={12}>
                <TabsView
                  value={selectedTab}
                  tabItems={tabItems}
                  handleChange={tabHandleChange}
                />
              </Grid>
            </Grid>

            <Grid container xs={12}>
              <TabPanel value={selectedTab} index={0}>
                <Grid container spacing={6} style={{ padding: 16 }}>
                  <Grid item xs={7}>
                    <BuyerRfqsInformation />
                  </Grid>
                  <Grid item xs={5}>
                    <SuppliersSection
                      targetedSuppliers={rfqDetails?.targeted_suppliers}
                      marketplaceSuppliers={rfqDetails?.marketplace_suppliers}
                      followupDate={rfqDetails?.followup_date}
                      pk={rfqDetails?.pk}
                      isPublicSwitchEnabled={isPublicSwitchEnabled}
                      onPublicSwitchToggle={onPublicSwitchToggle}
                      requestType={rfqDetails?.request_type}
                    />
                  </Grid>
                </Grid>
              </TabPanel>

              <TabPanel value={selectedTab} index={1}>
                <Box ml="20px">
                  <BuyerRfqsOffers
                    rfqDetails={rfqDetails}
                    token={token}
                    selectWinner={selectWinner}
                    getQuotationThreadDetails={getQuotationThreadDetails}
                    setActiveQuotationThread={setActiveQuotationThread}
                    handleOpenModal={handleOpenModal}
                    handleCloseModal={handleCloseModal}
                    expendedPanel={expendedPanel}
                  />
                </Box>
              </TabPanel>

              <TabPanel value={selectedTab} index={2}>
                <Box ml="20px">
                  <QuoteComparison
                    isAwardedRfq={rfqDetails?.status === "Contract"}
                  />
                </Box>
              </TabPanel>
            </Grid>
          </Grid>
        </Grid>
        <PartDialog
          isOpen={!!isOrderPurchaseModal}
          isPurchaseOrder={!!isOrderPurchaseModal}
          contractPk={congratsViewInfo["contractPk"]}
          isWinnerValidationPage={true}
          handleCloseModal={handleClosePOModal}
          isProject={params.isProject}
        />
        <RfqCommunicationDrawer />
      </Grid>
    </Box>
  );
}
