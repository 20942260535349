import React, { useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { modalsActions } from "@next/redux/modalsSlices";
import { Form, Formik } from "formik";
import { Box, Button, Divider, Typography } from "@mui/material";
import UsersPaginatedAutoComplete, {
  UserListItem,
} from "@next/modules/workspace/components/rfq-creation-form/users-paginated-autocomplete";
import { SharedAddCollaboratorModalProps } from "@next/modals/shared-add-collaborator-modal";
import {
  getUser,
  selectCompanyUsers,
} from "services/profile/profile.selectors";
import { workspaceNextActions } from "@next/modules/workspace/redux";
import { SharedModalTypes } from "@next/modals/types";
import {
  selectUpdateDraftRFQCollaboratorsLoading,
  selectUpdateRFQCollaboratorsLoading,
} from "@next/modules/workspace/redux/selectors";
import { useRFQCollaborators } from "@next/modules/workspace/hooks/useRFQCollaborators";
import CustomButton from "@next/components/custom-button/custom-button";
import AddIcon from "@mui/icons-material/Add";
import CustomListItem from "@next/modules/workspace/components/rfq-creation-form/custom-list-item";
import { GenericUser } from "@next/modules/profile/redux";
import { GenericTextInput } from "components/utils/input/text";
import { AppTheme } from "layout/AppTheme";
import {
  getAddedAndExistingCollaborators,
  getCollaboratorRoleTag,
} from "@next/modules/workspace/helpers/rfq-collaborators.helpers";
import { createStyles, makeStyles } from "@mui/styles";

const useStyles = makeStyles(() =>
  createStyles({
    autoCompletePaper: {
      minWidth: 600,
    },
  })
);

export const AddCollaboratorForm: React.FC<SharedAddCollaboratorModalProps> = ({
  collaborators: _collaborators,
  rfqPk,
  poId,
  createdBy,
  refetchData,
  draftId,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const submitLoading = useSelector(selectUpdateRFQCollaboratorsLoading);
  const updateDraftCollaboratorsLoading = useSelector(
    selectUpdateDraftRFQCollaboratorsLoading
  );
  const companyUsers = useSelector(selectCompanyUsers);
  const { owner, collaborators, lookup } = useRFQCollaborators(
    _collaborators,
    createdBy
  );
  const user = useSelector(getUser);
  const classes = useStyles();

  const handleSubmit = ({
    collaborators,
    note,
  }: {
    collaborators: string[];
    note: string;
  }) => {
    if (draftId) {
      dispatch(
        workspaceNextActions.updateDraftRFQCollaboratorsRequest({
          draftId,
          collaborators: collaborators.map((email) => ({
            email,
            note: lookup.get(email) ? null : note || null,
          })),
          onSuccess: () => {
            dispatch(
              modalsActions.closeModal(SharedModalTypes.ADD_COLLABORATOR_MODAL)
            );
          },
        })
      );
    } else {
      dispatch(
        workspaceNextActions.updateRFQCollaboratorsRequest({
          rfqId: rfqPk as number,
          collaborators: collaborators.map((email) => ({
            email,
            note: lookup.get(email) ? null : note || null,
          })),
          poId,
          refetchData,
          onSuccess: () => {
            dispatch(
              modalsActions.closeModal(SharedModalTypes.ADD_COLLABORATOR_MODAL)
            );
          },
        })
      );
    }
  };

  const onClickNewUser = useCallback(({ values, setFieldValue }) => {
    dispatch(
      modalsActions.showModal({
        key: SharedModalTypes.INVITE_COMPANY_USER_FORM,
        data: {
          onSuccess: (users: GenericUser) => {
            setFieldValue("collaborators", [
              ...values.collaborators,
              ...users.map((user) => user.email),
            ]);
          },
        },
      })
    );
  }, []);

  const options = useMemo(
    () =>
      companyUsers
        .filter((item) => !item.is_staff)
        .map((item) => ({
          ...item,
          tag: getCollaboratorRoleTag(item, owner, t),
          disabled: [user?.email, owner?.email].includes(item.email),
        })),
    [companyUsers, owner?.email, user?.email]
  );

  return (
    <Formik
      initialValues={{
        collaborators: collaborators.map((item) => item.email || ""),
        note: "",
      }}
      onSubmit={handleSubmit}
    >
      {({ values, setFieldValue }) => {
        const { collaborators: formCollaborators } = values;
        const { added, existing } = getAddedAndExistingCollaborators({
          collaboratorsEmails: formCollaborators,
          companyUsers,
          owner,
          lookup,
          t,
        });

        return (
          <Form>
            {existing.length ? (
              <>
                <Box mb={2}>
                  <Typography variant="subtitle2">
                    {t("workspaceNext:collaboratorsModal:currentlySharedWith")}
                  </Typography>
                </Box>

                <Box display={"flex"} flexDirection={"column"} gap={"8px"}>
                  {existing.map((user) => (
                    <CustomListItem
                      key={user.email}
                      onRemove={
                        !!user.disabled || user.email === owner?.email
                          ? undefined
                          : () =>
                              setFieldValue(
                                "collaborators",
                                formCollaborators.filter(
                                  (item) => item !== user.email
                                )
                              )
                      }
                    >
                      <UserListItem option={user} />
                    </CustomListItem>
                  ))}
                </Box>

                <Box my={3} mx={-3}>
                  <Divider />
                </Box>
              </>
            ) : null}

            <Box mb={2}>
              <Typography variant="subtitle2">
                {t("workspaceNext:collaboratorsModal:invite")}
              </Typography>
            </Box>

            <Box display="flex" gap="12px" mb={2}>
              <Box flex={1}>
                <UsersPaginatedAutoComplete
                  fieldName="collaborators"
                  placeholder={t(
                    "workspaceNext:collaboratorsModal:searchPlaceholder"
                  )}
                  valueKey="email"
                  options={options}
                  showSelected={false}
                  paperComponentClassName={classes.autoCompletePaper}
                />
              </Box>

              <Box
                borderRight={`1px solid ${AppTheme.palette.grey[400]}`}
                my="1px"
              />

              <Button
                variant="outlined"
                startIcon={<AddIcon />}
                onClick={() => onClickNewUser({ values, setFieldValue })}
              >
                {t("workspaceNext:collaboratorsModal:newUser")}
              </Button>
            </Box>

            {added.length ? (
              <Box display={"flex"} flexDirection={"column"} gap={"8px"}>
                {added.map((user) => (
                  <CustomListItem
                    key={user.email}
                    onRemove={
                      !!user.disabled
                        ? undefined
                        : () =>
                            setFieldValue(
                              "collaborators",
                              formCollaborators.filter(
                                (item) => item !== user.email
                              )
                            )
                    }
                  >
                    <UserListItem option={user} />
                  </CustomListItem>
                ))}

                <Box mt={1}>
                  <GenericTextInput
                    label={t(
                      "workspaceNext:collaboratorsModal:notePlaceholder"
                    )}
                    multiline
                    rows="4"
                    name="note"
                  />
                </Box>

                <Box mt={1}>
                  <Typography variant="body2">
                    {t("workspaceNext:collaboratorsModal:invitationHelpText", {
                      count: added.length,
                    })}
                  </Typography>
                </Box>
              </Box>
            ) : null}

            <Box position="sticky" bottom={0}>
              <Box
                display="flex"
                flexDirection="column"
                bgcolor="white"
                mx="-1px"
                position="relative"
                bottom="-24px"
                pb={2}
              >
                <Box mb={2} mx={-3}>
                  <Divider />
                </Box>
                <Box alignSelf="flex-end">
                  <CustomButton
                    loading={submitLoading || updateDraftCollaboratorsLoading}
                    type="submit"
                    color="primary"
                    variant="contained"
                  >
                    {t("workspaceNext:collaboratorsModal:submit")}
                  </CustomButton>
                </Box>
              </Box>
            </Box>
          </Form>
        );
      }}
    </Formik>
  );
};
