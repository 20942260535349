import React, { CSSProperties } from "react";

import { Box } from "@mui/material";
import { AppTheme } from "layout/AppTheme";
import { TopicMessageEventType } from "@next/modules/workspace/redux";

import AddIcon from "@mui/icons-material/Add";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import EventAvailableOutlinedIcon from "@mui/icons-material/EventAvailableOutlined";
import ClearIcon from "@mui/icons-material/Clear";
import OpenIcon from "@mui/icons-material/ToggleOn";
import SettingsSuggestRoundedIcon from "@mui/icons-material/SettingsSuggestRounded";
import DrawIcon from "@mui/icons-material/Draw";
import AutorenewIcon from "@mui/icons-material/Autorenew";

export const renderIconByEventType = (eventType: TopicMessageEventType) => {
  const renderIcon = getEventIconByType(eventType);

  return renderIcon ? (
    <Box style={getIconCircleStyle(eventType)}>{renderIcon}</Box>
  ) : null;
};

const getEventIconByType = (eventType: TopicMessageEventType) => {
  const baseStyle = { width: "16px", height: "16px" };

  switch (eventType) {
    case TopicMessageEventType.PO_SENT:
      return <AddIcon style={baseStyle} />;

    case TopicMessageEventType.PO_ACKNOWLEDGED:
      return <ThumbUpIcon style={baseStyle} />;

    case TopicMessageEventType.PO_DELIVERY_DATE_UPDATED:
      return <AutorenewIcon style={baseStyle} />;

    case TopicMessageEventType.PO_SHIPPING_BATCH_CONFIRMED:
      return <LocalShippingIcon style={baseStyle} />;

    case TopicMessageEventType.PO_REVISION:
      return <AutorenewIcon style={baseStyle} />;

    case TopicMessageEventType.PO_MARKED_AS_COMPLETED:
      return <DoneAllIcon style={baseStyle} />;

    case TopicMessageEventType.PO_MARKED_AS_COMPLETED_IN_BULK:
      return <SettingsSuggestRoundedIcon style={baseStyle} />;

    case TopicMessageEventType.PO_ON_TRACK:
      return <EventAvailableOutlinedIcon style={baseStyle} />;

    case TopicMessageEventType.PO_CANCELLED:
      return <ClearIcon style={baseStyle} />;

    case TopicMessageEventType.PO_REOPEN:
      return <OpenIcon style={baseStyle} />;

    case TopicMessageEventType.PO_PROPOSED_CHANGES:
      return <DrawIcon style={baseStyle} />;

    case TopicMessageEventType.PO_REVIEWED_CHANGES:
      return <AutorenewIcon style={baseStyle} />;
    default:
      return null;
  }
};

const getIconCircleStyle = (eventType: TopicMessageEventType) => {
  const baseStyle: CSSProperties = {
    position: "absolute",
    left: "80%",
    top: "10%",
    height: "26px",
    width: "26px",
    backgroundColor: AppTheme.palette.primary.main,
    borderRadius: "50%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: "white",
    border: "1px solid white",
  };

  switch (eventType) {
    case TopicMessageEventType.PO_SENT:
      return baseStyle;

    case TopicMessageEventType.PO_ACKNOWLEDGED:
      return {
        ...baseStyle,
        backgroundColor: AppTheme.palette.success.main,
      };

    case TopicMessageEventType.PO_DELIVERY_DATE_UPDATED:
      return baseStyle;

    case TopicMessageEventType.PO_SHIPPING_BATCH_CONFIRMED:
      return {
        ...baseStyle,
        backgroundColor: AppTheme.palette.success.main,
      };

    case TopicMessageEventType.PO_REVISION:
      return baseStyle;

    case TopicMessageEventType.PO_MARKED_AS_COMPLETED:
      return {
        ...baseStyle,
        backgroundColor: AppTheme.palette.success.main,
      };

    case TopicMessageEventType.PO_ON_TRACK:
      return {
        ...baseStyle,
        backgroundColor: AppTheme.palette.success.main,
      };

    case TopicMessageEventType.PO_CANCELLED:
      return {
        ...baseStyle,
        backgroundColor: AppTheme.palette.error.main,
      };

    case TopicMessageEventType.PO_REOPEN:
      return {
        ...baseStyle,
        backgroundColor: AppTheme.palette.primary.main,
      };
    case TopicMessageEventType.PO_MARKED_AS_COMPLETED_IN_BULK:
      return {
        ...baseStyle,
        color: AppTheme.palette.text.primary,
        backgroundColor: "#E0E0E0",
      };

    case TopicMessageEventType.PO_PROPOSED_CHANGES:
      return {
        ...baseStyle,
        backgroundColor: AppTheme.palette.primary.main,
      };

    case TopicMessageEventType.PO_REVIEWED_CHANGES:
      return {
        ...baseStyle,
        backgroundColor: AppTheme.palette.primary.main,
      };
    default:
      return {};
  }
};
