import React, { PropsWithChildren, useEffect, useState } from "react";
import {
  Accordion,
  AccordionClassKey,
  AccordionDetails,
  AccordionSummary,
  AccordionSummaryClassKey,
  Chip,
  ChipProps,
  Typography,
  TypographyProps,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

export interface AccordionWrapperProps {
  expandedKey: string;
  title: string | React.ReactNode;
  initiallyOpen?: boolean;
  accordionClasses?: Partial<Record<AccordionClassKey, string>> | undefined;
  accordionSummaryClasses?:
    | Partial<Record<AccordionSummaryClassKey, string>>
    | undefined;
  accordionDetailsClasses?: Partial<Record<"root", string>> | undefined;
  titleVariant?: TypographyProps["variant"];
  onChangeExpanded?: (expanded: boolean) => void;
  showExpandIcon?: boolean;
  titleTagProps?: ChipProps;
  disableAccordion?: boolean;
  disableGutters?: boolean;
}

const AccordionWrapper: React.FC<
  PropsWithChildren<AccordionWrapperProps & any>
> = ({
  expandedKey,
  children,
  title,
  initiallyOpen,
  accordionClasses,
  accordionSummaryClasses,
  accordionDetailsClasses,
  titleVariant = "h5",
  onChangeExpanded,
  showExpandIcon = true,
  titleTagProps,
  disableAccordion,
  disableGutters,
  ...props
}) => {
  const [expanded, setExpanded] = useState<string | false>(
    initiallyOpen ? expandedKey : false
  );

  // Function to expand accordion
  const handleChangeExpanded =
    (panel: string) =>
    (_event: React.ChangeEvent<{}>, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : false);
    };

  useEffect(() => {
    if (typeof onChangeExpanded === "function")
      onChangeExpanded(expanded === expandedKey);
  }, [expanded]);

  if (disableAccordion) return children;

  return (
    <Accordion
      expanded={expanded === expandedKey}
      elevation={0}
      onChange={handleChangeExpanded(expandedKey)}
      classes={accordionClasses}
      disableGutters={disableGutters}
      {...props}
    >
      <AccordionSummary
        expandIcon={showExpandIcon ? <ExpandMoreIcon /> : undefined}
        aria-controls={`${expandedKey}-content`}
        id={`${expandedKey}-header`}
        classes={accordionSummaryClasses}
      >
        {typeof title === "string" ? (
          <Typography variant={titleVariant}>{title}</Typography>
        ) : (
          title
        )}

        {titleTagProps ? <Chip variant="outlined" {...titleTagProps} /> : null}
      </AccordionSummary>
      <AccordionDetails classes={accordionDetailsClasses}>
        {children}
      </AccordionDetails>
    </Accordion>
  );
};

export default AccordionWrapper;
