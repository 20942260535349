import { workspaceNextActions } from "@next/modules/workspace/redux";
import {
  selectFavoriteSupplierGroups,
  selectFavoriteSupplierGroupsLoading,
  selectRfqFormFavoriteSuppliersList,
  selectRfqFormFavoriteSuppliersListLoading,
  selectRfqFormFavoriteSuppliersListNext,
} from "@next/modules/workspace/redux/selectors";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PaginatedAutoComplete from "@next/components/paginated-autocomplete";
import { RFQSupplierType } from "./rfq-creation.form.types";
import { Box, Button, Skeleton, Theme, Typography } from "@mui/material";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import { useTranslation } from "react-i18next";
import SupplierListItem, { GroupChip } from "./supplier-list-item";
import { FormikContextType, useField } from "formik";
import { createStyles, makeStyles } from "@mui/styles";
import { uniqBy } from "lodash";
import RFQPublicSwitch from "./rfq-public-switch";
import { RequestType } from "./rfq-creation.form.definitions";
import CustomButton from "@next/components/custom-button";

type StyleProps = {
  paperWidth?: number | string;
};

const useStyles = makeStyles<Theme, StyleProps>((theme) =>
  createStyles({
    button: {
      height: 40,
      marginLeft: theme.spacing(2),
    },
    paperComponent: {
      width: ({ paperWidth }) => paperWidth,
      marginLeft: "auto",
    },
    componentContainer: {
      padding: theme.spacing(2),
    },
    infoText: {
      color: theme.palette.action.active,
    },
  })
);

interface SuppliersAutoCompleteDropdownProps {
  onInvite?: () => void;
  pageSize?: number;
  showGroupsAndMarketplace?: boolean;
  getOptionDisabled?: Function;
  paperWidth?: number | string;
  multiple?: boolean;
  fieldName?: string;
  formik?: FormikContextType<any>;
  endAdornment?: React.ReactNode;
}

const SuppliersAutoCompleteDropdown: React.FC<
  SuppliersAutoCompleteDropdownProps
> = ({
  pageSize = 12,
  onInvite,
  showGroupsAndMarketplace = true,
  getOptionDisabled,
  paperWidth = 680,
  fieldName = "targeted_suppliers",
  multiple = true,
  formik,
  endAdornment,
}) => {
  const { t } = useTranslation();
  const favoriteSuppliersList = useSelector(selectRfqFormFavoriteSuppliersList);
  const nextPage = useSelector(selectRfqFormFavoriteSuppliersListNext);
  const loading = useSelector(selectRfqFormFavoriteSuppliersListLoading);
  const groups = useSelector(selectFavoriteSupplierGroups);
  const groupsLoading = useSelector(selectFavoriteSupplierGroupsLoading);
  const dispatch = useDispatch();
  const classes = useStyles({ paperWidth });

  const [field, _, fieldHelpers] = useField(fieldName);

  const [showAllGroups, setShowAllGroups] = useState(false);

  const value = useMemo(
    () =>
      field.value
        ? Array.isArray(field.value)
          ? field.value
          : favoriteSuppliersList.find(
              (supplier) => supplier["pk"] === field.value?.pk
            )
        : undefined,
    [favoriteSuppliersList, field.value]
  );

  const onEndReached = useCallback(() => {
    if (nextPage) {
      dispatch(
        workspaceNextActions.fetchRFQFavoriteSuppliersRequest({
          url: nextPage,
        })
      );
    }
  }, [nextPage]);

  const onSearch = useCallback(
    (search) => {
      dispatch(workspaceNextActions.fetchRFQFavoriteSuppliersReset());
      dispatch(
        workspaceNextActions.fetchRFQFavoriteSuppliersRequest({
          query: {
            page: 1,
            pageSize,
            name: search,
          },
        })
      );
    },
    [pageSize]
  );

  const onInitialFetch = useCallback(() => {
    dispatch(workspaceNextActions.fetchRFQFavoriteSuppliersReset());
    dispatch(
      workspaceNextActions.fetchRFQFavoriteSuppliersRequest({
        query: { page: 1, pageSize },
      })
    );
  }, [pageSize]);

  const renderOption = useCallback(
    (option, { selected }) => (
      <SupplierListItem
        name={option.name}
        picture={option.picture}
        groups={option.groups}
        selected={selected}
        enableCheckbox
        avatarStyle={{ borderRadius: "4px" }}
      />
    ),
    []
  );

  const onSuppliersFetched = useCallback(
    (groupSuppliers) => {
      fieldHelpers.setValue(uniqBy(field.value.concat(groupSuppliers), "pk"));
    },
    [field.value]
  );

  useEffect(() => {
    dispatch(workspaceNextActions.fetchRFQFavoriteSuppliersReset());
    return () => {
      dispatch(workspaceNextActions.fetchRFQFavoriteSuppliersReset());
    };
  }, []);

  const maxDisplayedGroups = 20;

  const displayedGroups = useMemo(() => {
    return showAllGroups ? groups : groups.slice(0, maxDisplayedGroups);
  }, [groups, showAllGroups]);
  const GroupComponent = useMemo(() => {
    return (
      <>
        {!groupsLoading && groups.length === 0 ? null : (
          <Box className={classes.componentContainer}>
            {groupsLoading ? (
              <Skeleton width="100%" height={32} variant="rectangular" />
            ) : (
              <>
                <Box mb={1} mr={1}>
                  <Typography className={classes.infoText} variant="caption">
                    {t("workspaceNext:rfqDrawer:groups", {
                      count: groups.length,
                    })}
                  </Typography>
                </Box>
                {displayedGroups.map((group) => (
                  <GroupChip
                    key={group.pk}
                    group={group}
                    onSuppliersFetched={onSuppliersFetched}
                  />
                ))}
                {groups.length > maxDisplayedGroups && (
                  <CustomButton
                    sx={{
                      borderRadius: "16px",
                    }}
                    variant="outlined"
                    onMouseDown={() => setShowAllGroups(!showAllGroups)}
                  >
                    <Typography variant="caption">
                      {showAllGroups
                        ? t("workspaceNext:rfqDrawer:viewLess")
                        : t("workspaceNext:rfqDrawer:viewMore", {
                            count: groups.length - maxDisplayedGroups,
                          })}
                    </Typography>
                  </CustomButton>
                )}
              </>
            )}
          </Box>
        )}
      </>
    );
  }, [
    groups,
    groupsLoading,
    showAllGroups,
    displayedGroups,
    onSuppliersFetched,
  ]);

  const SwitchComponent = useMemo(() => {
    if (!formik || formik.values?.request_type !== RequestType.RFQ) return null;
    return (
      <RFQPublicSwitch
        isEnabled={formik?.values?.is_public}
        onToggle={(enabled) => {
          formik.setFieldValue("is_public", enabled);
        }}
      />
    );
  }, [formik?.values?.is_public]);

  const SuppliersListTitle = useMemo(() => {
    return (
      <Box ml={2} mt={2}>
        <Typography className={classes.infoText} variant="caption">
          {t("workspaceNext:rfqDrawer:suppliersInList", {
            count: favoriteSuppliersList.length,
          })}
        </Typography>
      </Box>
    );
  }, [favoriteSuppliersList]);

  const CustomComponent = useMemo(() => {
    return (
      <>
        {GroupComponent || null}
        {SwitchComponent || null}
        {SuppliersListTitle}
      </>
    );
  }, [GroupComponent, SwitchComponent, SuppliersListTitle]);

  const getInputFieldValue = useCallback((value: any) => {
    return `${value.name}`;
  }, []);

  return (
    <Box display="flex">
      <Box flex={1}>
        <PaginatedAutoComplete
          name={field.name}
          value={value}
          onSelectionChange={fieldHelpers.setValue}
          loading={loading}
          options={favoriteSuppliersList}
          onEndReached={onEndReached}
          onSearch={onSearch}
          onInitialFetch={onInitialFetch}
          renderOption={renderOption}
          getOptionSelected={(
            option: RFQSupplierType,
            value: RFQSupplierType
          ) => option.pk === value.pk}
          CustomComponent={
            showGroupsAndMarketplace ? CustomComponent : SuppliersListTitle
          }
          paperComponentClassName={classes.paperComponent}
          placeholder={t("workspaceNext:rfqDrawer:searchInList")}
          getOptionDisabled={getOptionDisabled}
          multiple={multiple}
          disableClearable={multiple}
          disableCloseOnSelect={multiple}
          getInputFieldValue={multiple ? undefined : getInputFieldValue}
          endAdornment={endAdornment}
          getOptionLabel={(option) => option.name || ""}
        />
      </Box>
      {typeof onInvite === "function" ? (
        <Button
          className={classes.button}
          variant="contained"
          startIcon={<PersonAddIcon />}
          onClick={onInvite}
        >
          {t("workspaceNext:rfqDrawer:inviteSupplier")}
        </Button>
      ) : null}
    </Box>
  );
};

export default SuppliersAutoCompleteDropdown;
