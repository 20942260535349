import React, { useMemo } from "react";
import { Button, Theme, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { TabsView } from "@next/components/tabs-view";
import {
  ORDERS_TABLE_NAVIGATION_KEY,
  OrdersTable,
} from "../components/orders-table/orders-table";
import { selectOrdersCounts } from "../redux/selectors";
import { useDispatch, useSelector } from "react-redux";
import { checkCountAndReturn } from "@next/modules/workspace/pages/buyer-rfqs/buyer-rfqs.utils";
import AddIcon from "@mui/icons-material/Add";
import { modalsActions } from "@next/redux/modalsSlices";
import { OrdersModalTypes } from "../modals/types";
import { useTableSavedQueryPagination } from "@next/hooks/useTableSavedQueryPagination";
import { CreateOrderModal } from "../modals/create-order-modal";
import { createStyles, makeStyles } from "@mui/styles";
import { ErpConnectionStatus } from "@next/components/erp-connection-status";
import { ErpConfigurationFor } from "@next/components/erp-connection-status/erp-connection-status";
import { useHasCompanyRole } from "@next/hooks/useHasCompanyRole";

export enum OrdersTabType {
  ACTIVE = 0,
  ARCHIVED = 1,
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      marginRight: -theme.spacing(4),
      marginTop: -theme.spacing(1),
    },
    header: {
      display: "flex",
      flexDirection: "column",
    },

    titleContainer: {
      display: "flex",
      alignItems: "center",
      marginLeft: "12px",
      gap: theme.spacing(2),
    },
    content: {},
    tabPanels: {
      marginTop: theme.spacing(2.5),
    },
    button: {
      marginLeft: theme.spacing(1),
    },
  })
);

export const OrdersPage = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const hasViewerRole = useHasCompanyRole(["viewer"]);

  const { savedTableQueryPagination, setSavedTableQueryPagination } =
    useTableSavedQueryPagination(ORDERS_TABLE_NAVIGATION_KEY);

  const selectedTab = savedTableQueryPagination?.tab || 0;

  const setSelectedTab = (tab: number) => {
    setSavedTableQueryPagination({ ...savedTableQueryPagination, tab });
  };

  const orderCounts = useSelector(selectOrdersCounts);

  const tabItems = useMemo(
    () => [
      {
        tabName: t("orders:tabs:active", {
          count: checkCountAndReturn(orderCounts?.active_count),
        }),
      },
      {
        tabName: t("orders:tabs:archived", {
          count: checkCountAndReturn(orderCounts?.completed_count),
        }),
      },
    ],
    [orderCounts]
  );

  const tabHandleChange = (_: React.ChangeEvent<{}>, value: any) =>
    setSelectedTab(value);

  const onClickCreateManualOrder = () => {
    dispatch(
      modalsActions.showModal({
        key: OrdersModalTypes.CREATE_ORDER_MODAL,
      })
    );
  };

  return (
    <div className={classes.root}>
      <div className={classes.header}>
        <div className={classes.titleContainer}>
          <Typography variant="body1">{t("orders:title")}</Typography>

          <Button
            startIcon={<AddIcon />}
            onClick={onClickCreateManualOrder}
            className={classes.button}
            variant="contained"
            color="primary"
            disabled={hasViewerRole}
          >
            {t("common:create")}
          </Button>

          <ErpConnectionStatus
            erpConfigurationFor={ErpConfigurationFor.PO_SYNCHRONIZATION}
          />
        </div>
      </div>

      <div className={classes.content}>
        <TabsView
          value={selectedTab}
          tabItems={tabItems}
          handleChange={tabHandleChange}
        />

        <div className={classes.tabPanels}>
          <OrdersTable
            isCompletedOrders={selectedTab !== OrdersTabType.ACTIVE}
            disableFiltering={selectedTab !== OrdersTabType.ACTIVE}
          />
        </div>
      </div>

      <CreateOrderModal />
    </div>
  );
};
