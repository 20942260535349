import React, { useEffect } from "react";
import { Box, Theme, Typography } from "@mui/material";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import LocalShippingOutlinedIcon from "@mui/icons-material/LocalShippingOutlined";
import CalendarTodayOutlinedIcon from "@mui/icons-material/CalendarTodayOutlined";
import { createStyles, makeStyles } from "@mui/styles";
import { useTranslation } from "react-i18next";
import CustomButton from "@next/components/custom-button";
import { useDispatch, useSelector } from "react-redux";
import { modalsActions } from "@next/redux/modalsSlices";
import { OrdersModalTypes } from "@next/modules/orders/modals/types";
import {
  selectLineItemsTableSelectionModel,
  selectOrderByIsPortal,
} from "@next/modules/orders/redux/selectors";
import { useParams } from "react-router-dom";
import CheckIcon from "@mui/icons-material/Check";
import { AcknowledgeOrderModal } from "@next/modules/orders/modals/acknowledge-order-modal";
import { ActionPanelBox } from "./order-detail-actions-panel.styled";
import { ActionPanelType, ordersActions } from "@next/modules/orders/redux";

const useStyles = makeStyles<Theme>((theme: Theme) =>
  createStyles({
    iconContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      padding: "10px",
      borderRadius: "50%",
      border: `1px solid ${theme.palette.divider}`,
    },
    actionContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      padding: theme.spacing(2),
      width: "100%",
      borderRadius: "16px",
      border: `1px solid ${theme.palette.divider}`,
      backgroundColor: theme.palette.background.paper,
      cursor: "pointer",
    },
  })
);

type Props = {
  isPortal?: boolean;
};

export const ActionsPanelDefaultView: React.FC<Props> = ({ isPortal }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const params = useParams<{ orderToken?: string; qaToken?: string }>();

  const orderDetail = useSelector(selectOrderByIsPortal(isPortal));
  const lineItemsTableSelectionModel = useSelector(
    selectLineItemsTableSelectionModel
  );

  const [onAcknowledgeStep, setOnAcknowledgeStep] = React.useState(false);
  const orderToken = params?.orderToken;

  useEffect(() => {
    if (isPortal) {
      if (orderDetail?.step === "sent" && !orderDetail?.is_cancelled) {
        setOnAcknowledgeStep(true);
      } else {
        setOnAcknowledgeStep(false);
      }
    }
  }, [orderDetail?.step, orderDetail?.is_cancelled]);

  const handleAcknowledgeOrder = () => {
    dispatch(
      modalsActions.showModal({
        key: OrdersModalTypes.ACKNOWLEDGE_ORDER_MODAL,
        data: {
          orderToken,
          updateNote: "",
          buyerName: orderDetail?.buyer.name,
        },
      })
    );
  };

  const handleMarkAsShipped = () => {
    dispatch(
      modalsActions.showModal({
        key: OrdersModalTypes.ORDER_MARK_AS_SHIPPED_MODAL,
        data: {
          partsSelectionModel: lineItemsTableSelectionModel,
          isPortal,
        },
      })
    );
  };

  return (
    <ActionPanelBox centerItems>
      {onAcknowledgeStep && (
        <>
          <Typography>
            {t("orders:orderDetail:actionPanel:acknowledgeText")}
          </Typography>
          <CustomButton
            variant="contained"
            color="primary"
            size="large"
            className={classes.button}
            fullWidth
            onClick={handleAcknowledgeOrder}
            startIcon={<CheckIcon />}
          >
            {t("orders:orderDetail:actionPanel:acknowledgeOrder")}
          </CustomButton>
          <Typography>{t("orders:orderDetail:actionPanel:or")}</Typography>
        </>
      )}
      <CustomButton
        className={classes.actionContainer}
        color="secondary"
        onClick={() =>
          dispatch(
            ordersActions.setOrderActionPanelType(
              ActionPanelType.ProposeQtyAndPriceChange
            )
          )
        }
      >
        <Box display={"flex"} alignItems={"center"}>
          <Box className={classes.iconContainer}>
            <EditOutlinedIcon />
          </Box>
          <Typography variant="body1" ml={"12px"}>
            {t("orders:orderDetail:actionPanel:default:proposeRevision")}
          </Typography>
        </Box>
      </CustomButton>
      <CustomButton
        color="secondary"
        className={classes.actionContainer}
        onClick={() =>
          dispatch(
            ordersActions.setOrderActionPanelType(
              ActionPanelType.ModifyDeliveryDate
            )
          )
        }
      >
        <Box display={"flex"} alignItems={"center"}>
          <Box className={classes.iconContainer}>
            <CalendarTodayOutlinedIcon />
          </Box>
          <Typography variant="body1" ml={"12px"}>
            {t("orders:orderDetail:actionPanel:default:modifyDeliveryDate")}
          </Typography>
        </Box>
      </CustomButton>
      <CustomButton
        className={classes.actionContainer}
        color="secondary"
        onClick={handleMarkAsShipped}
        disabled={lineItemsTableSelectionModel?.length < 1}
      >
        <Box display={"flex"} alignItems={"center"}>
          <Box className={classes.iconContainer}>
            <LocalShippingOutlinedIcon />
          </Box>
          <Typography variant="body1" ml={"12px"}>
            {t("orders:orderDetail:actionPanel:default:confirmShipment")}
          </Typography>
        </Box>
      </CustomButton>
      <AcknowledgeOrderModal />
    </ActionPanelBox>
  );
};
