import React from "react";
import { IconButton, SvgIconProps } from "@mui/material";
import MoreHorizOutlinedIcon from "@mui/icons-material/MoreHorizOutlined";
import { GridRenderCellParams } from "@mui/x-data-grid-pro";

type Props = {
  // Default icon is MoreHorizOutlinedIcon
  Icon?: React.ComponentType<SvgIconProps>;
  disableHoverEffect?: boolean;
  disableMenu?: boolean;
} & GridRenderCellParams;

const IconButtonCell: React.FC<Props> = ({
  Icon = MoreHorizOutlinedIcon,
  disableHoverEffect,
  disableMenu,
}) => (
  <IconButton
    style={disableHoverEffect ? { backgroundColor: "transparent" } : undefined}
    size="large"
    disabled={disableMenu}
  >
    <Icon style={disableMenu ? { color: "grey" } : { color: "black" }} />
  </IconButton>
);

export default IconButtonCell;
