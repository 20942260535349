import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

import {
  Avatar,
  Divider,
  ListItem,
  ListItemAvatar,
  ListItemText,
  MenuItem,
  Theme,
  Typography,
} from "@mui/material";
import PowerSettingsNewIcon from "@mui/icons-material/PowerSettingsNew";
import WbSunnyIcon from "@mui/icons-material/WbSunny";

import { history } from "helpers/history";
import { frontendUrl } from "urls";
import { modalsActions } from "services/modals/modals.actions";
import { getIsNewUser, getUser } from "services/profile/profile.selectors";
import { CompanyTypeNext } from "@next/redux/types";
import { useAxyaTracking } from "@next/hooks/useAxyaTracking";

import { UserProfileSettingsModal } from "@next/modules/profile/modals/user-profile-settings-modal";
import { modalsActions as modalsActionsNext } from "@next/redux/modalsSlices";
import { ProfileModalTypes } from "@next/modules/profile/modals/types";
import SettingsIcon from "@mui/icons-material/Settings";
import { UserProfileTwoFactorManageModal } from "@next/modules/profile/modals/user-profile-two-factor-manage-modal";
import { modalsActions as nextModalActions } from "@next/redux/modalsSlices";
import { AnnouncementModalTypes } from "@next/modules/announcement/modals/types";
import { createStyles, makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: { width: "344px" },
    menuItem: {
      height: "50px",
    },
    menuItemText: { marginLeft: theme.spacing(1) },
  })
);

export const UserProfileMenu = () => {
  const classes = useStyles();
  const { showModal } = modalsActions;
  const dispatch = useDispatch();
  const location = useLocation();
  const { t } = useTranslation();
  const axyaTrack = useAxyaTracking();
  const user = useSelector(getUser);
  const isNewUser = useSelector(getIsNewUser);

  useEffect(() => {
    //check if announcement model is visited and hide the workspace tour
    const isModalOpen = localStorage.getItem("isAxyaAnnouncementVisited");
    if (
      isNewUser &&
      user &&
      user?.type === CompanyTypeNext.DO &&
      location &&
      location.pathname.includes("/my-parts") &&
      !isModalOpen
    ) {
      dispatch(showModal(true, "isAppTourOpen"));
    }
  }, [isNewUser]);

  const onClickSettings = () => {
    dispatch(
      modalsActionsNext.showModal({
        key: ProfileModalTypes.USER_PROFILE_SETTINGS_MODAL,
      })
    );
  };

  const onClickDiscoverThePlatform = () => {
    dispatch(showModal(true, "isAppTourOpen"));
    // tracking open app tour click
    axyaTrack({
      scout_category: "tour_guide",
      scout_feature_name: "open_app_tour",
    });
  };

  const onClickWhatsNew = () => {
    dispatch(
      nextModalActions.showModal({
        key: AnnouncementModalTypes.WHATS_NEW_MODAL,
        data: {
          userOpen: true,
        },
      })
    );
  };

  const onClickLogout = () => history.push(frontendUrl.logout);

  return (
    <div className={classes.root}>
      <ListItem>
        <ListItemAvatar>
          <Avatar
            alt="Photo de profile"
            src={user?.picture}
            variant="circular"
          />
        </ListItemAvatar>

        <ListItemText
          primary={
            <Typography>{`${user?.first_name} ${user?.last_name}`}</Typography>
          }
          secondary={<Typography>{user?.email || user?.username}</Typography>}
        />
      </ListItem>

      <Divider />

      <MenuItem className={classes.menuItem} onClick={onClickSettings}>
        <SettingsIcon />
        <Typography className={classes.menuItemText}>
          {t("profile:menu:profileSettings")}
        </Typography>
      </MenuItem>

      <Divider />

      <MenuItem className={classes.menuItem} onClick={onClickWhatsNew}>
        <WbSunnyIcon />
        <Typography className={classes.menuItemText}>
          {t("common:whatsNew")}
        </Typography>
      </MenuItem>
      <Divider />
      <MenuItem className={classes.menuItem} onClick={onClickLogout}>
        <PowerSettingsNewIcon />
        <Typography className={classes.menuItemText}>
          {t("SIDE_BAR:logout")}
        </Typography>
      </MenuItem>

      <UserProfileSettingsModal />
      <UserProfileTwoFactorManageModal />
    </div>
  );
};
