import {
  GridColTypeDef,
  getGridNumericOperators,
  getGridStringOperators,
} from "@mui/x-data-grid-pro-v5";

export const getDefaultStringFilterOperators = () => {
  return getGridStringOperators().filter(
    // only allow contains and equals operators
    (operator) => operator.value === "contains" || operator.value === "equals"
  );
};

export const getDefaultNumberFilterOperatorsV2 = () => {
  return getGridNumericOperators().filter(
    (operator) =>
      operator.value !== "isEmpty" && operator.value !== "isNotEmpty"
  );
};

export const CustomStringColumnType: GridColTypeDef = {
  type: "string",
  filterOperators: getDefaultStringFilterOperators(),
};

export const CustomNumberColumnType: GridColTypeDef = {
  type: "number",
  filterOperators: getDefaultNumberFilterOperatorsV2(),
};
