import React, { useCallback, useState } from "react";

import {
  GridColDef,
  GridColumns,
  GridRenderEditCellParams,
} from "@mui/x-data-grid-pro";

import { t } from "assets/configi18n/i18n";
import { NDATableField } from "./nda-table.types";
import { formatDate } from "helpers/formatDate";
import { Button, Checkbox } from "@mui/material";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { store } from "store";
import { workspaceNextActions } from "../../redux";
import { CustomTypography } from "@next/components/custom-typography";
import { openProfileInNewTab } from "@next/utils/browserUtils";
import { createStyles, makeStyles } from "@mui/styles";

const defaultColumnConfig: Partial<GridColDef> = {
  disableColumnMenu: true,
  sortable: false,
  hideSortIcons: true,
};

const CompanyNameCell: React.FC<GridRenderEditCellParams> = ({
  value,
  row,
}) => {
  const useStyles = makeStyles(() =>
    createStyles({
      root: {
        cursor: "pointer",
        "&:hover": {
          textDecoration: "underline",
        },
      },
    })
  );
  const classes = useStyles();
  const onClickName = useCallback(() => {
    openProfileInNewTab(value as string, row?.company_uuid as string);
  }, [value, row?.company_uuid]);
  return (
    <CustomTypography
      onClick={row?.company_uuid ? onClickName : undefined}
      className={classes.root}
      variant="body2"
      color="textPrimary"
    >
      {value}
    </CustomTypography>
  );
};

const SignedNDACell: React.FC<GridRenderEditCellParams> = ({ value }) => {
  const downloadFile = () => {
    window.open(value as string, "_blank", "noreferrer");
  };

  return (
    <Button
      variant="contained"
      color="secondary"
      startIcon={<OpenInNewIcon />}
      onClick={downloadFile}
    >
      {t("common:open")}
    </Button>
  );
};

const ManageAccessCell: React.FC<GridRenderEditCellParams> = ({
  value,
  row,
}) => {
  const [checked, setChecked] = useState<boolean>(value as boolean);

  const onChange = (
    _event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => {
    store.dispatch(workspaceNextActions.updateNDARequest({ pk: row?.pk }));
    setChecked(checked);
  };

  return <Checkbox checked={checked} onChange={onChange} />;
};

export const getNDATableColumns = () => {
  const _columns: GridColumns = [
    {
      ...defaultColumnConfig,
      field: NDATableField.COMPANY_NAME,
      headerName: t("workspaceNext:ndaTable:header:companyName"),
      flex: 1,
      renderCell: CompanyNameCell,
    },
    {
      ...defaultColumnConfig,
      field: NDATableField.SIGNED_ON,
      headerName: t("workspaceNext:ndaTable:header:signedOn"),
      valueFormatter: (params) => `${formatDate(params.value)}`,
      flex: 1,
    },
    {
      ...defaultColumnConfig,
      field: NDATableField.SIGNED_NDA,
      headerName: t("workspaceNext:ndaTable:header:signedNDA"),
      flex: 1,
      renderCell: SignedNDACell,
      align: "center",
      headerAlign: "center",
    },
    {
      ...defaultColumnConfig,
      field: NDATableField.MANAGE_ACCESS,
      headerName: t("workspaceNext:ndaTable:header:hasAccess"),
      flex: 1,
      align: "center",
      headerAlign: "center",
      renderCell: ManageAccessCell,
    },
  ];

  return _columns;
};
