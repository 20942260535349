import React from "react";
import { Button } from "@mui/material";
import { GridRenderCellParams } from "@mui/x-data-grid-pro";
import { Rating, RatingProps } from "@mui/material";
import { t } from "assets/configi18n/i18n";
import { CustomTypography } from "../custom-typography";
import { useDispatch } from "react-redux";
import { modalsActions } from "@next/redux/modalsSlices";
import { SharedModalTypes } from "@next/modals/types";

const RatingCell: React.FC<
  GridRenderCellParams & { size?: RatingProps["size"] }
> = ({ size, ...params }) => {
  const dispatch = useDispatch();

  const onClick = (e: React.SyntheticEvent) => {
    e.preventDefault();
    dispatch(
      modalsActions.showModal({
        key: SharedModalTypes.USER_RATING_MODAL,
        data: params.row.rating_token,
      })
    );
  };

  if (params.row.rating_score) {
    return (
      <Rating
        readOnly={true}
        size={size}
        value={params.row.rating_score?.overall_score}
      />
    );
  } else {
    return (
      <CustomTypography
        variant="caption"
        tooltipTitle={t("project:table:row:addRating")}
      >
        <Button
          size="small"
          variant="outlined"
          onClick={onClick}
          disabled={
            !params.row.rating_token ||
            !params.row.po_file ||
            !params.row?.winner
          }
        >
          {t("project:table:row:addRating")}
        </Button>
      </CustomTypography>
    );
  }
};

export default RatingCell;
