import React, { useCallback, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

// Material ui imports
import { Box, Button, Grid, Menu, MenuItem, Tooltip } from "@mui/material";
import noOffersImg from "assets/img/SendToQuotation.svg";
import { OffersView } from "components/workspace/buyer-rfqs-view/offers-view/offers-view.component";
import { InformationPage } from "components/utils/information-page/information-page.component";
import useStateCallback from "@next/hooks/useStateCallback";
import { useReactToPrint } from "react-to-print";
import { QuotationPDFViewer } from "@next/components/quotation-pdf-viewer";
import GavelOutlinedIcon from "@mui/icons-material/GavelOutlined";
import { useDispatch, useSelector } from "react-redux";
import { modalsActions } from "@next/redux/modalsSlices";
import { ProjectModalTypes } from "@next/modules/project/modals/types";
import { ProjectCreateMultiContractModal } from "@next/modules/project/modals/project-create-multi-contract-modal";
import { useHistory } from "react-router-dom";
import { backendUrl, frontendUrl } from "urls";
import CallMadeIcon from "@mui/icons-material/CallMade";
import { fileDownloadFromUrl } from "@next/utils/downloadUtils";
import { getCompany } from "services/profile/profile.selectors";
import { RequestType } from "@next/modules/workspace/components/rfq-creation-form/rfq-creation.form.definitions";
import { useHasCompanyRole } from "@next/hooks/useHasCompanyRole";
import { getReportsButtonAvailable } from "services/part-configuration/part-configuration.selectors";
import { workspaceNextActions } from "@next/modules/workspace/redux";
import { DownloadQuotationsAsZip } from "components/utils/download-all-quotation-as-zip/download-all-quotation-as-zip.component";
import { createStyles, makeStyles, withStyles } from "@mui/styles";

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      marginTop: "1.5rem",
    },
  })
);

const HtmlTooltip = withStyles(() => ({
  tooltip: {
    maxWidth: 300,
    fontSize: 12,
    marginTop: 5,
    textAlign: "center",
  },
}))(Tooltip);

export const BuyerRfqsOffers = ({
  rfqDetails,
  token,
  selectWinner,
  getQuotationThreadDetails,
  setActiveQuotationThread,
  modalState,
  handleCloseModal,
  handleOpenModal,
  expendedPanel,
}) => {
  const { t } = useTranslation();
  const history = useHistory();
  const hasViewerRole = useHasCompanyRole(["viewer", "restricted_viewer"]);

  const showSupplierSelectionButton =
    !hasViewerRole &&
    rfqDetails?.quotations?.length > 0 &&
    rfqDetails?.status === "Active";

  const [showPDF, setShowPDF] = useStateCallback(false);
  const [anchorEl, setAnchorEl] = useState(null | HTMLElement);
  const company = useSelector(getCompany);
  const reportsButtonAvailable = useSelector(getReportsButtonAvailable);

  const pdfComponentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => pdfComponentRef.current,
    onAfterPrint: () => setShowPDF(false),
    documentTitle: rfqDetails.rfq_display_name,
  });
  const classes = useStyles();
  const dispatch = useDispatch();

  const openExportMennu = useCallback((e) => {
    setAnchorEl(e.currentTarget);
  }, []);

  const closeExportMenu = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const handleMenuItemClick = useCallback((cb) => {
    closeExportMenu();
    if (typeof cb === "function") cb();
  }, []);

  const downloadFile = useCallback((file) => {
    if (file?.file_url) {
      fileDownloadFromUrl(file.file_url, file?.file_name, file?.file_extension);
    }
  }, []);

  const onClickContractMultipleSuppliersButton = () => {
    dispatch(
      modalsActions.showModal({
        key: ProjectModalTypes.CREATE_MULTI_CONTRACT_MODAL,
        data: {
          rfq: rfqDetails,
          onSuccess: () => {
            history.push(frontendUrl.buyerContracts);
          },
        },
      })
    );
  };

  const onClickExportQuoteComparisonSheet = () => {
    dispatch(
      workspaceNextActions.exportQuotesComparisonSheetRequest({
        rfqPk: rfqDetails.pk,
        rfqDisplayName: rfqDetails.rfq_display_name,
      })
    );
  };

  const hasQuotationAttachment = useMemo(() => {
    return rfqDetails.quotations.some(
      (quotation) =>
        Array.isArray(quotation.quotation_uploads_list) &&
        quotation.quotation_uploads_list.length > 0
    );
  }, [rfqDetails.quotations]);

  const isRequestTypeNotRfiAndSimpleRFQ = useMemo(() => {
    return (
      rfqDetails.request_type !== RequestType.RFI &&
      rfqDetails.request_type !== RequestType.SIMPLE_RFQ
    );
  }, [rfqDetails.request_type]);

  return (
    <section>
      <Box mt="1.5rem">
        <Grid container spacing={1}>
          {isRequestTypeNotRfiAndSimpleRFQ &&
            (showSupplierSelectionButton ? (
              <Grid item>
                <Button
                  onClick={onClickContractMultipleSuppliersButton}
                  variant="contained"
                  fullWidth
                  size="large"
                  color="primary"
                  startIcon={<GavelOutlinedIcon />}
                >
                  {t("rfq:quotations:supplierSelection")}
                </Button>
              </Grid>
            ) : null)}

          {isRequestTypeNotRfiAndSimpleRFQ &&
            (rfqDetails.quotations.length === 0 ? null : (
              <Grid item>
                <HtmlTooltip
                  title={
                    rfqDetails?.hide_quotation_sensitive_data
                      ? t("rfq:quotations:toolTipExportQuotationsPDFDisabled")
                      : ""
                  }
                >
                  <span>
                    <Button
                      onClick={openExportMennu}
                      variant="outlined"
                      fullWidth
                      size="large"
                      color="secondary"
                      startIcon={<CallMadeIcon />}
                      disabled={rfqDetails?.hide_quotation_sensitive_data}
                    >
                      {t("rfq:quotations:export")}
                    </Button>
                  </span>
                </HtmlTooltip>
              </Grid>
            ))}
          {hasQuotationAttachment && (
            <Grid item>
              <DownloadQuotationsAsZip
                quotations={rfqDetails.quotations}
                disabled={rfqDetails?.hide_quotation_sensitive_data}
              />
            </Grid>
          )}
        </Grid>
      </Box>

      <div className={`root ${classes.root}`}>
        <Grid item xs={12}>
          {/** DISPLAY LIST OF OFFERS */}
          {rfqDetails.quotations.length > 0 ? (
            rfqDetails.quotations.map((quote, ix) => {
              return (
                <OffersView
                  quoteIndex={ix}
                  quote={quote}
                  activeRfq={rfqDetails}
                  token={token}
                  selectWinner={selectWinner}
                  getQuotationThreadDetails={getQuotationThreadDetails}
                  setActiveQuotationThread={setActiveQuotationThread}
                  t={t}
                  modalState={modalState}
                  handleOpenModal={handleOpenModal}
                  handleCloseModal={handleCloseModal}
                  expendedPanel={expendedPanel}
                />
              );
            })
          ) : (
            <InformationPage
              src={noOffersImg}
              variant="body2"
              text={t("workspace:thereAreNoBidsForNow")}
            />
          )}
        </Grid>
      </div>
      {showPDF ? (
        <div style={{ display: "none" }}>
          <QuotationPDFViewer
            ref={pdfComponentRef}
            title={rfqDetails.rfq_display_name}
            quotations={rfqDetails.quotations}
          />
        </div>
      ) : null}

      <ProjectCreateMultiContractModal />
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={closeExportMenu}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        {rfqDetails.quotation_extra_fields ? null : (
          <MenuItem
            onClick={() =>
              handleMenuItemClick(() => setShowPDF(true, handlePrint))
            }
          >
            {t("rfq:quotations:exportQuotationsPDF")}
          </MenuItem>
        )}
        <MenuItem
          onClick={() =>
            handleMenuItemClick(() =>
              fileDownloadFromUrl(
                `${backendUrl.rfqs}/${rfqDetails.pk}/exportConsolidatedQuotationReport`,
                `consolidated_quotation_report_${rfqDetails.pk}`,
                undefined,
                true
              )
            )
          }
        >
          {t("rfq:quotations:exportQuotationsXLSX")}
        </MenuItem>

        {!!company?.requires_consolidated_report ? (
          <Tooltip
            open={!!rfqDetails.consolidated_report ? false : undefined}
            title={
              !rfqDetails.consolidated_report
                ? "The consolidated export will be available after the RFQ end date"
                : null
            }
          >
            <span>
              <MenuItem
                onClick={() => downloadFile(rfqDetails.consolidated_report)}
                disabled={!rfqDetails.consolidated_report}
              >
                {t("rfq:quotations:exportConsolidatedReport")}
              </MenuItem>
            </span>
          </Tooltip>
        ) : null}

        {!!reportsButtonAvailable ? (
          <MenuItem onClick={onClickExportQuoteComparisonSheet}>
            {t("rfq:quotations:exportQuotesComparisonSheet")}
          </MenuItem>
        ) : null}
      </Menu>
    </section>
  );
};
