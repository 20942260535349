import React from "react";
import { Box, Grid, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { QuotationExtraFieldsTableToolbar } from "./quotation-extra-fields-table.toolbar";
import { useExtraFieldsTableData } from "./quotation-extra-fields-table.hooks";
import { Rfq } from "@next/modules/project/redux";
import { useDispatch, useSelector } from "react-redux";
import { marketplaceActions } from "../../redux";
import { getCompany, getUser } from "services/profile/profile.selectors";
import { CompanyTypeNext } from "@next/redux/types";
import { workspaceNextActions } from "@next/modules/workspace/redux";
import { GridRowData, useGridApiRef } from "@mui/x-data-grid-pro";
import { DataGridProV4 } from "@next/components/data-grid-pro-v4";

const PAGE_SIZE = 10;

type Props = {
  activeQuote: Rfq & {
    parts: Rfq["parts"] & { rfq: { pk: number; display_name: string } }[];
    quote?: {
      quotationId: number;
      rfq_display_name: string;
      company_name: string;
    };
  };
  extraFieldsRowModels: Map<React.ReactText, GridRowData>;
  saveExtraFieldsRowModels: (
    rowModels: Map<React.ReactText, GridRowData>
  ) => void;
  isEditable: boolean;
  partExtraFields: any;
};

export const QuotationExtraFieldsTable: React.FC<Props> = ({
  activeQuote,
  extraFieldsRowModels,
  saveExtraFieldsRowModels,
  isEditable,
  partExtraFields,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const apiRef = useGridApiRef();
  const company = useSelector(getCompany);
  const user = useSelector(getUser);
  const rfq = activeQuote?.parts && activeQuote?.parts[0]?.rfq;
  const quote = activeQuote?.quote;
  const quoteNotExportable = activeQuote?.hide_quotation_sensitive_data;
  const { gridData } = useExtraFieldsTableData(
    activeQuote,
    partExtraFields,
    extraFieldsRowModels,
    isEditable
  );

  const onEditRowsModelChange = () => {
    if (apiRef?.current) {
      saveExtraFieldsRowModels(apiRef?.current?.getRowModels());
    }
  };

  const onImport = (file: any) => {
    if (rfq && file) {
      dispatch(
        marketplaceActions.customQuotationImportRequest({
          rfqId: rfq?.pk,
          file,
        })
      );
    }
  };

  const onExport = () => {
    if (company?.type === CompanyTypeNext.FAB) {
      if (rfq) {
        dispatch(
          marketplaceActions.customQuotationExportRequest({
            rfqId: rfq?.pk,
            fileName: `${rfq?.display_name} - ${company?.name}`,
          })
        );
      }
    } else {
      if (quote) {
        dispatch(
          workspaceNextActions.customQuotationExportRequest({
            quotationId: quote?.quotationId as number,
            fileName: `${quote?.rfq_display_name} - ${quote?.company_name}`,
          })
        );
      }
    }
  };

  return (
    <Grid
      className="c-quotation-extra-fields-table__container"
      container
      xs={12}
    >
      <Grid item className="c-quotation-extra-fields-table__title">
        <Typography variant="h6">
          {t("project:table:title:quotationSheet")}
        </Typography>
      </Grid>
      <Grid>
        <Box className="c-quotation-extra-fields-table__tableWrapper">
          <Box className="c-quotation-extra-fields-table__table">
            <DataGridProV4
              pagination
              pageSize={PAGE_SIZE}
              density="compact"
              autoHeight
              rows={gridData?.rows || []}
              columns={gridData?.columns || []}
              disableSelectionOnClick={true}
              getRowId={(row) => row.pk}
              onEditRowsModelChange={onEditRowsModelChange}
              components={{
                Toolbar: () => (
                  <QuotationExtraFieldsTableToolbar
                    onImport={onImport}
                    onExport={onExport}
                    exportDisabled={quoteNotExportable}
                  />
                ),
              }}
              apiRef={apiRef}
            />
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};
