import React, { useCallback, useState } from "react";
import { Formik } from "formik";

import {
  initialValues,
  validationSchema,
} from "./topic-message-form.definitions";

import { TopicMessageFormBase } from "./topic-message-form.base";
import { AnswerTopicInput, TopicType } from "@next/modules/workspace/redux";

type Props = {
  onSubmit: (values: Partial<AnswerTopicInput>) => void;
  requiresName: boolean;
  topic?: TopicType;
};

const TopicMessageForm: React.FC<Props> = ({
  onSubmit,
  requiresName,
  topic,
}) => {
  const [_requiresName, setRequiresName] = useState(requiresName);

  const handleSubmit = useCallback(
    (values, { resetForm, validateForm }) => {
      onSubmit(values);
      if (_requiresName) {
        setRequiresName(false);
      }
      resetForm();
      validateForm();
    },
    [_requiresName]
  );

  return (
    <div>
      <Formik
        validateOnMount
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={validationSchema(_requiresName)}
      >
        {(props) => (
          <TopicMessageFormBase
            requiresName={_requiresName}
            topic={topic}
            {...props}
          />
        )}
      </Formik>
    </div>
  );
};

export default TopicMessageForm;
