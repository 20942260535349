export const currencyLabels = [
  { pk: 1, type: "", name: "$ CAD", label: "cad" },
  { pk: 2, type: "", name: "$ USD", label: "usd" },
];

export const workTimeOptions = [
  { pk: 1, type: "", name: "working day(s)", label: "d" },
  { pk: 2, type: "", name: "weeks", label: "w" },
  { pk: 3, type: "", name: "months", label: "m" },
];
