import React from "react";
import ConfirmationDialog from "@next/components/confirmation-dialog/confirmation-dialog";
import { useTranslation } from "react-i18next";
import { Button } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { getModal, modalsActions } from "@next/redux/modalsSlices";
import { ProjectModalTypes } from "./types";
import { projectActions } from "../redux";

export const ProjectPartDeleteConfirmationModal: React.FC = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const modal = useSelector(
    getModal(ProjectModalTypes.PART_DELETE_CONFIRMATION_MODAL)
  );
  const selectedPartPk = modal?.data as number;

  const closeModal = () => {
    dispatch(
      modalsActions.closeModal(ProjectModalTypes.PART_DELETE_CONFIRMATION_MODAL)
    );
  };

  const onClickDeletePart = () => {
    dispatch(projectActions.deleteProjectPartRequest(selectedPartPk));
    closeModal();
  };

  return (
    <ConfirmationDialog
      title={t("workspace:confirmation")}
      content={t("workspace:areYouSureYouWantToDeleteThisItem")}
      isOpen={!!modal}
      handleClose={closeModal}
      dialogActions={
        <div>
          <Button autoFocus onClick={closeModal} color="primary">
            {t("workspace:cancel")}
          </Button>
          <Button onClick={onClickDeletePart} color="primary">
            {t("RFQ:yes")}
          </Button>
        </div>
      }
    />
  );
};
