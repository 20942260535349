import React from "react";
import { Box, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useStyles } from "./rfq-creation.form.styles";

interface Props {
  onRemove?: () => void;
  rootClassName?: string;
}

const CustomListItem: React.FC<Props> = ({
  onRemove,
  children,
  rootClassName,
}) => {
  const classes = useStyles();

  return (
    <Box className={`${classes.partRow} ${rootClassName || ""}`}>
      <Box flex={1}>{children}</Box>
      {onRemove ? (
        <Box>
          <IconButton
            edge="end"
            aria-label="remove"
            onClick={onRemove}
            className={classes.closeIconBtn}
            size="large"
          >
            <CloseIcon />
          </IconButton>
        </Box>
      ) : null}
    </Box>
  );
};

export default CustomListItem;
