import React, { useEffect, useState } from "react";

import {
  AppBar,
  Box,
  Button,
  Grid,
  Link,
  Theme,
  Toolbar,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import ProjectTabPanel from "../components/tab-panel/project-tabpanel";
import { ProjectStats } from "../components/project-stats/project-stats";
import { projectActions } from "../redux";
import {
  selectProjectCompanyPartsCount,
  selectProjectCompanyPartsFiltered,
  selectProjectCompanyPartsSelectAll,
  selectSelectedPartRows,
} from "../redux/selectors";
import { AppTheme } from "layout/AppTheme";
import NoteAddOutlinedIcon from "@mui/icons-material/NoteAddOutlined";
import { modalsActions } from "@next/redux/modalsSlices";
import { ProjectModalTypes } from "@next/modules/project/modals/types";
import { confirmDialog } from "@next/modals/shared-confirm-dialog";
import { enqueueSnackbar } from "notistack";
import { createStyles, makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    deleteButton: {
      color: theme.palette.error.main,
      borderColor: theme.palette.error.main,
    },
  })
);

export const ProjectMain: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const classes = useStyles();

  const params = useParams<{ code: string }>();
  const selectedPartsSelectionModel = useSelector(selectSelectedPartRows);
  const projectCompanyPartsSelectAll = useSelector(
    selectProjectCompanyPartsSelectAll
  );
  const projectCompanyPartsCount = useSelector(selectProjectCompanyPartsCount);
  const projectCompanyPartsFiltered = useSelector(
    selectProjectCompanyPartsFiltered
  );

  const [isShowSelectedPartToolbar, setIsShowSelectedPartToolbar] =
    useState(false);

  const selectedPartCount = projectCompanyPartsSelectAll
    ? projectCompanyPartsCount
    : selectedPartsSelectionModel?.length;

  useEffect(() => {
    dispatch(
      projectActions.fetchProjectStatsRequest({ projectCode: params.code })
    );

    return () => {
      dispatch(modalsActions.closeModal(ProjectModalTypes.RFQ_DETAILS_MODAL));
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (selectedPartsSelectionModel || projectCompanyPartsSelectAll) {
      setIsShowSelectedPartToolbar(
        selectedPartsSelectionModel.length > 0 || projectCompanyPartsSelectAll
      );
    }

    if (
      selectedPartsSelectionModel.length > 0 &&
      projectCompanyPartsCount > 0 &&
      selectedPartsSelectionModel.length === projectCompanyPartsCount &&
      !projectCompanyPartsFiltered
    ) {
      dispatch(projectActions.setProjectCompanyPartsSelectAll(true));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    selectedPartsSelectionModel,
    projectCompanyPartsSelectAll,
    projectCompanyPartsCount,
  ]);

  const onClickCreateRfq = () => {
    dispatch(
      projectActions.openRFQDrawer({
        projectCode: params.code,
        selectedParts: selectedPartCount,
      })
    );
  };

  const onClickSelectAll = (newSelection: boolean) => () => {
    if (!newSelection) {
      dispatch(projectActions.setSelectedPartRows([]));
      enqueueSnackbar(`${t("project:selectionCleared")}`, {
        autoHideDuration: 4000,
        disableCloseButton: true,
      });
    }

    dispatch(projectActions.setProjectCompanyPartsSelectAll(newSelection));
  };

  const onClickDelete = () => {
    confirmDialog(
      t("common:areYouSureWantToDelete"),
      t("project:deletePartWarningText", { count: selectedPartCount }),
      () => {
        if (selectedPartsSelectionModel.length > 0) {
          const ids = selectedPartsSelectionModel?.map((item) => item.pk);

          dispatch(
            projectActions.bulkDeleteProjectPartRequest({
              partIds: projectCompanyPartsSelectAll ? [] : (ids as number[]),
              selectAll: projectCompanyPartsSelectAll,
              projectCode: params.code,
            })
          );
        }
      },
      t("common:delete"),
      "delete"
    );
  };

  return (
    <Grid container xs={12} className="c-project">
      <AppBar
        position="static"
        color="transparent"
        className="c-project__navbar"
      >
        {isShowSelectedPartToolbar && selectedPartCount > 0 ? (
          <Toolbar
            disableGutters={true}
            className="c-project__navbar__toolbar--part-selection"
            style={{
              backgroundColor: AppTheme.palette.background.paper,
            }}
          >
            <Box display="flex" flexGrow={1}>
              <Typography color="inherit" variant="body2">
                {`${t("project:navbar:partSelectedWithCount", {
                  count: selectedPartCount,
                })} `}
              </Typography>

              <Box ml="8px" style={{ cursor: "pointer" }}>
                {projectCompanyPartsCount > 0 ? (
                  <Typography color="inherit" variant="body2">
                    {projectCompanyPartsSelectAll ? (
                      <Link onClick={onClickSelectAll(false)}>
                        {`${t("project:navbar:clearSelection", {
                          count: projectCompanyPartsCount,
                        })} `}
                      </Link>
                    ) : (
                      <Link onClick={onClickSelectAll(true)}>
                        {`${t("project:navbar:selectAllParts", {
                          count: projectCompanyPartsCount,
                        })} `}
                      </Link>
                    )}
                  </Typography>
                ) : null}
              </Box>
            </Box>
            <Box display="flex">
              <Box marginRight="12px">
                <Button
                  variant="outlined"
                  className={classes.deleteButton}
                  onClick={onClickDelete}
                  size="medium"
                >
                  {t("project:navbar:button:deleteParts", {
                    count: selectedPartCount,
                  })}
                </Button>
              </Box>

              <Button
                variant="contained"
                color="primary"
                onClick={onClickCreateRfq}
                startIcon={<NoteAddOutlinedIcon />}
              >
                {t("project:navbar:button:createARequestForQuotation")}
              </Button>
            </Box>
          </Toolbar>
        ) : (
          <Toolbar disableGutters={true} className="c-project__navbar__toolbar">
            <ProjectStats />{" "}
          </Toolbar>
        )}
      </AppBar>
      <Grid container xs={12}>
        <ProjectTabPanel />
      </Grid>
    </Grid>
  );
};
