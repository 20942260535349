import querystring from "querystring";

export const delay = (ms: number) => new Promise((res) => setTimeout(res, ms));

export const getQueryParams = () => {
  const queryParams = window.location.search.replace("?", "");
  return querystring.parse(queryParams);
};

export const bytesToReadableFormat = (sizeInBytes: number): string => {
  const suffixes = ["B", "KB", "MB", "GB"];
  const base = 1024;
  if (sizeInBytes < base) {
    return `${sizeInBytes} B`;
  }
  let magnitude = Math.floor(Math.log(sizeInBytes) / Math.log(base));
  magnitude = Math.min(magnitude, suffixes.length - 1);
  const value = sizeInBytes / Math.pow(base, magnitude);
  return `${value.toFixed(1)} ${suffixes[magnitude]}`;
};
