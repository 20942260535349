import React, { useCallback } from "react";
import { Formik } from "formik";
import {
  getRevisePoFormInitialValues,
  validationSchema,
} from "./revise-po-form.definitions";
import { RevisePoFormBase } from "./revise-po-form.base";
import { batch, useDispatch } from "react-redux";
import { Order, ordersActions } from "../../redux";
import { modalsActions } from "@next/redux/modalsSlices";
import { OrdersModalTypes } from "../../modals/types";
import { enqueueSnackbar } from "notistack";
import { useTranslation } from "react-i18next";

type Props = {
  order: Order;
};

const RevisePoForm: React.FC<Props> = ({ order }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const handleSubmit = useCallback(
    (values) => {
      const onSuccess = () => {
        dispatch(modalsActions.closeModal(OrdersModalTypes.REVISE_PO_MODAL));
        enqueueSnackbar(
          t("orders:revisePoModal:successSnackbar", {
            supplierName: order.supplier?.name,
          })
        );
      };

      batch(() => {
        dispatch(
          ordersActions.uploadOrderFileRequest({
            attachments: [values.file],
            pk: order.pk,
            onSuccess,
          })
        );
      });
    },
    [order.pk]
  );

  return (
    <Formik
      validateOnMount
      initialValues={getRevisePoFormInitialValues({ file: undefined })}
      onSubmit={handleSubmit}
      validationSchema={validationSchema()}
    >
      {(props) => <RevisePoFormBase {...props} order={order} />}
    </Formik>
  );
};

export default RevisePoForm;
