import { Paper, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { AppTheme } from "layout/AppTheme";
import { getSummaryItemIcon } from "./bulk-file-import-summary-item.utils";
import { BulkFileImportSummaryItemFileStatus } from "./bulk-file-import-summary-item-file-status";
import { BomFilesUploadReportStatusData } from "@next/modules/project/redux";
import { useTranslation } from "react-i18next";
import { createStyles, makeStyles } from "@mui/styles";
import { FileData } from "@next/components/attach-file/attach-file-list.types";
import { BulkFileImportSummaryItemFileStatusAttachment } from "./bulk-file-import-summary-item-file-status-attachment";

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      padding: "12px",
      marginBottom: "20px",
      display: "flex",
      flexDirection: "column",
    },
    title: {
      display: "flex",
      alignItems: "center",
    },
    titleText: {
      paddingLeft: "10px",
    },
    statusSection: {
      display: "flex",
      paddingLeft: "8px",
      paddingRight: "8px",
      marginTop: "12px",
    },
    statusSectionHeaderText: {
      width: "50%",
      color: AppTheme.palette.text.secondary,
    },
  })
);

export enum BulkFileImportSummaryItemType {
  SUCCESS = "success",
  WARNING = "warning",
  ERROR = "error",
  ATTACHMENT = "attachment",
}

type Props = {
  type: BulkFileImportSummaryItemType;
  summaryItemText: React.ReactNode;
  fileStatusReport?: BomFilesUploadReportStatusData[];
  hide?: boolean;
  rfqAttachments?: FileData[];
  onAttachmentsChange?: (files: FileData[], filePkToDelete?: number) => void;
};

export const BulkFileImportSummaryItem: React.FC<Props> = ({
  type,
  summaryItemText,
  fileStatusReport,
  hide,
  rfqAttachments,
  onAttachmentsChange,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();

  useEffect(() => {
    if (
      type === BulkFileImportSummaryItemType.ATTACHMENT &&
      rfqAttachments?.length > 0
    ) {
      onAttachmentsChange(rfqAttachments);
    }
  }, [rfqAttachments]);

  if (hide) return null;

  let files;
  if (type === BulkFileImportSummaryItemType.ATTACHMENT) {
    files = rfqAttachments?.map((attachment, index) => ({
      attachment,
      report: fileStatusReport[index],
    }));
  } else {
    files = fileStatusReport;
  }

  return (
    <Paper variant="outlined" className={classes.root}>
      <div className={classes.title}>
        {getSummaryItemIcon(type)}

        <Typography className={classes.titleText} variant="body1">
          {summaryItemText}
        </Typography>
      </div>

      {fileStatusReport?.length > 0 || rfqAttachments?.length > 0 ? (
        <div className={classes.statusSection}>
          <Typography
            className={classes.statusSectionHeaderText}
            variant="body2"
          >
            {t("project:bulkFileImport:summary:file")}
          </Typography>
          <Typography
            className={classes.statusSectionHeaderText}
            variant="body2"
          >
            {type === BulkFileImportSummaryItemType.WARNING
              ? t("project:bulkFileImport:summary:partToAttachItTo")
              : type === BulkFileImportSummaryItemType.ATTACHMENT
              ? t("project:bulkFileImport:summary:attachedTo")
              : t("project:bulkFileImport:summary:limitation")}
          </Typography>
        </div>
      ) : null}

      {type === BulkFileImportSummaryItemType.ATTACHMENT
        ? files?.map((file, i) => (
            <BulkFileImportSummaryItemFileStatusAttachment
              file={file}
              error={file?.report?.message}
              onAttachmentsChange={onAttachmentsChange}
            />
          ))
        : files?.map((file, i) => (
            <BulkFileImportSummaryItemFileStatus
              file={file}
              type={type}
              error={file?.message}
            />
          ))}
    </Paper>
  );
};
