import {
  DeprecatedThemeOptions,
  adaptV4Theme,
  createTheme as createThemeV5,
  responsiveFontSizes,
} from "@mui/material/styles";

// MUI v4
import { createGenerateClassName } from "@material-ui/core/styles";
import {
  ThemeOptions,
  createMuiTheme as createThemeV4,
} from "@material-ui/core/styles";

const primaryMainColor = "#2682fd";
const secondaryMainColor = "#0A2344";

const themeV4Options = {
  props: {
    MuiButton: {
      disableElevation: true, // No more elevation, on the whole application!
    },
    MuiListSubheader: {
      disableGutters: true,
    },
  },
  palette: {
    primary: {
      light: "#67a8fe",
      main: primaryMainColor,
      dark: "#1b5bb1",
    },
    secondary: {
      light: "#54657C",
      main: secondaryMainColor,
      dark: "#071930",
    },
    error: {
      light: "#ff7870",
      main: "#ff3e32",
      dark: "#b32b23",
    },
    warning: {
      light: "#ffc36b",
      main: "#ffa92c",
      dark: "#b3761f",
    },
    success: { light: "#59e2a4", main: "#12d67d", dark: "#0d9658" },
    info: {
      light: "#a072f6",
      main: "#7736f2",
      dark: "#5326A9",
    },
    text: {
      primary: "rgba(0, 0, 0, 0.87)",
      secondary: "rgba(0, 0, 0, 0.54)",
      disabled: "rgba(0, 0, 0, 0.38)",
    },
    background: {
      default: "#fff",
    },
    customBackground: {
      light: "#fafafa",
      main: "#acacac",
      dark: "#303030",
    },
  },

  typography: {
    fontFamily: "Roboto",
    h1: {
      fontWeight: 300,
      fontSize: "96px",
    },
    h2: {
      fontWeight: 300,
      fontSize: "60px",
    },
    h3: {
      fontSize: "48px",
    },
    h4: {
      fontSize: "34px",
    },
    h5: {
      fontSize: "24px",
    },
    h6: {
      fontWeight: 500,
      fontSize: "20px",
    },
    subtitle1: {
      fontSize: "16px",
      lineHeight: "20px",
    },
    subtitle2: {
      fontWeight: 500,
      fontSize: "14px",
    },
    body1: {
      fontSize: "16px",
    },
    body2: {
      fontSize: "14px",
    },
    button: {
      fontWeight: 500,
      fontSize: "14px",
    },
    caption: {
      fontSize: "12px",
      letterSpacing: "0.4px",
    },
    overline: {
      fontWeight: 500,
      fontSize: "10px",
    },
  },
  overrides: {
    MuiGridPanel: {
      root: {
        // disallow change OR and AND operators only allow AND
        "& .MuiGridFilterForm-linkOperatorSelect": {
          opacity: 0.5,
          pointerEvents: "none",
        },
      },
    },
    MuiPaper: {
      rounded: { borderRadius: 16 },
      // elevation1: {
      //   boxShadow: `0px 1px 2px rgba(0, 0, 0, 0.3), 0px 2px 6px 2px rgba(0, 0, 0, 0.15) !important`,
      // },
    },
    MuiPickersToolbar: {
      toolbar: {
        backgroundColor: secondaryMainColor,
      },
    },
    MuiPickersDay: {
      daySelected: {
        backgroundColor: secondaryMainColor,
      },
    },
    MuiOutlinedInput: {
      root: {
        borderRadius: 8,
      },
    },
    MuiSelect: {
      select: {
        "&:focus": {
          backgroundColor: "none",
        },
      },
    },
    MuiButton: {
      root: {
        textTransform: "none",
        borderRadius: 8,
      },
    },
    MuiDataGrid: {
      root: {
        borderLeft: 0,
        borderRight: 0,
        borderBottom: 0,
        borderRadius: 0,
        "& .MuiDataGrid-columnHeaderTitleContainer": {
          padding: 0,
        },
        "& .MuiDataGrid-columnHeaderTitle ": {
          fontWeight: "bold",
        },
        "& .MuiDataGrid-columnHeader": {
          background: "none",
        },
        "& .MuiDataGrid-columnHeaderWrapper": {
          background: "none",
        },
      },
    },
    MuiTableCell: {
      root: {
        borderBottom: "1px solid #e0e0e0",
      },
    },
    MuiListItemIcon: {
      root: {
        minWidth: "36px",
      },
    },
    // To fix changes colour with an Input field
    MuiAccordionSummary: {
      root: {
        "&.Mui-focused": {
          backgroundColor: "white !important",
        },
      },
    },
  } as DeprecatedThemeOptions["overrides"],
};

export const AppTheme = createThemeV4(themeV4Options as ThemeOptions);

export const AppThemeV5 = responsiveFontSizes(
  createThemeV5({
    ...adaptV4Theme(themeV4Options),
    palette: { ...adaptV4Theme(themeV4Options).palette },
  })
);

export const generateClassName = createGenerateClassName({
  // By enabling this option, if you have non-MUI elements (e.g. `<div />`)
  // using MUI classes (e.g. `.MuiButton`) they will lose styles.
  // Make sure to convert them to use `styled()` or `<Box />` first.
  disableGlobal: true,
  // Class names will receive this seed to avoid name collisions.
  seed: "mui-jss",
});
