import { GenericUser } from "@next/modules/profile/redux";
import { t } from "assets/configi18n/i18n";
import { formatDate } from "helpers/formatDate";

export const generateImageTooltipText = (
  user: GenericUser,
  includeEmailInTooltip: boolean,
  seenDate: string
) => {
  return `${user.full_name || user.email} ${
    includeEmailInTooltip && user.full_name ? `(${user.email})` : ""
  } ${
    seenDate
      ? t("orders:viewedOn", { date: formatDate(seenDate, "DD-MMM-YYYY") })
      : ""
  }`;
};
