import { Avatar, Tooltip, Typography } from "@mui/material";
import {
  GridRenderCellParams,
  GridValueGetterParams,
} from "@mui/x-data-grid-pro";
import { getFirstCharacter } from "@next/utils/stringUtils";
import React from "react";

const OwnerCell: React.FC<GridRenderCellParams> = (
  params: GridValueGetterParams
) => {
  return (
    <Tooltip title={(params.value as string) || ""}>
      <Avatar style={{ width: "24px", height: "24px" }}>
        <Typography
          variant="caption"
          style={{ lineHeight: "unset", paddingLeft: "1px" }}
        >
          {getFirstCharacter(params.value as string)}
        </Typography>
      </Avatar>
    </Tooltip>
  );
};

export default OwnerCell;
