import React from "react";
import { Avatar, Box, SvgIconProps, Tooltip, Typography } from "@mui/material";
import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid-pro-v5";

import { formatDate } from "helpers/formatDate";
import { getInitials } from "@next/utils/stringUtils";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import CheckOutlinedIcon from "@mui/icons-material/CheckOutlined";
import WatchLaterOutlinedIcon from "@mui/icons-material/WatchLaterOutlined";
import EventAvailableOutlinedIcon from "@mui/icons-material/EventAvailableOutlined";
import FlagOutlinedIcon from "@mui/icons-material/FlagOutlined";

import { IntentToBidDueDate } from "../intent-to-bid/intent-to-bid-due-date";
import moment from "moment";
import { IntendToNoQuote } from "../intent-to-bid/intent-to-no-quote";

import LockIcon from "@mui/icons-material/LockOutlined";
import LockOpenIcon from "@mui/icons-material/LockOpenOutlined";
import { createStyles, makeStyles } from "@mui/styles";
import { CreatedBy } from "@next/modules/workspace/redux";
import { IconText } from "@next/components/icon-text";
import { BuyerRFQSTableField } from "@next/modules/workspace/components/buyer-rfqs-table/buyer-rfqs-table.types";

const defaultColumnConfig: Omit<GridColDef, "field"> = {
  flex: 1,
  editable: false,
  sortable: false,
  minWidth: 100,
  hideSortIcons: true,
  disableColumnMenu: true,
};

const useStyles = makeStyles((theme) =>
  createStyles({
    badge: {
      width: 8,
      height: 8,
      borderRadius: "50%",
      backgroundColor: theme.palette.primary.main,
    },
    avatar: {
      width: 32,
      height: 32,
      borderRadius: 4,
      border: `1px solid ${theme.palette.grey[300]}`,
      "& img": {
        objectFit: "contain",
      },
    },
    initials: {
      minWidth: 32,
      textAlign: "center",
    },
    lockIcon: {
      width: "16px",
      height: "16px",
      color: theme.palette.text.primary,
    },
    lockIconGrey: {
      width: "16px",
      height: "16px",
      color: theme.palette.grey[500],
    },
    lockOpenIcon: {
      width: "16px",
      height: "16px",
      color: theme.palette.success.main,
    },
  })
);

const IconTextMap: {
  [key in BuyerRFQSTableField]?: React.ComponentType<SvgIconProps>;
} = {
  [BuyerRFQSTableField.VIEWS]: VisibilityOutlinedIcon,
  [BuyerRFQSTableField.QUOTATION_IN_PROGRESS]: WatchLaterOutlinedIcon,
  [BuyerRFQSTableField.QUOTES]: CheckOutlinedIcon,
  [BuyerRFQSTableField.CREATED_ON]: EventAvailableOutlinedIcon,
  [BuyerRFQSTableField.DEADLINE]: FlagOutlinedIcon,
};

const IconTextCell: React.FC<
  GridRenderCellParams & { tooltipText?: string }
> = (params: any & { tooltipText?: string }) => {
  return (
    <IconText
      Icon={IconTextMap[params?.field as BuyerRFQSTableField]}
      text={params?.value as string}
    />
  );
};

const CompanyCell: React.FC<GridRenderCellParams> = (params: any) => {
  const classes = useStyles();
  const companyName = params?.row?.company_name || "";

  return (
    <Tooltip title={companyName}>
      <Box display="flex" alignItems="center" gap="8px">
        {params?.row?.is_seen ? null : <Box className={classes.badge} />}
        <Avatar className={classes.avatar} src={params?.row?.company_logo}>
          <div className={classes.initials}>{getInitials(companyName)}</div>
        </Avatar>
        <Typography noWrap variant="caption">
          {companyName}
        </Typography>
      </Box>
    </Tooltip>
  );
};

const NameCell: React.FC<GridRenderCellParams> = (params: any) => {
  const classes = useStyles();
  const cellVal = params?.row?.rfq_display_name;
  const nda = params?.row?.nda;
  const supplierSignedNda = params?.row?.supplier_signed_nda;
  if (!cellVal) return null;

  return (
    <Box display={"flex"} alignItems={"center"}>
      <Box mr="8px">
        {nda && !supplierSignedNda ? (
          <LockIcon className={classes.lockIcon} />
        ) : supplierSignedNda && !supplierSignedNda["is_approved"] ? (
          <LockIcon className={classes.lockIconGrey} />
        ) : supplierSignedNda && supplierSignedNda["is_approved"] ? (
          <LockOpenIcon className={classes.lockOpenIcon} />
        ) : null}
      </Box>
      <Tooltip title={cellVal}>
        <Typography noWrap variant="caption">
          {cellVal}
        </Typography>
      </Tooltip>
    </Box>
  );
};

const InterestedToQuote: React.FC<GridRenderCellParams> = (params: any) => {
  if (params?.row?.is_locked_for_premium_plan) return null;
  return (
    <Box display="flex" alignItems="center" gap="8px">
      <IntentToBidDueDate
        rfqPk={params?.row?.pk}
        rfqDisplayName={params?.row?.rfq_display_name}
        maxDate={moment(params?.row?.deadline, "YYYY-MM-DD")
          .add(1, "days")
          .toString()}
        tabIndex={1}
        itnbReason={params?.row?.itnb_reason}
        isRecommended={params?.row?.is_recommended}
        dueDateToBid={params?.row?.intent_to_bid_due_date}
        disabled={params?.row?.is_locked_for_premium_plan}
        iconOnly
      />
      <IntendToNoQuote
        preference={{
          rfq_pk: Number.parseInt(params?.row?.pk),
          type: "hidden_rfqs",
        }}
        isRecommended={params?.row?.is_recommended}
        itnbReason={params?.row?.itnb_reason}
        itnbReasonTitle={params?.row?.itnb_reason_title}
        itnbReasonDescription={params?.row?.itnb_reason_description}
        rfqDisplayName={params?.row?.rfq_display_name}
        tabIndex={1}
        dueDateToBid={params?.row?.intent_to_bid_due_date}
        disabled={params?.row?.is_locked_for_premium_plan}
        iconOnly
      />
    </Box>
  );
};

export const getMarketplaceRequestsTableColumns = (
  t: (...args: any) => string
) => {
  const _columns: GridColDef[] = [
    {
      ...defaultColumnConfig,
      field: "company",
      headerName: t("marketplaceRequests:table:company"),
      renderCell: CompanyCell,
    },
    {
      ...defaultColumnConfig,
      field: "rfq_display_name",
      headerName: t("marketplaceRequests:table:name"),
      renderCell: NameCell,
    },
    {
      ...defaultColumnConfig,
      field: "items",
      headerName: t("marketplaceRequests:table:items"),
      valueGetter: (params) =>
        t("marketplaceRequests:table:itemsAndQtyCount", {
          itemsCount: params?.row?.parts_count || 0,
          qtyCount: params?.row?.parts_quantity || 0,
        }),
      renderCell: null,
    },
    {
      ...defaultColumnConfig,
      field: "created_by",
      headerName: t("marketplaceRequests:table:createdBy"),
      valueGetter: (params) => (params?.value as CreatedBy)?.full_name,
      renderCell: null,
    },
    {
      ...defaultColumnConfig,
      field: "created_at",
      headerName: t("tender:table:createdOn"),
      valueGetter: (params) => formatDate(params.value, "MMM D YYYY"),
      renderCell: IconTextCell,
      type: "date",
    },
    {
      ...defaultColumnConfig,
      field: "deadline",
      headerName: t("tender:table:endsOn"),
      valueGetter: (params) => formatDate(params.value, "MMM D YYYY"),
      renderCell: IconTextCell,
      type: "date",
    },
    {
      ...defaultColumnConfig,
      field: "interested_to_quote",
      headerName: t("marketplaceRequests:table:interestedToQuote"),
      minWidth: 80,
      renderCell: InterestedToQuote,
    },
  ];

  return _columns;
};
