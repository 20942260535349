import { Box, ButtonProps } from "@mui/material";
import React from "react";
import { pickFiles } from "@next/utils/fileUtils";
import { useTranslation } from "react-i18next";
import { AttachFileList } from "../attach-file/attach-file-list";
import { useDispatch } from "react-redux";
import { modalsActions } from "@next/redux/modalsSlices";
import { SharedModalTypes } from "@next/modals/types";
import { FileData } from "../attach-file/attach-file-list.types";
import { AttachFilePickButton } from "../attach-file/attach-file-pick-button";
import { getSupportedFileTypes } from "@next/utils/constantUtils";
import { enqueueSnackbar } from "notistack";

type Props = {
  files: FileData[];
  onAddFiles?: (newFiles: File[]) => void;
  onDeleteFile?: (fileName: string) => void;
  loading?: boolean;
  captionText?: string;
  btnProps?: ButtonProps;
  multiple?: boolean;
  btnText?: string;
  readOnly?: boolean;
  acceptFiles?: string;
};

const AttachFileControlled: React.FC<Props> = ({
  files,
  onAddFiles,
  onDeleteFile,
  loading,
  captionText,
  btnProps,
  multiple = true,
  btnText,
  readOnly,
  acceptFiles,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const onPickFiles = () => {
    pickFiles({
      multiple,
      maxMBs: 10,
      accept: acceptFiles ? acceptFiles : `${getSupportedFileTypes()}`,
      onChange: (newFiles) => {
        if (!multiple && newFiles?.length > 1) {
          return enqueueSnackbar(t("common:youCanOnlyUploadOneFile"), {
            variant: "error",
          });
        }

        onAddFiles && onAddFiles(newFiles);
      },
    });
  };

  const onClickDelete = (fileName: string) => {
    dispatch(
      modalsActions.showModal({
        key: SharedModalTypes.ATTACH_FILE_DELETE_CONFIRMATION_MODAL,
        data: () => {
          onDeleteFile && onDeleteFile(fileName);
        },
      })
    );
  };

  return (
    <div>
      <Box display="flex" alignItems="center">
        {!readOnly ? (
          <AttachFilePickButton
            onClick={onPickFiles}
            variant={"contained"}
            size="small"
            fullWidth={true}
            loading={loading}
            {...btnProps}
          >
            {btnText || t("workspaceNext:myParts:inputs:browse")}
          </AttachFilePickButton>
        ) : null}

        {captionText ? <Box ml="16px">{captionText}</Box> : null}
      </Box>

      <Box
        mt={"8px"}
        style={{
          opacity: loading ? 0.5 : 1,
          cursor: loading ? "not-allowed" : "inherit",
          pointerEvents: loading ? "none" : "inherit",
        }}
      >
        <AttachFileList
          files={files}
          onClickDelete={onClickDelete}
          readOnly={readOnly}
        />
      </Box>
    </div>
  );
};

export default AttachFileControlled;
