import { Box, Button, TextField, Typography } from "@mui/material";
import React, { useCallback } from "react";
import PublishIcon from "@mui/icons-material/Publish";
import GetAppIcon from "@mui/icons-material/GetApp";
import { ProjectTemplate } from "../../redux";
import { CustomGridToolbarImport } from "@next/components/custom-grid-toolbar-import";
import { useTranslation } from "react-i18next";
import PaginatedAutoComplete from "@next/components/paginated-autocomplete/paginated-autocomplete";
import { useDispatch, useSelector } from "react-redux";
import { workspaceNextActions } from "@next/modules/workspace/redux";
import { selectMyPartsExportLoading } from "@next/modules/workspace/redux/selectors";
import CustomButton from "@next/components/custom-button/custom-button";
interface Props {
  options: ProjectTemplate[];
  value?: ProjectTemplate;
  onChange: (value: ProjectTemplate) => void;
  disabled?: boolean;
  onImport?: (fileResults?: any) => void;
  onClickOnBulkImport?: () => void;
  projectCode: string;
  showDescription?: boolean;
  showImportBtns?: boolean;
  addMargins?: boolean;
  customWidth?: number;
  asProjectOverlay?: boolean;
}

const TemplateSelector: React.FC<Props> = ({
  options,
  value,
  onChange,
  disabled,
  onImport,
  onClickOnBulkImport,
  projectCode,
  showDescription = true,
  showImportBtns = true,
  addMargins = true,
  customWidth,
  asProjectOverlay,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const downloadLoading = useSelector(selectMyPartsExportLoading);

  const handleChange = useCallback(
    (option) => {
      onChange(option);
    },
    [onChange]
  );

  const onExport = useCallback(() => {
    dispatch(workspaceNextActions.exportMyPartsRequest({ code: projectCode }));
  }, []);

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      mt={addMargins ? 10 : 0}
      mb={addMargins ? 5 : 0}
      sx={
        asProjectOverlay
          ? {
              zIndex: 200,
              position: "absolute",
              left: "50%",
              transform: "translate(-50%, 0%)",
            }
          : {}
      }
    >
      {showDescription && (
        <Typography variant="body1">
          {t("project:templateSelector:description")}
        </Typography>
      )}

      <Box my={addMargins ? 3 : 0}>
        <PaginatedAutoComplete
          style={{ width: customWidth || 360 }}
          disableClearable
          value={value}
          inputValue={undefined}
          onSelectionChange={handleChange}
          getOptionSelected={(
            option: ProjectTemplate,
            value?: ProjectTemplate
          ) => option.id === value?.id}
          renderOption={(option) => option.name}
          getOptionLabel={(option: ProjectTemplate) => option.name || ""}
          options={options || []}
          size="small"
          disabled={disabled}
          placeholder={t("project:templateSelector:placeholder")}
          multiple={false}
          disableCloseOnSelect={false}
          renderInput={(params: any) => (
            <TextField
              {...params}
              disabled={disabled}
              placeholder={t("project:templateSelector:placeholder")}
              fullWidth
              variant="outlined"
              className="form-text-field"
              color="secondary"
            />
          )}
        />
      </Box>

      {showImportBtns && (
        <CustomGridToolbarImport
          importButtonTitle={t("project:table:toolbar:importParts")}
          bulkImportButtonTitle={t("project:table:toolbar:bulkFileImport")}
          onClickOnBulkImport={onClickOnBulkImport}
          onImport={onImport}
          renderButton={(props) => (
            <Box display="flex" gap="16px">
              <CustomButton
                variant="outlined"
                startIcon={<GetAppIcon />}
                disabled={disabled || !value}
                onClick={onExport}
                loading={downloadLoading}
              >
                {t("project:templateSelector:downloadTemplate")}
              </CustomButton>
              <Button
                variant="contained"
                color="primary"
                startIcon={<PublishIcon />}
                disabled={disabled || !value}
                {...(props || {})}
              >
                {t("project:templateSelector:importPartList")}
              </Button>
            </Box>
          )}
        />
      )}
    </Box>
  );
};

export default TemplateSelector;
