import i18next from "i18next";
import moment from "moment";
import {
  getNetDs,
  getTodayRFQsCount,
} from "services/part-configuration/part-configuration.selectors";
import { store } from "store";
import * as yup from "yup";
import { RFQFormValuesType } from "./rfq-creation.form.types";
import { addDays, format } from "date-fns";
import { selectActiveDraftRFQ } from "../../redux/selectors";
import { selectProjectStats } from "@next/modules/project/redux/selectors";
import { checkIsRTASCompany } from "@next/utils/checkCompanyUtils";

export enum RequestType {
  RFI = "rfi",
  RFQ = "rfq",
  SIMPLE_RFQ = "simple_rfq",
}

export const getInitialValues = (values: Partial<RFQFormValuesType>) => {
  const state = store.getState();
  const todayRfqsCount = getTodayRFQsCount(state);
  const projectStats = selectProjectStats(state);
  let partsAndFilesCount = null;
  if (values.project) {
    partsAndFilesCount = {
      partsCount: projectStats?.total_parts_count || 0,
      filesCount: projectStats?.attached_files_count || 0,
    };
  }
  const netDs = getNetDs(state);
  const selectByDefault = netDs?.filter((netD: any) => netD.checked === true);
  const requestType = values.request_type || RequestType.RFQ;
  return {
    rfq_name: values.rfq_name || formatRFQString(todayRfqsCount),
    additional_details: values.additional_details || "",
    additional_details_rich_text:
      values.additional_details_rich_text || values.additional_details || "",
    deadline: values.deadline || format(addDays(new Date(), 7), "yyyy-MM-dd"),
    supporter: values.supporter || "",
    tag: values.tag || "",
    request_type: requestType,
    net_ds:
      values.net_ds?.map((item: unknown) =>
        netDs.find((netD: { id?: number }) => netD?.id === item)
      ) || selectByDefault,
    ...(requestType === RequestType.RFQ ? { parts: values.parts || [] } : {}),
    invited_suppliers: values.invited_suppliers || [],
    targeted_suppliers: values.targeted_suppliers || [],
    is_public: values.is_public || checkIsRTASCompany() || false,
    attachments: values.attachments || [],
    nda: values.nda || null,
    created_by: values.created_by || "",
    followup_date: values.followup_date || null,
    project: values.project || null,
    partsAndFilesCount: values.partsAndFilesCount || partsAndFilesCount,
  };
};

export const getValidationSchema = (t: i18next.TFunction) =>
  yup.object().shape(
    {
      rfq_name: yup
        .string()
        .required(t("workspaceNext:rfqDrawer:error:titleIsReqd"))
        .max(128, t("common:validation:maxCharacters", { limit: 128 })),
      deadline: yup
        .string()
        .required(t("workspaceNext:rfqDrawer:error:endDateIsReqd"))
        .test(
          "is-future-date",
          t("workspaceNext:rfqDrawer:error:pastDate"),
          (val) => moment(val).diff(moment(), "days") >= 0
        ),
      invited_suppliers: yup.array().of(
        yup.object({
          name: yup.string().required(t("common:error:thisFieldIsRequired")),
          email: yup
            .string()
            .email(t("workspaceNext:rfqDrawer:error:invalidEmail"))
            .required(t("common:error:thisFieldIsRequired")),
          contact_name: yup.string(),
        })
      ),
      targeted_suppliers: yup.array(),
      followup_date: yup
        .string()
        .nullable()
        .test(
          "is-future-date",
          t("workspaceNext:rfqDrawer:error:pastDate"),
          (val) =>
            val?.length > 0 ? moment(val).diff(moment(), "days") >= 0 : true
        ),
    },
    [
      ["invited_suppliers", "targeted_suppliers"],
      ["is_public", "targeted_suppliers"],
    ]
  );

export const formDataToRFQData = (formData: RFQFormValuesType) => {
  const activeDraft = selectActiveDraftRFQ(store.getState());
  const rfqData: Record<string, any> = {
    ...formData,
    deadline: formData.deadline,
    supporter: formData.supporter || null,
    net_ds: formData.net_ds.map((item) => item.id),
    targeted_suppliers: formData.targeted_suppliers.map((item) => {
      return {
        id: item.pk,
        target_profiles: item.target_profiles
          ?.map((profile) => profile.id)
          .filter((id): id is number => id !== undefined),
      };
    }),
    attachments: formData.attachments.map((item) => item.pk),
    project: formData.project || null,
    parts: formData.parts?.map((part) => ({
      name: part.name,
      pk: part.pk,
      quantity: part.quantity,
    })),
    partsAndFilesCount: formData.partsAndFilesCount || null,
  };
  delete rfqData.nda;

  if (formData.nda?.pk !== undefined) {
    rfqData.rfq_nda_pk = formData.nda.pk;
  }

  if (
    rfqData.request_type === RequestType.RFI ||
    rfqData.request_type === RequestType.SIMPLE_RFQ
  ) {
    delete rfqData.parts;
  }

  if (activeDraft?.collaborators) {
    rfqData.draft_collaborators = [
      activeDraft.user,
      ...activeDraft.collaborators,
    ];
  }

  return rfqData;
};

const formatRFQString = (rfqCreatedToday: number): string => {
  const today = new Date().toISOString().split("T")[0];
  const countFormatted = String(rfqCreatedToday + 1).padStart(3, "0");
  return `${today}_${countFormatted}`;
};
