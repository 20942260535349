import {
  OrderLineItem,
  OrderLineItemChanges,
  ordersActions,
} from "@next/modules/orders/redux";
import {
  selectLineItemsChangesFromRevisionProposal,
  selectOrder,
  selectRevisionProposals,
} from "@next/modules/orders/redux/selectors";
import { Formik } from "formik";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  FinalizeReviewOptions,
  OrderDetailFinalizeReviewFormState,
  orderDetailFinalizeReviewFormState,
  orderDetailFinalizeReviewFormValidationSchema,
} from "./order-detail-finalize-review-form.definitions";
import { OrderDetailFinalizeReviewFormBase } from "./order-detail-finalize-review-form.base";
import {
  convertLineItemChangesToUpdatedLineItems,
  extractProposalDecisions,
} from "@next/modules/orders/redux/utils";
import { useQueryParam } from "@next/hooks/useQuery";
import { RightPanelTabs } from "../order-detail-right-tabs/order-detail-right-tabs";

type Props = {
  lineItemsChanges: OrderLineItemChanges[];
  makeChangesFromBuyer?: boolean;
  onClose: () => void;
  onSubmitted: () => void;
  asModal?: boolean;
};

export const OrderDetailFinalizeReviewForm: React.FC<Props> = ({
  lineItemsChanges,
  makeChangesFromBuyer,
  onClose,
  onSubmitted,
  asModal,
}) => {
  const dispatch = useDispatch();
  const orderDetail = useSelector(selectOrder);
  const revisionProposals = useSelector(selectRevisionProposals);
  const lineItemsChangesFromRevisionProposal = useSelector(
    selectLineItemsChangesFromRevisionProposal
  );
  const [_rightPanelTab, setRightPanelTab] = useQueryParam("panelTab");

  const onSubmit = (values: OrderDetailFinalizeReviewFormState) => {
    if (makeChangesFromBuyer) {
      if (values.reviewOptions === FinalizeReviewOptions.ACCEPT_AND_REVISE) {
        dispatch(
          ordersActions.uploadOrderFileRequest({
            attachments: [values.file],
            pk: orderDetail.pk,
            onSuccess: (newFileData) => {
              dispatch(
                ordersActions.updateOrderLineItemsRequest({
                  orderPk: orderDetail.pk,
                  update_note: values.note,
                  po_file_pk: newFileData[0].pk,
                  updated_line_items: convertLineItemChangesToUpdatedLineItems(
                    lineItemsChanges as OrderLineItem[]
                  ),
                  onSuccess: () => {
                    setRightPanelTab(RightPanelTabs.COMMUNICATION);
                    onSubmitted();
                  },
                })
              );
            },
          })
        );
      } else {
        dispatch(
          ordersActions.updateOrderLineItemsRequest({
            orderPk: orderDetail.pk,
            update_note: values.note,
            updated_line_items: convertLineItemChangesToUpdatedLineItems(
              lineItemsChanges as OrderLineItem[]
            ),
            onSuccess: () => {
              setRightPanelTab(RightPanelTabs.COMMUNICATION);
              onSubmitted();
            },
          })
        );
      }
    } else {
      if (values.reviewOptions === FinalizeReviewOptions.ACCEPT_AND_REVISE) {
        dispatch(
          ordersActions.uploadOrderFileRequest({
            attachments: [values.file],
            pk: orderDetail.pk,
            onSuccess: (newFileData) => {
              dispatch(
                ordersActions.reviewRevisionProposalRequest({
                  orderPk: orderDetail.pk,
                  po_file_pk: newFileData[0].pk,
                  note: values.note,
                  proposal_decisions: extractProposalDecisions(
                    revisionProposals,
                    lineItemsChangesFromRevisionProposal
                  ),
                  onSuccess: () => {
                    setRightPanelTab(RightPanelTabs.COMMUNICATION);
                    onSubmitted();
                  },
                })
              );
            },
          })
        );
      } else {
        dispatch(
          ordersActions.reviewRevisionProposalRequest({
            orderPk: orderDetail.pk,
            note: values.note,
            proposal_decisions: extractProposalDecisions(
              revisionProposals,
              lineItemsChangesFromRevisionProposal
            ),
            onSuccess: () => {
              setRightPanelTab(RightPanelTabs.COMMUNICATION);
              onSubmitted();
            },
          })
        );
      }
    }
  };

  return (
    <Formik
      initialValues={orderDetailFinalizeReviewFormState}
      validationSchema={orderDetailFinalizeReviewFormValidationSchema}
      onSubmit={onSubmit}
      validateOnMount
      render={(props) => (
        <OrderDetailFinalizeReviewFormBase
          lineItemsChanges={lineItemsChanges}
          onClose={onClose}
          asModal={asModal}
          {...props}
        />
      )}
    />
  );
};
