import { all, put, takeEvery, takeLatest } from "redux-saga/effects";
import { PayloadAction } from "@reduxjs/toolkit";

import { safeCall } from "@next/redux/safeCall";

import { GenericObject, SubscriptionInput, profileNextActions } from ".";
import { profileService } from "../services/profile-services";
import { t } from "assets/configi18n/i18n";
import {
  ConnectionInput,
  DeleteCompanyDocumentsInput,
  DeleteCompanyFileReferencesInput,
  DiscoveryRequestInput,
  EventType,
  FetchCompanyProfileRequestInput,
  FetchSupplierInvitationsInput,
  InviteCompanyUserInput,
  ReorderCompanyFileReferencesInput,
  ResendMemberInviteInput,
  SendBuyerProfileMessageInput,
  TwoFAActionType,
  Update2FAAuthInput,
  UpdateCompanyFileReferencesInput,
  UpdateMemberTeamPermissionInput,
  UpdateProfilePreferencesInput,
  UpdateUserLanguageInput,
  UploadCompanyDocumentsInput,
} from "./types";
import { ChangePasswordFormValues } from "../components/user-profile/user-profile-settings/authentication/change-password-form/change-password-form.types";
import { history } from "helpers/history";
import { frontendUrl } from "urls";
import { profileActions } from "services/profile";
import { modalsActions } from "@next/redux/modalsSlices";
import { ProfileModalTypes } from "../modals/types";
import { enqueueSnackbar } from "notistack";
import { marketplaceApi } from "services/marketplace";
import { genericErrorHandler } from "@next/utils/apiUtils";
import { dashboardActions } from "@next/modules/dashboard/redux";
import { WorkspaceModalTypes } from "@next/modules/workspace/modals/types";
import { OnboardingSteps } from "@next/modules/dashboard/components/seller-dashboard-onboarding-premium/seller-dashboard-onboarding-premium";
import { companyCapabilitiesActions } from "@next/modules/company-capabilities/redux";

function* handleFetchSubscriptionListRequest() {
  const { response, error } = yield safeCall(
    profileService.fetchSubscriptionList
  );

  if (error) {
    yield put(profileNextActions.fetchSubscriptionListFailure(error));
  } else {
    yield put(profileNextActions.fetchSubscriptionListSuccess(response.data));
  }
}

function* handleEnableSubscriptionRequest({
  payload,
}: PayloadAction<SubscriptionInput>) {
  const { error } = yield safeCall(profileService.enableSubscription, payload);
  if (error) {
    yield put(profileNextActions.enableSubscriptionFailure(error));
    yield enqueueSnackbar(error, { variant: "error", autoHideDuration: 6000 });
  } else {
    yield put(profileNextActions.enableSubscriptionSuccess(payload));

    let successMessage = t(
      `${
        payload?.isEnabled === true
          ? "profile:success:activate"
          : "profile:success:deActivate"
      }`,
      {
        notification_name: payload?.name,
      }
    );

    if (payload?.eventType === EventType?.RFQS_RECAP) {
      successMessage =
        payload?.isEnabled === true
          ? t("profile:userProfile:recapNotificationEntireNetwork")
          : t("profile:userProfile:recapNotificationSelectedBuyers");
    }

    yield enqueueSnackbar(`${successMessage}`, {
      variant: "success",
      autoHideDuration: 6000,
    });
  }
}

function* handleFetchCompanyProfileRequest({
  payload,
}: PayloadAction<FetchCompanyProfileRequestInput>) {
  const { response, error } = yield safeCall(
    profileService.fetchCompanyProfile,
    payload
  );

  if (error) {
    yield put(profileNextActions.fetchCompanyProfileFailure(error));
  } else {
    yield put(
      profileNextActions.fetchCompanyProfileSuccess({
        isPublic: payload.isPublic,
        data: response.data,
      })
    );
    const companyCapabilitesPK = [
      ...response.data?.expertise.map((item: any) => item.pk),
      ...response.data?.industry_expertise.map((item: any) => item.pk),
      ...response.data?.certificates.map((item: any) => item.pk),
    ];
    yield put(
      companyCapabilitiesActions.setCompanyCapabilities(companyCapabilitesPK)
    );
  }
}

function* handleUpdateCompanyProfileRequest({ payload }: PayloadAction<any>) {
  const { response, error } = yield safeCall(
    profileService.updateCompanyProfile,
    payload
  );

  if (error) {
    yield put(profileNextActions.updateCompanyProfileFailure(error));
    yield enqueueSnackbar(error, { variant: "error", autoHideDuration: 6000 });
  } else {
    yield put(profileNextActions.updateCompanyProfileSuccess(response.data));

    yield enqueueSnackbar(t("common:saved"));
  }
}

function* handleUpdateCompanyFileReferencesRequest({
  payload,
}: PayloadAction<UpdateCompanyFileReferencesInput>) {
  const results: GenericObject = yield all(
    payload.files?.map((file) =>
      safeCall(profileService.updateCompanyFileReferences, {
        file,
        type: payload.type,
      })
    )
  );

  const errorResults = results
    .map((result: any) => result?.error)
    .filter((item: any) => !!item);

  const successResults = results
    .map((result: any) => result?.response?.data)
    .filter((item: any) => !!item);

  if (errorResults?.length > 0) {
    yield put(
      profileNextActions.updateCompanyFileReferencesFailure(
        errorResults?.join(",")
      )
    );

    yield enqueueSnackbar(errorResults?.join(","), {
      variant: "error",
      autoHideDuration: 6000,
    });
  } else if (successResults?.length > 0) {
    yield put(
      profileNextActions.updateCompanyFileReferencesSuccess({
        data: successResults,
        type: payload.type,
      })
    );

    if (typeof payload.onSuccess === "function") payload.onSuccess();

    yield enqueueSnackbar(t("common:saved"));
  }
}

function* handleDeleteCompanyFileReferencesRequest({
  payload,
}: PayloadAction<DeleteCompanyFileReferencesInput>) {
  const { error } = yield safeCall(
    profileService.deleteCompanyFileReferences,
    payload
  );

  if (error) {
    yield put(profileNextActions.deleteCompanyFileReferencesFailure(error));
  } else {
    yield put(profileNextActions.deleteCompanyFileReferencesSuccess(payload));
    if (typeof payload.onSuccess === "function") payload.onSuccess();
    yield enqueueSnackbar(t("common:saved"));
  }
}

function* handleReorderCompanyFileReferencesRequest({
  payload,
}: PayloadAction<ReorderCompanyFileReferencesInput>) {
  const { error } = yield safeCall(
    profileService.reorderCompanyFileReferences,
    payload
  );

  if (error) {
    yield put(profileNextActions.reorderCompanyFileReferencesFailure(error));
  } else {
    yield put(profileNextActions.reorderCompanyFileReferencesSuccess());

    yield enqueueSnackbar(t("common:saved"));
  }
}

function* handleUpdateUserProfileRequest({ payload }: PayloadAction<any>) {
  const { response, error } = yield safeCall(
    profileService.updateUserProfile,
    payload
  );

  if (error) {
    yield put(profileNextActions.updateUserProfileFailure(error));
  } else {
    const user = response.data;

    yield put(profileNextActions.updateUserProfileSuccess(user));

    // TODO: Refactor this, since we are using the user state from the old structure, we have to do this for now.
    if (user) {
      yield put(
        profileActions.updateUserState({
          first_name: user.first_name,
          last_name: user.last_name,
          picture: user.picture,
        })
      );
    }

    yield enqueueSnackbar(t("profile:companyProfile:succesfullyUpdated"), {
      variant: "success",
      autoHideDuration: 6000,
    });
  }
}

function* handleUpdateUserPasswordRequest({
  payload,
}: PayloadAction<ChangePasswordFormValues>) {
  const { error } = yield safeCall(profileService.updateUserPassword, payload);

  if (error) {
    yield put(profileNextActions.updateUserPasswordFailure(error));
    yield genericErrorHandler(error);
  } else {
    yield put(profileNextActions.updateUserPasswordSuccess());

    yield enqueueSnackbar(t("profile:companyProfile:succesfullyUpdated"), {
      variant: "success",
      autoHideDuration: 6000,
    });

    history.push(frontendUrl.home);
  }
}

function* handleUpdateUserLanguageRequest({
  payload,
}: PayloadAction<UpdateUserLanguageInput>) {
  const { error } = yield safeCall(profileService.updateUserLanguage, payload);

  if (error) {
    yield put(profileNextActions.updateUserLanguageFailure(error));
  } else {
    yield put(
      profileActions.updateUserState({
        language: payload?.language,
      })
    );

    yield put(profileNextActions.updateUserLanguageSuccess());
  }
}

function* handleUpdate2FAAuthRequest({
  payload,
}: PayloadAction<Update2FAAuthInput>) {
  const { error, response } = yield safeCall(
    profileService.update2FAAuth,
    payload
  );

  if (error) {
    yield put(profileNextActions.fetchUserActive2FAsRequest());

    yield put(profileNextActions.update2FAAuthFailure(error));

    yield enqueueSnackbar(error?.toString(), {
      variant: "error",
      autoHideDuration: 6000,
    });
  } else {
    yield put(profileNextActions.update2FAAuthSuccess(response.data));

    if (!payload?.isConfirm) {
      yield put(
        modalsActions.showModal({
          key: ProfileModalTypes.USER_PROFILE_TWO_FACTOR_MANAGE_MODAL,
          data: {
            actionType: payload.actionType,
          },
        })
      );
    } else {
      yield put(profileNextActions.fetchUserActive2FAsRequest());

      if (payload.actionType === TwoFAActionType.DEACTIVATE) {
        yield put(
          modalsActions.closeModal(
            ProfileModalTypes.USER_PROFILE_TWO_FACTOR_MANAGE_MODAL
          )
        );
      }
    }
  }
}

function* handleFetchUserActive2FAsRequest() {
  const { response, error } = yield safeCall(
    profileService.fetchUserActive2FAs
  );

  if (error) {
    yield put(profileNextActions.fetchUserActive2FAsFailure(error));
  } else {
    yield put(profileNextActions.fetchUserActive2FAsSuccess(response.data));
  }
}

function* handleInviteCompanyUserRequest({
  payload,
}: PayloadAction<InviteCompanyUserInput>) {
  const { response, error } = yield safeCall(
    profileService.inviteCompanyUser,
    payload
  );
  if (error) {
    yield put(profileNextActions.inviteCompanyUserFailure(error));
    yield genericErrorHandler(error);
  } else {
    const invitedUsers = response.data.map((user: any) => ({
      ...user,
      invitation_status: "pending",
    }));
    yield put(profileNextActions.inviteCompanyUserSuccess(invitedUsers));
    yield put(profileActions.addUserInCompany(invitedUsers));
    if (typeof payload.onSuccess === "function")
      payload.onSuccess(invitedUsers);
  }
}

function* handleUpdateProfilePreferencesRequest({
  payload,
}: PayloadAction<UpdateProfilePreferencesInput>) {
  const { error } = yield safeCall(
    profileService.updateProfilePreferences,
    payload
  );

  if (error) {
    yield put(profileNextActions.updateProfilePreferencesFailure(error));
    yield enqueueSnackbar(t("common:error:general"), { variant: "error" });
  } else {
    yield put(profileNextActions.updateProfilePreferencesSuccess());
    yield enqueueSnackbar(t("publicPreferences:successMsg"));
  }
}

function* handleUploadCompanyDocumentsRequest({
  payload,
}: PayloadAction<UploadCompanyDocumentsInput>) {
  const { response, error } = yield safeCall(
    profileService.uploadCompanyFileDocuments,
    payload
  );

  if (error) {
    yield put(profileNextActions.uploadCompanyDocumentsFailure(error));
  } else {
    yield put(
      profileNextActions.uploadCompanyDocumentsSuccess(response?.data?.files)
    );
    if (typeof payload.onSuccess === "function") payload.onSuccess();
    yield enqueueSnackbar(t("common:saved"));

    const isFilesError = !!response.data.filesError;
    if (isFilesError) {
      yield enqueueSnackbar(response.data.filesError, {
        autoHideDuration: 5000,
        variant: "error",
      });
    }
  }
}

function* handleDeleteCompanyDocumentsRequest({
  payload,
}: PayloadAction<DeleteCompanyDocumentsInput>) {
  const { error } = yield safeCall(
    profileService.deleteCompanyFileReferences,
    payload
  );

  if (error) {
    yield put(profileNextActions.deleteCompanyDocumentsFailure(error));
  } else {
    yield put(
      profileNextActions.deleteCompanyDocumentsSuccess(payload?.fileId as any)
    );
    if (typeof payload.onSuccess === "function") payload.onSuccess();

    yield enqueueSnackbar(t("common:saved"));
  }
}

function* handleFetchPublicRequestRequest({ payload }: PayloadAction<number>) {
  const { response, error } = yield safeCall(
    marketplaceApi.getPublicRfqDetails,
    payload
  );

  if (error) {
    yield put(profileNextActions.fetchPublicRequestFailure(error));
  } else {
    yield put(profileNextActions.fetchPublicRequestSuccess(response));
  }
}

function* handleSendBuyerProfileMessageRequest({
  payload,
}: PayloadAction<SendBuyerProfileMessageInput>) {
  const { response, error } = yield safeCall(
    profileService.sendBuyerProfileMessage,
    payload
  );

  if (error) {
    yield put(profileNextActions.sendBuyerProfileMessageFailure(error));
    yield enqueueSnackbar(error, { variant: "error", autoHideDuration: 6000 });
  } else {
    yield put(
      modalsActions.closeModal(
        ProfileModalTypes.BUYER_PROFILE_SEND_MESSAGE_DRAWER
      )
    );
    yield put(profileNextActions.sendBuyerProfileMessageSuccess());
    yield put(
      dashboardActions.updateBuyerProfileConnection({
        buyerProfileId: payload.buyerProfileId,
        connection: response.data,
      })
    );
    yield put(
      dashboardActions.showPopUpOnboardingRequest(
        OnboardingSteps.SENDING_CONNECTION_REQUEST
      )
    );

    yield enqueueSnackbar(t("profile:buyerProfile:messageSent"), {
      autoHideDuration: 8000,
    });
  }
}

function* handleSendDiscoveryRequest({
  payload,
}: PayloadAction<DiscoveryRequestInput>) {
  const { response, error } = yield safeCall(
    profileService.sendDiscovery,
    payload
  );

  if (error) {
    yield put(profileNextActions.sendDiscoveryFailure(error));
    yield enqueueSnackbar(error, { variant: "error", autoHideDuration: 6000 });
  } else {
    yield put(
      modalsActions.closeModal(
        ProfileModalTypes.USER_PROFILE_SEND_DISCOVERY_DRAWER
      )
    );

    yield put(profileNextActions.sendDiscoverySuccess(response.data));

    yield enqueueSnackbar(t("discovery:successSnackbar"), {
      autoHideDuration: 8000,
    });
  }
}

function* handleFetchSupplierInvitations({
  payload,
}: PayloadAction<FetchSupplierInvitationsInput>) {
  const { response, error } = yield safeCall(
    profileService.fetchSupplierInvitations,
    payload
  );
  if (error) {
    yield put(profileNextActions.fetchSupplierInvitationsFailure(error));
  } else {
    yield put(
      profileNextActions.fetchSupplierInvitationsSuccess(response.data)
    );
  }
}

function* handleFetchInvitationDetails({
  payload,
}: PayloadAction<ConnectionInput>) {
  const { response, error } = yield safeCall(
    profileService.fetchInvitationDetails,
    payload
  );

  if (error) {
    yield put(profileNextActions.fetchInvitationDetailsFailure(error));
  } else {
    yield put(profileNextActions.fetchInvitationDetailsSuccess(response.data));
  }
}
function* handleApproveInvitation({
  payload,
}: PayloadAction<ConnectionInput & { onSuccess?: () => void }>) {
  const { error } = yield safeCall(profileService.approveInvitation, payload);

  if (error) {
    yield put(profileNextActions.approveInvitationFailure(error));
    yield enqueueSnackbar(error, { variant: "error", autoHideDuration: 6000 });
  } else {
    yield put(
      profileNextActions.approveInvitationSuccess({
        connectionId: payload.connectionId,
      })
    );
    yield put(profileActions.decrementCompanyStatsPendingConnectionsCount(1));
    yield put(
      modalsActions.closeModal(WorkspaceModalTypes.CONNECTION_REQUESTS_MODAL)
    );
    if (payload.onSuccess) payload.onSuccess();
    yield enqueueSnackbar(t("common:supplierAdded"));
  }
}
function* handleDeleteInvitation({ payload }: PayloadAction<ConnectionInput>) {
  const { error } = yield safeCall(profileService.deleteInvitation, payload);

  if (error) {
    yield put(profileNextActions.deleteInvitationFailure(error));
    yield enqueueSnackbar(error, { variant: "error", autoHideDuration: 6000 });
  } else {
    yield put(
      profileNextActions.deleteInvitationSuccess({
        connectionId: payload.connectionId,
      })
    );
    yield put(
      dashboardActions.updateBuyerProfileConnection({
        buyerProfileId: payload.buyerProfileId,
        connection: null,
      })
    );
    yield put(profileActions.decrementCompanyStatsPendingConnectionsCount(1));
    yield put(
      modalsActions.closeModal(WorkspaceModalTypes.CONNECTION_REQUESTS_MODAL)
    );
    yield enqueueSnackbar(t("common:succesfullyRemoved"));
  }
}

function* handleUpdateMemberTeamPermission({
  payload,
}: PayloadAction<UpdateMemberTeamPermissionInput>) {
  const { error } = yield safeCall(
    profileService.updateMemberTeamPermission,
    payload
  );

  if (error) {
    yield put(profileNextActions.updateMemberTeamPermissionFailure(error));
    yield enqueueSnackbar(error, { variant: "error", autoHideDuration: 6000 });
  } else {
    yield put(profileNextActions.updateMemberTeamPermissionSuccess(payload));
    if (typeof payload.onSuccess === "function") payload.onSuccess();
    yield enqueueSnackbar(t("common:saved"));
  }
}

function* handleResendMemberInvite({
  payload,
}: PayloadAction<ResendMemberInviteInput>) {
  const { error } = yield safeCall(profileService.resendMemberInvite, payload);
  if (error) {
    yield put(profileNextActions.resendMemberInviteFailure(error));
  } else {
    yield put(profileNextActions.resendMemberInviteSuccess());
    yield enqueueSnackbar(t("profile:members:invitationSent"));
  }
}

export function* profileSaga() {
  yield takeLatest(
    profileNextActions.fetchSubscriptionListRequest,
    handleFetchSubscriptionListRequest
  );

  yield takeLatest(
    profileNextActions.enableSubscriptionRequest,
    handleEnableSubscriptionRequest
  );

  yield takeLatest(
    profileNextActions.fetchCompanyProfileRequest,
    handleFetchCompanyProfileRequest
  );

  yield takeLatest(
    profileNextActions.updateCompanyProfileRequest,
    handleUpdateCompanyProfileRequest
  );

  yield takeLatest(
    profileNextActions.updateCompanyFileReferencesRequest,
    handleUpdateCompanyFileReferencesRequest
  );

  yield takeLatest(
    profileNextActions.deleteCompanyFileReferencesRequest,
    handleDeleteCompanyFileReferencesRequest
  );

  yield takeLatest(
    profileNextActions.reorderCompanyFileReferencesRequest,
    handleReorderCompanyFileReferencesRequest
  );

  yield takeLatest(
    profileNextActions.updateUserProfileRequest,
    handleUpdateUserProfileRequest
  );

  yield takeLatest(
    profileNextActions.updateUserPasswordRequest,
    handleUpdateUserPasswordRequest
  );

  yield takeLatest(
    profileNextActions.updateUserLanguageRequest,
    handleUpdateUserLanguageRequest
  );

  yield takeLatest(
    profileNextActions.update2FAAuthRequest,
    handleUpdate2FAAuthRequest
  );

  yield takeLatest(
    profileNextActions.fetchUserActive2FAsRequest,
    handleFetchUserActive2FAsRequest
  );

  yield takeLatest(
    profileNextActions.inviteCompanyUserRequest,
    handleInviteCompanyUserRequest
  );

  yield takeEvery(
    profileNextActions.updateProfilePreferencesRequest,
    handleUpdateProfilePreferencesRequest
  );

  yield takeLatest(
    profileNextActions.uploadCompanyDocumentsRequest,
    handleUploadCompanyDocumentsRequest
  );

  yield takeLatest(
    profileNextActions.deleteCompanyDocumentsRequest,
    handleDeleteCompanyDocumentsRequest
  );

  yield takeLatest(
    profileNextActions.fetchPublicRequestRequest,
    handleFetchPublicRequestRequest
  );

  yield takeLatest(
    profileNextActions.sendBuyerProfileMessageRequest,
    handleSendBuyerProfileMessageRequest
  );

  yield takeLatest(
    profileNextActions.sendDiscoveryRequest,
    handleSendDiscoveryRequest
  );

  yield takeLatest(
    profileNextActions.fetchSupplierInvitationsRequest,
    handleFetchSupplierInvitations
  );

  yield takeLatest(
    profileNextActions.fetchInvitationDetailsRequest,
    handleFetchInvitationDetails
  );

  yield takeLatest(
    profileNextActions.approveInvitationRequest,
    handleApproveInvitation
  );

  yield takeLatest(
    profileNextActions.deleteInvitationRequest,
    handleDeleteInvitation
  );

  yield takeLatest(
    profileNextActions.updateMemberTeamPermissionRequest,
    handleUpdateMemberTeamPermission
  );

  yield takeLatest(
    profileNextActions.resendMemberInviteRequest,
    handleResendMemberInvite
  );
}
