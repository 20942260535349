import { genericErrorHandler } from "@next/utils/apiUtils";
import { companyRatingsApi, companyRatingsConstants } from "./index";

import { enqueueSnackbar } from "notistack";

export const companyRatingsActions = {
  getCompanyRatingScores,
  clearCompanyRatingScores,
  sendRequestRating,
};

function getCompanyRatingScores(token, companyId, t) {
  return (dispatch) => {
    dispatch(request());
    companyRatingsApi
      .getCompanyRatingScores(token, companyId, t)
      .then((companyRatingsScores) => {
        dispatch(success(companyRatingsScores, companyId));
      })
      .catch((error) => {
        genericErrorHandler(JSON.stringify(error?.response?.data));
        dispatch(failure(error?.toString()));
      });
  };
  function request() {
    return { type: companyRatingsConstants.GET_COMPANY_RATINGS_SCORES_REQUEST };
  }

  function success(companyRatingsScores, companyId) {
    return {
      type: companyRatingsConstants.GET_COMPANY_RATINGS_SCORES_SUCCESS,
      companyRatingsScores,
      companyId,
    };
  }

  function failure(error) {
    return {
      type: companyRatingsConstants.GET_COMPANY_RATINGS_SCORES_FAILURE,
      error,
    };
  }
}

// clear company rating scores in the store
function clearCompanyRatingScores() {
  return (dispatch) => {
    dispatch({
      type: companyRatingsConstants.CLEAR_COMPANY_RATINGS_SCORES,
    });
  };
}

function sendRequestRating(pk, t) {
  return (dispatch, getState) => {
    dispatch(request());
    const token = getState().profile.token;
    companyRatingsApi.sendRequestRating(token, pk).then(
      () => {
        dispatch(success());
        enqueueSnackbar(t("ratingInContract:requestSent"), {
          variant: "success",
        });
      },
      (error) => {
        dispatch(failure(error?.toString()));
      }
    );
  };
  function request() {
    return { type: companyRatingsConstants.SEND_REQUEST_RATINGS_REQUEST };
  }

  function success() {
    return {
      type: companyRatingsConstants.SEND_REQUEST_RATINGS_SUCCESS,
    };
  }

  function failure(error) {
    return {
      type: companyRatingsConstants.SEND_REQUEST_RATINGS_FAILURE,
      error,
    };
  }
}
