import {
  Box,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  SvgIconProps,
} from "@mui/material";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import MenuIcon from "@mui/icons-material/Menu";
import { AppBarItemType } from "./app-bar-next-tabs";
import { AppBarNextItems } from "./app-bar-next-action-items/app-bar-next-action-items";
import {
  AppBarItemIconTextMap,
  AppBarItemKey,
} from "./app-bar-next.definitions";
import { getSelectedLanguage } from "@next/utils/browserUtils";

type Props = {
  appBarItems: AppBarItemType[];
};

const AppBarNextDrawerIcon: React.FC<{
  Icon?: React.ComponentType<SvgIconProps>;
}> = ({ Icon }) => {
  if (!Icon) return <></>;

  return Icon && <Icon />;
};

export const AppBarNextDrawer: React.FC<Props> = ({ appBarItems }) => {
  const [openDrawer, setOpenDrawer] = useState(false);
  const history = useHistory();

  const navigateTo = (appBarItem: AppBarItemType) => () => {
    if (appBarItem?.navigateWithHref) {
      const selectedLanguage = getSelectedLanguage();

      window.location.href = `/${selectedLanguage}/search`;
    } else {
      history.push(appBarItem?.path);
    }
  };

  return (
    <>
      <Drawer open={openDrawer} onClose={() => setOpenDrawer(false)}>
        <List>
          {appBarItems.map((appBarItem) => (
            <ListItem onClick={() => setOpenDrawer(false)} button>
              {appBarItem?.itemKey ? (
                <ListItemIcon>
                  <AppBarNextDrawerIcon
                    Icon={
                      AppBarItemIconTextMap[
                        appBarItem?.itemKey as AppBarItemKey
                      ]
                    }
                  />
                </ListItemIcon>
              ) : null}

              <ListItemText onClick={navigateTo(appBarItem)}>
                {appBarItem.label}
              </ListItemText>
            </ListItem>
          ))}
        </List>

        <Box flexGrow={1} display="flex" alignItems="flex-end">
          <AppBarNextItems inDrawer />
        </Box>
      </Drawer>

      <IconButton onClick={() => setOpenDrawer(!openDrawer)} size="large">
        <MenuIcon />
      </IconButton>
    </>
  );
};
