import { backendUrl } from "../../urls";
// Helper
import { handleResponse } from "../../helpers/responseHandling";
import { axiosApi } from "@next/api";

export const companyRatingsApi = {
  getCompanyRatingScores,
  sendRequestRating,
};

function getCompanyRatingScores(token, companyId) {
  const url = `${backendUrl.companyRatings}${companyId}/`;
  return axiosApi.get(url).then(handleResponse);
}

function sendRequestRating(token, pk) {
  const url = `${backendUrl.requestRating}${pk}/`;
  return axiosApi.post(url).then(handleResponse);
}
