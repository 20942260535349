import {
  call,
  delay,
  put,
  race,
  select,
  take,
  takeEvery,
  takeLatest,
} from "redux-saga/effects";
import { PayloadAction } from "@reduxjs/toolkit";

import {
  ClonePartInput,
  OpenRfqDrawerFromProject,
  PoFileInput,
  ProjectBulkInsertPartInput,
  ProjectCodes,
  ProjectCompanyOrderInput,
  ProjectCompanyPart,
  ProjectCreatePartInput,
  ProjectStatsInput,
  ProjectStatsType,
  ProjectUpdatePartInput,
  UpdateRfqInput,
} from "./types";

import { projectService } from "@next/modules/project/services/projectService";
import { projectActions } from "./slices";
import { t } from "assets/configi18n/i18n";
import { history } from "helpers/history";
import {
  selectBomFilesUploadReport,
  selectBulkPartImportReport,
  selectProjectCompanyOrdersLastQuery,
  selectProjectCompanyPartsLastQuery,
  selectProjectCompanyPartsSearchValue,
  selectProjectCompanyPartsSelectAll,
  selectProjectStats,
  selectSelectedPartRows,
} from "./selectors";
import { safeCall } from "@next/redux/safeCall";
import {
  FetchBomFilesUploadReportInput,
  FetchBomPartsUploadReportInput,
  ProjectBulkDeletePartInput,
  ProjectCreateInput,
  ProjectDeleteInput,
  ProjectExportMultiContractRFQExcelInput,
  ProjectFetchInput,
  ProjectUpdateInput,
  RFQStatus,
  UpdateProjectTemplateInput,
  UploadBomFilesInput,
  UploadReportStatus,
} from ".";
import { downloadFileNext } from "@next/utils/fileUtils";
import { modalsActions } from "@next/redux/modalsSlices";
import { ProjectModalTypes } from "../modals/types";
import { WorkspaceModalTypes } from "@next/modules/workspace/modals/types";
import { UserRatingActionTypes } from "services/rating/rating.constants";
import { workspaceActions } from "services/workspace";
import { enqueueSnackbar } from "notistack";
import { createRedirectTabUrl } from "@next/utils/browserUtils";
import { EBuyerRfqsTabType } from "@next/modules/workspace/pages/buyer-rfqs/buyer-rfqs";
import { workspaceConstants } from "services/workspace";
import { saveAs } from "file-saver";

function* handleFetchProjectStatsRequest({
  payload,
}: PayloadAction<ProjectStatsInput>) {
  const { response, error } = yield safeCall(
    projectService.getProjectStats,
    payload
  );

  if (error) {
    if (error === "Bad request No Project matches the given query.") {
      history.push("/projects/not-found");
    }

    if (error === "You must be a Buyer to perform this operation") {
      history.push("/");
      yield enqueueSnackbar(error, {
        variant: "error",
        autoHideDuration: 6000,
      });
    }

    yield put(projectActions.fetchProjectStatsFailure(error));
  } else {
    yield put(projectActions.fetchProjectStatsSuccess(response.data));

    if (payload?.onSuccess && typeof payload?.onSuccess === "function") {
      payload.onSuccess(response.data);
    }
  }
}

function* handleFetchProjectCompanyPartsRequest({
  payload,
}: PayloadAction<ProjectStatsInput>) {
  const { response, error } = yield safeCall(
    projectService.getCompanyParts,
    payload
  );

  if (error) {
    yield put(projectActions.fetchProjectCompanyPartsFailure(error));
  } else {
    yield put(projectActions.fetchProjectCompanyPartsSuccess(response.data));
  }
}

function* handleFetchProjectCompanyOrdersRequest({
  payload,
}: PayloadAction<ProjectCompanyOrderInput>) {
  const { response, error } = yield safeCall(
    projectService.getCompanyOrders,
    payload
  );

  if (error) {
    yield put(projectActions.fetchProjectCompanyOrdersFailure(error));
  } else {
    yield put(projectActions.fetchProjectCompanyOrdersSuccess(response.data));
  }
}

function* handleCreatePartRequest({
  payload,
}: PayloadAction<ProjectCreatePartInput>) {
  const { response, error } = yield safeCall(
    projectService.createProjectPart,
    payload
  );

  if (error) {
    yield put(projectActions.createProjectPartFailure(error));
  } else {
    yield put(projectActions.createProjectPartSuccess(response.data));

    if (!payload?.skipRefetchProjectStats) {
      yield refetchProjectStats();
    }
    if (payload.skipSuccessToast) return;
    yield enqueueSnackbar(
      `${t("workspace:yourPartHasBeenSuccessfullyCreated")}`,
      { variant: "success", autoHideDuration: 6000 }
    );
  }
}

function* handleUpdatePartRequest({
  payload,
}: PayloadAction<ProjectUpdatePartInput>) {
  const { response, error } = yield safeCall(
    projectService.updateProjectPart,
    payload
  );
  if (error) {
    yield put(projectActions.updateProjectPartFailure(error));
  } else {
    yield put(projectActions.updateProjectPartSuccess(response.data));
    if (!payload.skipSuccessToast) {
      yield enqueueSnackbar(
        `${t("workspace:yourPartHasBeenSuccessfullyUpdated")}`,
        {
          variant: "success",
          autoHideDuration: 6000,
        }
      );
    }

    if (payload.onSuccess && typeof payload.onSuccess === "function") {
      payload.onSuccess(response.data);
    }
  }
}

function* handleBulkInsertPartRequest({
  payload,
}: PayloadAction<ProjectBulkInsertPartInput>) {
  yield put(
    modalsActions.showModal({
      key: ProjectModalTypes.BULK_PART_IMPORT_LOADER_MODAL,
    })
  );

  const projectStats: ProjectStatsType = yield select(selectProjectStats);

  const { response, error } = yield safeCall(
    projectService.bulkInsertPartProject,
    {
      ...payload,
      code: projectStats?.code,
    }
  );

  if (error) {
    yield put(projectActions.bulkInsertProjectPartFailure(error));

    yield enqueueSnackbar(error, {
      variant: "error",
      autoHideDuration: 6000,
    });
  } else {
    if (response?.data?.report_uuid) {
      // Start polling for the status of the uploaded parts
      yield safeCall(uploadBulkPartImportStatusWatchWorker, {
        payload: {
          projectId: projectStats?.code,
          uuid: response?.data?.report_uuid,
        },
      });
    }

    yield put(projectActions.bulkInsertProjectPartSuccess([]));

    yield enqueueSnackbar(t("project:bulkPartImportLoader:importSuccess"), {
      variant: "success",
      autoHideDuration: 6000,
    });
  }
}

export function* uploadBulkPartImportStatusWatchWorker(
  action: PayloadAction<FetchBomPartsUploadReportInput>
) {
  yield put(projectActions.resetPolling());

  yield race({
    task: safeCall(uploadBulkPartImportPollingWorker, action),
    cancel: take(projectActions.resetPolling().type),
  });
}

export function* uploadBulkPartImportPollingWorker({
  payload,
}: PayloadAction<FetchBomPartsUploadReportInput>) {
  while (true) {
    try {
      yield put(projectActions.fetchBulkPartImportReportRequest(payload));

      yield take(projectActions.fetchBulkPartImportReportSuccess({}).type);
      const reportData = (yield select(
        selectBulkPartImportReport
      )) as ReturnType<typeof selectBulkPartImportReport>;

      const status = reportData?.status;

      if (status === UploadReportStatus.IN_PROGRESS) {
        // Wait for 1.5 seconds before polling again
        yield delay(1500);
      } else {
        yield put(
          modalsActions.closeModal(
            ProjectModalTypes.BULK_PART_IMPORT_LOADER_MODAL
          )
        );
        yield put(projectActions.resetBulkPartImportReport());
        yield put(projectActions.resetPolling());
      }
    } catch (error) {
      console.error(error);
      yield put(projectActions.resetPolling());
    }
  }
}

function* handleDeletePartRequest({ payload }: PayloadAction<any>) {
  const { error } = yield safeCall(projectService.deleteProjectPart, payload);

  if (error) {
    yield put(projectActions.deleteProjectPartFailure(error));
  } else {
    yield put(projectActions.resetSelectedPartRows());

    yield put(projectActions.deleteProjectPartSuccess(payload));

    yield enqueueSnackbar(
      `${t("workspace:yourPartHasBeenSuccessfullyDeleted")}`,
      {
        variant: "success",
        autoHideDuration: 6000,
      }
    );
  }
}

function* handleBulkDeletePartRequest({
  payload,
}: PayloadAction<ProjectBulkDeletePartInput>) {
  const { error } = yield safeCall(
    projectService.bulkDeleteProjectPart,
    payload
  );

  if (error) {
    yield put(projectActions.bulkDeleteProjectPartFailure(error));
  } else {
    yield put(projectActions.resetSelectedPartRows());

    yield put(projectActions.bulkDeleteProjectPartSuccess());

    if (payload.skipSuccessToast) return;
    yield enqueueSnackbar(
      `${t("workspace:yourPartHasBeenSuccessfullyDeleted")}`,
      {
        variant: "success",
        autoHideDuration: 6000,
      }
    );
  }
}

function* handleClonePartRequest({ payload }: PayloadAction<ClonePartInput>) {
  const { response, error } = yield safeCall(
    projectService.cloneProjectPart,
    payload
  );

  if (error) {
    yield put(projectActions.cloneProjectPartFailure(error));
  } else {
    yield put(projectActions.cloneProjectPartSuccess(response.data));

    if (payload?.skipSuccessToast) return;
    yield enqueueSnackbar(
      `${t("workspace:yourPartHasBeenSuccessfullyDuplicated")}`,
      {
        variant: "success",
        autoHideDuration: 6000,
      }
    );
  }
}

function* handleCreateRFQRequest({ payload }: PayloadAction<any>) {
  const { response, error } = yield safeCall(projectService.createRFQ, payload);

  if (error) {
    yield put(projectActions.createRFQFailure(error));
  } else {
    yield put(projectActions.createRFQSuccess(response.data));

    yield enqueueSnackbar(
      t("project:success:yourRFQHasBeenSuccessfullyCreated"),
      {
        variant: "success",
        autoHideDuration: 6000,
      }
    );
  }
}

function* handleUpdateRFQRequest({ payload }: PayloadAction<UpdateRfqInput>) {
  const { response, error } = yield safeCall(projectService.updateRFQ, payload);

  if (error) {
    yield put(projectActions.updateRFQFailure(error));
    yield enqueueSnackbar(t("workspaceNext:rfq:error:update"), {
      variant: "error",
      autoHideDuration: 6000,
    });
  } else {
    if (payload?.refetchData) {
      yield refetchCompanyOrders();
    }

    if (typeof payload?.onSuccess === "function")
      payload.onSuccess(response.data);

    yield put(projectActions.updateRFQSuccess(response.data));

    const callSetRfqDetails: ReturnType<typeof workspaceActions.setRFQDetails> =
      yield call(workspaceActions.setRFQDetails, response.data);

    yield put(callSetRfqDetails);

    const onHoldOrActive =
      payload.status === RFQStatus.ON_HOLD ||
      payload.status === RFQStatus.ACTIVE;

    if (onHoldOrActive) {
      yield enqueueSnackbar(
        payload.status === RFQStatus.ON_HOLD
          ? t("workspaceNext:buyerRfqs:rfqMovedToOnHoldTab")
          : t("workspaceNext:buyerRfqs:rfqMovedToActiveTab"),
        {
          buttonRedirectTo: createRedirectTabUrl(
            payload.status === RFQStatus.ON_HOLD
              ? EBuyerRfqsTabType.ON_HOLD
              : EBuyerRfqsTabType.ON_GOING
          ),
          buttonText: payload.isDetailPage
            ? undefined
            : (t("workspaceNext:buyerRfqs:goToTab") as string),
          persist: !payload.isDetailPage,
        }
      );
    } else if (payload.isContract) {
      yield enqueueSnackbar(
        payload.is_contract_completed
          ? t("workspaceNext:buyerRfqs:rfqMovedToCompletedTab")
          : t("workspaceNext:buyerRfqs:rfqMovedToAwardedTab"),
        {
          buttonRedirectTo: createRedirectTabUrl(
            payload.is_contract_completed
              ? EBuyerRfqsTabType.COMPLETED
              : EBuyerRfqsTabType.AWARDED
          ),
          buttonText: t("workspaceNext:buyerRfqs:goToTab") as string,
          persist: true,
        }
      );
    } else {
      yield enqueueSnackbar(
        payload.rfq_cancellation_reason_pk
          ? t("workspace:yourRFQHasBeenCanceled")
          : payload.extension_date
          ? t("workspace:yourRFQHasBeenExtended")
          : payload.targeted_suppliers
          ? t("workspaceNext:addSuppliersModal:successMessage", {
              count: payload.targeted_suppliers.length,
            })
          : t("workspace:yourRFQHasBeenUpdated"),
        {
          variant: "success",
          autoHideDuration: 6000,
        }
      );
    }
  }
}

export function* refetchCompanyOrders() {
  const projectCompanyOrdersLastQuery: string = yield select(
    selectProjectCompanyOrdersLastQuery
  );

  yield put(
    projectActions.fetchProjectCompanyOrdersRequest({
      query: projectCompanyOrdersLastQuery,
    })
  );
}

function* refetchCompanyParts() {
  yield delay(1000);

  const projectCompanyPartsLastQuery: string = yield select(
    selectProjectCompanyPartsLastQuery
  );
  const projectStats: ProjectStatsType = yield select(selectProjectStats);

  yield put(
    projectActions.fetchProjectCompanyPartsRequest({
      query: projectCompanyPartsLastQuery,
      projectCode: projectStats?.code,
    })
  );
}

function* refetchProjectStats() {
  const projectStats: ProjectStatsType = yield select(selectProjectStats);

  if (projectStats?.code) {
    yield put(
      projectActions.fetchProjectStatsRequest({
        projectCode: projectStats?.code,
        clearExisting: false,
      })
    );
  }
}

function* handleAttachPurchaseOrderRequest({
  payload,
}: PayloadAction<PoFileInput>) {
  const { response, error } = yield safeCall(
    projectService.attachPurchaseOrder,
    payload
  );

  if (error) {
    yield put(projectActions.attachPurchaseOrderFailure(error));
  } else {
    yield put(projectActions.attachPurchaseOrderSuccess(response.data));
    yield enqueueSnackbar(t("workspace:uploadedPurchaseOrder"), {
      variant: "success",
      autoHideDuration: 6000,
    });
  }
}

function* handleDownloadAxyaServtechRequest({
  payload,
}: PayloadAction<{ rfqId: string }>) {
  const { rfqId } = payload;

  const { response, error } = yield safeCall(
    projectService.downloadAxyaServtech,
    { rfqId }
  );

  if (error || !(response.data instanceof Blob)) {
    yield put(projectActions.downloadAxyaServtechFailure(error || rfqId));
    yield enqueueSnackbar(
      `${t("rfq:info:noServtechForThisRFQ", {
        rfqId: rfqId,
      })}`,
      {
        variant: "info",
        autoHideDuration: 6000,
      }
    );
  } else {
    let filename = response.headers["x-filename"];
    if (!filename) {
      try {
        const contentType = response.headers["content-disposition"];
        filename = contentType.split("filename=")[1].replace(".csv", "");
      } catch (e) {
        filename = `SERVTECH_${rfqId}`;
      }
    }
    downloadFileNext(response.data, filename);
  }
}

function* handleDownloadQuotationFileRequest({
  payload,
}: PayloadAction<ProjectCodes>) {
  const { projectCodes } = payload;
  const codesAsString = projectCodes.join(",");

  const { response, error } = yield safeCall(
    projectService.downloadWinnerQuotationFile,
    { projectCodes }
  );

  if (error) {
    yield enqueueSnackbar(error, {
      variant: "error",
      autoHideDuration: 6000,
    });
    yield put(projectActions.downloadQuotationFileFailure(new Error(error)));
  } else {
    const blob = new Blob([response.data], { type: response.data.type });
    const filename = `${codesAsString}.xlsx`;

    saveAs(blob, filename);
  }
}

function* handleFetchProjectsRequest({
  payload,
}: PayloadAction<ProjectFetchInput>) {
  const { response, error } = yield safeCall(
    projectService.getProjects,
    payload
  );

  if (error) {
    yield put(projectActions.fetchProjectsFailure(error));
  } else {
    yield put(projectActions.fetchProjectsSuccess(response.data));
  }
}

function* handleCreateProjectRequest({
  payload,
}: PayloadAction<ProjectCreateInput>) {
  const { response, error } = yield safeCall(
    projectService.createProject,
    payload
  );

  if (error) {
    yield put(projectActions.createProjectFailure(error));
    yield enqueueSnackbar(error, {
      variant: "error",
      autoHideDuration: 6000,
    });
  } else {
    yield put(projectActions.createProjectSuccess(response.data));

    yield put(modalsActions.closeModal(ProjectModalTypes.CREATE_RENAME_MODAL));

    yield enqueueSnackbar(
      `${t("project:success:yourProjectHasBeenSuccessfullyCreated")}`,
      {
        variant: "success",
        autoHideDuration: 3000,
      }
    );

    if (typeof payload.onSuccess === "function") {
      payload.onSuccess(response.data);
    }
  }
}

function* handleUpdateProjectRequest({
  payload,
}: PayloadAction<ProjectUpdateInput>) {
  const { response, error } = yield safeCall(
    projectService.updateProject,
    payload
  );

  if (error) {
    yield put(projectActions.updateProjectFailure(error));
    yield enqueueSnackbar(error, {
      variant: "error",
      autoHideDuration: 6000,
    });
  } else {
    yield put(projectActions.updateProjectSuccess(response.data));

    yield put(modalsActions.closeModal(ProjectModalTypes.CREATE_RENAME_MODAL));

    yield enqueueSnackbar(
      `${t("project:success:yourProjectHasBeenSuccessfullyUpdated")}`,
      { variant: "success", autoHideDuration: 6000 }
    );
  }
}

function* handleDeleteProjectRequest({
  payload,
}: PayloadAction<ProjectDeleteInput>) {
  const { error } = yield safeCall(projectService.deleteProject, payload);

  if (error) {
    yield put(projectActions.deleteProjectFailure(error));
    yield enqueueSnackbar(error, {
      variant: "error",
      autoHideDuration: 6000,
    });
  } else {
    yield put(projectActions.deleteProjectSuccess(payload?.code));

    yield enqueueSnackbar(
      `${t("project:success:yourProjectHasBeenSuccessfullyDeleted")}`,
      { variant: "success", autoHideDuration: 6000 }
    );
  }
}

function* handleCreateMultiContractRFQRequest({ payload }: PayloadAction<any>) {
  const { response, error } = yield safeCall(
    projectService.createMultiContractRFQ,
    payload
  );

  if (error) {
    yield put(projectActions.createMultiContractRFQFailure(error));
    yield enqueueSnackbar(error, {
      variant: "error",
      autoHideDuration: 6000,
    });
  } else {
    yield put(projectActions.createMultiContractRFQSuccess(response.data));

    if (typeof payload.onSuccess === "function") {
      payload.onSuccess();

      yield put(
        modalsActions.closeModal(ProjectModalTypes.CREATE_MULTI_CONTRACT_MODAL)
      );

      yield put(modalsActions.closeModal(ProjectModalTypes.RFQ_DETAILS_MODAL));
    }

    yield enqueueSnackbar(t("project:success:splitRFQCreated"), {
      variant: "success",
      autoHideDuration: 6000,
    });
  }
}

function* handleExportMultiContractRFQExcelRequest({
  payload,
}: PayloadAction<ProjectExportMultiContractRFQExcelInput>) {
  const { response, error } = yield safeCall(
    projectService.exportMultiContractRFQExcel,
    payload
  );

  if (error) {
    yield put(projectActions.exportMultiContractRFQExcelFailure(error));
    yield enqueueSnackbar(error, {
      variant: "error",
      autoHideDuration: 6000,
    });
  } else {
    if (response.data instanceof Blob) {
      downloadFileNext(
        response.data,
        t("project:multiContract:decisionSheet", { rfqName: payload?.rfq_name })
      );
      yield put(
        projectActions.exportMultiContractRFQExcelSuccess(response.data)
      );
    } else {
      yield put(projectActions.exportMultiContractRFQExcelFailure(error));
    }
  }
}

function* handleImportMultiContractRFQExcelRequest({
  payload,
}: PayloadAction<any>) {
  const { response, error } = yield safeCall(
    projectService.importMultiContractRFQExcel,
    payload
  );

  if (error) {
    yield put(projectActions.importMultiContractRFQExcelFailure(error));
    yield enqueueSnackbar(error, {
      variant: "error",
      autoHideDuration: 6000,
    });
  } else {
    yield put(projectActions.importMultiContractRFQExcelSuccess(response.data));
  }
}

function* handleOpenRFQDrawer({
  payload,
}: PayloadAction<OpenRfqDrawerFromProject>) {
  const parts: ProjectCompanyPart[] = yield select(selectSelectedPartRows);
  const selectAll = yield select(selectProjectCompanyPartsSelectAll);
  const partSearch = yield select(selectProjectCompanyPartsSearchValue);
  const projectStats = yield select(selectProjectStats);
  let selectedParts;
  if (payload.selectedParts === projectStats?.total_parts_count) {
    selectedParts = payload.selectedParts - projectStats?.parts_in_rfq_count;
  } else {
    selectedParts = payload.selectedParts;
  }

  yield put(
    modalsActions.showModal({
      key: WorkspaceModalTypes.RFQ_CREATION_DRAWER,
      data: {
        form: { parts: parts || [] },
        projectCode: payload.projectCode,
        selectAllParts: selectAll,
        partSearch,
        selectedPartsCount: selectedParts,
      },
    })
  );
}

function* handleUploadBomFilesRequest({
  payload,
}: PayloadAction<UploadBomFilesInput>) {
  const { response, error } = yield safeCall(
    projectService.uploadBomFiles,
    payload
  );

  if (error) {
    yield put(projectActions.uploadBomFilesFailure(error));
    yield enqueueSnackbar(error, {
      variant: "error",
      autoHideDuration: 6000,
    });
  } else {
    yield put(projectActions.uploadBomFilesSuccess(payload));

    if (response?.data?.report_uuid) {
      // Start polling for the status of the uploaded files
      yield safeCall(uploadBomFileStatusWatchWorker, {
        payload: {
          projectId: payload?.projectId,
          uuid: response?.data?.report_uuid,
        },
      });
    }
  }
}

export function* uploadBomFileStatusWatchWorker(
  action: PayloadAction<FetchBomFilesUploadReportInput>
) {
  yield put(projectActions.resetPolling());

  yield race({
    task: safeCall(uploadBomFileStatusPollingWorker, action),
    cancel: take(projectActions.resetPolling().type),
  });
}

export function* uploadBomFileStatusPollingWorker({
  payload,
}: PayloadAction<FetchBomFilesUploadReportInput>) {
  while (true) {
    try {
      yield put(projectActions.fetchBomFilesUploadReportRequest(payload));

      yield take(projectActions.fetchBomFilesUploadReportSuccess({}).type);
      const reportData = (yield select(
        selectBomFilesUploadReport
      )) as ReturnType<typeof selectBomFilesUploadReport>;

      const status = reportData?.status;

      if (status === "IN_PROGRESS") {
        // Wait for 1.5 seconds before polling again
        yield delay(1500);
      } else {
        yield put(projectActions.resetPolling());
      }
    } catch (error) {
      console.error(error);
      yield put(projectActions.resetPolling());
    }
  }
}

function* handleFetchBomFilesReportRequest({
  payload,
}: PayloadAction<FetchBomFilesUploadReportInput>) {
  const { response, error } = yield safeCall(
    projectService.fetchBomFilesUploadReport,
    payload
  );

  if (error) {
    yield put(projectActions.fetchBomFilesUploadReportFailure(error));
    yield enqueueSnackbar(error, {
      variant: "error",
      autoHideDuration: 6000,
    });
  } else {
    yield put(projectActions.fetchBomFilesUploadReportSuccess(response.data));
  }
}

function* handleFetchBulkPartImportReportRequest({
  payload,
}: PayloadAction<FetchBomFilesUploadReportInput>) {
  const { response, error } = yield safeCall(
    projectService.fetchBulkPartUploadReport,
    payload
  );

  if (error) {
    yield put(projectActions.fetchBulkPartImportReportFailure(error));
    yield enqueueSnackbar(error, {
      variant: "error",
      autoHideDuration: 6000,
    });
  } else {
    yield put(projectActions.fetchBulkPartImportReportSuccess(response.data));
  }
}

function* handleFetchProjectTemplatesRequest() {
  const { response, error } = yield safeCall(
    projectService.fetchProjectTemplates
  );

  if (error) {
    yield put(projectActions.fetchProjectTemplatesFailure(error));
    yield enqueueSnackbar(error, {
      variant: "error",
      autoHideDuration: 6000,
    });
  } else {
    yield put(projectActions.fetchProjectTemplatesSuccess(response.data));
  }
}

function* handleUpdateProjectTemplateRequest({
  payload,
}: PayloadAction<UpdateProjectTemplateInput>) {
  const { response, error } = yield safeCall(
    projectService.updateProjectTemplate,
    payload
  );

  if (error) {
    yield put(projectActions.updateProjectTemplateFailure(error));
    yield enqueueSnackbar(error, {
      variant: "error",
      autoHideDuration: 6000,
    });
  } else {
    yield put(projectActions.updateProjectTemplateSuccess(response.data));
  }
}

export function* projectSaga() {
  yield takeLatest(
    projectActions.fetchProjectStatsRequest,
    handleFetchProjectStatsRequest
  );

  yield takeLatest(
    [
      projectActions.deleteProjectPartSuccess,
      projectActions.cloneProjectPartSuccess,
      projectActions.createRFQSuccess,
      projectActions.bulkInsertProjectPartFailure,
      projectActions.bulkInsertProjectPartSuccess,
      projectActions.updateProjectPartSuccess,
      workspaceConstants.RFQ_SUCCESS,
      projectActions.bulkDeleteProjectPartSuccess,
    ],
    refetchProjectStats
  );

  yield takeLatest(
    [
      projectActions.attachPurchaseOrderSuccess,
      projectActions.createMultiContractRFQSuccess,
      UserRatingActionTypes.SEND_USER_RATING_SUCCESS,
    ],
    refetchCompanyOrders
  );

  yield takeLatest(
    [
      projectActions.uploadBomFilesSuccess,
      projectActions.bulkInsertProjectPartSuccess,
      projectActions.bulkDeleteProjectPartSuccess,
    ],
    refetchCompanyParts
  );

  yield takeLatest(
    projectActions.fetchProjectCompanyPartsRequest,
    handleFetchProjectCompanyPartsRequest
  );
  yield takeLatest(
    projectActions.fetchProjectCompanyOrdersRequest,
    handleFetchProjectCompanyOrdersRequest
  );
  yield takeEvery(
    projectActions.createProjectPartRequest,
    handleCreatePartRequest
  );
  yield takeLatest(
    projectActions.updateProjectPartRequest,
    handleUpdatePartRequest
  );
  yield takeLatest(
    projectActions.bulkInsertProjectPartRequest,
    handleBulkInsertPartRequest
  );
  yield takeLatest(
    projectActions.deleteProjectPartRequest,
    handleDeletePartRequest
  );
  yield takeLatest(
    projectActions.bulkDeleteProjectPartRequest,
    handleBulkDeletePartRequest
  );
  yield takeLatest(
    projectActions.cloneProjectPartRequest,
    handleClonePartRequest
  );

  yield takeLatest(projectActions.createRFQRequest, handleCreateRFQRequest);
  yield takeLatest(projectActions.updateRFQRequest, handleUpdateRFQRequest);

  yield takeLatest(
    projectActions.attachPurchaseOrderRequest,
    handleAttachPurchaseOrderRequest
  );

  yield takeEvery(
    projectActions.downloadAxyaServtechRequest,
    handleDownloadAxyaServtechRequest
  );
  yield takeEvery(
    projectActions.downloadQuotationFileRequest,
    handleDownloadQuotationFileRequest
  );

  yield takeLatest(
    projectActions.fetchProjectsRequest,
    handleFetchProjectsRequest
  );

  yield takeLatest(
    projectActions.createProjectRequest,
    handleCreateProjectRequest
  );

  yield takeLatest(
    projectActions.updateProjectRequest,
    handleUpdateProjectRequest
  );

  yield takeLatest(
    projectActions.deleteProjectRequest,
    handleDeleteProjectRequest
  );

  yield takeLatest(
    projectActions.createMultiContractRFQRequest,
    handleCreateMultiContractRFQRequest
  );

  yield takeLatest(
    projectActions.exportMultiContractRFQExcelRequest,
    handleExportMultiContractRFQExcelRequest
  );

  yield takeLatest(
    projectActions.importMultiContractRFQExcelRequest,
    handleImportMultiContractRFQExcelRequest
  );

  yield takeLatest(projectActions.openRFQDrawer, handleOpenRFQDrawer);

  yield takeLatest(
    projectActions.uploadBomFilesRequest,
    handleUploadBomFilesRequest
  );

  yield takeLatest(
    projectActions.fetchBomFilesUploadReportRequest,
    handleFetchBomFilesReportRequest
  );

  yield takeLatest(
    projectActions.fetchBulkPartImportReportRequest,
    handleFetchBulkPartImportReportRequest
  );

  yield takeLatest(
    projectActions.fetchProjectTemplatesRequest,
    handleFetchProjectTemplatesRequest
  );

  yield takeLatest(
    projectActions.updateProjectTemplateRequest,
    handleUpdateProjectTemplateRequest
  );
}
