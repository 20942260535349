import {
  GridRenderCellParams,
  GridValueGetterParams,
} from "@mui/x-data-grid-pro";
import { workspaceNextActions } from "@next/modules/workspace/redux";
import { t } from "assets/configi18n/i18n";
import React from "react";
import { store } from "store";
import CustomButton from "../custom-button";

const BRPExportCell: React.FC<GridRenderCellParams> = (
  params: GridValueGetterParams
) => {
  const rfqId = params.row?.pk?.toString();
  const rfqDisplayName = params.row?.rfq_display_name;
  const onClick = () => {
    store.dispatch(
      workspaceNextActions.exportBRPFilesRequest({
        rfqId,
        rfqDisplayName,
        quoteId: params.row?.quotations?.id,
      })
    );
  };

  //prevent row click if this cell is clicked
  const onSpanClick = (e: React.SyntheticEvent) => {
    e.stopPropagation();
  };

  return (
    <span onClick={onSpanClick}>
      <CustomButton size="small" variant="outlined" onClick={onClick}>
        {t("project:table:row:export")}
      </CustomButton>
    </span>
  );
};

export default BRPExportCell;
