import React from "react";
import {
  GridRenderCellParams,
  GridValueGetterParams,
} from "@mui/x-data-grid-pro";
import CollaboratorsList from "components/workspace/buyer-rfqs-view/collaborators-list";

const UsersCell: React.FC<GridRenderCellParams> = (
  params: GridValueGetterParams
) => {
  return (
    <CollaboratorsList
      data={{
        collaborators: params.row.collaborators,
        rfqPk: params.row.pk,
        createdBy: params.row.created_by,
      }}
      size="small"
    />
  );
};

export default UsersCell;
