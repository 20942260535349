import amplitude from "amplitude-js";

// Initialize Amplitude
export const initAmplitude = () => {
  if (process.env.REACT_APP_AMPLITUDE_API_KEY) {
    amplitude.getInstance().init(process.env.REACT_APP_AMPLITUDE_API_KEY || "");
  }
};

// Set Amplitude User ID
export const setAmplitudeUserId = (userId: string) => {
  amplitude.getInstance().setUserId(userId);
};

// Send Amplitude Data
export const sendAmplitudeData = (event: AmplitudeEvent, data: any) => {
  amplitude.getInstance().logEvent(event, data);
};

// Defined Amplitude Events
export enum AmplitudeEvent {
  LOGIN = "LOGIN",
  LOGOUT = "LOGOUT",
}
