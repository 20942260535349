import { IconButton, SvgIcon, Tooltip } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { SgvPathIcons } from "assets/svg-path-icons";
import { useAxyaTracking } from "@next/hooks/useAxyaTracking";
import { modalsActions } from "@next/redux/modalsSlices";
import { WorkspaceModalTypes } from "@next/modules/workspace/modals/types";
import { createStyles, makeStyles } from "@mui/styles";

const useStyles = makeStyles(() =>
  createStyles({
    feedbackIcon: {
      position: "relative",
      top: "3px",
      color: "black",
    },
  })
);

export const FeedbackActionItem = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const axyaTracking = useAxyaTracking();
  const classes = useStyles();

  const onClickOpenGiveYouFeedbackModal = () => {
    dispatch(
      modalsActions.showModal({
        key: WorkspaceModalTypes.CONTACT_US_MODAL,
        data: {
          modalTitle: t("contactUs:giveYouFeedback"),
          hasFormTitle: true,
          formTitle: t("contactUs:giveYouFeedbackFormTitle"),
          label: t("contactUs:giveYouFeedbackInputLabel"),
        },
      })
    );
    axyaTracking({
      scout_category: "contact-us",
      scout_feature_name: "open-give-you-feedback-modal-from-app-bar",
    });
  };

  return (
    <Tooltip title={t("appTour:giveYourFeedback")}>
      <IconButton
        data-tut="reactour__help"
        onClick={onClickOpenGiveYouFeedbackModal}
        size="large"
      >
        <SvgIcon className={classes.feedbackIcon}>
          <path d={SgvPathIcons.SVG_PATH_CAMPAIGN_ICON} />
        </SvgIcon>
      </IconButton>
    </Tooltip>
  );
};
