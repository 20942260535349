export const workspaceConstants = {
  CREATE_PART_REQUEST: "CREATE_PART_REQUEST",
  CREATE_PART_SUCCESS: "CREATE_PART_SUCCESS",
  CREATE_PART_FAILURE: "CREATE_PART_FAILURE",

  FETCH_COMPANY_PARTS_REQUEST: "FETCH_COMPANY_PARTS_REQUEST",
  FETCH_COMPANY_PARTS_SUCCESS: "FETCH_COMPANY_PARTS_SUCCESS",
  FETCH_COMPANY_PARTS_FAILURE: "FETCH_COMPANY_PARTS_FAILURE",

  FETCH_COMPANY_PARTS_NEXT_REQUEST: "FETCH_COMPANY_PARTS_NEXT_REQUEST",
  FETCH_COMPANY_PARTS_NEXT_SUCCESS: "FETCH_COMPANY_PARTS_NEXT_SUCCESS",
  FETCH_COMPANY_PARTS_NEXT_FAILURE: "FETCH_COMPANY_PARTS_NEXT_FAILURE",

  FETCH_COMPANY_RFQLIST_REQUEST: "FETCH_COMPANY_RFQLIST_REQUEST",
  FETCH_COMPANY_RFQLIST_SUCCESS: "FETCH_COMPANY_RFQLIST_SUCCESS",
  FETCH_COMPANY_RFQLIST_FAILURE: "FETCH_COMPANY_RFQLIST_FAILURE",

  FETCH_RFQ_DETAILS_REQUEST: "FETCH_RFQ_DETAILS_REQUEST",
  FETCH_RFQ_DETAILS_SUCCESS: "FETCH_RFQ_DETAILS_SUCCESS",
  FETCH_RFQ_DETAILS_FAILURE: "FETCH_RFQ_DETAILS_FAILURE",

  FETCH_CONTRACT_DETAILS_REQUEST: "FETCH_CONTRACT_DETAILS_REQUEST",
  FETCH_CONTRACT_DETAILS_SUCCESS: "FETCH_CONTRACT_DETAILS_SUCCESS",
  FETCH_CONTRACT_DETAILS_FAILURE: "FETCH_CONTRACT_DETAILS_FAILURE",

  FETCH_CAD_FILES_REQUEST: "FETCH_CAD_FILES_REQUEST",
  FETCH_CAD_FILES_SUCCESS: "FETCH_CAD_FILES_SUCCESS",
  FETCH_CAD_FILES_FAILURE: "FETCH_CADD_FILES_FAILURE",

  DELETE_CAD_FILE_REQUEST: "DELETE_CAD_FILE_REQUEST",
  DELETE_CAD_FILE_SUCCESS: "DELETE_CAD_FILE_SUCCESS",
  DELETE_CAD_FILE_FAILURE: "DELETE_CAD_FILE_FAILURE",

  RFQ_REQUEST: "RFQ_REQUEST",
  RFQ_SUCCESS: "RFQ_SUCCESS",
  RFQ_FAILURE: "RFQ_FAILURE",

  EDIT_PART_REQUEST: "EDIT_PART_REQUEST",
  EDIT_PART_SUCCESS: "EDIT_PART_SUCCESS",
  EDIT_PART_FAILURE: "EDIT_PART_FAILURE",

  SELECT_WINNER_REQUEST: "SELECT_WINNER_REQUEST",
  SELECT_WINNER_SUCCESS: "SELECT_WINNER_SUCCESS",
  SELECT_WINNER_FAILURE: "SELECT_WINNER_FAILURE",

  DELETE_PART_REQUEST: "DELETE_PART_REQUEST",
  DELETE_PART_SUCCESS: "DELETE_PART_SUCCESS",
  DELETE_PART_FAILURE: "DELETE_PART_SUCCESS",

  GET_PART_BY_ID_REQUEST: "GET_PART_BY_ID_REQUEST",
  GET_PART_BY_ID_SUCCESS: "GET_PART_BY_ID_SUCCESS",
  GET_PART_BY_ID_FAILURE: "GET_PART_BY_ID_FAILURE",

  CLONE_PART_REQUEST: "CLONE_PART_REQUEST",
  CLONE_PART_SUCCESS: "CLONE_PART_SUCCESS",
  CLONE_PART_FAILURE: "CLONE_PART_FAILURE",

  FETCH_BUYER_CONTRACTS_REQUEST: "FETCH_BUYER_CONTRACTS_REQUEST",
  FETCH_BUYER_CONTRACTS_SUCCESS: "FETCH_BUYER_CONTRACTS_SUCCESS",
  FETCH_BUYER_CONTRACTS_FAILURE: "FETCH_BUYER_CONTRACTS_FAILURE",

  PATCH_PURCHASE_ORDER_REQUEST: "PATCH_PURCHASE_ORDER_REQUEST",
  PATCH_PURCHASE_ORDER_SUCCESS: "PATCH_PURCHASE_ORDER_SUCCESS",
  PATCH_PURCHASE_ORDER_FAILURE: "PATCH_PURCHASE_ORDER_FAILURE",

  SET_SELECTED_TAB_IN_QUOTATIONS: "SET_SELECTED_TAB_IN_QUOTATIONS",

  RFQ_CANCELLATION_AND_EXTENSION_REQUEST:
    "RFQ_CANCELLATION_AND_EXTENSION_REQUEST",
  RFQ_CANCELLATION_AND_EXTENSION_SUCCESS:
    "RFQ_CANCELLATION_AND_EXTENSION_SUCCESS",
  RFQ_CANCELLATION_AND_EXTENSION_FAILURE:
    "RFQ_CANCELLATION_AND_EXTENSION_FAILURE",

  SET_RFQ_GENERAL_INFORMATION: "SET_RFQ_GENERAL_INFORMATION",
  SET_RFQ_ACTIVE_STEP: "SET_RFQ_ACTIVE_STEP",
  SET_SELECTED_PARTS: "SET_SELECTED_PARTS",

  CREATE_PART_TAGS_REQUEST: "CREATE_PART_TAGS_REQUEST",
  CREATE_PART_TAGS_SUCCESS: "CREATE_PART_TAGS_SUCCESS",
  CREATE_PART_TAGS_FAILURE: "CREATE_PART_TAGS_SUCCESS",

  GET_All_TAGS_CREATED_BY_CURRENT_COMPANY_REQUEST:
    "GET_All_TAGS_CREATED_BY_CURRENT_COMPANY_REQUEST",
  GET_All_TAGS_CREATED_BY_CURRENT_COMPANY_SUCCESS:
    "GET_All_TAGS_CREATED_BY_CURRENT_COMPANY_SUCCESS",
  GET_All_TAGS_CREATED_BY_CURRENT_COMPANY_FAILURE:
    "GET_All_TAGS_CREATED_BY_CURRENT_COMPANY_SUCCESS",

  SET_BUYER_NDA_ATTACHMENTS: "SET_BUYER_NDA_ATTACHMENTS",
  SET_BUYER_NDA_ATTACHMENTS: "SET_BUYER_NDA_ATTACHMENTS",

  GET_TAGS_LIST_REQUEST: "GET_TAGS_LIST_REQUEST",
  GET_TAGS_LIST_SUCCESS: "GET_TAGS_LIST_SUCCESS",
  GET_TAGS_LIST_FAILURE: "GET_TAGS_LIST_FAILURE",

  GET_SIGNED_NDA_LIST_REQUEST: "GET_SIGNED_NDA_LIST_REQUEST",
  GET_SIGNED_NDA_LIST_SUCCESS: "GET_SIGNED_NDA_LIST_SUCCESS",
  GET_SIGNED_NDA_LIST_FAILURE: "GET_SIGNED_NDA_LIST_FAILURE",

  SET_SELECTED_TAGS: "SET_SELECTED_TAGS",
  SET_BUYER_QUOTATIONS_TABS: "SET_BUYER_QUOTATIONS_TABS",

  GET_ALL_SIGNED_NDAS_REQUEST: "GET_ALL_SIGNED_NDAS_REQUEST",
  GET_ALL_SIGNED_NDAS_SUCCESS: "GET_ALL_SIGNED_NDAS_SUCCESS",
  GET_ALL_SIGNED_NDAS_FAILURE: "GET_ALL_SIGNED_NDAS_FAILURE",

  TOGGLE_NDA_APPROVE_REQUEST: "TOGGLE_NDA_APPROVE_REQUEST",
  TOGGLE_NDA_APPROVE_SUCCESS: "TOGGLE_NDA_APPROVE_SUCCESS",
  TOGGLE_NDA_APPROVE_FAILURE: "TOGGLE_NDA_APPROVE_FAILURE",

  SET_RECIPIENTS_LIST: "SET_RECIPIENTS_LIST",

  CLEAR_RFQ_TOUR_MOCK_IN_RFQLISTOFCOMPANY:
    "CLEAR_RFQ_TOUR_MOCK_IN_RFQLISTOFCOMPANY",
  SET_CONGRATULATION_DETAILS: "SET_CONGRATULATION_DETAILS",

  UPLOAD_RFQ_ATTACHMENT_REQUEST: "UPLOAD_RFQ_ATTACHMENT_REQUEST",
  UPLOAD_RFQ_ATTACHMENT_SUCCESS: "UPLOAD_RFQ_ATTACHMENT_SUCCESS",
  UPLOAD_RFQ_ATTACHMENT_FAILURE: "UPLOAD_RFQ_ATTACHMENT_FAILURE",

  DECREMENT_RFQ_NEW_QA_MSG_COUNT: "DECREMENT_RFQ_NEW_QA_MSG_COUNT",

  RFQ_UPDATE_PARTIAL_REQUEST: "RFQ_UPDATE_PARTIAL_REQUEST",
  RFQ_UPDATE_PARTIAL_SUCCESS: "RFQ_UPDATE_PARTIAL_SUCCESS",
  RFQ_UPDATE_PARTIAL_FAILURE: "RFQ_UPDATE_PARTIAL_FAILURE",

  RESET_PARTS_SELECTION: "RESET_PARTS_SELECTION",
  SET_PART_DETAILS: "SET_PART_DETAILS",

  SET_RFQ_DETAILS: "SET_RFQ_DETAILS",
  UPDATE_RFQ_DETAILS: "UPDATE_RFQ_DETAILS",

  FETCH_RFQ_PARTS_REQUEST: "FETCH_RFQ_PARTS_REQUEST",
  FETCH_RFQ_PARTS_SUCCESS: "FETCH_RFQ_PARTS_SUCCESS",
  FETCH_RFQ_PARTS_FAILURE: "FETCH_RFQ_PARTS_FAILURE",

  UPDATE_RFQ_TARGETED_PROFILES_SUCCESS: "UPDATE_RFQ_TARGETED_PROFILES_SUCCESS",
};
