import React, { useState } from "react";

import {
  APP_ENVIRONMENT,
  checkAppEnvironmentIs,
  getAppEnvironmentOverrides,
} from "@next/utils/constantUtils";

const isProdEnvironment = checkAppEnvironmentIs([APP_ENVIRONMENT.PRODUCTION]);
const envOverrides = getAppEnvironmentOverrides();

export default function OverridesBar() {
  const [isVisible, setIsVisible] = useState<boolean>(true);

  const handleToggleVisibility = (): void => {
    setIsVisible(!isVisible);
  };

  return (
    <>
      {!isProdEnvironment && Object.keys(envOverrides).length > 0 && isVisible && (
        <div
          style={{
            background: "rgb(224 224 224)",
            color: "black",
            position: "absolute",
            zIndex: 10000,
            top: 0,
            left: 0,
            borderRadius: "0 0 5px 0",
            paddingLeft: 10,
            paddingRight: 10,
          }}
        >
          VARIABLES OVERRIDE: {"  "}
          {Object.keys(envOverrides).map((key) => (
            <>
              {envOverrides[key] && (
                <span key={key}>
                  <strong>{key}</strong>:{" "}
                  {envOverrides[key].includes("app.axya.co") ? (
                    <span
                      style={{ color: "white", background: "red", padding: 6 }}
                    >
                      <span role="img" aria-label="warning">
                        ⚠️⚠️
                      </span>
                      {envOverrides[key]}
                    </span>
                  ) : (
                    envOverrides[key]
                  )}
                </span>
              )}
            </>
          ))}
          <button
            style={{ padding: 2, margin: 2 }}
            onClick={handleToggleVisibility}
          >
            Hide
          </button>
        </div>
      )}
    </>
  );
}
